import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Cara Bermain" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Kamu mempunyai enam (6) percubaan. Selepas meneka perkataan, warna Jubin akan berubah mengikut aturan abjad. 
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="M" />
        <Cell value="P" />
        <Cell value="U" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Abjad L ada di dalam perkataan dan berada di aturan yang betul.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="E" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="T"
          status="present"
        />
        <Cell value="I" />
        <Cell value="K" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Abjad T ada di dalam perkataan tapi aturan yang salah.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="H" />
        <Cell value="I" />
        <Cell value="J" />
        <Cell isRevealing={true} isCompleted={true} value="A" status="absent" />
        <Cell value="U" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Abjad A tiada dalam perkataan.
      </p>
    </BaseModal>
  )
}
