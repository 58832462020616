export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!
export const URL = process.env.REACT_APP_URL

export const WIN_MESSAGES = ['Bagus!', 'Pandai!', 'Hebat!']
export const GAME_COPIED_MESSAGE = 'Permainan telah disalin.'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Tak cukup abjad.'
export const WORD_NOT_FOUND_MESSAGE = 'Tak jumpa perkataan ini.'
export const HARD_MODE_ALERT_MESSAGE =
  'Hard Mode can only be enabled at the start!'
export const HARD_MODE_DESCRIPTION =
  'Any revealed hints must be used in subsequent guesses'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Perkataan betul adalah ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Mesti guna ${guess} di ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Tekaan mesti mengandungi ${letter}`
export const ENTER_TEXT = 'Hantar'
export const DELETE_TEXT = 'Padam'
export const STATISTICS_TITLE = 'Statistik'
export const GUESS_DISTRIBUTION_TEXT = 'Prestasi'
export const NEW_WORD_TEXT = 'Perkataan baru dalam'
export const SHARE_TEXT = 'Kongsi'
export const TOTAL_TRIES_TEXT = 'Percubaan'
export const SUCCESS_RATE_TEXT = 'Kadar Berjaya'
export const CURRENT_STREAK_TEXT = 'Kombo Sekarang'
export const BEST_STREAK_TEXT = 'Kombo Terbaik'
