export const VALID_GUESSES = [
  'abad',
  'abadi',
  'abadikan',
  'keabadian',
  'pengabadian',
  'abah',
  'mengabah',
  'abai',
  'pengabai',
  'abaikan',
  'abaimana',
  'abaka',
  'abang',
  'berabang',
  'abar',
  'abau',
  'abdas',
  'abdi',
  'abdikan',
  'pengabdian',
  'abdiad',
  'abdiaz',
  'abdikasi',
  'abdomen',
  'abese',
  'abet',
  'abid',
  'abidin',
  'abilah',
  'abintara',
  'abiogenesis',
  'abjad',
  'mengabjadkan',
  'abnormal',
  'abnus',
  'aboi',
  'abon',
  'abonemen',
  'abrak',
  'abrar',
  'abras',
  'abrit',
  'absah',
  'mengabsahkan',
  'absen',
  'abses',
  'absolusi',
  'abstrak',
  'mengabstrakkan',
  'abstraksi',
  'absurd',
  'abtar',
  'abu',
  'mengabu',
  'abuan',
  'abuh',
  'abuk',
  'abun',
  'abung',
  'abur',
  'abus',
  'abuya',
  'acah',
  'acahan',
  'acak',
  'acan',
  'acang',
  'acap',
  'acapkali',
  'acar',
  'acara',
  'acaranya',
  'acarakan',
  'pengacaraan',
  'acaram',
  'acau',
  'aci',
  'mengaci',
  'acik',
  'acita',
  'acu',
  'acukan',
  'acuan',
  'acuh',
  'mengacuhkan',
  'acum',
  'acuman',
  'acung',
  'mengacung',
  'ada',
  'adapun',
  'berada',
  'diada',
  'mengada',
  'keadaan',
  'keadaannya',
  'seada',
  'adab',
  'peradaban',
  'memperadapkan',
  'adad',
  'adai',
  'adakala',
  'adalat',
  'adam',
  'adan',
  'adang',
  'adangkan',
  'pengadangan',
  'adap',
  'adaptasi',
  'mengadap',
  'adar',
  'adas',
  'adat',
  'adatkan',
  'adawat',
  'adegan',
  'adem',
  'adhesi',
  'adi',
  'adiabatik',
  'adib',
  'adicita',
  'adik',
  'beradik',
  'memperadik',
  'adika',
  'adikara',
  'adikong',
  'adikuasa',
  'adikudrati',
  'adil',
  'adili',
  'adilkan',
  'pengadilan',
  'keadilan',
  'adinda',
  'ading',
  'adiningrat',
  'adipati',
  'adipos',
  'adiposuria',
  'adipsia',
  'adiraja',
  'adiratna',
  'adisejuk',
  'adiwangsa',
  'adiwarna',
  'adjektif',
  'administrasi',
  'administratif',
  'administrator',
  'admiral',
  'adoi',
  'adopsi',
  'adpertensi',
  'adpis',
  'adpisir',
  'adrenal',
  'adrenalin',
  'adrenosklerosis',
  'adres',
  'adu',
  'adunya',
  'adukan',
  'aduan',
  'aduh',
  'aduhai',
  'aduk',
  'adukkan',
  'adun',
  'adunkan',
  'adunan',
  'adun',
  'adverba',
  'advertensi',
  'advis',
  'advokat',
  'aedes',
  'aerial',
  'aeroangkasa',
  'aerob',
  'aerobatik',
  'aerobik',
  'aerodinamik',
  'aerogram',
  'aerometer',
  'aeronautik',
  'aerosol',
  'aerotaksis',
  'aerotropisme',
  'afal',
  'afasia',
  'afdal',
  'afdeling',
  'afiat',
  'afidavit',
  'afiks',
  'afiliasi',
  'afiniti',
  'aforisme',
  'afrika',
  'afrit',
  'afta',
  'afwah',
  'aga',
  'mengagakan',
  'agah',
  'agak',
  'agaknya',
  'agakkan',
  'agal',
  'agam',
  'agama',
  'beragama',
  'seagama',
  'keagamaan',
  'agamani',
  'agan',
  'agar',
  'agas',
  'agen',
  'agenda',
  'agensi',
  'aghlaf',
  'agih',
  'agihnya',
  'agihkan',
  'agihan',
  'aging',
  'agitasi',
  'agitator',
  'aglosia',
  'agnostik',
  'agong',
  'agorafobia',
  'agraria',
  'agraris',
  'agregat',
  'agresi',
  'agresif',
  'agresor',
  'agrokimia',
  'agronomi',
  'agroteknologi',
  'aguhuselesslagah',
  'aguk',
  'agul',
  'agun',
  'agung',
  'agungnya',
  'agungkan',
  'keagungan',
  'agus',
  'agut',
  'ahad',
  'ahad',
  'ahadiat',
  'ahai',
  'ahem',
  'ahkam',
  'ahlan',
  'ahli',
  'keahlian',
  'ahmak',
  'ahmar',
  'ahram',
  'aib',
  'aibkan',
  'keaiban',
  'aidiladha',
  'aidilfitri',
  'aids',
  'aikido',
  'ain',
  'ainulbanat',
  'air',
  'mengair',
  'pengairan',
  'perairan',
  'ais',
  'aising',
  'aiskrim',
  'aiwan',
  'aiya',
  'aja',
  'ajab',
  'ajaib',
  'mengajaibkan',
  'keajaiban',
  'ajak',
  'ajakan',
  'ajal',
  'ajam',
  'ajami',
  'ajan',
  'ajar',
  'ajarkan',
  'ajari',
  'ajarnya',
  'ajaran',
  'belajar',
  'pelajar',
  'berpelajaran',
  'pembelajaran',
  'terpelajar',
  'pelajari',
  'ajeh',
  'ajek',
  'ajeng',
  'ajengan',
  'aji',
  'ajir',
  'ajnabi',
  'ajnas',
  'ajrih',
  'aju',
  'ajukan',
  'ajuk',
  'ajukan',
  'ajun',
  'ajung',
  'ajutan',
  'akad',
  'akadnikahkan',
  'akademi',
  'akademik',
  'akademikus',
  'akademis',
  'akah',
  'akaid',
  'akak',
  'akal',
  'akalnya',
  'akalku',
  'mengakali',
  'diakali',
  'akan',
  'akar',
  'akarnya',
  'pengakaran',
  'akaun',
  'akauntan',
  'perakaunan',
  'akbar',
  'akhbar',
  'akhir',
  'akhiran',
  'akhiri',
  'akhirat',
  'akhlak',
  'akhlaknya',
  'akibat',
  'akibatkan',
  'akidah',
  'akidahnya',
  'akikah',
  'mengakikahkan',
  'akil',
  'akit',
  'aklamasi',
  'akliah',
  'akmal',
  'akolia',
  'akoluria',
  'akomodasi',
  'akordion',
  'akrab',
  'mengakrabkan',
  'keakraban',
  'akreditasi',
  'akrilik',
  'akrobat',
  'akrobatik',
  'akrofobia',
  'akronim',
  'akru',
  'aksam',
  'aksara',
  'aksen',
  'aksep',
  'akses',
  'aksesori',
  'aksi',
  'aksinya',
  'aksiom',
  'aksomiset',
  'akta',
  'aktentas',
  'aktif',
  'aktifkan',
  'pengaktifan',
  'keaktifan',
  'aktinium',
  'aktinoterapi',
  'aktivis',
  'aktivisme',
  'aktivitet',
  'aktiviti',
  'aktor',
  'aktres',
  'aku',
  'mengaku',
  'akui',
  'memperakukan',
  'diperakukan',
  'akuan',
  'akujanji',
  'akur',
  'diakuri',
  'mengakuri',
  'keakuran',
  'aktual',
  'aktuari',
  'aktuil',
  'akua',
  'akuakultur',
  'akuarium',
  'akuatik',
  'akueus',
  'akuk',
  'akulturasi',
  'akumulator',
  'akupunktur',
  'akus',
  'akustik',
  'akut',
  'akwal',
  'al',
  'ala',
  'alabangka',
  'alaf',
  'alah',
  'mengalah',
  'alahan',
  'alahai',
  'alai',
  'alaihi',
  'alaihissalam',
  'alaika',
  'alaikum',
  'alak',
  'alam',
  'alami',
  'pengalaman',
  'alamak',
  'alamang',
  'alamar',
  'alamas',
  'alamat',
  'alamatkan',
  'alamatnya',
  'alamatulhayat',
  'alamiah',
  'alamin',
  'alan',
  'alang',
  'alap',
  'alar',
  'alarem',
  'alas',
  'alasan',
  'alasannya',
  'alat',
  'alatnya',
  'peralat',
  'alatan',
  'alatulis',
  'alau',
  'albino',
  'albion',
  'album',
  'albumen',
  'albumin',
  'aldehid',
  'alegori',
  'alem',
  'alergi',
  'alfa',
  'alfabet',
  'aluselessfatihah',
  'alferes',
  'alfiah',
  'alga',
  'algebra',
  'algojo',
  'algoritma',
  'alhamdulillah',
  'alhasil',
  'ali',
  'alias',
  'alibi',
  'alif',
  'alifatik',
  'alih',
  'alihkan',
  'alihan',
  'alihnya',
  'alibaba',
  'alik',
  'alim',
  'kealiman',
  'alimbubu',
  'alimiat',
  'alimoni',
  'alin',
  'alinea',
  'aling',
  'alip',
  'alir',
  'alirkan',
  'dialiri',
  'mengalir',
  'aliran',
  'alis',
  'alit',
  'aliterasi',
  'aljabar',
  'alkah',
  'alkali',
  'alkaloid',
  'alkamar',
  'alkana',
  'alkari',
  'alkasyaf',
  'alkatipa',
  'alkena',
  'alketip',
  'alkimi',
  'alkimia',
  'alkisah',
  'alkohol',
  'alkoholuria',
  'alkonya',
  'alku',
  'alkuna',
  'allah',
  'allahuakbar',
  'allahumma',
  'allahyarham',
  'allahyarhamah',
  'allamah',
  'alma',
  'almaktub',
  'almalun',
  'almanak',
  'almarhum',
  'almarhumah',
  'almari',
  'almas',
  'aluselessmasih',
  'almukhalis',
  'aloi',
  'alokasi',
  'alon',
  'along',
  'alongan',
  'alopesia',
  'alot',
  'alotrop',
  'alotropi',
  'alpa',
  'kealpaan',
  'alpeniti',
  'alperes',
  'alpukah',
  'alquran',
  'altar',
  'alternatif',
  'alti',
  'altimeter',
  'altitud',
  'altruisme',
  'alu',
  'aluan',
  'alukan',
  'alum',
  'alumina',
  'aluminium',
  'alun',
  'alunkan',
  'alunan',
  'alung',
  'alur',
  'aluran',
  'alusi',
  'alusmitai',
  'alwah',
  'am',
  'amah',
  'amal',
  'amalan',
  'amalkan',
  'amalgam',
  'amali',
  'amaliah',
  'aman',
  'mengamankan',
  'keamanan',
  'pengaman',
  'amanah',
  'amanahkan',
  'amanat',
  'amanatnya',
  'amanatkan',
  'amang',
  'amangku',
  'amangkurat',
  'amansari',
  'amanuensis',
  'amar',
  'amarah',
  'amat',
  'amati',
  'amatkan',
  'amatlah',
  'pengamatan',
  'amatur',
  'amaun',
  'ambah',
  'ambai',
  'ambak',
  'ambal',
  'ambalan',
  'ambalela',
  'ambang',
  'ambar',
  'ambasador',
  'ambat',
  'ambau',
  'ambek',
  'ambelar',
  'ambelas',
  'ambeles',
  'ambelu',
  'amben',
  'amberal',
  'amberol',
  'amberuk',
  'ambet',
  'ambi',
  'ambien',
  'ambiguiti',
  'ambil',
  'ambilkan',
  'ambilan',
  'pengambilalihan',
  'ambin',
  'ambing',
  'ambisi',
  'ambivalen',
  'amboi',
  'ambon',
  'ambon',
  'ambring',
  'ambu',
  'ambul',
  'ambulans',
  'ambung',
  'ambungkan',
  'ambur',
  'ameba',
  'amen',
  'amendmen',
  'amenorea',
  'amerika',
  'amerikanisme',
  'amerisium',
  'amfibia',
  'amfiun',
  'amfoterik',
  'amil',
  'amilase',
  'amin',
  'aminkan',
  'amir',
  'amirulbahar',
  'amirulhaj',
  'amirulmukminin',
  'amit',
  'amko',
  'amma',
  'ammabakdahu',
  'ammabakdu',
  'ammeter',
  'ammi',
  'ammonia',
  'auselessmuselessnuseless',
  'amnesia',
  'amnesti',
  'amoi',
  'amor',
  'amorfus',
  'ampai',
  'mengampai',
  'ampaian',
  'ampama',
  'ampamar',
  'ampang',
  'ampe',
  'ampean',
  'ampek',
  'ampel',
  'ampela',
  'ampere',
  'ampit',
  'amplifier',
  'amplop',
  'ampo',
  'ampu',
  'mengampu',
  'ampuh',
  'mengampuhkan',
  'keampuhan',
  'ampuk',
  'ampul',
  'ampun',
  'ampunkan',
  'ampuni',
  'ampunan',
  'pengampun',
  'amput',
  'amra',
  'amril',
  'amsal',
  'amtenar',
  'amuh',
  'amuk',
  'mengamuk',
  'beramuk',
  'amung',
  'ana',
  'anaam',
  'anaerobik',
  'anafora',
  'anagogi',
  'anagram',
  'anai',
  'anak',
  'beranak',
  'anakan',
  'memperanakkan',
  'diperanakkan',
  'keanak',
  'analisa',
  'analisis',
  'analogi',
  'analogus',
  'ananas',
  'anang',
  'anani',
  'anarki',
  'anarkis',
  'anarkisme',
  'anasir',
  'anatomi',
  'anbia',
  'anca',
  'ancak',
  'ancala',
  'ancam',
  'mengancamkan',
  'ancaman',
  'ancang',
  'ancer',
  'ancoa',
  'ancu',
  'ancuk',
  'ancung',
  'anda',
  'andai',
  'andaikan',
  'andainya',
  'andaian',
  'andaikata',
  'andaipun',
  'andak',
  'andaka',
  'andal',
  'andalas',
  'andam',
  'andaman',
  'andan',
  'andang',
  'andapita',
  'andar',
  'andartu',
  'andas',
  'mengandaskan',
  'andeka',
  'anderak',
  'anderiguru',
  'andewi',
  'andi',
  'andiko',
  'andil',
  'andilau',
  'anding',
  'andong',
  'androgen',
  'anduh',
  'anduhan',
  'menganduh',
  'anduk',
  'andun',
  'andung',
  'andur',
  'aneh',
  'keanehan',
  'menganehkan',
  'aneka',
  'anekdot',
  'anemer',
  'anemia',
  'anemometer',
  'anerob',
  'anestesia',
  'anestetik',
  'anfas',
  'ang',
  'angah',
  'angan',
  'angankan',
  'angannya',
  'anggap',
  'anggapan',
  'beranggap',
  'anggaplah',
  'angga',
  'anggabaya',
  'anggai',
  'anggak',
  'anggal',
  'anggan',
  'anggar',
  'anggarkan',
  'anggaran',
  'anggerik',
  'anggerka',
  'anggit',
  'angglap',
  'anggota',
  'anggotanya',
  'dianggotai',
  'menganggotai',
  'keanggotaan',
  'angguk',
  'anggukan',
  'anggukkan',
  'anggun',
  'keanggunan',
  'anggung',
  'anggup',
  'anggur',
  'penganggur',
  'anggut',
  'anghun',
  'angin',
  'anginkan',
  'peranginan',
  'angka',
  'angkanya',
  'angkaan',
  'mengangka',
  'angkara',
  'angkasa',
  'angkasawan',
  'angkat',
  'angkatkan',
  'angkatan',
  'angkatannya',
  'angkatnya',
  'angkatlah',
  'perangkat',
  'keberangkatan',
  'diberangkatkan',
  'memberangkatkan',
  'angkit',
  'angklung',
  'angkor',
  'angkuh',
  'keangkuhan',
  'angkuk',
  'angkul',
  'angkung',
  'angkup',
  'angkus',
  'angkusa',
  'angkut',
  'angkutan',
  'anglap',
  'anglo',
  'anglong',
  'anglung',
  'angon',
  'angot',
  'angpau',
  'angsa',
  'angsal',
  'angsama',
  'angsana',
  'angsoka',
  'angun',
  'angur',
  'angut',
  'anhu',
  'ani',
  'aniaya',
  'dianiaya',
  'menganiaya',
  'penganiaya',
  'animasi',
  'animis',
  'animisme',
  'animo',
  'aning',
  'anion',
  'anis',
  'anja',
  'anjak',
  'anjakan',
  'anjakkan',
  'anjal',
  'keanjalan',
  'anjang',
  'anjar',
  'anjiman',
  'anjing',
  'anjir',
  'anju',
  'anjung',
  'anjungkan',
  'anjungan',
  'anjur',
  'penganjur',
  'anjurkan',
  'anjuran',
  'anoa',
  'anod',
  'anok',
  'anoksemia',
  'anom',
  'anomali',
  'anonim',
  'anoreksia',
  'anorkisme',
  'anosmia',
  'anotasi',
  'ansar',
  'ansur',
  'ansuran',
  'mengansur',
  'anta',
  'antaboga',
  'antagonis',
  'antagonisme',
  'antagonistik',
  'antah',
  'antakesuma',
  'antan',
  'antap',
  'antar',
  'antara',
  'antaranya',
  'pengantara',
  'perantara',
  'mengantara',
  'antarabangsa',
  'antari',
  'antartika',
  'antasid',
  'antat',
  'anteken',
  'antelas',
  'anten',
  'antena',
  'antenatal',
  'anteng',
  'antep',
  'anter',
  'anterior',
  'antero',
  'anti',
  'antiagama',
  'antiaparteid',
  'antibiotik',
  'antibodi',
  'antidadah',
  'antidepresan',
  'antidiuretik',
  'antidot',
  'antiemetik',
  'antigen',
  'antih',
  'antijenayah',
  'antik',
  'keantikan',
  'antikarat',
  'antikedut',
  'antikeganasan',
  'antikerajaan',
  'antiklimaks',
  'antimoni',
  'antinasional',
  'anting',
  'antipati',
  'antiplot',
  'antipode',
  'antiradang',
  'antirasuah',
  'antiseptik',
  'antisilau',
  'antisipasi',
  'antisosial',
  'antitesis',
  'antitoksin',
  'antologi',
  'antonim',
  'antraks',
  'antri',
  'antropologi',
  'antropomorfisme',
  'antui',
  'antukar',
  'antul',
  'antun',
  'antung',
  'antup',
  'anugerah',
  'penganugerahan',
  'anugerahkan',
  'dianugerahi',
  'menganugerahi',
  'anuiti',
  'anum',
  'anumerta',
  'anut',
  'anuti',
  'penganut',
  'anutan',
  'anyal',
  'anyam',
  'anyamkan',
  'anyaman',
  'anyang',
  'anyar',
  'anyelir',
  'anyih',
  'anyur',
  'apa',
  'mengapa',
  'mempengapakan',
  'diapa',
  'berapa',
  'apabila',
  'apahal',
  'apakala',
  'apalagi',
  'apam',
  'apar',
  'aparteid',
  'apartmen',
  'apas',
  'apatah',
  'apati',
  'apek',
  'apel',
  'apendiks',
  'apendisitis',
  'api',
  'perapian',
  'apikan',
  'apinya',
  'apik',
  'apilan',
  'aping',
  'apion',
  'apit',
  'apitan',
  'memperapitkan',
  'apkir',
  'aplikasi',
  'aplikasikan',
  'mengaplikasikannya',
  'aplus',
  'apnea',
  'apodia',
  'apoi',
  'apologetik',
  'apopleksi',
  'apotek',
  'apresiasi',
  'apri',
  'aprikot',
  'april',
  'aprit',
  'apron',
  'apum',
  'apung',
  'apungan',
  'apungkan',
  'apuran',
  'apus',
  'aquarius',
  'ara',
  'arab',
  'aragonit',
  'arah',
  'arahan',
  'arahkan',
  'arahnya',
  'arafah',
  'arai',
  'arak',
  'araklah',
  'arakan',
  'arakian',
  'aral',
  'aram',
  'arang',
  'mengarang',
  'pengarangan',
  'ararut',
  'aras',
  'arasy',
  'arau',
  'araz',
  'arbaa',
  'arbab',
  'arbei',
  'arbitraj',
  'arbitrari',
  'arboretum',
  'arca',
  'mengarcakan',
  'ardi',
  'are',
  'arek',
  'aren',
  'arena',
  'ares',
  'aretasi',
  'argari',
  'argentum',
  'argon',
  'argus',
  'ari',
  'aria',
  'aries',
  'arif',
  'mengarifkan',
  'kearifan',
  'arifin',
  'arik',
  'aring',
  'ariningsun',
  'arip',
  'aris',
  'arisan',
  'aristokrasi',
  'aristokrat',
  'arit',
  'aritmetik',
  'arjuna',
  'arkaik',
  'arkaisme',
  'arkan',
  'arked',
  'arkeologi',
  'arkian',
  'arkib',
  'arkitek',
  'arloji',
  'armada',
  'arnab',
  'arnal',
  'aroma',
  'aromatik',
  'arombai',
  'arpus',
  'arsenik',
  'arteri',
  'arteriol',
  'arteriosklerosis',
  'artifak',
  'artifisial',
  'artik',
  'artikel',
  'artikulasi',
  'artileri',
  'artis',
  'artistik',
  'artritis',
  'aru',
  'aruda',
  'aruh',
  'aruk',
  'arun',
  'arung',
  'arus',
  'arusnya',
  'arwah',
  'mengarwahkan',
  'asa',
  'mengasa',
  'asabah',
  'asabat',
  'asad',
  'asah',
  'asahan',
  'asai',
  'asak',
  'mengasak',
  'asakan',
  'asal',
  'berasal',
  'asam',
  'seasam',
  'mengasam',
  'asang',
  'asap',
  'mengasap',
  'perasap',
  'asar',
  'asas',
  'berasas',
  'diasaskan',
  'mengasaskan',
  'asasi',
  'asb',
  'asbab',
  'asbak',
  'asbestos',
  'asbut',
  'ase',
  'asean',
  'asean',
  'asep',
  'aseran',
  'asese',
  'aset',
  'asetaldehid',
  'asetilena',
  'aseton',
  'asfal',
  'asfalt',
  'asfar',
  'ashab',
  'asi',
  'asia',
  'asid',
  'mengasid',
  'keasidan',
  'pengasidan',
  'asik',
  'asimetri',
  'asimilasi',
  'mengasimilasikan',
  'asin',
  'asindeton',
  'asing',
  'asingkan',
  'asingan',
  'pengasingan',
  'asisten',
  'askar',
  'asli',
  'keaslian',
  'asma',
  'asmara',
  'asmaradana',
  'asmaradanta',
  'asmaraloka',
  'asn',
  'asnad',
  'aso',
  'asok',
  'asonansi',
  'asor',
  'asosiasi',
  'asparagus',
  'aspek',
  'aspero',
  'aspiran',
  'aspirasi',
  'aspirin',
  'asrama',
  'asrar',
  'assalamualaikum',
  'asta',
  'astaga',
  'astaghfirullah',
  'astagina',
  'astagmatisme',
  'astaka',
  'astakona',
  'astana',
  'astatin',
  'asterisk',
  'asteroid',
  'astringen',
  'astrofizik',
  'astrologi',
  'astronaut',
  'astronomi',
  'asu',
  'asuh',
  'asuhan',
  'asung',
  'asungan',
  'asuransi',
  'aswad',
  'asyhadu',
  'asyik',
  'mengasyikkan',
  'keasyikan',
  'asyikin',
  'asyura',
  'atak',
  'atap',
  'beratap',
  'mengatap',
  'atar',
  'atas',
  'atasnya',
  'atasi',
  'atasan',
  'mengataskan',
  'atase',
  'atau',
  'ataupun',
  'atawa',
  'ateis',
  'ateisme',
  'ateistik',
  'atendan',
  'ati',
  'atip',
  'atlas',
  'atlet',
  'atletik',
  'atlit',
  'atm',
  'atma',
  'atmometer',
  'atmosfera',
  'atol',
  'atom',
  'atop',
  'atos',
  'atribut',
  'atrium',
  'atuk',
  'atun',
  'atung',
  'atur',
  'aturnya',
  'aturkan',
  'aturan',
  'beratur',
  'berperaturan',
  'aubade',
  'audiens',
  'audio',
  'audiologi',
  'audiometer',
  'audiovisual',
  'audit',
  'pengauditan',
  'auditorium',
  'auksin',
  'aula',
  'aulia',
  'aum',
  'aung',
  'auns',
  'aup',
  'aur',
  'aura',
  'aurat',
  'aurum',
  'austronesia',
  'auta',
  'autarki',
  'autentik',
  'auto',
  'autobiografi',
  'autobis',
  'autogami',
  'autograf',
  'autoklaf',
  'autokrasi',
  'autokrat',
  'autokratik',
  'automasi',
  'automatif',
  'automatik',
  'automobil',
  'automotif',
  'autonom',
  'autonomi',
  'autoped',
  'autopsi',
  'autoritarian',
  'autoritatif',
  'autoriti',
  'auzubillah',
  'avisenian',
  'avokado',
  'awa',
  'awah',
  'awahama',
  'awai',
  'awak',
  'perawakan',
  'awal',
  'awalnya',
  'awalkan',
  'awalan',
  'awam',
  'awamnya',
  'mengawamkan',
  'awan',
  'awang',
  'awangan',
  'awar',
  'awas',
  'awaslah',
  'pengawas',
  'awasi',
  'awat',
  'awau',
  'awet',
  'diawet',
  'mengawet',
  'pengawetan',
  'awewe',
  'awewek',
  'awi',
  'awur',
  'ayah',
  'berayah',
  'ayahan',
  'ayahanda',
  'ayai',
  'ayak',
  'ayakan',
  'ayam',
  'ayan',
  'ayang',
  'ayap',
  'ayat',
  'ayeng',
  'ayir',
  'ayu',
  'keayuan',
  'ayuh',
  'ayuhai',
  'ayuk',
  'ayum',
  'ayun',
  'ayunkan',
  'ayunan',
  'ayunda',
  'ayut',
  'azab',
  'azabnya',
  'mengazabkan',
  'keazaban',
  'azabnya',
  'mengazab',
  'azal',
  'azali',
  'azam',
  'berazam',
  'keazaman',
  'azamat',
  'azan',
  'azim',
  'azimat',
  'azimut',
  'aziz',
  'azzawajalla',
  'baada',
  'bab',
  'baba',
  'babad',
  'babak',
  'membabak',
  'dibabakkan',
  'babal',
  'babang',
  'babar',
  'membabar',
  'pembabaran',
  'babas',
  'membabaskan',
  'babat',
  'babe',
  'babi',
  'babil',
  'babit',
  'babitkan',
  'pembabitan',
  'babon',
  'babu',
  'babuk',
  'babun',
  'babut',
  'baca',
  'dibaca',
  'membaca',
  'terbaca',
  'bacaan',
  'bacak',
  'bacang',
  'bacar',
  'bacin',
  'bacok',
  'bacokan',
  'bacuk',
  'bacul',
  'kebaculan',
  'bacut',
  'bad',
  'bada',
  'badai',
  'badak',
  'badal',
  'badam',
  'badan',
  'berbadan',
  'diperbadan',
  'memperbadan',
  'perbadanan',
  'badang',
  'badaniah',
  'badar',
  'badari',
  'badi',
  'badinya',
  'badik',
  'badminton',
  'baduk',
  'badut',
  'badwi',
  'bafta',
  'baftah',
  'bagai',
  'sebagai',
  'berbagai',
  'pelbagai',
  'kepelbagaian',
  'perbagai',
  'memperbagaikan',
  'bagaimana',
  'sebagaimana',
  'bagak',
  'bagal',
  'bagan',
  'bagang',
  'bagar',
  'bagas',
  'bagasi',
  'bagat',
  'bagau',
  'baghal',
  'baghi',
  'bagi',
  'baginya',
  'bagikan',
  'bagian',
  'bagiku',
  'bagimu',
  'bagih',
  'baginda',
  'bagis',
  'bagor',
  'baguh',
  'bagus',
  'kebagusan',
  'baguk',
  'bagul',
  'bagung',
  'bagur',
  'bah',
  'bahadi',
  'bahadur',
  'bahaduri',
  'bahagi',
  'bahagian',
  'bahagikan',
  'bahagia',
  'kebahagiaan',
  'membahagiakan',
  'bahak',
  'bahala',
  'bahalan',
  'baham',
  'bahan',
  'bahannya',
  'bahana',
  'bahang',
  'bahantara',
  'bahar',
  'bahara',
  'bahari',
  'baharu',
  'baharui',
  'pembaharu',
  'bahas',
  'bahaskan',
  'bahasan',
  'bahasa',
  'bahasanya',
  'bahasakan',
  'kebahasaan',
  'perbahasa',
  'bahau',
  'bahawa',
  'bahawasanya',
  'bahaya',
  'berbahaya',
  'bahayakan',
  'baheula',
  'bahkan',
  'bahlul',
  'bahrulhayat',
  'bahtera',
  'bahu',
  'membahu',
  'bahwa',
  'baiah',
  'baid',
  'baiduri',
  'baih',
  'baik',
  'berbaik',
  'sebaik',
  'terbaik',
  'pembaik',
  'kebaikan',
  'membaik',
  'dibaik',
  'baiki',
  'baikbiak',
  'dibaikpulih',
  'membaikpulih',
  'pembaikpulihan',
  'bailif',
  'bainah',
  'bainat',
  'bairup',
  'bait',
  'baitullah',
  'baitulmal',
  'baitulmuqaddis',
  'baja',
  'bajai',
  'pembajaan',
  'bajak',
  'pembajak',
  'bajang',
  'bajau',
  'bajau',
  'bajet',
  'baji',
  'bajik',
  'bajing',
  'baju',
  'berbaju',
  'bajul',
  'bak',
  'baka',
  'perbakaan',
  'bakai',
  'bakak',
  'bakal',
  'membakalkan',
  'bakam',
  'bakap',
  'bakar',
  'membakar',
  'kebakaran',
  'pembakar',
  'bakarat',
  'bakat',
  'bakatnya',
  'bakau',
  'bakawali',
  'bakbat',
  'bakca',
  'bakdul',
  'bakelit',
  'baken',
  'bakhil',
  'kebakhilan',
  'baki',
  'bakinya',
  'bakiak',
  'bakik',
  'bakir',
  'bakit',
  'bako',
  'baksi',
  'baksis',
  'baksut',
  'baktan',
  'bakteria',
  'bakteriofaj',
  'bakteriologi',
  'bakti',
  'membaktikan',
  'kebaktian',
  'baku',
  'bakukan',
  'pembakuan',
  'bakuh',
  'bakuk',
  'bakul',
  'membakulkan',
  'bakung',
  'bakup',
  'bal',
  'bala',
  'balad',
  'balada',
  'balaghah',
  'balah',
  'membalah',
  'perbalahan',
  'balai',
  'balairung',
  'balaisah',
  'balak',
  'pembalak',
  'balalaika',
  'balam',
  'balan',
  'balang',
  'balap',
  'balar',
  'balas',
  'balaslah',
  'berbalas',
  'balasan',
  'balast',
  'balau',
  'baldi',
  'baldu',
  'bale',
  'balela',
  'balerina',
  'bales',
  'balet',
  'balgham',
  'bali',
  'bali',
  'balian',
  'baligh',
  'kebalighan',
  'balighah',
  'balik',
  'terbalikkan',
  'balikkan',
  'baliknya',
  'baliklah',
  'pembalikan',
  'baling',
  'berbaling',
  'balingan',
  'balistik',
  'balkon',
  'balkoni',
  'baloi',
  'berbaloi',
  'balok',
  'balon',
  'balong',
  'balsam',
  'balu',
  'baluarti',
  'baluh',
  'balui',
  'baluk',
  'balun',
  'balung',
  'balur',
  'balus',
  'balut',
  'dibalut',
  'membalut',
  'bam',
  'bambang',
  'bambu',
  'bambun',
  'bambungan',
  'bamiyah',
  'bampar',
  'ban',
  'banal',
  'banang',
  'banar',
  'banat',
  'bancang',
  'bancau',
  'bancet',
  'banci',
  'bancian',
  'bancuh',
  'bancuhan',
  'bancut',
  'banda',
  'bandar',
  'bandaran',
  'membandarkan',
  'bandaraya',
  'bandan',
  'bandang',
  'bandea',
  'bandel',
  'bandela',
  'banderik',
  'bandering',
  'banderul',
  'banding',
  'bandingnya',
  'berbanding',
  'bandingan',
  'bandingkan',
  'bandit',
  'bandos',
  'bandot',
  'bandu',
  'banduan',
  'banduk',
  'bandul',
  'bandulan',
  'bandung',
  'bandusa',
  'bandut',
  'bang',
  'membangkan',
  'bangai',
  'bangan',
  'bangang',
  'bangar',
  'bangas',
  'bangat',
  'sebangatnya',
  'kebangatan',
  'bangau',
  'bangbang',
  'bangbung',
  'banget',
  'bangga',
  'banggakan',
  'kebanggaan',
  'banggi',
  'bangir',
  'bangka',
  'bangkah',
  'bangkai',
  'bangkal',
  'bangkang',
  'bangkangan',
  'bangkar',
  'bangkas',
  'bangkau',
  'bangkih',
  'bangking',
  'bangkir',
  'bangkit',
  'bangkitkan',
  'bangkitan',
  'bangkitlah',
  'bangkitnya',
  'bangkot',
  'bangku',
  'bangkuh',
  'bangkung',
  'bangkut',
  'banglas',
  'banglo',
  'bangor',
  'bangpak',
  'bangsa',
  'berbangsa',
  'kebangsaan',
  'bangsai',
  'bangsal',
  'bangsat',
  'bangsawan',
  'kebangsawanan',
  'bangsi',
  'bangta',
  'bangun',
  'bangunan',
  'bangunkan',
  'bangunlah',
  'bangus',
  'bani',
  'banian',
  'baning',
  'banir',
  'banit',
  'banjang',
  'banjar',
  'banjaran',
  'banji',
  'banjir',
  'membanjir',
  'dibanjiri',
  'kebanjiran',
  'banjo',
  'bank',
  'bankrap',
  'membankrapkan',
  'bankuet',
  'banta',
  'bantah',
  'bantahan',
  'perbantahkan',
  'bantai',
  'bantaian',
  'bantal',
  'berbantal',
  'bantalan',
  'bantam',
  'bantar',
  'bantat',
  'bantau',
  'banter',
  'banteras',
  'dibanterasnya',
  'membanterasnya',
  'pembanterasan',
  'banting',
  'membanting',
  'bantingan',
  'bantu',
  'bantuan',
  'bantulah',
  'bantun',
  'bantut',
  'membantut',
  'pembantutan',
  'banu',
  'banuk',
  'banyak',
  'banyaknya',
  'banyaklah',
  'membanyakkan',
  'perbanyak',
  'kebanyakan',
  'banyo',
  'banyol',
  'banyu',
  'bapa',
  'sebapa',
  'kebapaan',
  'berbapa',
  'bapak',
  'bapet',
  'baptis',
  'membabtis',
  'pembaptisan',
  'baqa',
  'baqarah',
  'bar',
  'bara',
  'perbara',
  'barah',
  'barai',
  'barak',
  'baran',
  'barang',
  'sebarang',
  'sembarang',
  'barangkali',
  'barangas',
  'barap',
  'baras',
  'barat',
  'kebaratan',
  'barat',
  'barau',
  'barbar',
  'barbeku',
  'barbiturat',
  'barbur',
  'bardi',
  'bareh',
  'bareng',
  'baret',
  'bari',
  'barid',
  'barik',
  'baring',
  'baringlah',
  'baringkan',
  'perbaringan',
  'baris',
  'barisnya',
  'membaris',
  'barisan',
  'dibarisi',
  'barium',
  'barkas',
  'barli',
  'barometer',
  'baron',
  'barong',
  'barongsai',
  'barter',
  'baru',
  'terbaru',
  'perbaharui',
  'barua',
  'baruh',
  'barung',
  'barus',
  'barusan',
  'barut',
  'barzakh',
  'bas',
  'basa',
  'basah',
  'berbasah',
  'basahkan',
  'membasahi',
  'dibasahi',
  'basahan',
  'basal',
  'basalt',
  'basau',
  'basi',
  'basikal',
  'basikalnya',
  'basil',
  'basilus',
  'basis',
  'basit',
  'baskom',
  'basmi',
  'pembasmi',
  'bastar',
  'basuh',
  'basuhkan',
  'basuhan',
  'basuk',
  'basung',
  'bata',
  'batai',
  'batak',
  'batak',
  'batal',
  'batalkan',
  'pembatal',
  'batalion',
  'batang',
  'berbatang',
  'sebatang',
  'batas',
  'batasnya',
  'berbatas',
  'batasan',
  'dibatasi',
  'dibataskan',
  'membatas',
  'kebatasan',
  'batat',
  'batau',
  'batela',
  'bateri',
  'bati',
  'batih',
  'batik',
  'batikan',
  'batil',
  'batin',
  'kebatinan',
  'batin',
  'batiniah',
  'batir',
  'batis',
  'batok',
  'baton',
  'batu',
  'batuan',
  'batunya',
  'batuk',
  'membatukkan',
  'batuknya',
  'batung',
  'bau',
  'membaui',
  'dibaui',
  'baunya',
  'bauan',
  'baucar',
  'baud',
  'bauk',
  'bauksit',
  'baulu',
  'baun',
  'baung',
  'baur',
  'bauran',
  'membauri',
  'dibauri',
  'membaurkan',
  'dibaurkan',
  'bausastera',
  'bausuku',
  'baut',
  'bawa',
  'bawaan',
  'bawakan',
  'bawalah',
  'terbawa',
  'bawab',
  'bawah',
  'bawahan',
  'bawahnya',
  'terkebawah',
  'bawak',
  'bawal',
  'bawang',
  'bebawang',
  'bawasir',
  'bawat',
  'bawel',
  'baya',
  'bayak',
  'bayam',
  'bayan',
  'bayang',
  'bayangi',
  'bayangkan',
  'bayangkanlah',
  'bayangan',
  'bayangnya',
  'bayangkara',
  'bayangkari',
  'bayar',
  'dibayar',
  'membayar',
  'bayarkan',
  'bayaran',
  'bayas',
  'bayi',
  'bayonet',
  'bayu',
  'bayuh',
  'bayung',
  'bayur',
  'bazar',
  'bazir',
  'membazir',
  'pembaziran',
  'bazuka',
  'bea',
  'bearing',
  'bebal',
  'bebalai',
  'bebalang',
  'bebaling',
  'beban',
  'terbeban',
  'bebani',
  'bebankan',
  'bebanan',
  'bebandos',
  'bebang',
  'bebangun',
  'bebar',
  'bebarau',
  'bebaru',
  'bebas',
  'bebaskan',
  'kebebasan',
  'pembebas',
  'bebat',
  'bebatai',
  'bebawang',
  'bebayuh',
  'bebe',
  'bebeh',
  'bebek',
  'bebel',
  'bebelan',
  'bebelak',
  'bebenah',
  'benang',
  'beber',
  'beberapa',
  'bebesaran',
  'bebeti',
  'bebodoran',
  'bebola',
  'beboreh',
  'bebotok',
  'bebuli',
  'beca',
  'becak',
  'membecakkan',
  'becang',
  'becar',
  'becek',
  'becok',
  'becokok',
  'becuk',
  'becus',
  'beda',
  'bedah',
  'pembedah',
  'bedak',
  'membedakkan',
  'bedal',
  'dibedal',
  'membedal',
  'bedama',
  'bedan',
  'bedata',
  'bedaya',
  'bedebah',
  'bedegap',
  'bedegong',
  'bedel',
  'bedeng',
  'bedil',
  'bedilan',
  'bedooh',
  'beduan',
  'beduk',
  'bedukang',
  'bedung',
  'bedungan',
  'beg',
  'bega',
  'begahak',
  'begak',
  'begal',
  'begana',
  'begandering',
  'begap',
  'begar',
  'begawan',
  'begenuak',
  'begian',
  'begini',
  'membeginikan',
  'dibeginikan',
  'beginilah',
  'begitu',
  'membegitukan',
  'dibegitukan',
  'begitulah',
  'begitupun',
  'begol',
  'begpaip',
  'begu',
  'beguk',
  'begundal',
  'begung',
  'beguri',
  'behena',
  'beiri',
  'bejana',
  'bejat',
  'bejelak',
  'bejemo',
  'bek',
  'beka',
  'bekah',
  'bekak',
  'bekal',
  'berbekal',
  'membekal',
  'bekalkan',
  'bekalan',
  'bekalembaga',
  'bekam',
  'pembekaman',
  'bekas',
  'membekas',
  'bekasnya',
  'bekat',
  'bekatul',
  'bekeng',
  'beker',
  'bekil',
  'bekoi',
  'beksa',
  'beksan',
  'beku',
  'bekukan',
  'pembekuan',
  'bekuan',
  'bekuk',
  'bekukung',
  'bekwah',
  'bela',
  'terbela',
  'belaan',
  'berbela',
  'belabas',
  'belabat',
  'belabik',
  'belacak',
  'belacan',
  'belacu',
  'beladau',
  'beladung',
  'belaga',
  'belah',
  'belahnya',
  'sebelah',
  'bersebelah',
  'menyebelah',
  'belahan',
  'belahak',
  'belai',
  'belaian',
  'belak',
  'belaka',
  'belakang',
  'terbelakang',
  'kebelakangkan',
  'terkebelakang',
  'membelakang',
  'belakangan',
  'belalai',
  'belalak',
  'belalang',
  'belam',
  'belan',
  'belanak',
  'belanar',
  'belanda',
  'belanda',
  'belandang',
  'belandar',
  'belandawi',
  'belandong',
  'belang',
  'belangnya',
  'belanga',
  'belangah',
  'belangkan',
  'belangkas',
  'belangko',
  'belangsungkawa',
  'belanja',
  'berbelanjalah',
  'belanjakan',
  'belanjaan',
  'membelanja',
  'belanjawan',
  'belantah',
  'belantan',
  'belantara',
  'belantik',
  'belantuk',
  'belar',
  'belas',
  'membelaskan',
  'belasan',
  'kesebelasan',
  'belasah',
  'belasting',
  'belasut',
  'belat',
  'belata',
  'belati',
  'belatuk',
  'belatung',
  'belau',
  'belawak',
  'belawan',
  'belebas',
  'belebat',
  'belebau',
  'beleber',
  'beleda',
  'beledang',
  'beleduh',
  'beleh',
  'belek',
  'belekek',
  'belekok',
  'belelang',
  'belelek',
  'belembang',
  'belemin',
  'belenceh',
  'belencong',
  'beleng',
  'belengas',
  'belenggu',
  'dibelenggui',
  'belengkok',
  'belengkong',
  'belengset',
  'belentung',
  'belepas',
  'belera',
  'belerang',
  'belerong',
  'beles',
  'beli',
  'terbeli',
  'belian',
  'belikan',
  'belilah',
  'belia',
  'beliawanis',
  'beliak',
  'membeliak',
  'belian',
  'beliau',
  'belibas',
  'belibat',
  'belibis',
  'belida',
  'beligat',
  'beligo',
  'beligu',
  'belikas',
  'belikat',
  'beliku',
  'belimbing',
  'belin',
  'belindan',
  'beling',
  'belingas',
  'belingkang',
  'belingut',
  'belinjau',
  'belinjo',
  'belintan',
  'belintang',
  'belir',
  'belit',
  'membelit',
  'belitan',
  'belitung',
  'beliung',
  'beliut',
  'belo',
  'belodok',
  'beloh',
  'belok',
  'membelokkan',
  'belokan',
  'belokok',
  'belolok',
  'belom',
  'belon',
  'belonggok',
  'belongkang',
  'belongkeng',
  'belongsong',
  'belontok',
  'belot',
  'pembelot',
  'belota',
  'belu',
  'beluam',
  'belubur',
  'beludak',
  'beludar',
  'beluduh',
  'beluh',
  'beluhan',
  'belujur',
  'belukang',
  'belukap',
  'belukar',
  'beluku',
  'belulang',
  'beluluk',
  'belum',
  'sebelum',
  'belumpai',
  'beluncas',
  'belungkang',
  'belungkur',
  'belungsing',
  'belunjur',
  'beluntas',
  'beluru',
  'belus',
  'belut',
  'beluting',
  'bemak',
  'bemban',
  'bena',
  'benah',
  'benak',
  'berbenak',
  'benalu',
  'benam',
  'benamkan',
  'pembenaman',
  'benang',
  'membenangkan',
  'pembenang',
  'benar',
  'sebenar',
  'membenar',
  'dibenar',
  'kebenaran',
  'benara',
  'benatu',
  'benbarang',
  'bencah',
  'bencana',
  'membencanai',
  'bencat',
  'benci',
  'kebencian',
  'bencilah',
  'benda',
  'membendakan',
  'kebendaan',
  'bendahara',
  'perbendaharaan',
  'bendaharawan',
  'bendahari',
  'bendal',
  'bendala',
  'bendalir',
  'bendalu',
  'bendang',
  'bendar',
  'bendari',
  'bendasing',
  'bendawat',
  'bende',
  'bendel',
  'bender',
  'bendera',
  'benderang',
  'benderung',
  'bendi',
  'bendir',
  'bendo',
  'bendok',
  'bendoro',
  'bendul',
  'bendung',
  'membendung',
  'bendungan',
  'benefisiari',
  'benet',
  'bengah',
  'bengal',
  'bengang',
  'bengap',
  'bengawan',
  'benge',
  'benggal',
  'benggala',
  'benggali',
  'benggil',
  'benggol',
  'bengik',
  'bengis',
  'membengis',
  'dibengisi',
  'kebengisan',
  'bengit',
  'bengkah',
  'bengkak',
  'bengkal',
  'bengkalai',
  'bengkalis',
  'bengkang',
  'bengkap',
  'bengkar',
  'bengkarak',
  'bengkaras',
  'bengkarung',
  'bengkatak',
  'bengkawan',
  'bengkawang',
  'bengkayang',
  'bengkel',
  'perbengkelan',
  'bengkeng',
  'bengkil',
  'bengkok',
  'bengkokkan',
  'pembengkokan',
  'bengkong',
  'bengkongkong',
  'bengku',
  'bengkuang',
  'bengkudu',
  'bengkunang',
  'bengkung',
  'bengot',
  'bengu',
  'benguk',
  'bengul',
  'benian',
  'benigna',
  'benih',
  'benihnya',
  'membenih',
  'bening',
  'membeningkan',
  'kebeningan',
  'benitan',
  'benjol',
  'bensol',
  'benta',
  'bentak',
  'bentala',
  'bentan',
  'bentang',
  'bentangkan',
  'bentangan',
  'membentangi',
  'dibentangi',
  'bentangur',
  'bentar',
  'bentara',
  'bentaus',
  'benteh',
  'benteng',
  'berbenteng',
  'membentengi',
  'bentes',
  'bentet',
  'bentil',
  'bentok',
  'bentrok',
  'bentuk',
  'bentuknya',
  'bentukan',
  'bentukkan',
  'bentulu',
  'bentur',
  'benturung',
  'bentus',
  'benua',
  'kebenuaan',
  'benuang',
  'benum',
  'benyai',
  'benyek',
  'benyut',
  'benzena',
  'benzin',
  'benzina',
  'beo',
  'bepang',
  'bera',
  'berabe',
  'berahi',
  'memberahikan',
  'diberahikan',
  'keberahian',
  'berai',
  'beraikan',
  'beraja',
  'berak',
  'memberak',
  'berakah',
  'beraksa',
  'berakya',
  'beram',
  'berambang',
  'berambi',
  'berambung',
  'berambur',
  'beramin',
  'beran',
  'berancai',
  'beranda',
  'berandal',
  'berandang',
  'berang',
  'memberang',
  'berangan',
  'berangas',
  'berangasan',
  'berangsang',
  'memberangsang',
  'berangta',
  'berangus',
  'berani',
  'keberanian',
  'memberanikan',
  'beranta',
  'berantuk',
  'berapa',
  'seberapa',
  'berarakan',
  'beras',
  'berasnya',
  'beraso',
  'berat',
  'beratnya',
  'memberat',
  'beratkan',
  'keberatan',
  'pemberat',
  'bercak',
  'bercat',
  'berdanur',
  'berdene',
  'berdikari',
  'berdus',
  'berecak',
  'berecek',
  'berek',
  'bereke',
  'bereksa',
  'beremban',
  'berembang',
  'berembas',
  'berembau',
  'beremi',
  'berenas',
  'berenga',
  'berengau',
  'berengut',
  'berentang',
  'berentuh',
  'berenuak',
  'bereo',
  'berepas',
  'bererot',
  'beres',
  'bereskan',
  'pemberesan',
  'beret',
  'berewok',
  'bergajul',
  'bergedel',
  'berguk',
  'berhala',
  'beri',
  'diberi',
  'memberi',
  'pemberi',
  'beria',
  'beriang',
  'beriani',
  'berida',
  'berik',
  'berilium',
  'berinda',
  'bering',
  'beringas',
  'beringin',
  'beringis',
  'berisik',
  'berit',
  'berita',
  'beritakan',
  'pemberita',
  'beritahu',
  'beritahulah',
  'pemberitahu',
  'memberitahuku',
  'berkas',
  'diberkas',
  'memberkas',
  'pemberkasan',
  'berkat',
  'berkati',
  'diberkati',
  'diberkatinya',
  'berkatilah',
  'keberkatan',
  'berkelium',
  'berkik',
  'berkil',
  'berko',
  'berkuk',
  'berlian',
  'berma',
  'bermat',
  'bernam',
  'bernama',
  'bernas',
  'beroci',
  'beroga',
  'beroi',
  'berok',
  'berokna',
  'berombong',
  'berona',
  'berondong',
  'berongsang',
  'berongsong',
  'beronok',
  'berontak',
  'pemberontak',
  'beronyeh',
  'berot',
  'beroti',
  'bersat',
  'bersia',
  'bersih',
  'bersihkan',
  'kebersihan',
  'pembersih',
  'terbersih',
  'membersih',
  'bersil',
  'bersin',
  'bersit',
  'bersut',
  'bertam',
  'bertas',
  'bertih',
  'bertuh',
  'beruang',
  'beruas',
  'berudu',
  'berujul',
  'beruk',
  'berumbun',
  'berumbung',
  'berunai',
  'berungut',
  'berunsai',
  'beruntus',
  'berup',
  'berus',
  'berusnya',
  'berwari',
  'berzanji',
  'bes',
  'besan',
  'besannya',
  'besar',
  'besaran',
  'besarkan',
  'besarnya',
  'besarlah',
  'besbol',
  'besek',
  'besel',
  'besen',
  'besengek',
  'beser',
  'beset',
  'besi',
  'besikan',
  'besikur',
  'besin',
  'besing',
  'besit',
  'beskap',
  'beskat',
  'beslah',
  'beslit',
  'besluit',
  'besok',
  'besot',
  'bestari',
  'bestel',
  'besteler',
  'bestir',
  'besusu',
  'besut',
  'besuta',
  'bet',
  'beta',
  'betagur',
  'betah',
  'membetahkan',
  'betahak',
  'betak',
  'betapa',
  'betara',
  'betari',
  'betas',
  'betat',
  'betemak',
  'betet',
  'beti',
  'betik',
  'betika',
  'betina',
  'beting',
  'betis',
  'beton',
  'betuk',
  'betul',
  'sebetulnya',
  'betulkan',
  'betulkannya',
  'betulkanlah',
  'kebetulan',
  'kebetulannya',
  'pembetulan',
  'berbetul',
  'dibetuli',
  'membetuli',
  'betung',
  'pembetung',
  'betut',
  'betutu',
  'bewok',
  'beza',
  'bezanya',
  'bezakan',
  'membezakannya',
  'pembeza',
  'perbezaan',
  'bezantara',
  'biadab',
  'kebiadaban',
  'biadat',
  'biadi',
  'biah',
  'biak',
  'pembiak',
  'membiak',
  'dibiakkan',
  'biang',
  'bianglala',
  'biar',
  'dibiar',
  'membiar',
  'terbiar',
  'kebiaran',
  'biara',
  'biarawan',
  'biarawati',
  'bias',
  'membias',
  'biasan',
  'biasa',
  'terbiasa',
  'biasakan',
  'kebiasaan',
  'biasiswa',
  'biat',
  'biawak',
  'biawas',
  'biaya',
  'biayai',
  'pembiaya',
  'dibiayakan',
  'membiayakan',
  'bibi',
  'bibinda',
  'bibir',
  'berbibir',
  'bibit',
  'membibitkan',
  'bibliografi',
  'bicara',
  'berbicara',
  'bicarakan',
  'perbicaraan',
  'pembicara',
  'bicokok',
  'bicu',
  'bicut',
  'bida',
  'bidaah',
  'bidadari',
  'bidai',
  'bidak',
  'bidal',
  'bidan',
  'membidani',
  'kebidanan',
  'bidang',
  'bidangnya',
  'bidangan',
  'membidangkan',
  'pemidang',
  'bidara',
  'bidas',
  'bidasan',
  'bidi',
  'bidik',
  'membidik',
  'pembidik',
  'bidis',
  'biduan',
  'biduanda',
  'biduanita',
  'biduk',
  'bidur',
  'bienial',
  'bifstik',
  'bigami',
  'bighair',
  'bigu',
  'bihun',
  'bija',
  'bijak',
  'kebijakan',
  'bijaksana',
  'kebijaksanaan',
  'bijan',
  'bijana',
  'bijaya',
  'biji',
  'bijinya',
  'bijian',
  'bijih',
  'bijirin',
  'bika',
  'bikar',
  'bikin',
  'bikinan',
  'bikini',
  'bikir',
  'biksu',
  'biksuni',
  'biku',
  'bikung',
  'bil',
  'bilkan',
  'bila',
  'bilabial',
  'bilad',
  'bilah',
  'bilahnya',
  'bilai',
  'bilak',
  'bilal',
  'bilamana',
  'bilamasa',
  'bilang',
  'bilangan',
  'membilangkan',
  'bilar',
  'bilas',
  'bilasan',
  'bilateral',
  'bilau',
  'biliard',
  'bilik',
  'biliknya',
  'bilion',
  'bilionair',
  'bilis',
  'bilit',
  'bilur',
  'bilut',
  'bimasakti',
  'bimbang',
  'membimbangkan',
  'kebimbangan',
  'dibimbangkan',
  'bimbing',
  'membimbing',
  'berbimbing',
  'dibimbing',
  'pembimbing',
  'bimbit',
  'bimbitnya',
  'bimbitan',
  'bin',
  'berbin',
  'bina',
  'binaan',
  'binal',
  'binantu',
  'binar',
  'binari',
  'binasa',
  'binasakan',
  'kebinasaan',
  'pembinasa',
  'binatang',
  'kebinatangan',
  'binau',
  'binawah',
  'bincacak',
  'bincang',
  'bincanglah',
  'bincangkan',
  'perbincangan',
  'bincau',
  'bincu',
  'bincut',
  'bindam',
  'bindeng',
  'bindil',
  'bindu',
  'bingal',
  'bingar',
  'bingas',
  'bingit',
  'membingitkan',
  'kebingitan',
  'bingka',
  'bingkah',
  'bingkai',
  'membingkai',
  'bingkas',
  'bingkatak',
  'bingkis',
  'membingkis',
  'bingung',
  'membingungkan',
  'kebingungan',
  'bini',
  'berbini',
  'membinikan',
  'memperbini',
  'diperbini',
  'binjai',
  'binjat',
  'binokular',
  'binomial',
  'bintang',
  'bintangnya',
  'membintangi',
  'dibintangi',
  'bintangan',
  'bintangur',
  'bintara',
  'binti',
  'berbinti',
  'bintik',
  'bintil',
  'bintit',
  'bintuh',
  'bintul',
  'binturung',
  'binuang',
  'biocerakin',
  'biodata',
  'biodisel',
  'biodiversiti',
  'biofizik',
  'biogas',
  'biogenesis',
  'biogeografi',
  'biografi',
  'biojisim',
  'biokimia',
  'biola',
  'biologi',
  'biom',
  'bionik',
  'bioplastik',
  'biopelancongan',
  'biopendar',
  'biopsi',
  'bios',
  'biosfera',
  'bioskop',
  'biot',
  'bioteknologi',
  'bir',
  'bira',
  'birah',
  'kebirahan',
  'birai',
  'biram',
  'biramani',
  'biras',
  'birat',
  'biri',
  'bebiri',
  'birih',
  'biring',
  'birit',
  'biro',
  'birokrasi',
  'birokrat',
  'birokratik',
  'birsam',
  'biru',
  'birunya',
  'biruan',
  'bis',
  'bisa',
  'bisanya',
  'kebisaan',
  'bisai',
  'bisektris',
  'bisik',
  'bisikkan',
  'bisikan',
  'bising',
  'membising',
  'kebisingan',
  'biskop',
  'biskut',
  'bismillah',
  'bismillahi',
  'bismut',
  'bisnes',
  'bison',
  'bissawab',
  'bisu',
  'kebisuan',
  'bisul',
  'bit',
  'bitara',
  'bitbit',
  'biti',
  'biting',
  'bitis',
  'bito',
  'bitu',
  'bitumen',
  'biuku',
  'biul',
  'bius',
  'pembiusan',
  'biut',
  'blangkon',
  'blaster',
  'blastosista',
  'blaus',
  'blazer',
  'blok',
  'blokade',
  'blokir',
  'blurp',
  'bob',
  'bobek',
  'bobok',
  'bobol',
  'bobos',
  'bobotok',
  'bobrok',
  'bocah',
  'boceh',
  'bocok',
  'bocong',
  'bocor',
  'bocoran',
  'pembocor',
  'bocorkan',
  'bodek',
  'bodi',
  'bodoh',
  'kebodohan',
  'bodohkan',
  'bodok',
  'bodong',
  'bodor',
  'boga',
  'bogam',
  'bogang',
  'bogel',
  'bogelkan',
  'kebogelan',
  'bogem',
  'bogi',
  'bogot',
  'bohemian',
  'bohlam',
  'bohong',
  'berbohong',
  'membohong',
  'dibohongi',
  'bohongkan',
  'kebohongan',
  'pembohong',
  'bohorok',
  'bohsia',
  'boi',
  'boikot',
  'pemboikot',
  'bojeng',
  'bok',
  'bokca',
  'boke',
  'bokeh',
  'bokek',
  'boket',
  'bokoh',
  'bokong',
  'bokop',
  'bokor',
  'boksu',
  'bol',
  'bola',
  'bolah',
  'bolai',
  'bolak',
  'bolakuselessbalik',
  'bole',
  'boleh',
  'seboleh',
  'dibolehkan',
  'membolehkan',
  'kebolehan',
  'kebolehannya',
  'kebolehpercayaan',
  'bolen',
  'boli',
  'boling',
  'bolong',
  'bolor',
  'bolos',
  'dibolosi',
  'membolos',
  'bolot',
  'pembolot',
  'bolsak',
  'bolt',
  'bom',
  'pengebom',
  'bomba',
  'kebombaan',
  'bombardemen',
  'bombardir',
  'bombastik',
  'bombin',
  'bomoh',
  'bon',
  'bona',
  'bonak',
  'bonang',
  'bonanza',
  'bonbon',
  'bonceng',
  'diboncengnya',
  'membonceng',
  'pembonceng',
  'boncengan',
  'boncol',
  'bond',
  'bonda',
  'bondok',
  'bondol',
  'bondong',
  'bondot',
  'boneka',
  'membonekakan',
  'dibonekakan',
  'bonet',
  'bongak',
  'bongbong',
  'bonggo',
  'bonggol',
  'bongkah',
  'bongkak',
  'kebongkakan',
  'bongkang',
  'bongkar',
  'bongkaran',
  'bongkas',
  'bongkeng',
  'bongkok',
  'bongkokkan',
  'bongkol',
  'bongkong',
  'bongkot',
  'bonglai',
  'bongok',
  'bongor',
  'bongsai',
  'bongsoi',
  'bongsor',
  'bongsu',
  'bonjol',
  'bonsai',
  'bonus',
  'bonyor',
  'bookie',
  'bopeng',
  'bopet',
  'bopong',
  'bor',
  'borak',
  'memborak',
  'boraks',
  'borang',
  'borat',
  'borci',
  'bordir',
  'bordu',
  'boreh',
  'borek',
  'boreng',
  'borgol',
  'boria',
  'boring',
  'borjuis',
  'boroh',
  'boroi',
  'borok',
  'boron',
  'borong',
  'diborong',
  'memborong',
  'boros',
  'keborosan',
  'pemboros',
  'memboroskan',
  'bortel',
  'bos',
  'bosan',
  'membosankan',
  'kebosanan',
  'boseta',
  'bosman',
  'bosor',
  'bot',
  'bota',
  'botak',
  'botakkan',
  'kebotakan',
  'botani',
  'botoh',
  'botok',
  'botol',
  'botolkan',
  'pembotolan',
  'botor',
  'botox',
  'boya',
  'boyak',
  'boyan',
  'boyas',
  'boyo',
  'boyong',
  'boyot',
  'bra',
  'bradikardia',
  'brahman',
  'brahmin',
  'braille',
  'brandi',
  'brankar',
  'brankas',
  'bras',
  'bredel',
  'brek',
  'membrek',
  'pembrekan',
  'brengan',
  'brevet',
  'bridge',
  'briged',
  'brigedier',
  'bris',
  'brisan',
  'brisik',
  'british',
  'broked',
  'broker',
  'brokoli',
  'bromida',
  'bromin',
  'bronkiol',
  'bronkitis',
  'bronkus',
  'bros',
  'brosur',
  'brunei',
  'bruto',
  'bskl',
  'buah',
  'sebuah',
  'berbuah',
  'membuah',
  'buai',
  'buaian',
  'buaikan',
  'buak',
  'bual',
  'bualkan',
  'bualan',
  'buana',
  'buang',
  'buangnya',
  'buangkan',
  'buangan',
  'buanglah',
  'buapak',
  'buar',
  'buari',
  'buas',
  'kebuasan',
  'buasir',
  'buat',
  'buatkan',
  'buatkannya',
  'buatan',
  'buatnya',
  'buatlah',
  'buatmu',
  'buatku',
  'buaya',
  'membuaya',
  'kebuayaan',
  'bubar',
  'pembubar',
  'bubarkan',
  'bubu',
  'bubuh',
  'dibubuh',
  'membubuh',
  'bubuhan',
  'bubuk',
  'bubul',
  'bubun',
  'bubur',
  'bubus',
  'bubut',
  'bubutan',
  'bucu',
  'budak',
  'budakan',
  'membudak',
  'memperbudak',
  'diperbudak',
  'budaya',
  'budayanya',
  'budayakan',
  'kebudayaan',
  'budayawan',
  'buddha',
  'budek',
  'budi',
  'berbudi',
  'sebudi',
  'memperbudikan',
  'budidaya',
  'budiman',
  'budu',
  'buduk',
  'budul',
  'buenam',
  'bufet',
  'bugar',
  'bugi',
  'bugu',
  'buhuk',
  'buhul',
  'bui',
  'buih',
  'membuih',
  'pembuihan',
  'bujal',
  'bujam',
  'bujang',
  'bujangnya',
  'pembujangan',
  'bujangga',
  'bujuk',
  'bujur',
  'bujurkan',
  'bujurnya',
  'buka',
  'berbuka',
  'dibuka',
  'membuka',
  'pembuka',
  'terbuka',
  'bukan',
  'membukankan',
  'bukat',
  'bukau',
  'bukit',
  'bukti',
  'buktikan',
  'pembuktian',
  'buktinya',
  'terbukti',
  'buktidiri',
  'buku',
  'bukunya',
  'bukukan',
  'pembukuan',
  'bukung',
  'bukur',
  'bukut',
  'bulai',
  'bulan',
  'bulanan',
  'bulang',
  'bular',
  'bulat',
  'bulatkan',
  'bulatan',
  'membulat',
  'bulatnya',
  'bulbul',
  'buldan',
  'buldozer',
  'buletin',
  'buli',
  'dibuli',
  'membuli',
  'bulir',
  'bulsak',
  'bulu',
  'bulunya',
  'buluh',
  'bulur',
  'kebulur',
  'bulus',
  'bumantara',
  'bumban',
  'bumbu',
  'dibumbui',
  'membumbui',
  'bumbun',
  'bumbung',
  'bumbungan',
  'bumerang',
  'bumi',
  'membumi',
  'kebumikan',
  'pengebumian',
  'bumiputera',
  'buna',
  'buncah',
  'buncis',
  'buncit',
  'membuncit',
  'bundai',
  'bundak',
  'bundar',
  'bundarkan',
  'bundaran',
  'bundel',
  'bunduk',
  'bunduki',
  'bundung',
  'bung',
  'bunga',
  'berbunga',
  'membunga',
  'dibungai',
  'bungaan',
  'bungar',
  'bungkal',
  'bungkalan',
  'bungkam',
  'pembungkam',
  'bungkil',
  'bungkin',
  'bungkup',
  'bungkus',
  'bungkuskan',
  'bungkusnya',
  'bungkusan',
  'bunglon',
  'bungsil',
  'buni',
  'bunian',
  'bunjai',
  'buntal',
  'buntang',
  'membuntang',
  'buntar',
  'buntat',
  'buntel',
  'buntet',
  'buntil',
  'bunting',
  'membunting',
  'dibuntingkan',
  'buntingan',
  'buntu',
  'membuntukan',
  'kebuntuan',
  'buntuk',
  'buntung',
  'buntur',
  'buntut',
  'membuntut',
  'berbuntut',
  'bunuh',
  'dibunuh',
  'membunuh',
  'berbunuh',
  'pembunuh',
  'terbunuh',
  'bunut',
  'bunyi',
  'berbunyi',
  'bunyikan',
  'bunyian',
  'bupala',
  'bupati',
  'bura',
  'burai',
  'burakah',
  'buram',
  'buran',
  'burangsang',
  'buraq',
  'buras',
  'burat',
  'burdah',
  'bureng',
  'buret',
  'burger',
  'burhan',
  'buri',
  'burit',
  'buritan',
  'burka',
  'buron',
  'bursa',
  'buru',
  'buruan',
  'burunya',
  'buruh',
  'buruhan',
  'buruj',
  'buruk',
  'diburukkan',
  'burukkan',
  'keburukan',
  'buruknya',
  'burun',
  'burung',
  'burut',
  'busa',
  'busana',
  'busar',
  'busat',
  'buset',
  'busi',
  'busjaket',
  'bustan',
  'busu',
  'busuk',
  'dibusukkan',
  'membusuk',
  'kebusukan',
  'sebusuk',
  'busung',
  'membusung',
  'pembusungan',
  'busur',
  'busut',
  'but',
  'buta',
  'butakan',
  'kebutaan',
  'butal',
  'butala',
  'butang',
  'butangkan',
  'pembutangan',
  'butangnya',
  'butbut',
  'butek',
  'butik',
  'butir',
  'membutirkan',
  'butiran',
  'butirnya',
  'butuh',
  'membutuhkan',
  'kebutuhan',
  'butul',
  'butung',
  'butut',
  'buya',
  'buyar',
  'buyung',
  'buyut',
  'cabai',
  'kecabaian',
  'cabak',
  'cabang',
  'cabangnya',
  'percabangan',
  'cabar',
  'cabaran',
  'mencabar',
  'pencabar',
  'cabau',
  'cabik',
  'cabikan',
  'cabir',
  'cabit',
  'cabo',
  'cabuh',
  'cabuk',
  'cabul',
  'pencabulan',
  'mencabul',
  'dicabuli',
  'cabur',
  'cabut',
  'dicabut',
  'mencabut',
  'cabutan',
  'caca',
  'cacah',
  'cacak',
  'dicacak',
  'mencacak',
  'cacamarba',
  'cacang',
  'cacap',
  'cacar',
  'cacat',
  'cacatnya',
  'dicacat',
  'mencacat',
  'kecacatan',
  'cacau',
  'caci',
  'cacian',
  'cacibar',
  'cacil',
  'cacing',
  'cadai',
  'cadak',
  'cadang',
  'cadangnya',
  'cadangkan',
  'cadangan',
  'cadar',
  'bercadar',
  'cadas',
  'cadi',
  'cadik',
  'caduk',
  'cadung',
  'cadur',
  'cagak',
  'cagar',
  'cagarkan',
  'cagaran',
  'cagu',
  'cagun',
  'cagut',
  'cahang',
  'cahar',
  'cahaya',
  'mencahayai',
  'mencahayakan',
  'pencahayaan',
  'caing',
  'mencairkan',
  'cair',
  'cecair',
  'cairkan',
  'cairan',
  'cais',
  'caj',
  'cak',
  'caka',
  'cakah',
  'cakak',
  'cakal',
  'cakalele',
  'cakap',
  'cakapnya',
  'cakapkan',
  'cakapan',
  'cakar',
  'cakaran',
  'cakawari',
  'cakduh',
  'cakela',
  'cakera',
  'cakerawala',
  'cakerawati',
  'cakiah',
  'caklempong',
  'cakna',
  'caku',
  'cakuk',
  'cakup',
  'mencakup',
  'cakus',
  'cala',
  'calabikang',
  'calai',
  'calak',
  'calang',
  'calar',
  'calarkan',
  'calat',
  'calet',
  'calik',
  'caling',
  'calit',
  'calitan',
  'mencalit',
  'calo',
  'calon',
  'calonkan',
  'pencalonan',
  'calu',
  'calui',
  'calung',
  'cam',
  'dicam',
  'mengecam',
  'pengecam',
  'camar',
  'camat',
  'camau',
  'cambah',
  'percambahan',
  'cambai',
  'cambang',
  'cambuk',
  'cambung',
  'camca',
  'camdik',
  'camik',
  'campa',
  'campadang',
  'campah',
  'campak',
  'campakkan',
  'campang',
  'campera',
  'campin',
  'campung',
  'campur',
  'bercampur',
  'campurkan',
  'campurnya',
  'campuri',
  'campurilah',
  'campuran',
  'camuk',
  'camur',
  'cana',
  'canai',
  'canaian',
  'canak',
  'canang',
  'canangkan',
  'canar',
  'cancang',
  'cancut',
  'canda',
  'candak',
  'candal',
  'candan',
  'candang',
  'candat',
  'candelier',
  'candi',
  'candik',
  'candit',
  'candu',
  'dicandui',
  'mencandu',
  'kecanduan',
  'candung',
  'cang',
  'cangah',
  'cangak',
  'cangal',
  'cangap',
  'cangcang',
  'cangerai',
  'canggah',
  'percanggahan',
  'canggai',
  'canggal',
  'canggih',
  'kecanggihan',
  'canggung',
  'kecanggungan',
  'cangkaliwang',
  'cangkang',
  'cangkat',
  'cangkel',
  'cangkelong',
  'cangkerang',
  'cangkering',
  'cangkihuselessmangkih',
  'cangking',
  'cangkir',
  'cangkriman',
  'cangku',
  'cangkuk',
  'mencangkuk',
  'cangkul',
  'cangkum',
  'cangkung',
  'cangkup',
  'canguk',
  'cangut',
  'canselor',
  'canselori',
  'cantan',
  'cantas',
  'cantasan',
  'cantel',
  'cantengan',
  'canterik',
  'cantik',
  'secantik',
  'cantiknya',
  'cantikkan',
  'kecantikan',
  'cantil',
  'canting',
  'cantis',
  'cantol',
  'cantum',
  'cantumkan',
  'cantuman',
  'cap',
  'capnya',
  'pengecapan',
  'capa',
  'capah',
  'capai',
  'dicapai',
  'mencapai',
  'tercapai',
  'capaian',
  'kecapaian',
  'capak',
  'capal',
  'capang',
  'capar',
  'capati',
  'capcai',
  'capek',
  'capelin',
  'capgome',
  'capik',
  'mencapikkan',
  'kecapikan',
  'capil',
  'caping',
  'capjiki',
  'caplak',
  'caplok',
  'capoi',
  'capuk',
  'mencapukkan',
  'car',
  'cara',
  'secara',
  'carah',
  'carak',
  'caram',
  'caran',
  'carang',
  'carca',
  'cari',
  'carian',
  'pencari',
  'sepencarian',
  'carilah',
  'carik',
  'carikan',
  'caring',
  'carta',
  'carter',
  'caruk',
  'carum',
  'caruman',
  'carut',
  'cas',
  'dicaskan',
  'mencas',
  'casis',
  'cat',
  'catnya',
  'pengecatan',
  'catak',
  'catan',
  'catang',
  'catat',
  'dicatat',
  'mencatat',
  'pencatat',
  'tercatat',
  'catu',
  'catuan',
  'mencatu',
  'catuh',
  'catuk',
  'catur',
  'caturan',
  'catut',
  'catutan',
  'cauk',
  'caul',
  'caung',
  'cauvanis',
  'cauvanisme',
  'cauvanistik',
  'cawuseless',
  'cawan',
  'cawannya',
  'cawang',
  'cawangan',
  'cawat',
  'cawi',
  'cawis',
  'cdrom',
  'cebak',
  'cebaruselesscebur',
  'cebeh',
  'cebek',
  'cebelus',
  'ceber',
  'cebet',
  'cebik',
  'dicebikkan',
  'mencebik',
  'cebir',
  'cebis',
  'cebisan',
  'cebok',
  'cebol',
  'cebong',
  'cebur',
  'mencebur',
  'diceburi',
  'ceburi',
  'cecadu',
  'cecah',
  'mencecah',
  'cecair',
  'cecak',
  'cecap',
  'cecar',
  'cece',
  'ceceh',
  'cecekik',
  'ceceng',
  'cecewe',
  'cedak',
  'cedas',
  'cedera',
  'kecederaan',
  'cederakan',
  'pencederaan',
  'ceding',
  'cedok',
  'ceduk',
  'cedung',
  'cega',
  'cegah',
  'cegahan',
  'cegak',
  'cegar',
  'cegat',
  'ceguk',
  'cek',
  'ceka',
  'cekah',
  'cekak',
  'cekal',
  'kecekalan',
  'cekam',
  'cekang',
  'cekap',
  'mempercekap',
  'kecekapan',
  'cekar',
  'cekarau',
  'cekatan',
  'cekati',
  'cekau',
  'cekcok',
  'cekel',
  'ceker',
  'cekera',
  'ceketing',
  'ceki',
  'cekibar',
  'cekih',
  'cekik',
  'cekikan',
  'ceking',
  'cekit',
  'cekodok',
  'ceku',
  'cekuh',
  'cekuk',
  'cekukuselesscekik',
  'cekung',
  'cekup',
  'cekur',
  'cekut',
  'cela',
  'celanya',
  'celaan',
  'mencela',
  'celadang',
  'celaga',
  'celagi',
  'celaguri',
  'celah',
  'mencelah',
  'celak',
  'celaka',
  'kecelakaan',
  'celam',
  'celana',
  'celang',
  'celangak',
  'celangap',
  'celapak',
  'celar',
  'celaru',
  'kecelaruan',
  'mencelarukan',
  'celas',
  'celat',
  'celatu',
  'celatuk',
  'celau',
  'cele',
  'celebuk',
  'celebur',
  'celedanguselessceledok',
  'celeguri',
  'celek',
  'celekati',
  'celekeh',
  'celempong',
  'celempung',
  'celeng',
  'celengan',
  'celengap',
  'celengkakuselesscelengkok',
  'celengkanguselesscelengkok',
  'celepa',
  'celepik',
  'celepuk',
  'celerek',
  'celetuk',
  'celi',
  'celici',
  'celih',
  'celik',
  'celikkan',
  'celika',
  'celis',
  'celober',
  'celomes',
  'celomok',
  'celonet',
  'celopar',
  'celoreng',
  'celorong',
  'celoteh',
  'celotehnya',
  'celoteng',
  'celsius',
  'celuk',
  'celum',
  'celung',
  'celungap',
  'celup',
  'celupkan',
  'celuplah',
  'celupan',
  'celupak',
  'celur',
  'celurut',
  'celus',
  'celutak',
  'celutuk',
  'cema',
  'cemabai',
  'cemak',
  'cemamai',
  'cemamar',
  'cemamping',
  'cemangi',
  'cemani',
  'cemantung',
  'cemar',
  'mencemar',
  'dicemar',
  'pencemaran',
  'cemara',
  'cemas',
  'mencemaskan',
  'kecemasan',
  'cemat',
  'cembang',
  'cembeng',
  'cemberut',
  'cembul',
  'cembung',
  'cemburu',
  'dicemburui',
  'mencemburui',
  'cemburukan',
  'cemburuan',
  'cemburunya',
  'cemeeh',
  'cemeh',
  'cemek',
  'cemeki',
  'cemekian',
  'cemer',
  'cemerkap',
  'cemerlang',
  'kecemerlangan',
  'cemeti',
  'cemetuk',
  'cemidu',
  'cemiduk',
  'cemik',
  'mencemik',
  'cemo',
  'cemomot',
  'cemong',
  'cemongkahuselesscemangkih',
  'cemoyang',
  'cempa',
  'cempaka',
  'cempala',
  'cempedak',
  'cempek',
  'cempelik',
  'cempelung',
  'cempenai',
  'cempeng',
  'cempening',
  'cemperai',
  'cemperas',
  'cemperling',
  'cempiang',
  'cemping',
  'cempo',
  'cempung',
  'mencempung',
  'cempurit',
  'cempurna',
  'cemucup',
  'cemuh',
  'dicemuh',
  'mencemuh',
  'cemuih',
  'cemuk',
  'cemus',
  'cena',
  'cenak',
  'cenama',
  'cenana',
  'cenangau',
  'cenangga',
  'cenangkas',
  'cenayang',
  'cencala',
  'cencaluk',
  'cencang',
  'cencangan',
  'cencaru',
  'cencawan',
  'cenceng',
  'cencodak',
  'cencorot',
  'cenda',
  'cendala',
  'cendana',
  'cendang',
  'cendara',
  'cendarah',
  'cendarahan',
  'cendarik',
  'cendas',
  'cendawan',
  'cendayan',
  'cendekel',
  'cendekia',
  'cendekiawan',
  'cenderadurja',
  'cenderahati',
  'cenderai',
  'cenderamata',
  'cenderamulia',
  'cenderasa',
  'cenderasari',
  'cenderasuri',
  'cenderawasih',
  'cenderu',
  'cenderung',
  'kecenderungan',
  'cenderus',
  'cendol',
  'cendorong',
  'cenduai',
  'cenduk',
  'cenekah',
  'ceneker',
  'cenela',
  'cenerah',
  'cenerul',
  'cengal',
  'cengam',
  'cengang',
  'mencengangkan',
  'cengap',
  'cengcong',
  'cenge',
  'cengek',
  'cengeng',
  'cenggang',
  'cenggeh',
  'cenggek',
  'cengkadak',
  'cengkam',
  'dicengkam',
  'mencengkam',
  'cengkaman',
  'cengkarung',
  'cengkeh',
  'cengkeram',
  'mencengkeram',
  'cengkerik',
  'cengkih',
  'cengkung',
  'cenonot',
  'centong',
  'ceongsam',
  'cepat',
  'cepatkan',
  'cepatkannya',
  'cepatnya',
  'cepatlah',
  'cepatan',
  'ceper',
  'cepu',
  'cepuk',
  'cepumas',
  'ceracak',
  'cerah',
  'kecerahan',
  'pencerahan',
  'cerahkan',
  'cerai',
  'ceraian',
  'ceraikan',
  'ceraiuselessberai',
  'cerakin',
  'ceramah',
  'ceramahnya',
  'menceramahkan',
  'ceranggah',
  'cerap',
  'cerapan',
  'ceras',
  'ceratuk',
  'cerca',
  'cercaan',
  'cerdas',
  'kecerdasan',
  'cerdaskan',
  'cerdik',
  'kecerdikan',
  'cerek',
  'cereka',
  'cerewet',
  'dicereweti',
  'mencereweti',
  'cergas',
  'kecergasan',
  'mencergaskan',
  'dicergaskan',
  'pencergasan',
  'ceri',
  'ceria',
  'menceriakan',
  'keceriaan',
  'cerita',
  'ceritaan',
  'ceritakan',
  'ceritanya',
  'ceritera',
  'cermai',
  'cermat',
  'kecermatan',
  'mencermatkan',
  'cermin',
  'cerminkan',
  'cerminan',
  'cerminnya',
  'cerna',
  'mencerna',
  'pencernaan',
  'ceroboh',
  'menceroboh',
  'diceroboh',
  'penceroboh',
  'cerobong',
  'cerocok',
  'cerpelai',
  'cerpen',
  'cerpenis',
  'cerucuk',
  'cerucup',
  'ceruk',
  'cerun',
  'kecerunan',
  'cerut',
  'pencerut',
  'cetak',
  'cetaknya',
  'cetakan',
  'mencetak',
  'cetek',
  'mencetekkan',
  'kecetekan',
  'cetera',
  'ceterawara',
  'ceteria',
  'ceti',
  'cetus',
  'cetusan',
  'cetuskan',
  'champagne',
  'china',
  'cia',
  'ciap',
  'ciau',
  'cicak',
  'cicah',
  'cicip',
  'cicir',
  'mencicirkan',
  'berciciran',
  'keciciran',
  'cicit',
  'cik',
  'cika',
  'cikgu',
  'ciku',
  'cili',
  'cilik',
  'cimni',
  'cimpanzi',
  'cina',
  'cina',
  'cincai',
  'cincang',
  'cincangan',
  'cincau',
  'cincin',
  'cindai',
  'cingge',
  'cinta',
  'bercinta',
  'mencinta',
  'dicintai',
  'pencinta',
  'tercinta',
  'cintaan',
  'cintamani',
  'cip',
  'cipnya',
  'ciplak',
  'cipta',
  'ciptaan',
  'ciptakan',
  'ciri',
  'berciri',
  'cirit',
  'mencirit',
  'cis',
  'cita',
  'bercita',
  'dicita',
  'mencita',
  'citarasa',
  'citra',
  'mencitrakan',
  'cium',
  'ciumnya',
  'ciuman',
  'menciumi',
  'ciut',
  'cocok',
  'kecocokan',
  'cogan',
  'cognac',
  'coklat',
  'cokmar',
  'cola',
  'colak',
  'colek',
  'coli',
  'colok',
  'combol',
  'comel',
  'mencomel',
  'comelan',
  'comelnya',
  'comot',
  'compang',
  'compeng',
  'condong',
  'condongkan',
  'kecondongan',
  'congah',
  'congak',
  'conggok',
  'congkak',
  'congok',
  'conteng',
  'contengnya',
  'contengan',
  'contoh',
  'contohi',
  'contohilah',
  'contohkan',
  'percontohan',
  'cop',
  'copet',
  'pencopetan',
  'corak',
  'mencorak',
  'dicorakkan',
  'coraknya',
  'coreng',
  'mencoreng',
  'corengan',
  'coret',
  'coretan',
  'corong',
  'mencorong',
  'corot',
  'mencorotkan',
  'cota',
  'coulomb',
  'cuaca',
  'cuai',
  'kecuaian',
  'mencuaikan',
  'dicuaikan',
  'cuak',
  'cuba',
  'dicuba',
  'mencuba',
  'cubaan',
  'cuban',
  'cubit',
  'cubitan',
  'cuci',
  'mencuci',
  'cucian',
  'cucu',
  'cucunya',
  'cucuh',
  'dicucuh',
  'mencucuh',
  'cucuk',
  'cucukkan',
  'cucuknya',
  'cucunda',
  'cucup',
  'cucur',
  'cucuran',
  'dicucuri',
  'mencucuri',
  'cuepacs',
  'cuit',
  'bercuit',
  'cuitan',
  'cuka',
  'cukai',
  'cukainya',
  'percukaian',
  'cukin',
  'cukup',
  'cukupkan',
  'mencukupi',
  'kecukupan',
  'secukup',
  'cukur',
  'cula',
  'culas',
  'culik',
  'penculik',
  'cuma',
  'cumbu',
  'dicumbui',
  'mencumbu',
  'cumi',
  'cundang',
  'cungap',
  'cungapan',
  'cunggit',
  'cungkil',
  'cungkilan',
  'cupak',
  'cuping',
  'curah',
  'curahan',
  'mencurah',
  'curahkan',
  'curam',
  'curaman',
  'curang',
  'mencurangi',
  'kecurangan',
  'curi',
  'curian',
  'curiga',
  'mencurigakan',
  'curigai',
  'kecurigaan',
  'cuti',
  'cutinya',
  'percutian',
  'czar',
  'dabik',
  'dabing',
  'dabung',
  'dabus',
  'dacing',
  'dada',
  'dadaan',
  'dadanya',
  'dadah',
  'dadak',
  'dadar',
  'dadih',
  'dadu',
  'daeng',
  'daerah',
  'kedaerahan',
  'daftar',
  'pendaftar',
  'daftarkan',
  'dagang',
  'dagangkan',
  'dagangan',
  'daging',
  'dagingnya',
  'dagu',
  'dah',
  'dahaga',
  'mendahaga',
  'kedahagaan',
  'dahan',
  'dahi',
  'dahlia',
  'dahsyat',
  'mendahsyat',
  'memperdahsyat',
  'kedahsyatan',
  'dahulu',
  'dahului',
  'dahulukan',
  'pendahuluan',
  'daif',
  'kedaifan',
  'daik',
  'dail',
  'daiman',
  'dajal',
  'dakap',
  'dakapan',
  'daki',
  'dakinya',
  'pendakian',
  'dakron',
  'daksina',
  'daktilitis',
  'daku',
  'dakwa',
  'mendakwa',
  'dakwaan',
  'dakwanya',
  'dakwah',
  'dakwat',
  'dakwatnya',
  'dakyah',
  'dal',
  'dalal',
  'dalam',
  'dalamnya',
  'dalami',
  'dalamkan',
  'dalaman',
  'dalamannya',
  'dalang',
  'mendalang',
  'pendalangan',
  'dalca',
  'dalfin',
  'dalih',
  'dalihnya',
  'mendalihkan',
  'dalil',
  'dalilnya',
  'mendalilkan',
  'dalu',
  'dam',
  'damai',
  'berdamai',
  'kedamaian',
  'pendamai',
  'perdamaian',
  'damaikan',
  'damak',
  'damar',
  'pedamaran',
  'damba',
  'perdambaan',
  'dami',
  'dampak',
  'dampar',
  'mendamparkan',
  'didamparkan',
  'dampil',
  'damping',
  'berdamping',
  'didampingi',
  'mendampingi',
  'perdampingan',
  'dan',
  'dana',
  'danaharta',
  'danasiswa',
  'danau',
  'dandan',
  'dandanan',
  'didandan',
  'mendandan',
  'dandannya',
  'dandang',
  'dang',
  'dangai',
  'dangau',
  'dangdut',
  'dangir',
  'dangkal',
  'kedangkalan',
  'mendangkalkan',
  'danguk',
  'dansa',
  'dansauselessdansi',
  'dansanak',
  'dansing',
  'danta',
  'danu',
  'danuh',
  'danur',
  'dap',
  'dapa',
  'dapat',
  'dapati',
  'dapatkan',
  'dapatnya',
  'sependapat',
  'pendapat',
  'pendapatannya',
  'dapatan',
  'dapatlah',
  'dapatkah',
  'dapatkanlah',
  'dapur',
  'dapurnya',
  'kedapuran',
  'dara',
  'kedaraan',
  'darab',
  'darabkan',
  'darah',
  'pendarah',
  'mendarah',
  'darahnya',
  'darahlah',
  'darai',
  'daras',
  'darat',
  'mendarat',
  'daratan',
  'daratuselessdarau',
  'dari',
  'darihal',
  'daripada',
  'darjah',
  'darjat',
  'mendarjatkan',
  'darjatnya',
  'darma',
  'darmawisata',
  'daru',
  'darul',
  'darulakhirat',
  'darulaman',
  'darulbaqa',
  'darulfana',
  'darulharab',
  'darulislam',
  'daruljalal',
  'darulrizwan',
  'darun',
  'darurat',
  'mendaruratkan',
  'daruri',
  'darussalam',
  'darwis',
  'das',
  'dasa',
  'dasalumba',
  'dasar',
  'berdasar',
  'mendasarkan',
  'didasarkan',
  'dasau',
  'dasawarsa',
  'dasi',
  'dastur',
  'dasun',
  'dat',
  'data',
  'datang',
  'datanglah',
  'mendatang',
  'didatangi',
  'didatangkan',
  'kedatangan',
  'berdatang',
  'datar',
  'mendatar',
  'didatarkan',
  'dataran',
  'dati',
  'datia',
  'datin',
  'datoâ',
  'datuk',
  'dauk',
  'daulat',
  'mendaulat',
  'pendaulat',
  'kedaulatan',
  'daun',
  'berdaun',
  'daup',
  'daur',
  'dawah',
  'dawai',
  'dedawai',
  'daya',
  'perdaya',
  'memperdaya',
  'diperdaya',
  'mendaya',
  'terpedaya',
  'perdayaan',
  'dayah',
  'dayak',
  'dayang',
  'pendayang',
  'dayu',
  'dayung',
  'mendayung',
  'dayus',
  'debab',
  'debak',
  'debuk',
  'debam',
  'deban',
  'debap',
  'debar',
  'debaran',
  'mendebarkan',
  'debas',
  'debat',
  'mendebat',
  'perdebatan',
  'perdebatkan',
  'debentur',
  'debik',
  'debing',
  'debit',
  'didebitkan',
  'mendebitkan',
  'debu',
  'debunya',
  'debuk',
  'debunga',
  'mendebugakan',
  'pendebungaan',
  'debur',
  'deburan',
  'debus',
  'decap',
  'decit',
  'decup',
  'dedah',
  'dedahkan',
  'dedahan',
  'dedak',
  'dedalu',
  'dedap',
  'dedaunan',
  'dedeman',
  'dedes',
  'dedikasi',
  'defendan',
  'defensif',
  'definisi',
  'definisikan',
  'pendefinisian',
  'definitif',
  'defisit',
  'deflasi',
  'degam',
  'degap',
  'degar',
  'degil',
  'kedegilan',
  'degilnya',
  'deguk',
  'degum',
  'degung',
  'degup',
  'degupan',
  'degur',
  'deham',
  'dehidrasi',
  'dek',
  'deka',
  'dekad',
  'dekagram',
  'dekah',
  'dekaiter',
  'dekak',
  'dekam',
  'dekameter',
  'dekan',
  'dekar',
  'dekat',
  'dekatnya',
  'dekati',
  'dekatkan',
  'pendekatan',
  'berdekat',
  'dekih',
  'dekit',
  'deklamasi',
  'deklamator',
  'deklarasi',
  'dekor',
  'dekorasi',
  'dekri',
  'dekuhuselessdekah',
  'dekunci',
  'dekus',
  'dekut',
  'delah',
  'delamak',
  'delan',
  'delapan',
  'delegasi',
  'delik',
  'delima',
  'delman',
  'delta',
  'delung',
  'demah',
  'demam',
  'demang',
  'demap',
  'dembam',
  'demberang',
  'dembun',
  'demdem',
  'demi',
  'demik',
  'demikian',
  'demikianlah',
  'demis',
  'demit',
  'demo',
  'demograf',
  'demografi',
  'demokrasi',
  'pendemokrasian',
  'demokrat',
  'demokratik',
  'demonstrasi',
  'mendemonstrasikan',
  'dempak',
  'dempang',
  'dempek',
  'dempet',
  'dempir',
  'dempit',
  'dempuk',
  'dempul',
  'dempung',
  'demuduk',
  'demut',
  'denah',
  'denai',
  'denak',
  'dencang',
  'denda',
  'dendaan',
  'dendam',
  'mendendam',
  'dendaman',
  'dendang',
  'dendangkan',
  'dendangan',
  'dendeng',
  'denderam',
  'dengan',
  'dengap',
  'dengar',
  'dengarkan',
  'dengari',
  'pendengar',
  'kedengaran',
  'dengarnya',
  'dengarlah',
  'denggi',
  'dengih',
  'denging',
  'dengkang',
  'dengkel',
  'dengki',
  'kedengkian',
  'dengkik',
  'dengking',
  'dengkis',
  'dengkol',
  'dengkul',
  'dengkung',
  'dengkur',
  'dengkuran',
  'dengkus',
  'dengu',
  'denguk',
  'dengung',
  'dengungkan',
  'dengungan',
  'dengus',
  'dengusnya',
  'dengut',
  'denim',
  'dentam',
  'dentaman',
  'dentang',
  'denting',
  'dentum',
  'dentuman',
  'dentung',
  'dentur',
  'denyar',
  'denyut',
  'denyutan',
  'depa',
  'mendepakan',
  'depak',
  'depan',
  'depannya',
  'depani',
  'depang',
  'mendepang',
  'depap',
  'depo',
  'deposit',
  'mendepositkan',
  'depot',
  'depu',
  'depun',
  'depus',
  'deputi',
  'dera',
  'deraan',
  'derahan',
  'derai',
  'berderai',
  'derajang',
  'derak',
  'derakuselessderik',
  'deram',
  'deramuselessderum',
  'derana',
  'derang',
  'derap',
  'deras',
  'menderas',
  'penderas',
  'derasnya',
  'derau',
  'derauan',
  'derawa',
  'derek',
  'deret',
  'deretan',
  'mendereti',
  'didereti',
  'menderetkan',
  'dideretkan',
  'derhaka',
  'penderhaka',
  'deria',
  'menderia',
  'derik',
  'dering',
  'deringnya',
  'deringan',
  'deringo',
  'deringu',
  'deris',
  'derisip',
  'derit',
  'derita',
  'deritanya',
  'deritai',
  'menderita',
  'deritaan',
  'derji',
  'derma',
  'dermanya',
  'dermakan',
  'pendermaan',
  'dermawan',
  'kedermawanan',
  'dermaga',
  'dermasiswa',
  'dermatologi',
  'dersana',
  'dersik',
  'deru',
  'menderu',
  'deruan',
  'deruk',
  'derum',
  'deruman',
  'derun',
  'derung',
  'derup',
  'derus',
  'derut',
  'desa',
  'kedesaan',
  'desah',
  'desahan',
  'desak',
  'mendesak',
  'desakan',
  'desar',
  'desas',
  'desaskan',
  'desasuselessdesus',
  'desau',
  'desi',
  'desigram',
  'desik',
  'desiliter',
  'desimeter',
  'desing',
  'desingan',
  'desir',
  'desiran',
  'desis',
  'desisan',
  'desit',
  'deskripsi',
  'deskriptif',
  'destar',
  'destinasi',
  'desuk',
  'desup',
  'desur',
  'desus',
  'desuskan',
  'desut',
  'detak',
  'detap',
  'detapan',
  'detar',
  'detas',
  'detergen',
  'detik',
  'detikan',
  'deting',
  'detup',
  'detus',
  'deuterium',
  'deuteron',
  'dewa',
  'dewakan',
  'pendewaan',
  'dewadaru',
  'dewala',
  'dewan',
  'dewana',
  'dewangga',
  'dewasa',
  'mendewasakan',
  'pendewasaan',
  'kedewasaan',
  'dewata',
  'dewi',
  'di',
  'dia',
  'diabetes',
  'diafragma',
  'diagnosa',
  'diagnosis',
  'diagnostik',
  'diakoni',
  'diakronik',
  'dialek',
  'dialektik',
  'dialisis',
  'dialog',
  'dialognya',
  'diam',
  'diamlah',
  'diamnya',
  'diami',
  'diamkan',
  'kediaman',
  'diameter',
  'dian',
  'diang',
  'pendiangan',
  'diari',
  'diat',
  'diatkan',
  'diayah',
  'pendiayah',
  'dibaja',
  'didaktik',
  'didal',
  'didih',
  'didihkan',
  'pendidihan',
  'didik',
  'didikan',
  'berpendidikan',
  'didis',
  'diesel',
  'diet',
  'dietnya',
  'dif',
  'difteria',
  'diftong',
  'digit',
  'digital',
  'dikara',
  'dikau',
  'dikesampingkan',
  'dikir',
  'dikit',
  'sedikit',
  'sedikitnya',
  'sedikitlah',
  'berdikit',
  'mendikit',
  'pendikit',
  'diksa',
  'diktator',
  'dil',
  'dila',
  'dilah',
  'dilalah',
  'dilam',
  'dilema',
  'dim',
  'dimensi',
  'dinamik',
  'mendinamikkan',
  'kedinamikan',
  'dinamika',
  'dinamit',
  'dinamo',
  'dinar',
  'dinas',
  'dinasti',
  'dinda',
  'dinding',
  'dindingnya',
  'mendinding',
  'berdinding',
  'pendinding',
  'ding',
  'dingin',
  'mendingin',
  'didinginkan',
  'kedinginan',
  'pendingin',
  'dinginbeku',
  'dinginbekukan',
  'dingkis',
  'dingkit',
  'dini',
  'dinihari',
  'dinosaur',
  'dioksida',
  'dioksin',
  'dipan',
  'dipati',
  'dipertua',
  'dipertuan',
  'diploma',
  'diplomanya',
  'diplomasi',
  'diplomat',
  'diplomatik',
  'diraja',
  'direktori',
  'dirgahayu',
  'dirham',
  'diri',
  'berdiri',
  'dirikan',
  'terdiri',
  'pendiri',
  'berpendirian',
  'diris',
  'dirus',
  'disember',
  'disenteri',
  'disertasi',
  'disiplin',
  'disiplinkan',
  'disiplinnya',
  'diskaun',
  'disko',
  'diskotek',
  'diskriminasi',
  'didiskriminasikan',
  'mendiskriminasikan',
  'diskusi',
  'mendiskusikan',
  'didiskusikan',
  'dispensari',
  'distribusi',
  'mendistribusikan',
  'dito',
  'dius',
  'dividen',
  'divisyen',
  'diwangsa',
  'doa',
  'mendoakan',
  'didoakan',
  'berdoa',
  'dobi',
  'mendobi',
  'dobrak',
  'dodoi',
  'dodoikan',
  'dodol',
  'dodong',
  'dodos',
  'dodot',
  'dogel',
  'dogeng',
  'doger',
  'dogma',
  'dogmatik',
  'dogmatisme',
  'dogol',
  'dohok',
  'dok',
  'dokar',
  'dokoh',
  'dokok',
  'dokong',
  'doktor',
  'kedoktoran',
  'doktoranda',
  'doktorandus',
  'doktrin',
  'dokumen',
  'dokumenkan',
  'dokumentari',
  'dokumentasi',
  'dol',
  'dolak',
  'dalik',
  'dolar',
  'domah',
  'domba',
  'domestik',
  'dominan',
  'dominasi',
  'didominasikan',
  'dominasinya',
  'dominggo',
  'domino',
  'domol',
  'dompak',
  'dompet',
  'donat',
  'dondang',
  'dondangan',
  'mendondang',
  'dondon',
  'dondong',
  'dongak',
  'donggakkan',
  'dongeng',
  'mendongeng',
  'didongengkan',
  'dongengan',
  'dongenan',
  'dongkok',
  'dongkol',
  'kedongkolan',
  'dongkrak',
  'dongok',
  'dop',
  'dopis',
  'dor',
  'dorong',
  'dorongan',
  'mendorong',
  'didorong',
  'dos',
  'dosa',
  'berdosa',
  'dosej',
  'dosen',
  'dosonok',
  'doyak',
  'doyong',
  'dozen',
  'dr',
  'draf',
  'drafnya',
  'dram',
  'drama',
  'dramanya',
  'dramatik',
  'dramatis',
  'drastik',
  'drebar',
  'dresar',
  'dril',
  'dua',
  'berdua',
  'pendua',
  'mendua',
  'perdua',
  'perduaan',
  'perduakan',
  'duai',
  'duak',
  'dualisme',
  'dubilah',
  'dubuk',
  'dubur',
  'duda',
  'dudanya',
  'dudu',
  'duduk',
  'duduki',
  'dudukkan',
  'kedudukan',
  'dudukan',
  'pendudukan',
  'sekedudukan',
  'penduduk',
  'duduknya',
  'dudut',
  'duet',
  'duetnya',
  'duga',
  'duganya',
  'dugaan',
  'dugal',
  'dugang',
  'dugas',
  'duha',
  'duit',
  'berduit',
  'duka',
  'kedukaan',
  'mendukakan',
  'dukacita',
  'dukacitanya',
  'mendukacitakan',
  'dukan',
  'dukana',
  'dukat',
  'duku',
  'dukuh',
  'dukun',
  'perdukunan',
  'mendukuni',
  'didukuni',
  'dukung',
  'berdukung',
  'dukungan',
  'dulang',
  'pendulangan',
  'duli',
  'dulinya',
  'dulu',
  'dumung',
  'dunak',
  'dungkul',
  'dungu',
  'dungun',
  'dunia',
  'dunianya',
  'duniaku',
  'keduniaan',
  'duniawi',
  'dupa',
  'mendupai',
  'pendupaan',
  'dupak',
  'dura',
  'duralium',
  'duralumin',
  'durasi',
  'duri',
  'durinya',
  'durian',
  'durias',
  'durja',
  'durjana',
  'mendurjanakan',
  'pendurjanaan',
  'kedurjanaan',
  'dursila',
  'duru',
  'dusi',
  'dusta',
  'mendusta',
  'pendustaan',
  'dustur',
  'dusun',
  'duta',
  'kedutaan',
  'duti',
  'duyun',
  'duyung',
  'dwi',
  'dwiatom',
  'dwibahasa',
  'dwibahasawan',
  'dwibibir',
  'dwibulanan',
  'dwifokus',
  'dwifungsi',
  'dwiganda',
  'dwiguna',
  'dwihuruf',
  'dwilambang',
  'dwilipat',
  'dwilogam',
  'dwimingguan',
  'dwipaksa',
  'dwipaksi',
  'dwipurwa',
  'dwisamping',
  'dwisisi',
  'dwisuku',
  'dwisyarat',
  'dwitahun',
  'dwitahunan',
  'dwitunggal',
  'dwiwarna',
  'ebam',
  'ebek',
  'ebeng',
  'ebi',
  'ebonit',
  'ebro',
  'ecek',
  'ecer',
  'ecstasy',
  'edan',
  'edar',
  'edarkan',
  'edaran',
  'edisi',
  'efektif',
  'efisien',
  'efisiensi',
  'egah',
  'ego',
  'keegoan',
  'egois',
  'egoisme',
  'egoistik',
  'egosentrik',
  'egotisme',
  'eja',
  'ejaan',
  'ejawantah',
  'ejek',
  'ejekan',
  'ejen',
  'ekaatom',
  'ekabahasa',
  'ekafungsi',
  'ekahala',
  'ekamatra',
  'ekapaksi',
  'ekar',
  'ekasamping',
  'ekasuku',
  'ekawarna',
  'ekologi',
  'ekonomi',
  'ekonominya',
  'ekonomikal',
  'ekor',
  'ekori',
  'ekoran',
  'ekosistem',
  'ekopelancongan',
  'eksais',
  'eksekutif',
  'eksklusif',
  'eksklusiviti',
  'eksogami',
  'eksotik',
  'ekspedisi',
  'eksperimen',
  'eksperimental',
  'eksperimentasi',
  'eksplisit',
  'eksploit',
  'eksploitasi',
  'eksplorasi',
  'ekspo',
  'eksponen',
  'eksport',
  'mengeksport',
  'pengeksport',
  'eksposisi',
  'ekspres',
  'ekspresi',
  'ekspresif',
  'ekstra',
  'ekstravganza',
  'ekstrem',
  'ekstremis',
  'ekstrim',
  'ekuinoks',
  'ekuiti',
  'ekuestrian',
  'ekzos',
  'ela',
  'elak',
  'elaknya',
  'elakkan',
  'elang',
  'elastik',
  'elaun',
  'elegan',
  'elegi',
  'elektrik',
  'elektrikal',
  'elektrod',
  'elektrodinamik',
  'elektrolisis',
  'elektrolit',
  'elektromagnet',
  'elektrometer',
  'elektron',
  'elektronik',
  'dielektronikkan',
  'elektroskop',
  'elektroteknik',
  'elektrum',
  'elemen',
  'elit',
  'eloan',
  'elok',
  'eloklah',
  'elokkan',
  'elon',
  'elus',
  'emak',
  'emansipasi',
  'emas',
  'keemasan',
  'emat',
  'embacang',
  'embak',
  'embal',
  'embalau',
  'embarau',
  'embargo',
  'embaru',
  'embat',
  'embek',
  'embih',
  'embrio',
  'embriologi',
  'embuai',
  'embuh',
  'embun',
  'mengembun',
  'embus',
  'embut',
  'emel',
  'emeritus',
  'emigran',
  'emigrasi',
  'emolumen',
  'emosi',
  'emosional',
  'empang',
  'empangan',
  'empap',
  'empar',
  'empat',
  'empatnya',
  'perempat',
  'perempatan',
  'empati',
  'empayar',
  'empedal',
  'empedu',
  'empelas',
  'empenak',
  'emper',
  'emping',
  'empirik',
  'empirikal',
  'empiris',
  'empis',
  'empoh',
  'emporium',
  'empoyan',
  'empu',
  'empuk',
  'mengempukkan',
  'empul',
  'empulur',
  'empunya',
  'emunggai',
  'enak',
  'enaknya',
  'mengenakkan',
  'dienakkan',
  'keenakan',
  'enakmen',
  'enam',
  'enamnya',
  'enamel',
  'enap',
  'enapan',
  'enau',
  'encal',
  'encang',
  'encer',
  'encik',
  'encit',
  'endah',
  'mengendahkan',
  'diendahkan',
  'perendahan',
  'endal',
  'endan',
  'endang',
  'endap',
  'endapan',
  'enda',
  'endemik',
  'endokrin',
  'endometriosis',
  'endong',
  'endul',
  'endus',
  'enggak',
  'enggan',
  'mengenggankan',
  'keengganan',
  'enggang',
  'enggat',
  'enggil',
  'engkabang',
  'engkah',
  'engkapuselessengkip',
  'engkau',
  'berengkau',
  'engkil',
  'engkol',
  'engku',
  'engkuk',
  'engsel',
  'engsot',
  'enjak',
  'enjal',
  'enjelai',
  'enjet',
  'enjin',
  'enjut',
  'enjutan',
  'ensiklopedia',
  'entah',
  'berentah',
  'entek',
  'enteng',
  'entiti',
  'entomologi',
  'entrepot',
  'enzim',
  'epak',
  'epal',
  'epek',
  'epidemik',
  'epidemiologi',
  'epidiaskop',
  'epigram',
  'epik',
  'epikepilog',
  'episod',
  'episodepok',
  'epok',
  'epolet',
  'epos',
  'epsom',
  'era',
  'erak',
  'erang',
  'erangan',
  'erat',
  'eratkan',
  'eratnya',
  'keeratan',
  'erau',
  'ereksi',
  'ergonomik',
  'erik',
  'erong',
  'eropah',
  'erotik',
  'erti',
  'diertikan',
  'mengerti',
  'ertian',
  'esa',
  'mengesakan',
  'keesaan',
  'esak',
  'esakan',
  'esei',
  'esek',
  'eselon',
  'esok',
  'keesokan',
  'mengesokkan',
  'estar',
  'estet',
  'estetik',
  'estetika',
  'eter',
  'etika',
  'etiket',
  'etimologi',
  'etiologi',
  'etnik',
  'etnografi',
  'etnologi',
  'europium',
  'eutektik',
  'evolusi',
  'exco',
  'faal',
  'fabel',
  'fabrik',
  'fabrikasi',
  'fadilah',
  'faedah',
  'faedahnya',
  'faham',
  'fahami',
  'fahamkan',
  'fahaman',
  'sefahaman',
  'fahamlah',
  'fahamilah',
  'faharasat',
  'fail',
  'pemfailan',
  'fajar',
  'fakir',
  'faks',
  'fakskan',
  'fakta',
  'faktor',
  'fakulti',
  'falak',
  'falsafah',
  'famili',
  'familinya',
  'fana',
  'memfanakan',
  'difanakan',
  'fanatik',
  'kefanatikan',
  'fanatisme',
  'fantasi',
  'memfantasikan',
  'fantasia',
  'fantastik',
  'farad',
  'faraid',
  'faraj',
  'fardu',
  'farmakologi',
  'farmaseutikal',
  'farmasi',
  'farsakh',
  'fasa',
  'fasakh',
  'fasal',
  'fasih',
  'memfasihkan',
  'kefasihan',
  'fasilitator',
  'fasik',
  'fasis',
  'fasisme',
  'fatahah',
  'fatihah',
  'fatimah',
  'fatogram',
  'fatom',
  'fatometer',
  'fatur',
  'fatwa',
  'berfatwa',
  'difatwa',
  'memfatwa',
  'fauna',
  'februari',
  'felo',
  'fenol',
  'fenologi',
  'fenomena',
  'feri',
  'ferum',
  'festival',
  'fesyen',
  'memfesyenkan',
  'fetus',
  'feudal',
  'fiber',
  'fidyah',
  'figura',
  'figuratif',
  'fiil',
  'fikah',
  'fikir',
  'berfikir',
  'memikir',
  'difikir',
  'pemikir',
  'terfikir',
  'fiksyen',
  'filamen',
  'filem',
  'difilemkan',
  'memfilemkan',
  'perfileman',
  'filipina',
  'filologi',
  'final',
  'finalis',
  'firasat',
  'firaun',
  'firdaus',
  'firdausi',
  'firma',
  'firman',
  'firus',
  'fisabilillah',
  'fisiologi',
  'fisioterapi',
  'fiskal',
  'fitnah',
  'fitnahkan',
  'pemfitnahan',
  'fitrah',
  'memfitrahkan',
  'fius',
  'fizik',
  'fizikal',
  'fleksibel',
  'fleksibiliti',
  'flet',
  'flora',
  'florin',
  'flut',
  'fobia',
  'fokus',
  'fokuskan',
  'folio',
  'folikel',
  'fomen',
  'fomica',
  'fonem',
  'fonemik',
  'fonetik',
  'fonologi',
  'forensik',
  'formal',
  'format',
  'formatnya',
  'formula',
  'forseps',
  'forum',
  'fosfat',
  'fosforus',
  'fosil',
  'foto',
  'fotogenik',
  'fotograf',
  'fotografi',
  'fotogravur',
  'fotosintesis',
  'fotostat',
  'foya',
  'fragmen',
  'franca',
  'francais',
  'francaisnya',
  'frekuensi',
  'fret',
  'fros',
  'fundamental',
  'fundamentalis',
  'fungsi',
  'fungsinya',
  'fungsional',
  'furlong',
  'furqan',
  'furuk',
  'fusta',
  'futsal',
  'futuristik',
  'gaba',
  'gabah',
  'gabai',
  'gabak',
  'gabal',
  'gabas',
  'gabenor',
  'gabuk',
  'gabung',
  'gabungkan',
  'gabungan',
  'gabus',
  'gada',
  'gadah',
  'gadai',
  'gadaikan',
  'gadaian',
  'gadamala',
  'gadang',
  'gading',
  'gadis',
  'bergadis',
  'kegadisan',
  'gadouselessgado',
  'gadu',
  'gaduh',
  'menggaduh',
  'digaduhkan',
  'pergaduhan',
  'gadung',
  'gagah',
  'gagahi',
  'gagahkan',
  'kegagahan',
  'gagai',
  'gagak',
  'gagal',
  'gagalkan',
  'kegagalan',
  'gagang',
  'gagap',
  'gagas',
  'gagau',
  'gaguk',
  'gah',
  'gahar',
  'gahara',
  'gahari',
  'gaharu',
  'gai',
  'gait',
  'gajah',
  'gajak',
  'gajet',
  'gaji',
  'bergaji',
  'menggaji',
  'digaji',
  'penggajian',
  'gajus',
  'gala',
  'galanya',
  'galah',
  'galai',
  'galak',
  'galaknya',
  'galakkan',
  'galakan',
  'galang',
  'menggalang',
  'digalang',
  'galangan',
  'galar',
  'galas',
  'galau',
  'galei',
  'galeri',
  'galeria',
  'gali',
  'galian',
  'galias',
  'galibut',
  'galir',
  'galu',
  'galuh',
  'galur',
  'galvani',
  'galvanometer',
  'gam',
  'gamkan',
  'gama',
  'gamak',
  'tergamak',
  'gamaknya',
  'gamam',
  'gamang',
  'gamat',
  'gamatkan',
  'gamba',
  'gambang',
  'gambar',
  'gambarkan',
  'gambaran',
  'gambarnya',
  'gambarajah',
  'gambir',
  'gambuh',
  'gambus',
  'gambut',
  'gamelan',
  'gamis',
  'gamit',
  'bergamit',
  'menggamit',
  'gamitan',
  'gampang',
  'gampar',
  'gamut',
  'ganal',
  'ganas',
  'keganasan',
  'gancang',
  'gancaran',
  'gancu',
  'ganda',
  'gandakan',
  'gandaan',
  'gandal',
  'gandan',
  'gandang',
  'gandapura',
  'gandar',
  'gandaria',
  'gandariah',
  'gandarukam',
  'gandarusa',
  'gandasuli',
  'gandi',
  'gandik',
  'ganding',
  'gandingkan',
  'gandingan',
  'gandingannya',
  'gandu',
  'ganduh',
  'gandum',
  'gandung',
  'gandus',
  'gang',
  'ganggang',
  'ganggu',
  'gangguan',
  'ganggut',
  'gangsa',
  'gangsar',
  'gangsi',
  'gangsir',
  'ganih',
  'ganja',
  'ganjak',
  'mengganjak',
  'ganjakan',
  'ganjal',
  'ganjar',
  'ganjaran',
  'ganjat',
  'ganjil',
  'mengganjil',
  'keganjilan',
  'ganjur',
  'gantal',
  'gantang',
  'ganti',
  'berganti',
  'gantikan',
  'gantian',
  'gantih',
  'ganting',
  'gantung',
  'gantungkan',
  'bergantung',
  'digantung',
  'menggantung',
  'gantungan',
  'mempergantungi',
  'dipergantungi',
  'ganu',
  'ganyah',
  'ganyang',
  'ganyar',
  'ganyut',
  'gapai',
  'gapil',
  'gapis',
  'gapura',
  'gar',
  'gara',
  'garaj',
  'garam',
  'garamnya',
  'menggaram',
  'penggaraman',
  'garan',
  'garang',
  'garap',
  'garapan',
  'garapannya',
  'garau',
  'gari',
  'garing',
  'garis',
  'gariskan',
  'garisan',
  'garison',
  'garit',
  'garpu',
  'garu',
  'garunya',
  'garuda',
  'garuk',
  'garut',
  'gas',
  'gasak',
  'gasal',
  'gasang',
  'gasiuselessgasi',
  'gasing',
  'gasket',
  'gasolin',
  'gastrik',
  'gastrin',
  'gatal',
  'menggatalkan',
  'kegatalan',
  'gatalbirah',
  'gatra',
  'gauk',
  'gaul',
  'bergaul',
  'gauli',
  'gaulkan',
  'pergaulan',
  'gaun',
  'gaung',
  'gawai',
  'gawal',
  'gawang',
  'gawar',
  'gawat',
  'kegawatan',
  'gay',
  'gaya',
  'gayakan',
  'gayaan',
  'gayanya',
  'gayal',
  'gayam',
  'gayang',
  'gayat',
  'gayau',
  'gayuh',
  'gayuk',
  'gayun',
  'gayung',
  'gayut',
  'menggayutkan',
  'gaz',
  'gear',
  'gearnya',
  'geartronik',
  'gebang',
  'gebar',
  'gebeng',
  'gebu',
  'gebuk',
  'gecar',
  'gedabah',
  'gedabang',
  'gedabir',
  'gedabu',
  'gedabung',
  'gedaluselessgeduk',
  'gedang',
  'gedayang',
  'gedayung',
  'gedebak',
  'gedebuk',
  'gedebung',
  'gedegah',
  'gedeguh',
  'gedek',
  'gedembai',
  'gedempol',
  'gedempong',
  'gedempung',
  'gedik',
  'gedoboh',
  'gedombak',
  'gedubang',
  'gedung',
  'geduyut',
  'gegadan',
  'gegai',
  'gegak',
  'gegala',
  'gegambir',
  'gegap',
  'gegar',
  'gegarnya',
  'gegarkan',
  'gegaran',
  'bergegar',
  'gegas',
  'gegasing',
  'gegat',
  'gegau',
  'gegeli',
  'geger',
  'gegerit',
  'gegetar',
  'gegiring',
  'gejala',
  'gejolak',
  'gelabah',
  'gelabir',
  'gelabur',
  'geladak',
  'gelagah',
  'gelagar',
  'gelagat',
  'gelak',
  'menggelakkan',
  'gelakak',
  'gelalar',
  'gelam',
  'gelama',
  'gelamai',
  'gelambir',
  'gelandang',
  'gelandangan',
  'gelang',
  'pergelangan',
  'gelanggang',
  'gelanggangnya',
  'gelangsar',
  'gelangsir',
  'gelantung',
  'gelap',
  'gelapkan',
  'kegelapan',
  'penggelapan',
  'gelapuselessgelemat',
  'gelap',
  'gelita',
  'gelar',
  'gelaran',
  'menggelarkan',
  'gelas',
  'gelatak',
  'gelatik',
  'gelatuk',
  'gelawai',
  'gelebak',
  'gelebap',
  'gelebar',
  'gelebat',
  'geleber',
  'gelebuk',
  'gelebung',
  'geleca',
  'gelecah',
  'gelecek',
  'geledah',
  'penggeledahan',
  'geledang',
  'geleding',
  'geledur',
  'gelegak',
  'gelegar',
  'gelegata',
  'gelek',
  'bergelek',
  'tergelek',
  'gelekkan',
  'gelekak',
  'gelekek',
  'gelema',
  'geleman',
  'gelemat',
  'gelembung',
  'gelembungan',
  'gelembur',
  'gelempung',
  'gelen',
  'gelenang',
  'gelencong',
  'gelendong',
  'geleng',
  'bergeleng',
  'gelengan',
  'gelenggang',
  'gelentang',
  'gelenting',
  'gelenyar',
  'gelepak',
  'gelepar',
  'gelepik',
  'gelepuk',
  'gelepung',
  'gelepur',
  'gelesai',
  'gelesek',
  'geleser',
  'geleta',
  'geletak',
  'geletar',
  'geletek',
  'geletik',
  'geleting',
  'geletis',
  'geletuk',
  'geli',
  'menggeli',
  'kegelian',
  'geliang',
  'geliut',
  'geliat',
  'gelibar',
  'gelibas',
  'gelibat',
  'gelibir',
  'gelicau',
  'geliga',
  'geliuselessgeleman',
  'geligi',
  'geligin',
  'geligir',
  'geligis',
  'geligit',
  'gelimang',
  'gelimbir',
  'gelimir',
  'gelimpang',
  'bergelimpangan',
  'gelimun',
  'gelincat',
  'gelincik',
  'gelincir',
  'gelinciran',
  'gelincirannya',
  'gelincuh',
  'gelinding',
  'gelinggam',
  'gelingsir',
  'gelinjang',
  'gelintang',
  'gelintar',
  'gelintin',
  'gelintir',
  'gelipar',
  'gelip',
  'gelisah',
  'menggelisah',
  'kegelisahan',
  'gelitak',
  'geliung',
  'gelobok',
  'gelobor',
  'gelocak',
  'gelodak',
  'gelodar',
  'gelogok',
  'gelohok',
  'gelojak',
  'gelojoh',
  'kegelojohan',
  'gelokak',
  'gelombang',
  'gelomor',
  'gelompar',
  'geloneng',
  'gelonggang',
  'gelonggong',
  'gelongsor',
  'gelongsoran',
  'gelontor',
  'gelopak',
  'gelora',
  'menggelora',
  'gelosok',
  'gelotak',
  'geluga',
  'gelugur',
  'gelugut',
  'geluh',
  'geluk',
  'gelulur',
  'gelumak',
  'gelumang',
  'gelumat',
  'gelumbak',
  'gelumpung',
  'geluncur',
  'gelung',
  'menggelung',
  'gelungan',
  'geluntur',
  'gelup',
  'gelupas',
  'gelupur',
  'gelut',
  'pergelutan',
  'gema',
  'menggema',
  'gemal',
  'gemala',
  'gemalai',
  'gemam',
  'geman',
  'gemang',
  'gemap',
  'gemar',
  'gemari',
  'gemarkan',
  'kegemaran',
  'gemas',
  'gemaung',
  'gemawan',
  'gembadak',
  'gembak',
  'gembala',
  'menggembala',
  'gembar',
  'gembira',
  'gembiranya',
  'menggembirakan',
  'kegembiraan',
  'gembleng',
  'penggemblengan',
  'gembuk',
  'gembul',
  'gembung',
  'gembur',
  'gemburkan',
  'gembut',
  'gemelentam',
  'gemeletak',
  'gemeletap',
  'gemeletek',
  'gemeletuk',
  'gemelugut',
  'gemendit',
  'gementam',
  'gementar',
  'gemercak',
  'gemercik',
  'gemerencang',
  'gemerencing',
  'gemerencung',
  'gemeresak',
  'gemeresik',
  'gemeretap',
  'gemeretik',
  'gemeretuk',
  'gemerlap',
  'gemerlapan',
  'gemi',
  'gemilang',
  'kegemilangan',
  'gemilap',
  'gemirang',
  'gemirap',
  'gempa',
  'gempal',
  'gempar',
  'gemparlah',
  'gemparkan',
  'kegemparan',
  'gempita',
  'gempul',
  'gempur',
  'penggempur',
  'gemuk',
  'menggemukkan',
  'gemukkan',
  'kegemukan',
  'penggemukan',
  'gemulung',
  'gemuntur',
  'gemuruh',
  'genahar',
  'genang',
  'menggenang',
  'digenangi',
  'genap',
  'genapkan',
  'menggenapi',
  'genapnya',
  'genaplah',
  'gencat',
  'gencatan',
  'gencel',
  'gencir',
  'gendala',
  'menggendalakan',
  'gendang',
  'gendeng',
  'genderang',
  'gendis',
  'gendola',
  'gendon',
  'gendong',
  'gendongan',
  'gendut',
  'genealogi',
  'generasi',
  'generator',
  'genetik',
  'geng',
  'genggam',
  'menggenggam',
  'digenggam',
  'segenggam',
  'tergenggam',
  'genggang',
  'genggayang',
  'genggeng',
  'genggong',
  'genggulang',
  'gengster',
  'genih',
  'genit',
  'kegenitan',
  'genjala',
  'genjang',
  'genjot',
  'genjur',
  'genomik',
  'genre',
  'genseng',
  'gensi',
  'genta',
  'gentari',
  'gentarkan',
  'gentaran',
  'gentala',
  'gentar',
  'gentas',
  'gentat',
  'gentel',
  'gentian',
  'genting',
  'kegentingan',
  'gentis',
  'genu',
  'genus',
  'genyeh',
  'genyut',
  'geobotano',
  'geofizik',
  'geofizikal',
  'geografi',
  'geoinformatik',
  'geokimia',
  'geologi',
  'geometri',
  'geometrik',
  'geometrikal',
  'geopolitik',
  'gepuh',
  'gepuk',
  'gera',
  'gerabak',
  'gerabakuselessgerubuk',
  'gerabang',
  'geradi',
  'geragai',
  'geragas',
  'geragau',
  'geraguk',
  'gerahak',
  'geraham',
  'gerai',
  'gerainya',
  'gerak',
  'gerakkan',
  'gerakan',
  'gerakuselessgerayu',
  'gerak',
  'geri',
  'gerik',
  'geram',
  'menggeram',
  'kegeraman',
  'geramang',
  'geramsut',
  'geramung',
  'geramut',
  'geran',
  'gerang',
  'gerangan',
  'gerantang',
  'gerantung',
  'gerapai',
  'gerapak',
  'gerapu',
  'gerat',
  'gerau',
  'gerawan',
  'gerbak',
  'gerbang',
  'menggerbang',
  'gerbanguselessgeresing',
  'gerbanguselessgersul',
  'gerbasuselessgerbus',
  'gerbong',
  'gerdam',
  'gerdum',
  'gerecak',
  'gereja',
  'gerek',
  'gerencang',
  'gerenek',
  'gerengau',
  'gerenggung',
  'gerengseng',
  'gerentam',
  'gerentang',
  'gerenyam',
  'gerenyau',
  'gerenyeng',
  'gerenyet',
  'gerenyot',
  'gerepek',
  'gerepes',
  'geresek',
  'geretak',
  'geretuk',
  'gergaji',
  'gergasi',
  'gerha',
  'gerhana',
  'geriak',
  'gericau',
  'geridip',
  'gerigi',
  'gerigik',
  'gerigis',
  'gerila',
  'gerimis',
  'gerimit',
  'gerinda',
  'gerinding',
  'gering',
  'kegeringan',
  'geringsing',
  'gerinja',
  'gerinjam',
  'gerinting',
  'gerinyau',
  'gerip',
  'gerisik',
  'gerising',
  'gerit',
  'geritik',
  'gerlap',
  'gerlip',
  'gerluh',
  'germang',
  'gernyut',
  'gerobak',
  'geroboh',
  'gerobok',
  'geroda',
  'gerodak',
  'gerombol',
  'gerombolan',
  'gerombong',
  'gerompok',
  'gerondong',
  'geronggang',
  'geronggong',
  'gerontologi',
  'geronyong',
  'geronyot',
  'geropes',
  'gerosak',
  'gerosok',
  'gersak',
  'gersang',
  'gersik',
  'gertak',
  'digertak',
  'menggertak',
  'gertakan',
  'gertik',
  'gertuk',
  'geru',
  'geruai',
  'gerudi',
  'penggerudian',
  'geruguh',
  'gerugun',
  'gerugut',
  'geruh',
  'geruit',
  'geruk',
  'gerumit',
  'gerumuk',
  'gerun',
  'menggerunkan',
  'digeruni',
  'gerundang',
  'gerunguselessgerang',
  'gerunggung',
  'geruning',
  'gerunyut',
  'gerup',
  'gerus',
  'gerusih',
  'gerut',
  'gerutu',
  'gerutup',
  'gesa',
  'gesaan',
  'gesek',
  'gesekkan',
  'gesekan',
  'gesel',
  'geselkan',
  'geselan',
  'geseng',
  'geser',
  'menggeser',
  'geseran',
  'geta',
  'getah',
  'getak',
  'getang',
  'getangguri',
  'getar',
  'menggetar',
  'getaran',
  'getas',
  'getek',
  'geti',
  'getil',
  'getir',
  'kegetiran',
  'getis',
  'getu',
  'gewang',
  'ghafar',
  'ghaib',
  'mengghaibkan',
  'keghaiban',
  'ghain',
  'ghair',
  'ghairah',
  'mengghairahkan',
  'keghairahan',
  'ghali',
  'ghalib',
  'gharamiah',
  'gharib',
  'ghazal',
  'ghulam',
  'ghurab',
  'ghurub',
  'giam',
  'gian',
  'giang',
  'giat',
  'giatkan',
  'kegiatan',
  'giau',
  'gibas',
  'gigau',
  'gigi',
  'pergigian',
  'giginya',
  'gigih',
  'kegigihan',
  'gigil',
  'menggigil',
  'gigis',
  'gigit',
  'gigitan',
  'bergigit',
  'gila',
  'gilakan',
  'menggilai',
  'digilai',
  'kegilaan',
  'gilalah',
  'gilan',
  'gilang',
  'gilap',
  'gilas',
  'gilau',
  'gili',
  'gilik',
  'giling',
  'menggiling',
  'gilir',
  'bergilir',
  'gilirkan',
  'giliran',
  'gilis',
  'gilitar',
  'gimik',
  'gimnasium',
  'gimnastik',
  'gimpal',
  'gimrama',
  'ginang',
  'gincah',
  'gincu',
  'gingging',
  'gingsir',
  'ginjal',
  'ginko',
  'ginseng',
  'gipsi',
  'gipsum',
  'girah',
  'girang',
  'kegirangan',
  'menggirangkan',
  'girap',
  'girapuselessgemirap',
  'giras',
  'giri',
  'giring',
  'giroskop',
  'gisal',
  'gisar',
  'gisi',
  'gising',
  'gita',
  'gitan',
  'gitar',
  'giuk',
  'giur',
  'menggiurkan',
  'giwah',
  'gizi',
  'gladiator',
  'glamor',
  'glasier',
  'gliserin',
  'glob',
  'global',
  'globalisasi',
  'glotis',
  'glu',
  'glukos',
  'glukosa',
  'gobang',
  'gobar',
  'gobek',
  'gocoh',
  'goda',
  'tergoda',
  'godaan',
  'godak',
  'godam',
  'godek',
  'godok',
  'gogah',
  'gogok',
  'gogol',
  'goh',
  'gol',
  'menggolkan',
  'golak',
  'pergolakan',
  'golakuselessgalik',
  'golanguselessgolek',
  'golbi',
  'golek',
  'golekkan',
  'golf',
  'golok',
  'golong',
  'golongkan',
  'golongan',
  'gomak',
  'gomba',
  'gombak',
  'gombang',
  'gombeng',
  'gomok',
  'gomol',
  'pergomolan',
  'goncang',
  'goncangkan',
  'goncangan',
  'gondang',
  'gondok',
  'gondol',
  'digondolkan',
  'menggondolkan',
  'gondola',
  'gondong',
  'gondrong',
  'gong',
  'gonggok',
  'gonggong',
  'gonggongan',
  'gonjak',
  'gonjok',
  'gonjong',
  'gonorea',
  'gonyeh',
  'gonyel',
  'gonyoh',
  'gopoh',
  'kegopohan',
  'gapah',
  'gopohuselessgapik',
  'gopohuselessgemang',
  'gopohuselessmamang',
  'gorak',
  'gorek',
  'goreng',
  'penggoreng',
  'gorengan',
  'gores',
  'goreskan',
  'goresan',
  'gori',
  'gorila',
  'gorok',
  'gosip',
  'gosipnya',
  'gosok',
  'gosokkan',
  'gosokan',
  'gosong',
  'gotes',
  'gotong',
  'gotonguselessroyong',
  'goyah',
  'menggoyahkan',
  'goyak',
  'goyang',
  'goyangkan',
  'goyangan',
  'graduan',
  'graf',
  'grafik',
  'grafit',
  'gram',
  'gramofon',
  'granit',
  'graviti',
  'gred',
  'digredkan',
  'menggredkan',
  'penggredan',
  'gril',
  'gris',
  'gros',
  'gua',
  'guah',
  'gualuselessguil',
  'guam',
  'guaman',
  'guamannya',
  'guano',
  'guar',
  'guatak',
  'guba',
  'gubah',
  'gubahan',
  'gubal',
  'penggubalan',
  'gubang',
  'gubas',
  'gubir',
  'gubra',
  'gubuk',
  'guci',
  'gudang',
  'pergudangan',
  'gudeg',
  'gudi',
  'gudu',
  'gugah',
  'gugat',
  'gugup',
  'menggugupkan',
  'kegugupan',
  'gugur',
  'gugurkan',
  'berguguran',
  'keguguran',
  'gugus',
  'gugusan',
  'guit',
  'gul',
  'gula',
  'menggula',
  'gulai',
  'menggulai',
  'digulaikan',
  'gulali',
  'gulat',
  'guli',
  'guling',
  'gulingkan',
  'berguling',
  'gulinguselessgantang',
  'gulinguselessgelantang',
  'gulir',
  'gulonggok',
  'gulung',
  'digulung',
  'menggulung',
  'gulungkan',
  'gulungan',
  'gulunguselessgemulung',
  'gulut',
  'gum',
  'gumai',
  'gumal',
  'gumam',
  'gumba',
  'gumbang',
  'gumbar',
  'gumbuk',
  'gumpal',
  'gumpalkan',
  'gumpalan',
  'gumpung',
  'gumuk',
  'guna',
  'gunakan',
  'gunaan',
  'kepenggunaan',
  'gunakanlah',
  'guncak',
  'gunci',
  'guncip',
  'gundah',
  'menggundahkan',
  'kegundahan',
  'gulana',
  'gundak',
  'gundi',
  'gundik',
  'bergundik',
  'guni',
  'gunjai',
  'guntak',
  'guntang',
  'gunting',
  'guntingnya',
  'guntingkan',
  'guntingan',
  'guntung',
  'guntur',
  'gunu',
  'gunung',
  'pergunungan',
  'ganang',
  'gup',
  'gurah',
  'gurang',
  'gurau',
  'gurauan',
  'gurdan',
  'guri',
  'gurih',
  'gurindam',
  'guring',
  'guris',
  'gurita',
  'guru',
  'berguru',
  'perguruan',
  'guruh',
  'gurun',
  'gurur',
  'gus',
  'gusar',
  'kegusaran',
  'gusarkan',
  'digusari',
  'menggusari',
  'gusi',
  'gusti',
  'gutik',
  'hab',
  'haba',
  'menghabakan',
  'perhabaan',
  'habeas',
  'habib',
  'habis',
  'habiskan',
  'habisan',
  'penghabisan',
  'habislah',
  'habisnya',
  'habitat',
  'habluk',
  'hablur',
  'menghablur',
  'dihablurkan',
  'habuan',
  'habuk',
  'had',
  'hadkan',
  'hadnya',
  'hadam',
  'penghadam',
  'dihadamkan',
  'menghadamkan',
  'hadang',
  'hadap',
  'hadapi',
  'hadapkan',
  'hadapan',
  'hadapannya',
  'terhadap',
  'hadas',
  'hadasnya',
  'hadat',
  'hadiah',
  'menghadiahi',
  'hadiahkan',
  'hadir',
  'berhadir',
  'hadiri',
  'hadirkan',
  'kehadiran',
  'hadirat',
  'hadirin',
  'hadis',
  'hadrah',
  'hadrat',
  'hafal',
  'hafalan',
  'hafaz',
  'hafazan',
  'hafbek',
  'hafiz',
  'hagiologi',
  'hahuuselesshahu',
  'hai',
  'haid',
  'hairan',
  'terhairan',
  'hairankan',
  'kehairanan',
  'haiwan',
  'kehaiwanan',
  'haj',
  'hajah',
  'hajarulaswad',
  'hajat',
  'hajati',
  'hajatkan',
  'hajatnya',
  'haji',
  'hajim',
  'hak',
  'haknya',
  'menghakkan',
  'hakcipta',
  'hakikat',
  'hakiki',
  'hakim',
  'berhakim',
  'kehakiman',
  'penghakiman',
  'dihakimi',
  'menghakimi',
  'menghakimkan',
  'hakis',
  'menghakis',
  'dihakiskan',
  'hakisan',
  'hakmilik',
  'menghakmilikkan',
  'hal',
  'berhal',
  'hala',
  'halakan',
  'halai',
  'halaju',
  'halal',
  'halalkan',
  'halalbihalal',
  'halaman',
  'halamannya',
  'halang',
  'terhalang',
  'menghalang',
  'halangan',
  'halangannya',
  'halau',
  'halaukan',
  'halba',
  'ehwal',
  'halia',
  'halilintar',
  'halim',
  'halimun',
  'halir',
  'halkum',
  'haloba',
  'halogen',
  'haluan',
  'haluannya',
  'halus',
  'halusi',
  'haluskan',
  'kehalusan',
  'halwa',
  'ham',
  'hama',
  'hamam',
  'hamba',
  'hambanya',
  'perhambaan',
  'hambakan',
  'hambar',
  'hambarnya',
  'hambat',
  'hambatan',
  'hambung',
  'hambur',
  'menghambur',
  'dihambur',
  'hamburan',
  'hambus',
  'hamil',
  'menghamilkan',
  'penghamilan',
  'kehamilan',
  'hamis',
  'kehamisan',
  'hampa',
  'hampakan',
  'kehampaan',
  'hampar',
  'hamparkan',
  'dihampari',
  'menghampari',
  'hamparan',
  'hampas',
  'hamper',
  'hampir',
  'hampirnya',
  'hampiri',
  'hampirkan',
  'hampiran',
  'hampung',
  'hamput',
  'hamun',
  'hamzah',
  'hancing',
  'kehancingan',
  'hancur',
  'hancurkan',
  'hancuran',
  'hancurlebur',
  'hancurluluh',
  'hancuruselessmumur',
  'handai',
  'handal',
  'handalan',
  'handam',
  'handasah',
  'hang',
  'hangat',
  'hangatkan',
  'kehangatan',
  'hanggau',
  'hangit',
  'menghangitkan',
  'kehangitan',
  'hangus',
  'kehangusan',
  'hanifi',
  'hantam',
  'hantar',
  'hantarkan',
  'berhantar',
  'terhantar',
  'hantaran',
  'pengantar',
  'hantu',
  'menghantu',
  'dihantui',
  'hantuk',
  'berhantuk',
  'hantukkan',
  'hanya',
  'hanyir',
  'kehanyiran',
  'menghanyirkan',
  'hanyut',
  'hanyutkan',
  'hanyutan',
  'hapak',
  'hapus',
  'hapuslah',
  'hapuskan',
  'penghapus',
  'harafiah',
  'harak',
  'harakat',
  'harakiri',
  'haram',
  'haramkan',
  'pengharaman',
  'harap',
  'harapnya',
  'haraplah',
  'berharap',
  'harapan',
  'terharap',
  'harawan',
  'harbi',
  'harga',
  'harganya',
  'hargai',
  'penghargaan',
  'hari',
  'harian',
  'harinya',
  'harijadi',
  'harimau',
  'haring',
  'haris',
  'harisah',
  'harkat',
  'harmoni',
  'harmoninya',
  'mengharmonikan',
  'keharmonian',
  'harmonika',
  'harmonium',
  'harp',
  'harta',
  'berharta',
  'hartal',
  'hartanah',
  'hartawan',
  'haru',
  'terharu',
  'mengharu',
  'diharu',
  'keterharuan',
  'haruan',
  'harubi',
  'haruuselessbiru',
  'haruuselesshara',
  'harum',
  'mengharum',
  'haruman',
  'harung',
  'harungi',
  'harungan',
  'harus',
  'seharusnya',
  'haruskan',
  'keharusan',
  'has',
  'hasad',
  'hasil',
  'hasilkan',
  'penghasil',
  'hasilnya',
  'hasilan',
  'hasiluselessmahsul',
  'hasis',
  'hasrat',
  'hasratkan',
  'berhasrat',
  'terhasrat',
  'hasta',
  'hasut',
  'hasutan',
  'hasyiah',
  'hasyur',
  'hati',
  'berhati',
  'sehati',
  'perhati',
  'memperhatikan',
  'perhatian',
  'pemerhati',
  'hatrik',
  'hatta',
  'haul',
  'haus',
  'kehausan',
  'menghauskan',
  'hawa',
  'hawanya',
  'hawari',
  'hawariun',
  'hawiah',
  'haya',
  'hayat',
  'hayati',
  'penghayat',
  'hayun',
  'dihayunnya',
  'menghayunnya',
  'hayunan',
  'hebah',
  'hebahkan',
  'penghebah',
  'heban',
  'hebat',
  'hebatkan',
  'kehebatan',
  'hebatnya',
  'heboh',
  'hebohkan',
  'hebohnya',
  'kehebohan',
  'hegeh',
  'hegemoni',
  'hei',
  'hektar',
  'hektarnya',
  'hektogram',
  'hektoliter',
  'hektometer',
  'hela',
  'helakan',
  'helaan',
  'helah',
  'menghelah',
  'helahnya',
  'helai',
  'helaian',
  'helang',
  'helat',
  'helicak',
  'helikopter',
  'heliks',
  'heliograf',
  'helioskop',
  'helium',
  'helm',
  'hemah',
  'hemahnya',
  'hemat',
  'menghemat',
  'hematan',
  'hematit',
  'hembalang',
  'menghembalang',
  'hembus',
  'hembuskan',
  'hembusan',
  'hemisfera',
  'hemoglobin',
  'hempap',
  'menghempap',
  'dihempap',
  'hempapkan',
  'hempas',
  'dihempas',
  'menghempas',
  'hempasan',
  'hempedal',
  'hempedu',
  'hempuk',
  'hendak',
  'menghendaki',
  'kehendak',
  'berkehendak',
  'kehendaki',
  'hendal',
  'hendamuselesskaram',
  'hendap',
  'menghendap',
  'henggung',
  'hengit',
  'hening',
  'mengheningkan',
  'diheningkan',
  'keheningan',
  'hentak',
  'hentakkan',
  'hentakan',
  'hentam',
  'hentaman',
  'hentar',
  'henti',
  'berhenti',
  'terhenti',
  'hentikan',
  'berhentikan',
  'pemberhentian',
  'hentian',
  'henyak',
  'menghenyak',
  'hepar',
  'hepatitis',
  'herba',
  'herbarium',
  'herbivor',
  'herdik',
  'herdikan',
  'hereng',
  'heret',
  'diheret',
  'mengheret',
  'heretan',
  'hering',
  'hero',
  'heroin',
  'herot',
  'herotkan',
  'herotuselessbenyot',
  'herotuselessberot',
  'heterogen',
  'hias',
  'hiaskan',
  'hiasi',
  'hiasnya',
  'hiasan',
  'hiba',
  'menghibakan',
  'dihibakan',
  'kehibaan',
  'hibur',
  'terhibur',
  'hiburkan',
  'hiburan',
  'hidang',
  'hidangkan',
  'hidangan',
  'hidangannya',
  'hidap',
  'menghidap',
  'penghidap',
  'hidapi',
  'hidapan',
  'hidayat',
  'menghidayatkan',
  'hidorkarbon',
  'hidrat',
  'hidraulik',
  'hidro',
  'hidrobiologi',
  'hidrodinamik',
  'hidroelektrik',
  'hidrofobia',
  'hidrogen',
  'hidrogeologi',
  'hidrograf',
  'hidrografi',
  'hidroklorik',
  'hidroksida',
  'hidrolfoil',
  'hidrolisis',
  'hidrologi',
  'hidrometer',
  'hidropati',
  'hidroskop',
  'hidu',
  'dihidu',
  'menghidu',
  'hidung',
  'hidungnya',
  'hidup',
  'sehidup',
  'hidupkan',
  'hidupan',
  'kehidupan',
  'penghidup',
  'menghidupi',
  'dihidupi',
  'hierarki',
  'hijab',
  'hijau',
  'hijaukan',
  'hijauan',
  'hijrah',
  'hijrahkan',
  'penghijrah',
  'hijrah',
  'hikayat',
  'dihikayatkan',
  'menghikayatkan',
  'hikmah',
  'hikmat',
  'menghikmat',
  'hilai',
  'hilang',
  'hilanglah',
  'hilangkan',
  'kehilangan',
  'penghilangan',
  'hilanguselesspintang',
  'hilau',
  'hilir',
  'sehilir',
  'menghilir',
  'hilirkan',
  'berhiliran',
  'himbau',
  'himpang',
  'himpit',
  'himpitkan',
  'himpitan',
  'himpun',
  'berhimpun',
  'himpunkan',
  'himpunan',
  'hina',
  'hinanya',
  'hinakan',
  'hinaan',
  'kehinaan',
  'hinauselessdina',
  'hinauselessleta',
  'hinap',
  'hincut',
  'hindar',
  'hindarkan',
  'hindari',
  'penghindaran',
  'hindik',
  'hindu',
  'hingar',
  'menghingari',
  'kehingaran',
  'hingaruselessbangar',
  'hingaruselessbingar',
  'hingga',
  'hinggalah',
  'hinggakan',
  'perhinggaan',
  'hinggan',
  'hinggap',
  'dihinggapi',
  'menghinggapi',
  'hinggut',
  'hingus',
  'hingusnya',
  'hio',
  'hiperbola',
  'hipertensi',
  'hipokrit',
  'hiponim',
  'hipotalamus',
  'hipotesis',
  'hipsometer',
  'hiput',
  'hirap',
  'hirau',
  'hiraukan',
  'hiris',
  'hiriskan',
  'hirisan',
  'hiruk',
  'hirukuselesspiruk',
  'hirup',
  'hirupan',
  'hisab',
  'hisabkan',
  'hisap',
  'dihisap',
  'menghisap',
  'histeria',
  'histografi',
  'histologi',
  'hitam',
  'hitamkan',
  'hitaman',
  'menghitamputihkan',
  'hitamuselesslegam',
  'hitung',
  'hitungkan',
  'menghitungi',
  'hitungan',
  'hiyayat',
  'hobat',
  'hobatkan',
  'hobi',
  'hodoh',
  'hoki',
  'homofon',
  'homogami',
  'homogen',
  'homograf',
  'homonim',
  'homeopati',
  'homoseks',
  'homoseksual',
  'hon',
  'honar',
  'menghonarkan',
  'kehoranan',
  'honggar',
  'hongkoe',
  'honorarium',
  'hore',
  'horizon',
  'hormat',
  'hormati',
  'berhormat',
  'kehormat',
  'penghormat',
  'terhormat',
  'hormon',
  'horologi',
  'horoskop',
  'hortikultur',
  'hospital',
  'hostel',
  'hotel',
  'perhotelan',
  'hoverkraf',
  'hub',
  'hubaya',
  'hubung',
  'berhubung',
  'hubungi',
  'hubungkan',
  'hubungan',
  'sehubungan',
  'hubungkait',
  'hubungkaitkan',
  'hubungkaitnya',
  'hud',
  'hudai',
  'hudhud',
  'hudud',
  'hujah',
  'hujahnya',
  'hujahan',
  'hujan',
  'hujankan',
  'hujani',
  'kehujanan',
  'menghujananginkan',
  'menghujanpanaskan',
  'hujung',
  'hujungan',
  'hujungnya',
  'huka',
  'hukah',
  'hukka',
  'hukum',
  'hukumkan',
  'hukuman',
  'kehukuman',
  'hukumnya',
  'hakam',
  'hulauselesshula',
  'hulu',
  'berhulu',
  'menghulu',
  'hulubalang',
  'hulur',
  'hulurkan',
  'huluri',
  'huluran',
  'huma',
  'memperhumai',
  'perhumaan',
  'humanisme',
  'humban',
  'humbankan',
  'humbas',
  'humor',
  'humus',
  'hun',
  'huna',
  'hungap',
  'huni',
  'dihuni',
  'menghuni',
  'penghuni',
  'penghuninya',
  'hunjam',
  'dihunjam',
  'menghunjam',
  'hunjin',
  'hunus',
  'menghunus',
  'huru',
  'hara',
  'hurai',
  'huraikan',
  'menghuraikannya',
  'huraian',
  'huruf',
  'hurufnya',
  'hurung',
  'hurungkan',
  'hurungi',
  'hutan',
  'menghutan',
  'dihutankan',
  'perhutanan',
  'kehutanan',
  'hutang',
  'menghutangkan',
  'hutangan',
  'huyung',
  'hayang',
  'ia',
  'iaitu',
  'ialah',
  'ianya',
  'mengiakan',
  'iau',
  'ibadah',
  'ibadahnya',
  'ibadat',
  'beribadat',
  'ibarat',
  'ibaratkan',
  'ibar',
  'ibid',
  'iblis',
  'ibni',
  'ibu',
  'beribu',
  'keibuan',
  'keibubapaan',
  'ibul',
  'ibunda',
  'ibung',
  'ibus',
  'icak',
  'idah',
  'mengidah',
  'idam',
  'idami',
  'idaman',
  'idea',
  'ideal',
  'idealis',
  'idealistik',
  'identiti',
  'beridentiti',
  'ideologi',
  'idgham',
  'idola',
  'ifadat',
  'ifrad',
  'ifrit',
  'iftar',
  'iftitah',
  'igal',
  'igau',
  'igaukan',
  'igauan',
  'iglu',
  'igneus',
  'ihram',
  'ihramnya',
  'ihsan',
  'ihsanat',
  'ihtilam',
  'ijab',
  'mengijabkan',
  'diijabkabulkan',
  'mengijabkabulkan',
  'ijas',
  'ijazah',
  'ijmak',
  'ijmal',
  'ijtihad',
  'ijuk',
  'ikal',
  'ikan',
  'ikannya',
  'perikanan',
  'ikat',
  'ikatkan',
  'ikatnya',
  'ikatan',
  'ikatlah',
  'ikhfak',
  'ikhlas',
  'mengikhlaskan',
  'keikhlasan',
  'ikhtiar',
  'ikhtiarkan',
  'ikhtiarnya',
  'ikhtiari',
  'ikhtilaf',
  'ikhtisar',
  'mengikhtisarkan',
  'ikhtisas',
  'ikhwan',
  'iklan',
  'iklankan',
  'pengiklanan',
  'iklim',
  'keikliman',
  'ikon',
  'ikrab',
  'keikraban',
  'ikrar',
  'ikrarkan',
  'ikrarnya',
  'iktibar',
  'iktidal',
  'iktikad',
  'iktikaf',
  'iktikafnya',
  'iktiraf',
  'diiktiraf',
  'mengiktiraf',
  'pengiktirafan',
  'iktisab',
  'iktisad',
  'ikut',
  'ikutnya',
  'ikutkan',
  'ikuti',
  'ikutan',
  'ilahi',
  'ilai',
  'ilak',
  'ilamuselessilam',
  'ilat',
  'ilham',
  'diilhamkan',
  'mengilham',
  'ilmiah',
  'ilmiawan',
  'ilmu',
  'ilmunya',
  'keilmuan',
  'ilmuwan',
  'iltizam',
  'ilustrasi',
  'ilustrator',
  'imaginasi',
  'berimaginasi',
  'imaginatif',
  'imago',
  'imajan',
  'imak',
  'imam',
  'berimam',
  'imamkan',
  'mengimami',
  'keimaman',
  'iman',
  'beriman',
  'mengimankan',
  'imani',
  'keimanan',
  'imbal',
  'mengimbal',
  'imbang',
  'imbangkan',
  'imbangi',
  'imbangan',
  'seimbang',
  'seimbangkan',
  'seimbangan',
  'imbas',
  'mengimbas',
  'imbasan',
  'imbau',
  'imbauan',
  'imbuh',
  'mengimbuh',
  'imbuhan',
  'imej',
  'pengimejan',
  'imigran',
  'imigrasi',
  'imigresen',
  'imlak',
  'imlek',
  'impak',
  'impas',
  'imperialis',
  'imperialisme',
  'impi',
  'impikan',
  'impian',
  'implementasi',
  'diimplementasikan',
  'mengimplementasikan',
  'implikasi',
  'implikasinya',
  'import',
  'pengimportan',
  'impresionisme',
  'imsak',
  'imun',
  'imunisasi',
  'imuniti',
  'ina',
  'inabat',
  'inai',
  'inainya',
  'inang',
  'inangda',
  'inap',
  'penginapan',
  'inas',
  'inayat',
  'incauselessbinca',
  'binca',
  'incanguselessincut',
  'incar',
  'incer',
  'inci',
  'incil',
  'incit',
  'incitkan',
  'incung',
  'incut',
  'indah',
  'indahkan',
  'keindahan',
  'indahnya',
  'indang',
  'indarus',
  'indayang',
  'indeks',
  'indera',
  'keinderaan',
  'inderalaksana',
  'inderaloka',
  'inderia',
  'inderwasih',
  'india',
  'indik',
  'indikator',
  'inding',
  'individu',
  'individualis',
  'individualisme',
  'individualistik',
  'indo',
  'indoktrinasi',
  'indonesia',
  'induk',
  'perinduk',
  'induksi',
  'induktor',
  'indung',
  'industri',
  'mengindustrikan',
  'perindustrian',
  'pengindustrian',
  'industralisasi',
  'industrialisme',
  'infantri',
  'infeksi',
  'infiniti',
  'inflasi',
  'influenza',
  'informan',
  'informasi',
  'informatif',
  'infra',
  'infraglasier',
  'inframerah',
  'infraneritik',
  'infraorbit',
  'infrastruktur',
  'inga',
  'ingat',
  'seingat',
  'ingati',
  'ingatinya',
  'ingatkan',
  'ingatkannya',
  'ingatan',
  'ingatlah',
  'ingau',
  'ingauan',
  'inggang',
  'inggung',
  'inggeris',
  'inggu',
  'ingin',
  'beringin',
  'inginkan',
  'ingini',
  'diingininya',
  'mengingininya',
  'keinginan',
  'ingkar',
  'ingkari',
  'keingkaran',
  'pengingkaran',
  'ingus',
  'mengingusi',
  'ingusan',
  'ini',
  'inisiatif',
  'inja',
  'injak',
  'menginjakkan',
  'injakan',
  'injap',
  'injek',
  'injeksi',
  'injunksi',
  'injil',
  'ink',
  'inkarnasi',
  'inkues',
  'inovasi',
  'inovatif',
  'input',
  'insaf',
  'menginsafkan',
  'menginsafi',
  'keinsafan',
  'insan',
  'keinsanan',
  'insang',
  'insani',
  'insanulkamil',
  'insentif',
  'insiden',
  'insinerator',
  'insinyur',
  'inspektor',
  'inspirasi',
  'berinspirasi',
  'institusi',
  'institut',
  'instrumen',
  'instrumental',
  'insulin',
  'insurans',
  'insuranskan',
  'insya',
  'intai',
  'intaikan',
  'pengintaian',
  'intan',
  'intaran',
  'integrasi',
  'integrasinya',
  'integriti',
  'intelek',
  'intelektual',
  'keintelektualan',
  'intelektualisme',
  'inteligensia',
  'intensif',
  'berintensif',
  'mengintensifkan',
  'interaktif',
  'interaksi',
  'interkom',
  'intermedia',
  'berinternet',
  'internet',
  'interpretasi',
  'diinterpretasi',
  'menginterpretasi',
  'inti',
  'berinti',
  'intifada',
  'intikad',
  'intil',
  'intim',
  'keintiman',
  'intimidasi',
  'intip',
  'intipan',
  'intipati',
  'intisari',
  'intiqal',
  'intonasi',
  'intransitif',
  'intravena',
  'intrinsik',
  'introvert',
  'intuisi',
  'intuitif',
  'inulangan',
  'inventori',
  'inversi',
  'invois',
  'iodin',
  'ion',
  'ionium',
  'ipar',
  'beripar',
  'ipi',
  'ipoh',
  'ipuh',
  'ipuk',
  'ipukan',
  'iqamat',
  'iqamatkan',
  'iqlab',
  'ira',
  'iradat',
  'iram',
  'irama',
  'mengiramakan',
  'iramanya',
  'irap',
  'iras',
  'irasi',
  'iri',
  'iridium',
  'irik',
  'iring',
  'iringan',
  'iringannya',
  'iringi',
  'mengiringinya',
  'pengiringnya',
  'iringkan',
  'iris',
  'irit',
  'pengirit',
  'ironi',
  'ironis',
  'irung',
  'isbat',
  'mengisbatkan',
  'iseng',
  'isi',
  'isikan',
  'isian',
  'isinya',
  'isipadu',
  'isih',
  'isihan',
  'isim',
  'isirung',
  'islam',
  'islamkan',
  'keislaman',
  'pengislaman',
  'islamiah',
  'isnin',
  'isobar',
  'isomer',
  'isometri',
  'isomorf',
  'isotop',
  'israk',
  'istabrak',
  'istana',
  'istananya',
  'isteri',
  'beristeri',
  'diperisteri',
  'memperisteri',
  'istiadat',
  'mengistiadatkan',
  'istighfar',
  'istikharah',
  'istilah',
  'istilahnya',
  'istilahkan',
  'peristilahan',
  'istimewa',
  'mengistimewakan',
  'diistimewakan',
  'keistimewaan',
  'istinggar',
  'istinjak',
  'istirahat',
  'mengistirahatkan',
  'peristirahatan',
  'isu',
  'isyak',
  'isyarat',
  'mengisyaratkan',
  'diisyaratkan',
  'isyaratnya',
  'isytihar',
  'pengisytiharan',
  'perisytiharan',
  'itah',
  'italik',
  'itik',
  'itu',
  'iwak',
  'izhar',
  'izin',
  'izinnya',
  'izinkan',
  'keizinan',
  'pengizinan',
  'jabang',
  'jabar',
  'menjabarkan',
  'penjabaran',
  'jabat',
  'jabatan',
  'berjabat',
  'jabil',
  'jabing',
  'jabir',
  'jabun',
  'jabung',
  'jadah',
  'jadam',
  'jadar',
  'jadi',
  'jadikan',
  'jadian',
  'jadinya',
  'jadilah',
  'jadual',
  'jadualkan',
  'penjadualan',
  'jadualnya',
  'jag',
  'jaga',
  'jaganya',
  'jagakan',
  'jagaan',
  'jagai',
  'jagalah',
  'jagak',
  'jagal',
  'jagalan',
  'jagat',
  'jagatraya',
  'jagnag',
  'jagoan',
  'jaguh',
  'menjaguhkan',
  'jaguhi',
  'kejaguhan',
  'jagung',
  'jagungnya',
  'jagur',
  'jaha',
  'jahan',
  'jahanam',
  'jahanamkan',
  'jahang',
  'jaharu',
  'jahat',
  'jahatkan',
  'kejahatan',
  'menjahati',
  'jahil',
  'kejahilan',
  'jahiliah',
  'jahit',
  'jahitnya',
  'jahitkan',
  'jahitan',
  'jail',
  'menjaili',
  'jaing',
  'jais',
  'jaiz',
  'jaja',
  'dijaja',
  'menjaja',
  'jajaan',
  'jajah',
  'jajahan',
  'jajar',
  'jajarkan',
  'jajaran',
  'menyejajarkan',
  'persejajaran',
  'jajat',
  'jajpa',
  'jaka',
  'jakal',
  'jakas',
  'jaket',
  'jaki',
  'jaksa',
  'jakuzi',
  'kejaksaan',
  'jala',
  'penjalaan',
  'jalad',
  'jalak',
  'jalan',
  'jalankan',
  'jalani',
  'jalanan',
  'jalannya',
  'jalang',
  'kejalangan',
  'jalangkung',
  'jalar',
  'jalaran',
  'menjalar',
  'jali',
  'jalia',
  'jalibut',
  'jalil',
  'jalin',
  'jalinkan',
  'jalinnya',
  'jalinan',
  'jalir',
  'penjaliran',
  'jaloi',
  'jalu',
  'jalur',
  'jaluran',
  'jam',
  'jamadilakhir',
  'jamadilawal',
  'jamah',
  'dijamah',
  'menjamah',
  'jamahan',
  'jamak',
  'dijamak',
  'menjamak',
  'jamang',
  'jamba',
  'jambak',
  'jambal',
  'jamban',
  'jambang',
  'jambar',
  'jambat',
  'jambatan',
  'menjambatani',
  'jambelang',
  'jambian',
  'jambori',
  'jambu',
  'jambua',
  'jambul',
  'jambung',
  'jamhur',
  'jamiah',
  'jamik',
  'jamin',
  'menjamin',
  'jaminan',
  'jamiz',
  'jampal',
  'jampi',
  'jampikan',
  'jampian',
  'jampuk',
  'jampul',
  'jampung',
  'jamrah',
  'jamu',
  'jamuan',
  'jamui',
  'menjamunya',
  'jamung',
  'jana',
  'janaan',
  'janakan',
  'janakuasa',
  'janabat',
  'janda',
  'jandanya',
  'jangak',
  'jangan',
  'janganan',
  'jangar',
  'jangat',
  'jangatkan',
  'menjangati',
  'janggal',
  'menjanggalkan',
  'kejanggalan',
  'janggi',
  'janggul',
  'janggus',
  'janggut',
  'janggutnya',
  'jangka',
  'berjangka',
  'jangkaan',
  'jangkah',
  'jangkak',
  'jangkang',
  'jangkar',
  'jangkat',
  'jangkau',
  'jangkauan',
  'sejangkauan',
  'penjangkauan',
  'jangkerik',
  'jangkih',
  'mangkih',
  'jangkir',
  'jangkit',
  'menjangkit',
  'dijangkiti',
  'jangkitan',
  'jangkung',
  'jangla',
  'janik',
  'janin',
  'janji',
  'janjikan',
  'perjanjian',
  'janjinya',
  'jannatulnaim',
  'jantan',
  'menjantani',
  'kejantanan',
  'jantang',
  'jantina',
  'jantinanya',
  'jantung',
  'jantur',
  'janturan',
  'januari',
  'janur',
  'jap',
  'jar',
  'jara',
  'penjaraan',
  'jarab',
  'jarah',
  'menjarah',
  'jarahan',
  'jarak',
  'jaraknya',
  'jarakkan',
  'jaram',
  'jaraman',
  'jaran',
  'jarang',
  'menjarang',
  'jaranglah',
  'jargon',
  'jari',
  'jarinya',
  'jariah',
  'jariamun',
  'jaring',
  'jaringkan',
  'jaringan',
  'jaru',
  'jarum',
  'jarumnya',
  'jaruman',
  'jarwa',
  'jas',
  'jasa',
  'berjasa',
  'menjasai',
  'jasad',
  'jasadi',
  'jasmani',
  'kejasmanian',
  'jasmaniah',
  'jatah',
  'jati',
  'kejatian',
  'penjatian',
  'jatik',
  'jatuh',
  'jatuhkan',
  'jatuhi',
  'jatuhan',
  'berjatuh',
  'jauh',
  'jauhnya',
  'menjauh',
  'jauhkan',
  'dijauhkannya',
  'jauhi',
  'dijauhinya',
  'jauhan',
  'jauhar',
  'jauhari',
  'jawa',
  'jawa',
  'jawab',
  'jawablah',
  'jawapan',
  'berjawab',
  'jawang',
  'jawat',
  'menjawat',
  'sejawat',
  'berjawat',
  'jawatan',
  'jawatankuasa',
  'jawawut',
  'jawi',
  'menjawikan',
  'jawi',
  'jaya',
  'jayakan',
  'kejayaan',
  'jaz',
  'jazam',
  'menjazamkan',
  'jazirah',
  'jean',
  'jebah',
  'jebai',
  'jebak',
  'jebakan',
  'jebang',
  'jebat',
  'jebat',
  'jebik',
  'jebuh',
  'jebung',
  'jed',
  'jeda',
  'jegang',
  'jegil',
  'jegilkan',
  'jegung',
  'jejak',
  'jejakkan',
  'jejaki',
  'jejaknya',
  'jejaka',
  'jejal',
  'menjejali',
  'jejala',
  'jejamang',
  'jejamu',
  'jejantas',
  'jejap',
  'jejari',
  'jejarum',
  'jejas',
  'jejaskan',
  'jejasan',
  'jejawi',
  'jejeh',
  'jejenang',
  'jejentik',
  'jejer',
  'jejeri',
  'jejeran',
  'jejuang',
  'jek',
  'jel',
  'jeli',
  'jela',
  'jelabak',
  'jelabas',
  'jeladan',
  'jeladeri',
  'jelaga',
  'menjelagakan',
  'jelah',
  'jelai',
  'jelajah',
  'menjelajah',
  'dijelajah',
  'penjelajahan',
  'jelak',
  'jelanak',
  'jelang',
  'jelangak',
  'jelantah',
  'jelapak',
  'berjelapakan',
  'jelapang',
  'jelar',
  'jelas',
  'jelaskan',
  'jelaskannya',
  'kejelasan',
  'jelasnya',
  'jelaslah',
  'penjelasan',
  'jelata',
  'jelatang',
  'jelatik',
  'jelatuk',
  'jelau',
  'jelawai',
  'jelawat',
  'jelebat',
  'jeleber',
  'jelejeh',
  'jelek',
  'jelempah',
  'jelengar',
  'jelentik',
  'jelepak',
  'jelepok',
  'jeleweh',
  'jelik',
  'menjelikkan',
  'kejelikan',
  'jelimpat',
  'jeling',
  'jelingan',
  'jelinglah',
  'jelir',
  'jelirkan',
  'jelira',
  'jelit',
  'jelita',
  'kejelitaan',
  'jelitawan',
  'jeliti',
  'jelma',
  'jelmakan',
  'menjelma',
  'jelmaan',
  'jeluak',
  'jeluang',
  'jeludu',
  'jelujur',
  'jeluk',
  'jelum',
  'jelungkap',
  'jeluntung',
  'jelunut',
  'jelurai',
  'jelur',
  'jelutung',
  'jem',
  'jemaah',
  'jemah',
  'jemala',
  'jemalin',
  'jemantung',
  'jemari',
  'jemawa',
  'kejemawaan',
  'jemba',
  'menjemba',
  'dijemba',
  'jembak',
  'jembalang',
  'jembiah',
  'jemboan',
  'jemedi',
  'jemeki',
  'jemerah',
  'jemerelang',
  'jemertas',
  'jemerung',
  'jempana',
  'jemparing',
  'jempol',
  'jempul',
  'jemput',
  'menjemput',
  'dijemput',
  'jemputan',
  'jemputlah',
  'jemu',
  'menjemukan',
  'kejemuan',
  'jemuas',
  'jemudi',
  'jemuduk',
  'jemuju',
  'jemur',
  'menjemur',
  'jemuran',
  'jenahar',
  'jenak',
  'menjenaki',
  'jenaka',
  'jenakanya',
  'kejenakaan',
  'jenama',
  'jenamanya',
  'penjenamaan',
  'jenang',
  'menjenangi',
  'jenawi',
  'jenayah',
  'jenazah',
  'menjenazahkan',
  'jendal',
  'jendela',
  'jenderus',
  'jendul',
  'jeneng',
  'jeneral',
  'jengagung',
  'jengah',
  'menjengah',
  'jengat',
  'jenggala',
  'jenggar',
  'jenggur',
  'jenggul',
  'jengit',
  'jengkal',
  'jengkang',
  'jengkaut',
  'jengkot',
  'jengkek',
  'jengkel',
  'menjengkeli',
  'menjengkelkan',
  'kejengkelan',
  'jengkelenan',
  'jengkerik',
  'jengket',
  'jengking',
  'jengkit',
  'menjengkit',
  'jengkolet',
  'jengkot',
  'jengkus',
  'jenguk',
  'jengul',
  'jenis',
  'berjenis',
  'jeniskan',
  'penjenisan',
  'jenitan',
  'jenjala',
  'jenjalau',
  'jenjang',
  'jenjarum',
  'jenlidah',
  'jenohong',
  'jentaka',
  'jentat',
  'jentayu',
  'jentera',
  'jentik',
  'jentikan',
  'jentolak',
  'jentu',
  'jenuh',
  'menjenuhkan',
  'kejenuhan',
  'jenulung',
  'jenut',
  'jepit',
  'jepitan',
  'jepun',
  'jera',
  'jerabut',
  'jeradik',
  'jeragur',
  'jerah',
  'jerahak',
  'jerahap',
  'jerah',
  'jerih',
  'jerai',
  'jerait',
  'jerak',
  'jeram',
  'jeramah',
  'jeramak',
  'jerambah',
  'jerambai',
  'jerambang',
  'jerami',
  'jeran',
  'jerang',
  'jerangkan',
  'jerangan',
  'jerangau',
  'jerangkang',
  'berjerangkangan',
  'jerangkap',
  'jerat',
  'jerau',
  'jeraus',
  'jerawat',
  'jerba',
  'jerbak',
  'jerebu',
  'jerebunya',
  'jereket',
  'jeremba',
  'jerembap',
  'jerembat',
  'jerembun',
  'jerempak',
  'jerepet',
  'jeri',
  'jeriau',
  'jerih',
  'menjerihkan',
  'memperjerihkan',
  'kejerihan',
  'jeriji',
  'jerijit',
  'jering',
  'jeringing',
  'jerit',
  'jeritkan',
  'jeritan',
  'jerjak',
  'jerkah',
  'jerkat',
  'jerlai',
  'jerlus',
  'jermal',
  'jermang',
  'jeremang',
  'jernang',
  'jernih',
  'menjernihkan',
  'kejernihan',
  'jerohok',
  'jerojol',
  'jerongkah',
  'jerongkang',
  'jerongkoh',
  'jerongkok',
  'jerongkong',
  'jersi',
  'jeruang',
  'jerjuit',
  'jerubung',
  'jeruji',
  'jeruju',
  'jeruk',
  'jerukun',
  'jerumat',
  'jerumau',
  'jerumbai',
  'jerumun',
  'jerumus',
  'jerumuskan',
  'jerun',
  'jerunang',
  'jerung',
  'jerungkau',
  'jerungkis',
  'jerungkup',
  'jerut',
  'jet',
  'jeti',
  'jetis',
  'jiawang',
  'jibaku',
  'jibril',
  'jibun',
  'jicing',
  'jidal',
  'jidar',
  'jidur',
  'jih',
  'jihad',
  'jihat',
  'jijik',
  'menjijikkan',
  'kejijikan',
  'jika',
  'jikalau',
  'jilat',
  'dijilat',
  'menjilat',
  'jilatan',
  'jilid',
  'jilidkan',
  'penjilidan',
  'jim',
  'jimak',
  'jimat',
  'jimatkan',
  'penjimatan',
  'jimbam',
  'jin',
  'jinak',
  'jinakkan',
  'menjinaki',
  'jinakan',
  'jingga',
  'jinggang',
  'jingkat',
  'jingkik',
  'jingkir',
  'jinjang',
  'jinjing',
  'jinjingan',
  'jinjit',
  'jinsom',
  'jintan',
  'jintih',
  'jip',
  'jiplak',
  'jiplakan',
  'jirai',
  'jirak',
  'jiran',
  'jirannya',
  'kejiranan',
  'jirat',
  'jirian',
  'jirim',
  'jirus',
  'jisim',
  'jitah',
  'jitu',
  'kejituan',
  'menjitukan',
  'jitung',
  'jiwa',
  'berjiwa',
  'jiwai',
  'menjiwainya',
  'penjiwaan',
  'kejiwaan',
  'jiwat',
  'jiwit',
  'jobak',
  'jodoh',
  'jodohnya',
  'jodohku',
  'jodohkan',
  'perjodohan',
  'jodong',
  'jogar',
  'joget',
  'joging',
  'jogingnya',
  'johan',
  'kejohanan',
  'johong',
  'johor',
  'jojol',
  'joker',
  'joki',
  'jolak',
  'jolek',
  'joli',
  'menjolikan',
  'jolok',
  'menjoloknya',
  'jolokan',
  'jolong',
  'jolor',
  'jompak',
  'jong',
  'jongang',
  'jongek',
  'jonget',
  'jongkang',
  'jangking',
  'jongkang',
  'jongkar',
  'jangkir',
  'jongket',
  'jongkok',
  'jongkong',
  'jongor',
  'jongos',
  'jonjot',
  'jontoh',
  'joran',
  'jorannya',
  'joreng',
  'jori',
  'jorong',
  'jose',
  'joule',
  'jua',
  'juadah',
  'juak',
  'jual',
  'berjual',
  'dijual',
  'menjual',
  'penjual',
  'terjual',
  'memperjualbelikan',
  'juala',
  'juandang',
  'juang',
  'berjuang',
  'perjuang',
  'perjuangan',
  'seperjuangan',
  'juar',
  'juara',
  'menjuarai',
  'kejuaraan',
  'jubah',
  'jubahnya',
  'jubal',
  'jubin',
  'jubli',
  'judi',
  'menjudi',
  'perjudi',
  'dijudikan',
  'perjudian',
  'penjudian',
  'judo',
  'judul',
  'berjudul',
  'juek',
  'juga',
  'juih',
  'juita',
  'jujai',
  'jujat',
  'juju',
  'jujuh',
  'jujuk',
  'jujukannya',
  'jujur',
  'menjujuri',
  'kejujuran',
  'jujut',
  'julai',
  'julai',
  'julang',
  'julangan',
  'julap',
  'julat',
  'sepenjulat',
  'juling',
  'menjulingi',
  'julir',
  'julo',
  'julukan',
  'julung',
  'julur',
  'julurkan',
  'jalar',
  'jumaat',
  'berjumaat',
  'jumantan',
  'jumantara',
  'jumbai',
  'berjumbai',
  'jumbil',
  'jumbo',
  'jumbul',
  'jumjumah',
  'jumlah',
  'jumlahnya',
  'jumlahkan',
  'penjumlahan',
  'jumpa',
  'berjumpa',
  'dijumpai',
  'menjumpai',
  'jumpaan',
  'terjumpa',
  'jumpelang',
  'jumpul',
  'jumud',
  'jun',
  'junam',
  'menjunam',
  'dijunamkan',
  'jundai',
  'junggang',
  'jungkit',
  'jungkar',
  'jungkat',
  'jungkatan',
  'jungkir',
  'jungut',
  'junior',
  'junjung',
  'junjungkan',
  'junjungan',
  'junta',
  'juntai',
  'menjuntaikan',
  'juntaian',
  'junub',
  'jura',
  'juragan',
  'jurah',
  'jurai',
  'juraian',
  'jurang',
  'jurangnya',
  'juri',
  'juring',
  'juringan',
  'jurnal',
  'juru',
  'kejuruan',
  'juruacara',
  'juruaudit',
  'juruazan',
  'jurubahasa',
  'jurubang',
  'jurubatu',
  'juruberita',
  'jurubicara',
  'jurubina',
  'jurucakap',
  'jurucerakin',
  'jurudalam',
  'jurudapur',
  'jurugambar',
  'juruhebah',
  'jurujual',
  'jurukaki',
  'jurukamera',
  'jurukira',
  'jurukunci',
  'jurulatih',
  'kejurulatihan',
  'jurulayar',
  'jurulelong',
  'jurulito',
  'jurumasak',
  'jurumekap',
  'jurumesin',
  'jurumudi',
  'jurunikah',
  'jurupandu',
  'juruperancah',
  'juruperunding',
  'juruprogram',
  'jururawat',
  'kejururawatan',
  'jurus',
  'menjurus',
  'dijurus',
  'jurusan',
  'jurusawat',
  'juruselam',
  'jurusolek',
  'jurutaip',
  'juruteknik',
  'jurutera',
  'kejuruteraan',
  'juruterbang',
  'jurutrengkas',
  'jurutulis',
  'jurutunjuk',
  'juruubat',
  'juruukur',
  'juruulas',
  'juruwang',
  'jus',
  'justeru',
  'justifikasi',
  'jut',
  'juta',
  'jutaan',
  'jutawan',
  'jutung',
  'juvana',
  'juz',
  'juzuk',
  'kaabah',
  'kaba',
  'kabak',
  'kabaret',
  'kabau',
  'kabel',
  'kabil',
  'kabilah',
  'kabin',
  'kabinet',
  'kabir',
  'kabisat',
  'kabit',
  'kaboi',
  'kabriolet',
  'kabu',
  'kabul',
  'terkabul',
  'kabulkan',
  'kabuli',
  'pengabulan',
  'kabung',
  'berkabung',
  'perkabungan',
  'kabupaten',
  'kabur',
  'kaburkan',
  'kaburi',
  'kekaburan',
  'kabus',
  'kabut',
  'berkabut',
  'mengabutkan',
  'kaca',
  'kacanya',
  'kacak',
  'kekacakan',
  'kacam',
  'kacang',
  'kacangnya',
  'kacapiring',
  'kacapuri',
  'kacar',
  'kacau',
  'berkacau',
  'kacaukan',
  'kacauan',
  'kacauuselessbalau',
  'kacauuselessbecah',
  'kacauuselessbicau',
  'kacauuselessbilau',
  'kacauuselessbirau',
  'kaci',
  'kacici',
  'kacip',
  'kacu',
  'kacuk',
  'kacung',
  'kad',
  'kadam',
  'kadang',
  'kadangkala',
  'kadar',
  'kadaran',
  'kadarnya',
  'kadbod',
  'kader',
  'kadera',
  'kadet',
  'kadi',
  'kadim',
  'kadmium',
  'kaduk',
  'kadut',
  'kaedah',
  'kaf',
  'kafal',
  'kafan',
  'kafankan',
  'mengkafani',
  'dikafani',
  'kafe',
  'kafein',
  'kafeteria',
  'kafilah',
  'kafir',
  'kafirkan',
  'kekafiran',
  'kafling',
  'kaftan',
  'kaget',
  'mengagetkan',
  'kagum',
  'mengagumkan',
  'kagumi',
  'kekaguman',
  'kah',
  'kahak',
  'kahaknya',
  'kahang',
  'kahar',
  'kahin',
  'kahrab',
  'kahwa',
  'kahwin',
  'kahwinkan',
  'mengahwinkannya',
  'dikahwini',
  'mengahwini',
  'perkahwinan',
  'kahwinuselessmawin',
  'kaifiat',
  'kail',
  'kailnya',
  'kain',
  'kainnya',
  'kaing',
  'kais',
  'kaisar',
  'kekaisaran',
  'kait',
  'kaitkan',
  'dikaitkan',
  'kaitan',
  'kaitnya',
  'kajai',
  'kajang',
  'pekajangan',
  'mengajangi',
  'dikajangi',
  'kajang',
  'kaji',
  'kajinya',
  'kajian',
  'kajilah',
  'sepengajiannya',
  'kajialam',
  'kajibahasa',
  'kajibina',
  'kajibintang',
  'kajibumi',
  'kajibunyi',
  'kajicuaca',
  'kajidaya',
  'kajigerak',
  'kajihaiwan',
  'kajihayat',
  'kajimanusia',
  'kajioptik',
  'kajipenyakit',
  'kajipurba',
  'kajiselidik',
  'kajiserangga',
  'kajiteknik',
  'kajiterbang',
  'kajitumbuhan',
  'kakah',
  'kakak',
  'berkakak',
  'kakaktua',
  'kakanda',
  'kakap',
  'kakas',
  'kakawin',
  'kakek',
  'kaki',
  'kakinya',
  'kakiku',
  'kakitangan',
  'kaksa',
  'kaktus',
  'kaku',
  'kakunya',
  'kal',
  'kala',
  'kalanya',
  'kalah',
  'mengalah',
  'dikalahkan',
  'terkalahkan',
  'kekalahan',
  'kalai',
  'kalak',
  'kalakanji',
  'kalakian',
  'kalam',
  'kalamdan',
  'kalamkari',
  'kalamsani',
  'kalandar',
  'kalang',
  'kalangkan',
  'kalangan',
  'kalanguselesskabut',
  'kalat',
  'kalau',
  'kalbu',
  'kaldu',
  'kaleidoskop',
  'kalendar',
  'kaleng',
  'kali',
  'kalinya',
  'kalikan',
  'kalian',
  'sekali',
  'sekalipun',
  'sekaligus',
  'kaliber',
  'kalibut',
  'kalicau',
  'kaligrafi',
  'kalih',
  'kalikausar',
  'kalimah',
  'kalimantang',
  'kalimat',
  'kalimatullah',
  'kalipso',
  'kalis',
  'kalium',
  'kalkulus',
  'kaloi',
  'kalori',
  'kalowatan',
  'kalsium',
  'kalung',
  'kalungnya',
  'kalungkan',
  'kalungan',
  'kalut',
  'mengalutkan',
  'kekalutan',
  'kama',
  'kaman',
  'kamar',
  'kamarnya',
  'kamariah',
  'kamat',
  'kambai',
  'kamban',
  'kambang',
  'kambas',
  'kambeli',
  'kamber',
  'kambi',
  'kambing',
  'kambingnya',
  'kambu',
  'kambuh',
  'kambus',
  'kambut',
  'kamera',
  'kamhar',
  'kami',
  'kamil',
  'kamir',
  'kamiran',
  'kamkam',
  'kampa',
  'kampah',
  'kampai',
  'mengampaikan',
  'berkampaian',
  'kampil',
  'kampit',
  'kampuh',
  'kampung',
  'berkampung',
  'mengampungkan',
  'kampungan',
  'sekampung',
  'kampus',
  'kamu',
  'berkamu',
  'kamus',
  'mengamuskan',
  'perkamusan',
  'kan',
  'kana',
  'kanabalisme',
  'kanabis',
  'kanak',
  'kanal',
  'kanan',
  'terkanan',
  'menganan',
  'kekananan',
  'kancah',
  'kancil',
  'kancing',
  'mengancing',
  'kancut',
  'kanda',
  'manda',
  'kandang',
  'kandangnya',
  'mengandang',
  'kandar',
  'mengandar',
  'kandaran',
  'kandas',
  'mengandaskan',
  'kekandasan',
  'kandi',
  'kandil',
  'kandis',
  'kandul',
  'kandung',
  'mengandung',
  'dikandung',
  'kandungan',
  'kandungnya',
  'kang',
  'kangar',
  'kanggaru',
  'kangka',
  'kangkang',
  'mengangkang',
  'kangkung',
  'kangsar',
  'kani',
  'kanigara',
  'kanisah',
  'kanjal',
  'kanjang',
  'kanjar',
  'kanji',
  'kanjinya',
  'kano',
  'kanopi',
  'kanser',
  'kanta',
  'kantan',
  'kantang',
  'kantin',
  'kanton',
  'kantuk',
  'mengantuk',
  'kantung',
  'mengantungkan',
  'mengantungi',
  'kanu',
  'kanun',
  'dikanunkan',
  'mengkanunkan',
  'kanvas',
  'kanyon',
  'kaolin',
  'kap',
  'kapah',
  'kapai',
  'kapak',
  'kapa',
  'kapal',
  'kapalnya',
  'perkapalan',
  'mengapalkan',
  'kapan',
  'kapang',
  'kapangan',
  'kapar',
  'kaparnya',
  'mengkaparkan',
  'dikaparkan',
  'kaparan',
  'kapas',
  'kapasiti',
  'kapasitinya',
  'kaper',
  'kapi',
  'kapis',
  'kapista',
  'kapit',
  'pengapit',
  'mengapit',
  'kapita',
  'kapitan',
  'kapital',
  'kapitalis',
  'kapri',
  'kapsul',
  'kapten',
  'kapuk',
  'kapu',
  'kapung',
  'kapur',
  'mengapur',
  'pengapuran',
  'pekapur',
  'kara',
  'karaf',
  'karah',
  'karakter',
  'berkarakter',
  'karam',
  'mengaram',
  'kekaraman',
  'karan',
  'karang',
  'karangnya',
  'karangkan',
  'karangan',
  'karaoke',
  'karaokenya',
  'karap',
  'karapelai',
  'karas',
  'karat',
  'mengarat',
  'karatan',
  'karate',
  'karau',
  'karbid',
  'karbida',
  'karbohidrat',
  'karbolik',
  'karbon',
  'karbonnya',
  'karbonat',
  'karbonatnya',
  'karburetor',
  'karcis',
  'kardamunggu',
  'kardinal',
  'kardiovaskular',
  'kardus',
  'karektor',
  'karena',
  'karet',
  'kargo',
  'kari',
  'kariah',
  'karib',
  'mengaribkan',
  'mengaribi',
  'kekariban',
  'karibu',
  'karier',
  'berkarier',
  'karih',
  'karikatur',
  'karil',
  'karim',
  'karing',
  'karipap',
  'karisma',
  'karismanya',
  'karkun',
  'karma',
  'karnival',
  'karpet',
  'karpus',
  'kartel',
  'kartika',
  'kartografi',
  'kartrij',
  'kartu',
  'mengartukan',
  'perkartuan',
  'kartun',
  'karu',
  'karun',
  'karung',
  'karut',
  'karutlah',
  'karutnya',
  'kekarutan',
  'karwapalai',
  'karya',
  'karyanya',
  'karyawan',
  'kas',
  'kasa',
  'kasab',
  'kasad',
  'dikasadkan',
  'mengasadkan',
  'kasai',
  'kasaj',
  'kasak',
  'kasanova',
  'kasap',
  'kasar',
  'kasarnya',
  'mengasarkan',
  'kasari',
  'kekasaran',
  'kasasi',
  'kasau',
  'kasein',
  'kaserol',
  'kaset',
  'kasi',
  'pengasian',
  'kasih',
  'berkasih',
  'mengasih',
  'kekasih',
  'dikasihi',
  'pengasih',
  'pekasih',
  'kasihan',
  'mengasihani',
  'mengasihankan',
  'berpengasihan',
  'kasino',
  'kasip',
  'kasir',
  'kaskas',
  'kasmaran',
  'kasrah',
  'kasta',
  'kastam',
  'perkastaman',
  'kastard',
  'kasti',
  'kastroli',
  'kasuari',
  'kasur',
  'kasus',
  'kasut',
  'berkasut',
  'kaswi',
  'kata',
  'berkata',
  'dikata',
  'perkatakan',
  'mengata',
  'sekata',
  'terkata',
  'perkataan',
  'katah',
  'katai',
  'katak',
  'katalog',
  'katam',
  'katang',
  'katar',
  'kategori',
  'kategorikan',
  'kategorinya',
  'kati',
  'katian',
  'katib',
  'katifah',
  'katih',
  'katik',
  'katil',
  'katilnya',
  'bersekatil',
  'katir',
  'katod',
  'katolik',
  'katuk',
  'katung',
  'katup',
  'katupkan',
  'katut',
  'katwal',
  'kau',
  'kaukab',
  'kaul',
  'mengaulkan',
  'dikaulkan',
  'kaum',
  'berkaum',
  'perkauman',
  'kaung',
  'kaunseling',
  'kaunselor',
  'kaunter',
  'kaup',
  'kaus',
  'kaut',
  'kausal',
  'kausaliti',
  'kawa',
  'kawad',
  'kawadnya',
  'kawah',
  'kawal',
  'dikawal',
  'mengawal',
  'pengawal',
  'kawalan',
  'kawan',
  'berkawan',
  'sekawan',
  'mengawan',
  'memperkawan',
  'kawang',
  'kawasan',
  'kawat',
  'mengawatkan',
  'dikawatkan',
  'kawi',
  'kawik',
  'kaya',
  'kayakan',
  'kekayaan',
  'kayanya',
  'kayalah',
  'kayai',
  'kayak',
  'kayangan',
  'kayap',
  'kayau',
  'kayu',
  'kayunya',
  'perkayuan',
  'kayuh',
  'kayuhkan',
  'kayuhan',
  'kayan',
  'kayun',
  'ke',
  'kebab',
  'kebabal',
  'kebah',
  'kebaji',
  'kebajikan',
  'kebal',
  'kebalkan',
  'kekebalan',
  'kebam',
  'keban',
  'kebas',
  'dikebas',
  'mengebas',
  'kebasi',
  'kebus',
  'kebat',
  'kebit',
  'kebaya',
  'kebayanya',
  'kebayan',
  'kebayang',
  'kebek',
  'kebel',
  'kebelai',
  'kebil',
  'kebuk',
  'kebuli',
  'kebun',
  'kebunnya',
  'perkebunan',
  'memperkebuni',
  'kebur',
  'kebut',
  'mengebut',
  'kecah',
  'kecahkan',
  'becah',
  'kecai',
  'kecak',
  'kecam',
  'mengecam',
  'kecaman',
  'kecambah',
  'kecamuk',
  'kecap',
  'kecapan',
  'kecapi',
  'kecar',
  'kecek',
  'keceknya',
  'mengecek',
  'keceng',
  'mengeceng',
  'kecepek',
  'kecewa',
  'kecewakan',
  'mengecewakan',
  'kecewanya',
  'kekecewaan',
  'keci',
  'keciak',
  'kecibeling',
  'kecicak',
  'kecil',
  'kecilkan',
  'kecilan',
  'kecilnya',
  'ngecilkan',
  'kecimpung',
  'kecimus',
  'kecindan',
  'kecipak',
  'kecoh',
  'kecohnya',
  'mengecoh',
  'dikecohkan',
  'kekecohan',
  'pengecoh',
  'kecong',
  'kecuali',
  'kecualikan',
  'kecualian',
  'pengecualian',
  'kecubung',
  'kecuhuselesskecah',
  'keculun',
  'kecumik',
  'kecundang',
  'kecup',
  'mengecup',
  'dikecupi',
  'kecur',
  'kecut',
  'kecutkan',
  'pengecutan',
  'kekecutan',
  'kecutnya',
  'kedabu',
  'kedada',
  'kedadak',
  'kedah',
  'kedah',
  'kedai',
  'kedainya',
  'dikedaikan',
  'mengedaikan',
  'kedaian',
  'kedal',
  'kedaluwarsa',
  'kedamba',
  'kedampang',
  'kedana',
  'kedang',
  'kedanga',
  'kedangkai',
  'kedangkang',
  'kedangsa',
  'kedap',
  'kedau',
  'kedaung',
  'kedayan',
  'kedek',
  'kedekai',
  'kedekut',
  'kedelai',
  'kedemah',
  'kedemi',
  'kedempung',
  'kedemut',
  'kedengkang',
  'kedengkik',
  'kedengking',
  'kedenuk',
  'kedera',
  'kederang',
  'kedewas',
  'kedi',
  'kedidi',
  'kedih',
  'kedik',
  'kedip',
  'mengedipkan',
  'kedipan',
  'kedok',
  'kedombak',
  'kedondong',
  'kedongkok',
  'kedu',
  'kedua',
  'keduauselessduanya',
  'kedubang',
  'keduduk',
  'kedul',
  'kedunak',
  'kedut',
  'kedutnya',
  'mengedut',
  'kedutan',
  'keembung',
  'keempat',
  'keempatuselessempat',
  'keenam',
  'keenamuselessenam',
  'kehel',
  'kejai',
  'kejal',
  'kejam',
  'kekejaman',
  'mengejam',
  'kejamas',
  'kejan',
  'kejang',
  'mengejang',
  'kekejangan',
  'kejap',
  'mengejapkan',
  'dikejapkan',
  'kejaplah',
  'kejapan',
  'kejar',
  'mengejar',
  'dikejar',
  'kejari',
  'kejarkan',
  'kejaran',
  'kejat',
  'keji',
  'mengeji',
  'diperkejikan',
  'memperkejikan',
  'kekejian',
  'kejora',
  'keju',
  'kejuju',
  'kejur',
  'kejut',
  'terkejut',
  'dikejut',
  'mengejut',
  'berkejut',
  'pengejut',
  'kekejutan',
  'kek',
  'kekabu',
  'kekacang',
  'kekah',
  'kekait',
  'kekal',
  'kekalkan',
  'mengekal',
  'dikekal',
  'berkekalan',
  'pengekalan',
  'kekekalan',
  'kekalu',
  'kekam',
  'kekang',
  'kekangan',
  'kekandi',
  'kekapal',
  'kekapas',
  'kekar',
  'kekara',
  'kekaras',
  'kekas',
  'kekat',
  'kekau',
  'kekeh',
  'kekek',
  'kekel',
  'ngekel',
  'kekepuk',
  'kekeruh',
  'kekili',
  'kekisi',
  'kekok',
  'kekoleh',
  'kekolong',
  'kekorok',
  'kekot',
  'kekuda',
  'kekudung',
  'kekuma',
  'kekunyit',
  'kekwa',
  'kelab',
  'kelabak',
  'kelabang',
  'kelabangan',
  'kelabat',
  'kelabau',
  'kelabu',
  'mengelabui',
  'kelabuan',
  'kelabung',
  'kelabut',
  'keladak',
  'keladan',
  'keladang',
  'keladar',
  'keladau',
  'keladi',
  'kelah',
  'perkelahan',
  'kelahi',
  'memperkelahikan',
  'perkelahian',
  'kelak',
  'kelakar',
  'kelakarnya',
  'kelakkeling',
  'kelakuselesskeluk',
  'kelalang',
  'kelalawar',
  'kelalut',
  'kelam',
  'mengelamkan',
  'kekelaman',
  'pengelaman',
  'kelama',
  'kelambai',
  'kelambir',
  'kelambit',
  'kelambu',
  'kelambur',
  'kelamin',
  'perkelaminan',
  'kelamuselesskabut',
  'kelampadang',
  'kelampi',
  'kelampung',
  'kelamun',
  'pengelamunan',
  'kelana',
  'kelang',
  'kelanguselesskabut',
  'kelangkang',
  'kelanit',
  'kelanjar',
  'berkelanjaran',
  'kelantan',
  'kelantang',
  'kelap',
  'mengelapkan',
  'kelapa',
  'kelapak',
  'kelapan',
  'kelapang',
  'kelapanuselesslapan',
  'kelapuselesskelip',
  'kelapung',
  'kelar',
  'kelaran',
  'kelara',
  'kelarah',
  'kelarai',
  'kelari',
  'kelas',
  'kelasnya',
  'kelaskan',
  'pengelasan',
  'kelasa',
  'kelasak',
  'kelasi',
  'kelat',
  'mengelati',
  'kelawai',
  'kelawar',
  'kelawat',
  'kelayang',
  'kelayar',
  'kelayau',
  'kelayu',
  'keldai',
  'kelebang',
  'kelebek',
  'kelebet',
  'mengelebetkan',
  'kelebuk',
  'kelebur',
  'kelebut',
  'keleceh',
  'kelecek',
  'kelecus',
  'keledar',
  'berkeledar',
  'keledek',
  'kelek',
  'kelekan',
  'kelekati',
  'kelekatu',
  'kelelawar',
  'kelelawat',
  'kelelesa',
  'kelelot',
  'kelemata',
  'kelemayar',
  'kelemayuh',
  'kelembahang',
  'kelembai',
  'kelembak',
  'kelemben',
  'kelembuai',
  'kelembubu',
  'kelemek',
  'kelemoyang',
  'kelempai',
  'kelempayan',
  'kelempenang',
  'kelempening',
  'kelempeti',
  'kelemping',
  'kelempung',
  'kelemumur',
  'kelemumurnya',
  'kelencong',
  'kelendara',
  'keleneng',
  'kelengkang',
  'kelenjar',
  'kelentang',
  'kelentanguselesskelentung',
  'kelenteng',
  'kelenting',
  'kelentit',
  'kelentom',
  'kelentong',
  'kelentung',
  'kelenung',
  'kelenut',
  'kelenyuk',
  'kelepai',
  'kelepat',
  'kelepayang',
  'kelepek',
  'kelepet',
  'kelepir',
  'kelepit',
  'kelepot',
  'kelepuk',
  'kelepung',
  'keler',
  'kelereh',
  'kelereng',
  'kelesah',
  'kelesehuselesspeseh',
  'kelesek',
  'keletah',
  'keletahnya',
  'keletak',
  'keletang',
  'keletar',
  'keletuk',
  'keletung',
  'kelewang',
  'keleweh',
  'kelewer',
  'keli',
  'keliar',
  'berkeliaran',
  'kelibang',
  'berkelibangan',
  'kelibut',
  'kelibat',
  'kelibatnya',
  'kelicap',
  'kelici',
  'kelido',
  'kelih',
  'kelik',
  'mengelikkan',
  'kelikir',
  'keliling',
  'kelilingnya',
  'mengelilingkan',
  'kelilingi',
  'mengelilinginya',
  'kelim',
  'kelima',
  'kelimauselesslima',
  'kelimun',
  'kelinci',
  'kelindan',
  'keling',
  'kelintang',
  'kelintar',
  'berkelintaran',
  'kelinting',
  'kelip',
  'kelipkan',
  'kelipan',
  'kelipuk',
  'kelir',
  'keliru',
  'kelirunya',
  'kelirukan',
  'kekeliruan',
  'kelis',
  'kelisa',
  'kelisah',
  'kelit',
  'keliti',
  'kelitik',
  'kelmarin',
  'kelo',
  'kelobak',
  'kelocak',
  'kelodan',
  'kelok',
  'mengelokkan',
  'kelola',
  'kelolakan',
  'kelolaan',
  'keloloh',
  'kelolong',
  'kelompang',
  'kelompok',
  'kelompoknya',
  'mengelompokkan',
  'kelompokan',
  'kelompong',
  'kelona',
  'kelonak',
  'keloneng',
  'kelong',
  'kelongkang',
  'kelongkong',
  'kelongsong',
  'kelontang',
  'kelontong',
  'kelonyor',
  'kelopak',
  'kelopaknya',
  'kelorah',
  'kelorak',
  'kelotak',
  'keloyak',
  'keloyang',
  'kelu',
  'kelulah',
  'mengelukan',
  'keluai',
  'keluak',
  'keluan',
  'keluang',
  'keluangsa',
  'keluar',
  'keluarlah',
  'keluarkan',
  'keluaran',
  'mengeluar',
  'dikeluari',
  'keluarga',
  'berkeluarga',
  'sekeluarga',
  'kekeluargaan',
  'perkeluargaan',
  'kelubi',
  'kelubu',
  'kelubung',
  'mengelubungi',
  'keluh',
  'keluhnya',
  'mengeluh',
  'keluhan',
  'kesah',
  'kelui',
  'keluik',
  'keluk',
  'kelukup',
  'keluli',
  'kelulu',
  'kelulus',
  'kelulut',
  'kelum',
  'kelumbai',
  'kelumpang',
  'kelun',
  'keluna',
  'kelung',
  'kelupas',
  'mengelupas',
  'kelurut',
  'kelus',
  'kelusuh',
  'kelasah',
  'kelut',
  'melut',
  'kelutum',
  'keluyu',
  'keluyuk',
  'keluyur',
  'kem',
  'kemab',
  'kemal',
  'kemalau',
  'kemam',
  'kemamam',
  'keman',
  'kemanakan',
  'kemancung',
  'kemang',
  'kemangi',
  'kemangur',
  'kemani',
  'kemantan',
  'kemantu',
  'kemap',
  'kemarau',
  'kemaruk',
  'kemas',
  'kemaskan',
  'mengemasi',
  'kemasnya',
  'dikemaskini',
  'mengemaskini',
  'kemasan',
  'kematu',
  'kembal',
  'kembali',
  'kembalikan',
  'sekembali',
  'perkembalian',
  'pengembalian',
  'kemban',
  'kembang',
  'berkembang',
  'kembangkan',
  'kembangkannya',
  'kembangan',
  'mengembangbiakkan',
  'ngempiskan',
  'kembut',
  'kembar',
  'kembarnya',
  'mengembari',
  'terkembari',
  'kembaran',
  'kembara',
  'pengembaraan',
  'kembayat',
  'kembera',
  'kembiang',
  'kembili',
  'kembiri',
  'kemboja',
  'kembol',
  'kembola',
  'kembu',
  'kembuk',
  'kembung',
  'mengembung',
  'kembur',
  'kemburan',
  'kembut',
  'kemeja',
  'kemejanya',
  'kemeling',
  'kemelut',
  'kemendang',
  'kemendelam',
  'kemengu',
  'kementam',
  'kemenyan',
  'kemesak',
  'kemetot',
  'kemi',
  'kemih',
  'kemik',
  'kemili',
  'kemin',
  'keminting',
  'kemirau',
  'kemiri',
  'kemis',
  'kemit',
  'kemoterapi',
  'kempa',
  'kempaan',
  'kempang',
  'kempas',
  'kempelang',
  'kempen',
  'kempennya',
  'kemperas',
  'kempiluh',
  'kempilur',
  'kempis',
  'kempiskan',
  'kempit',
  'kempu',
  'kempuh',
  'kempul',
  'kempun',
  'kempunan',
  'kempung',
  'kemucing',
  'kemudi',
  'kemudikan',
  'kemudian',
  'terkemudian',
  'kemudiankan',
  'kemukus',
  'kemumu',
  'kemuncak',
  'kemuncup',
  'kemung',
  'kemungkus',
  'kemuning',
  'kemunting',
  'kemuruk',
  'kemut',
  'kemutan',
  'kemutul',
  'kemutun',
  'ken',
  'kena',
  'terkena',
  'mengena',
  'dikenakan',
  'berkena',
  'pengenaan',
  'kenal',
  'mengenal',
  'kenalkan',
  'kenalkannya',
  'kenali',
  'kenalan',
  'kenalannya',
  'dikenalpasti',
  'mengenalpasti',
  'kenan',
  'perkenankan',
  'memperkenankan',
  'perkenanan',
  'kenang',
  'mengenang',
  'dikenang',
  'kenangan',
  'kenanga',
  'kenapa',
  'kenari',
  'kenarus',
  'kenas',
  'kencana',
  'kencang',
  'mengencang',
  'dikencangkan',
  'kekencangan',
  'kencung',
  'kencar',
  'kenceng',
  'kencing',
  'kencingnya',
  'mengencingi',
  'pengencingan',
  'kencong',
  'kencung',
  'kendaga',
  'kendak',
  'kendali',
  'kendalikan',
  'kendalian',
  'pengendali',
  'kendang',
  'kendati',
  'kendatipun',
  'kendera',
  'kenderaan',
  'kenderaannya',
  'kenderi',
  'kendi',
  'kendil',
  'kendiri',
  'kendit',
  'kendong',
  'kenduduk',
  'kendung',
  'kendur',
  'kendurkan',
  'kekenduran',
  'pengenduran',
  'kenduri',
  'kendurinya',
  'dikendurikan',
  'mengendurikan',
  'kenerak',
  'kenerian',
  'kengkarung',
  'kengkatuk',
  'kengkunang',
  'kenidai',
  'kenik',
  'kenikah',
  'kening',
  'kenini',
  'kenit',
  'kenja',
  'kenjar',
  'kenjing',
  'kenkacang',
  'kenohong',
  'kental',
  'mengentalkan',
  'kentalan',
  'kentala',
  'kentang',
  'kentar',
  'kentara',
  'kentung',
  'kentut',
  'mengentutkan',
  'kenung',
  'kenur',
  'kenya',
  'kenyal',
  'kekenyalan',
  'kenyalang',
  'kenyam',
  'kenyang',
  'mengenyangkan',
  'kekenyangan',
  'kenyat',
  'kenyit',
  'mengenyitkan',
  'kenyitan',
  'kenyir',
  'kenyut',
  'kenyutan',
  'keok',
  'keong',
  'kepada',
  'kepah',
  'kepai',
  'kepak',
  'kepaknya',
  'kepal',
  'mengepalkan',
  'kepalan',
  'kepala',
  'mengepala',
  'dikepalai',
  'kepalanya',
  'kepalang',
  'kepam',
  'kepang',
  'kepangan',
  'kepot',
  'kepar',
  'berkeparan',
  'keparat',
  'kepari',
  'kepau',
  'kepaya',
  'kepayang',
  'kepeng',
  'kepet',
  'kepialu',
  'kepiat',
  'kepik',
  'kepil',
  'kepilkan',
  'kepilan',
  'kepinding',
  'keping',
  'kepingan',
  'kepingin',
  'kepinis',
  'kepis',
  'kepit',
  'kepitan',
  'kepiting',
  'kepompong',
  'kepong',
  'keprak',
  'kepudang',
  'kepuh',
  'kepuk',
  'mengepukkan',
  'kepul',
  'mengepulkan',
  'kepulan',
  'kepundan',
  'kepundung',
  'kepung',
  'terkepung',
  'kepungan',
  'kepur',
  'kepurun',
  'kera',
  'kerabat',
  'kerabatnya',
  'perkerabatan',
  'kerabit',
  'kerabu',
  'keracak',
  'keracap',
  'keraeng',
  'kerah',
  'kerahkan',
  'kerahan',
  'kerai',
  'kerajang',
  'kerajat',
  'kerak',
  'kerakah',
  'kerakal',
  'kerakap',
  'kerakeling',
  'keram',
  'kerama',
  'keramas',
  'keramat',
  'kerambil',
  'kerambit',
  'kerampang',
  'keramunting',
  'keran',
  'kerana',
  'kerancang',
  'keranda',
  'kerandang',
  'kerandut',
  'kerang',
  'kerangan',
  'kerangka',
  'kerangkanya',
  'kerangkai',
  'keranguselesskeroh',
  'keranguselesskerung',
  'keranguselesskerup',
  'kerani',
  'keranjang',
  'keranji',
  'keranta',
  'kerantai',
  'keranting',
  'kerantu',
  'kerantung',
  'keranyah',
  'kerap',
  'mengerapkan',
  'mengerapi',
  'kekerapan',
  'kerapkali',
  'kerapu',
  'keras',
  'keraskan',
  'kerasan',
  'kerasnya',
  'dikerasi',
  'mengerasi',
  'kerat',
  'keratnya',
  'keratan',
  'keratabasa',
  'kerating',
  'keraton',
  'kerau',
  'kerawai',
  'kerawak',
  'kerawang',
  'kerawat',
  'kerawit',
  'kerayung',
  'kerbang',
  'kerbau',
  'kerbaunya',
  'kerbu',
  'kerbuk',
  'kercap',
  'kercip',
  'kercup',
  'kercau',
  'kercing',
  'kercit',
  'kercut',
  'kerdak',
  'kerdam',
  'kerdum',
  'kerdan',
  'kerdil',
  'kekerdilan',
  'kerdip',
  'kerdipkan',
  'kerdipan',
  'kereceng',
  'keredas',
  'kerek',
  'kerekot',
  'keremak',
  'keremangka',
  'keremi',
  'kerempagi',
  'kerempung',
  'keremut',
  'kerenah',
  'kerenam',
  'kerenan',
  'kerencang',
  'kerencung',
  'kerengga',
  'kerenggamunggu',
  'kerengkam',
  'kerengkek',
  'kerengkiang',
  'kerenting',
  'kerenyot',
  'kerepek',
  'kerepes',
  'kerepot',
  'kerepuh',
  'kereseng',
  'keresot',
  'keret',
  'kereta',
  'keretanya',
  'keretan',
  'keretek',
  'keretot',
  'keretuk',
  'keri',
  'keria',
  'kerian',
  'kerianguselesskeriut',
  'keriap',
  'berkeriapan',
  'keriat',
  'keriut',
  'keriau',
  'kerical',
  'kericau',
  'kericik',
  'keridas',
  'keridau',
  'keridik',
  'kerih',
  'kerik',
  'kerikan',
  'kerikal',
  'kerikam',
  'kerikil',
  'kerinan',
  'kerincing',
  'kering',
  'keringkan',
  'kekeringan',
  'pengeringan',
  'mengeringi',
  'keringat',
  'keringatnya',
  'mengeringatkan',
  'keringatan',
  'kelontang',
  'kerontang',
  'kontang',
  'kekontangan',
  'kerinjal',
  'kerinjang',
  'kerinjing',
  'kerintil',
  'kerinting',
  'kerip',
  'keris',
  'kerisi',
  'kerisik',
  'kerisikan',
  'kerisin',
  'kerit',
  'keritik',
  'keriting',
  'mengeriting',
  'keriuk',
  'keriut',
  'berkeriut',
  'kerja',
  'bekerja',
  'kerjakan',
  'kerjakannya',
  'pekerjaan',
  'pekerjaannya',
  'pengerjaan',
  'mengerjai',
  'kerjang',
  'kerjantara',
  'kerjap',
  'mengerjap',
  'kerjasama',
  'bekerjasama',
  'kerjaya',
  'bekerjaya',
  'kerjung',
  'kerkah',
  'kerkak',
  'kerkau',
  'kerki',
  'kerkup',
  'kerlap',
  'kerlapan',
  'kerling',
  'kerlingan',
  'kerlip',
  'kerlipan',
  'kermah',
  'kerman',
  'kermi',
  'kernai',
  'kernap',
  'kernu',
  'kernyam',
  'kernyat',
  'kernyut',
  'kernyih',
  'kernying',
  'kernyit',
  'kernyut',
  'kernyutkan',
  'keryutan',
  'kero',
  'kerobak',
  'kerabik',
  'kerobek',
  'kerobok',
  'kerocok',
  'kerodak',
  'keroh',
  'keroi',
  'kerokot',
  'keromong',
  'keroncong',
  'keroncongan',
  'mengeroncongkan',
  'keroncor',
  'keronguselesskerong',
  'kerongkong',
  'kerongsang',
  'keropak',
  'keropas',
  'kerapis',
  'keropeng',
  'keropok',
  'keropos',
  'kerosek',
  'kerosin',
  'kerosok',
  'kerosong',
  'kerotok',
  'kerotot',
  'keroyok',
  'keroyokan',
  'kerpai',
  'kerpak',
  'kerpas',
  'kerpik',
  'kerpis',
  'kerpuk',
  'kerpus',
  'kersai',
  'kersak',
  'kersang',
  'kersik',
  'kersuk',
  'kersul',
  'kertah',
  'kertak',
  'kertang',
  'kertap',
  'kertas',
  'kertasnya',
  'kertatuk',
  'kertau',
  'kerteh',
  'kertik',
  'kerting',
  'kertip',
  'kertuk',
  'kertup',
  'kertip',
  'kertus',
  'keruan',
  'kerubian',
  'kerubin',
  'kerubung',
  'mengerubungi',
  'dikerubungi',
  'kerubut',
  'mengerubuti',
  'kerucut',
  'kerudung',
  'mengerudungi',
  'kerudut',
  'keruh',
  'keruhkan',
  'kekeruhan',
  'keruing',
  'keruit',
  'keruk',
  'mengerukkan',
  'pengerukan',
  'kerukup',
  'kerukut',
  'kerul',
  'kerumit',
  'kerumuk',
  'mengerumukkan',
  'kerumun',
  'mengerumunkan',
  'dikerumuni',
  'mengerumuni',
  'dikerumunkan',
  'kerumunan',
  'kerung',
  'kerang',
  'keruntang',
  'pukang',
  'kerunting',
  'keruntul',
  'keruntum',
  'keruntung',
  'kerunyut',
  'kerup',
  'keruping',
  'kerusi',
  'kerusinya',
  'pengerusi',
  'pengerusikan',
  'kerut',
  'kerutkan',
  'kerutan',
  'kerutuk',
  'kerutung',
  'mengerutungi',
  'kerutup',
  'kerutut',
  'kes',
  'kesah',
  'kesal',
  'kesalan',
  'kesalkan',
  'kesali',
  'kesambi',
  'kesami',
  'kesan',
  'kesannya',
  'kesani',
  'keberkesanan',
  'mengesankan',
  'pengesanan',
  'kesang',
  'kesangka',
  'kesapuselesskesip',
  'kesasar',
  'kesat',
  'mengesatnya',
  'kesatkan',
  'kesateria',
  'kesebelas',
  'kesejuta',
  'kesembilan',
  'kesenai',
  'keseng',
  'kesepuluh',
  'keser',
  'keseratus',
  'keseribu',
  'kesian',
  'kesidang',
  'kesikuselesskesik',
  'kesima',
  'kesimbukan',
  'kesinambungan',
  'kesinambungannya',
  'kesinan',
  'kesing',
  'kesinga',
  'kesip',
  'keskul',
  'kesmak',
  'kesmaran',
  'kesot',
  'mengesotkan',
  'kesturi',
  'kesuhuselesskesih',
  'kesuk',
  'kesuuselesskesi',
  'kesum',
  'kesuma',
  'kesumat',
  'kesup',
  'kesut',
  'ketaban',
  'ketagih',
  'ketai',
  'ketak',
  'ketakung',
  'ketam',
  'mengetam',
  'pengetaman',
  'ketambak',
  'ketampi',
  'ketan',
  'ketap',
  'mengetap',
  'ketapak',
  'ketapang',
  'ketapi',
  'ketapik',
  'ketar',
  'ketara',
  'ketarap',
  'ketat',
  'ketatkan',
  'ketatnya',
  'ketawa',
  'ketawakan',
  'mengetawai',
  'ketaya',
  'ketayap',
  'ketegar',
  'ketel',
  'ketela',
  'ketemu',
  'ketemukan',
  'ketemui',
  'keteng',
  'mengeteng',
  'ketengan',
  'ketenggah',
  'ketenun',
  'keteping',
  'ketering',
  'ketetel',
  'keti',
  'ketiak',
  'ketial',
  'ketiap',
  'ketiau',
  'ketidakadilan',
  'ketidakaktifan',
  'ketidakakuran',
  'ketidakbenaran',
  'ketidakberhasilan',
  'ketidakcekapan',
  'ketidakfahaman',
  'ketidakfungsian',
  'ketidakhadiran',
  'ketidakharmonian',
  'ketidakmampuan',
  'ketidaknormalan',
  'ketidakpastian',
  'ketidakpatuhan',
  'ketidakpercayaan',
  'ketidakpuasan',
  'ketidaksamaan',
  'ketidaksefahaman',
  'ketidakseimbangan',
  'ketidaksehaluan',
  'ketidakselarasan',
  'ketidakselesaan',
  'ketidaksenangan',
  'ketidakseragaman',
  'ketidakserasian',
  'ketidakseriusan',
  'ketidaksetujuan',
  'ketidakstabilan',
  'ketidaksuburan',
  'ketidaktahuan',
  'ketidaktelusan',
  'ketidaktenteraman',
  'ketidaktentuan',
  'ketidakupayaan',
  'ketiding',
  'ketiga',
  'ketik',
  'ketikan',
  'ketika',
  'ketikanya',
  'ketil',
  'ketilang',
  'ketimbung',
  'berketimbungan',
  'keting',
  'ketingting',
  'ketip',
  'ketipung',
  'ketirah',
  'ketis',
  'ketitir',
  'ketola',
  'ketoprak',
  'ketot',
  'ketua',
  'ketuai',
  'mengetuakan',
  'berketuakan',
  'ketuat',
  'ketuau',
  'ketuban',
  'ketuhar',
  'ketujuh',
  'ketujuhuselesstujuh',
  'ketuk',
  'ketukan',
  'ketul',
  'mengetulkan',
  'ketulan',
  'ketum',
  'ketumbak',
  'ketumbar',
  'ketumbit',
  'ketumbu',
  'ketumbukan',
  'ketun',
  'ketung',
  'ketungging',
  'ketup',
  'ketupat',
  'ketupuk',
  'ketur',
  'ketus',
  'ketut',
  'ketutu',
  'ketutuh',
  'keweh',
  'kha',
  'khabar',
  'khabarkan',
  'perkhabaran',
  'khabarnya',
  'khadam',
  'khairat',
  'khakan',
  'khalayak',
  'khali',
  'khalifah',
  'khalik',
  'khalilullah',
  'khalwah',
  'khalwat',
  'khamar',
  'khamir',
  'khamis',
  'khanah',
  'khanjar',
  'kharab',
  'khartoum',
  'khas',
  'khaskan',
  'khasnya',
  'khasiat',
  'khasiatnya',
  'mengkhasiati',
  'khat',
  'khatam',
  'khatamkan',
  'khatan',
  'khatankan',
  'khatib',
  'khatulistiwa',
  'khayal',
  'khayalkan',
  'khayalan',
  'khayali',
  'khazanah',
  'khemah',
  'perkhemahan',
  'khianat',
  'mengkhianatkan',
  'khianati',
  'pengkhianatan',
  'khidmat',
  'khidmatnya',
  'mengkhidmati',
  'dikhidmati',
  'perkhidmatan',
  'khilaf',
  'kekhilafan',
  'khilafiyah',
  'khuatir',
  'khuatiri',
  'khuatirkan',
  'kekhuatiran',
  'khuldi',
  'khuluk',
  'khunsa',
  'khurafat',
  'khusus',
  'khususnya',
  'khususkan',
  'pengkhususan',
  'khusyuk',
  'kekhusyukkan',
  'khutbah',
  'khutbahnya',
  'dikhutbahkan',
  'mengkhutbahkan',
  'kia',
  'kiah',
  'kiai',
  'kiak',
  'kial',
  'mengialkan',
  'perkialan',
  'kiam',
  'kiamat',
  'kiambang',
  'kian',
  'sekian',
  'kiangk',
  'kiut',
  'kiani',
  'kiap',
  'kias',
  'kiaskan',
  'mengiasi',
  'kiasan',
  'kiat',
  'kibar',
  'kibarkan',
  'kibaran',
  'kibas',
  'ngibaskan',
  'kibir',
  'kiblat',
  'berkiblat',
  'mengiblatkan',
  'kicang',
  'kicap',
  'kicar',
  'kicau',
  'kici',
  'kidal',
  'kidung',
  'mengidung',
  'kifarah',
  'kifarat',
  'kifayah',
  'kijang',
  'kijil',
  'kijing',
  'kikir',
  'kikiran',
  'kikis',
  'kikiskan',
  'kikisan',
  'kilai',
  'kilan',
  'kilang',
  'mengilangkan',
  'dikilang',
  'kilangan',
  'kilangin',
  'kilap',
  'kilar',
  'kilas',
  'kilat',
  'kilatnya',
  'kilatkan',
  'kilatan',
  'kilau',
  'kilauannya',
  'mengilau',
  'kemilau',
  'kili',
  'kilik',
  'kiliuselesskili',
  'kilir',
  'kilo',
  'kiloan',
  'kilobit',
  'kilogram',
  'kilohertz',
  'kilokalori',
  'kiloliter',
  'kilometer',
  'kilowatt',
  'kima',
  'kimantu',
  'kimbang',
  'kimbul',
  'kimia',
  'kimiawi',
  'kimkha',
  'kimono',
  'kimpal',
  'kimpalan',
  'kimpul',
  'kimput',
  'kinantan',
  'kinarut',
  'kincah',
  'kincak',
  'kincang',
  'kincau',
  'kincir',
  'kinciran',
  'kincit',
  'kincung',
  'kincup',
  'kindap',
  'kinestetik',
  'kinetik',
  'kini',
  'kinja',
  'kinjang',
  'kinjat',
  'kinrara',
  'kintal',
  'kinyang',
  'kip',
  'kipai',
  'kipang',
  'kipas',
  'kipaskan',
  'kipasi',
  'kipsiau',
  'kipu',
  'kir',
  'kira',
  'kirakan',
  'kiranya',
  'kiralah',
  'kiraan',
  'ngira',
  'kirai',
  'mengirai',
  'kirap',
  'kiras',
  'kirau',
  'kirbat',
  'kiri',
  'terkiri',
  'mengiri',
  'dikirikan',
  'kirim',
  'kirimkan',
  'kirimnya',
  'kiriman',
  'mengirimi',
  'kirip',
  'kirmizi',
  'kiru',
  'kirung',
  'kisa',
  'kisah',
  'kisahkan',
  'kisai',
  'kisar',
  'kisarkan',
  'kisaran',
  'kisi',
  'kisiuselesskisi',
  'kisit',
  'kismat',
  'kismis',
  'kisut',
  'mengisutkan',
  'kiswah',
  'kita',
  'kekitaan',
  'kitab',
  'kitang',
  'kitar',
  'mengitar',
  'kitaran',
  'kitarnya',
  'persekitaran',
  'kiting',
  'kitiran',
  'kiub',
  'kiung',
  'kiut',
  'kiutan',
  'kiwi',
  'kizib',
  'klac',
  'klarinet',
  'klasifikasi',
  'klasifikasikan',
  'klasik',
  'klasikal',
  'klien',
  'klik',
  'klimaks',
  'klinik',
  'klinikal',
  'klip',
  'klise',
  'klon',
  'klorida',
  'klorin',
  'pengklorinan',
  'kloroform',
  'kloroplas',
  'knot',
  'koala',
  'kobalt',
  'kobar',
  'kobarkan',
  'kobe',
  'koboi',
  'kobok',
  'koc',
  'kocahuselesskacih',
  'kocak',
  'kocakan',
  'mengocakkan',
  'kocek',
  'kocoh',
  'kocok',
  'kocong',
  'kod',
  'kodnya',
  'kodkan',
  'pengkodan',
  'kodak',
  'kodi',
  'kodian',
  'kodok',
  'kognisi',
  'kognitif',
  'koheren',
  'kohesi',
  'kohol',
  'kohong',
  'koir',
  'kojoh',
  'kojol',
  'kok',
  'kokain',
  'koko',
  'kokok',
  'kokol',
  'kokokuselessbelut',
  'kokot',
  'kokpit',
  'koktel',
  'kokurikulum',
  'kolagen',
  'kolah',
  'kolak',
  'kolakuselesskalik',
  'kolam',
  'kolar',
  'kolateral',
  'kole',
  'koleh',
  'kolej',
  'kolek',
  'koleksi',
  'kolektif',
  'mengkolektifkan',
  'kolembing',
  'koleng',
  'kolera',
  'kolesom',
  'kolesterol',
  'kolestrin',
  'koloh',
  'kolokasi',
  'kolokium',
  'kolon',
  'kolonel',
  'kolong',
  'kolongan',
  'koloni',
  'kolonial',
  'kolonialisme',
  'kolonisasi',
  'kolot',
  'kekolotan',
  'kolum',
  'kolumnya',
  'kolumnis',
  'koma',
  'mengomakan',
  'koman',
  'komandan',
  'komander',
  'komando',
  'komanwel',
  'kombat',
  'kombinasi',
  'kombinasikan',
  'kombo',
  'komedi',
  'komedinya',
  'komen',
  'komennya',
  'komenlah',
  'komentar',
  'komersial',
  'komersialkan',
  'pengkomersialan',
  'komet',
  'komik',
  'komikal',
  'komisen',
  'komited',
  'komitmen',
  'komoditi',
  'komodo',
  'komodor',
  'komoi',
  'kompak',
  'kompang',
  'komparitif',
  'kompas',
  'kompaun',
  'kompaunnya',
  'kompeni',
  'kompensasi',
  'kompeten',
  'kompetensi',
  'kompetitif',
  'kompil',
  'kompilasi',
  'kompleks',
  'kompleksiti',
  'komplemen',
  'komplementari',
  'komplians',
  'komplikasi',
  'komplot',
  'berkomplot',
  'kompol',
  'komponen',
  'kompos',
  'komposer',
  'komposit',
  'komposisi',
  'kompot',
  'komprehensif',
  'kompres',
  'kompresor',
  'kompromi',
  'komputer',
  'komputernya',
  'mengkomputerkan',
  'dikomputerkan',
  'pengkomputeran',
  'komun',
  'komunal',
  'komunaliti',
  'komunikasi',
  'komunikatif',
  'komunike',
  'komunis',
  'komunisme',
  'komuniti',
  'komuter',
  'kon',
  'konan',
  'konbif',
  'koncah',
  'konco',
  'kondem',
  'kondemnasi',
  'kondensasi',
  'kondenser',
  'kondensor',
  'kondo',
  'kondom',
  'kondominium',
  'konduk',
  'konduksi',
  'konduktiviti',
  'konduktor',
  'kondusif',
  'koneng',
  'konfederasi',
  'konferensi',
  'konfigurasi',
  'konflik',
  'konfrontasi',
  'konfrontasional',
  'kongkang',
  'kongkong',
  'mengongkongi',
  'kongkongan',
  'konglomerat',
  'kongres',
  'kongruen',
  'kongsi',
  'kongsinya',
  'kongsian',
  'kongsilah',
  'konifer',
  'konkrit',
  'konon',
  'mengonon',
  'kononkan',
  'konotasi',
  'konotatif',
  'konsain',
  'konsainan',
  'konsensus',
  'konsentrasi',
  'konsentrat',
  'konsep',
  'berkonsep',
  'konsepsi',
  'konseptual',
  'konsert',
  'konserto',
  'konservatif',
  'konservatori',
  'konsesi',
  'konsigni',
  'konsignor',
  'konsiliasi',
  'konsisten',
  'konsol',
  'konsolidasi',
  'mengkonsolidasikan',
  'konsonan',
  'konsortium',
  'konspirasi',
  'konstabel',
  'konstituen',
  'konstruk',
  'konstruksi',
  'konsul',
  'konsular',
  'konsulat',
  'konsultan',
  'konsultasi',
  'konsultatif',
  'kontaluselesskantil',
  'kontaminasi',
  'kontan',
  'kontang',
  'pengontangan',
  'kanting',
  'konteks',
  'kontekstual',
  'kontekstualiti',
  'kontemporari',
  'kontena',
  'kontinjen',
  'kontinuiti',
  'kontinum',
  'kontot',
  'kontra',
  'kontradiksi',
  'kontrak',
  'kontraksi',
  'kontraktor',
  'kontraktual',
  'kontraktualiti',
  'kontras',
  'kontrasepsi',
  'kontraseptif',
  'kontrastif',
  'kontrol',
  'kontroversi',
  'kontur',
  'konvensional',
  'konvensyen',
  'konveyor',
  'konvo',
  'konvoi',
  'konvoinya',
  'konvokesyen',
  'konyong',
  'kooperatif',
  'koordinasi',
  'koordinat',
  'koorperasi',
  'kopak',
  'kopaklah',
  'kopakuselesskapik',
  'kopek',
  'mengopek',
  'dikopek',
  'kopeng',
  'koperal',
  'koperasi',
  'koperasinya',
  'koperatif',
  'kopi',
  'kopiah',
  'kopula',
  'kor',
  'korban',
  'korbannya',
  'korbankan',
  'pengorbanan',
  'kord',
  'kordinasi',
  'mengkordinasikan',
  'korea',
  'korek',
  'pengorekan',
  'koreksi',
  'korelasi',
  'koreografer',
  'koreografi',
  'koresponden',
  'koridor',
  'kornea',
  'kornet',
  'korok',
  'korona',
  'koronari',
  'koroner',
  'korporat',
  'dikorporatkan',
  'mengkorporatkan',
  'pengkorporatan',
  'korpus',
  'korset',
  'korum',
  'korup',
  'korupsi',
  'korus',
  'kos',
  'kosa',
  'kosakuselesskasik',
  'kosinus',
  'kosmetik',
  'kosmologi',
  'kosmopolitan',
  'kosmos',
  'kosong',
  'kosongkan',
  'mengosongkannya',
  'kekosongan',
  'pengosongan',
  'kostum',
  'kot',
  'kota',
  'kotanya',
  'kotakan',
  'perkotaan',
  'kekotaan',
  'kotak',
  'kotaknya',
  'kotakuselesskatik',
  'kotamadya',
  'kotamara',
  'kotapraja',
  'kotek',
  'koteng',
  'kotiledon',
  'kotong',
  'kotor',
  'kotoran',
  'kotorkan',
  'mengotori',
  'dikotorkan',
  'kotori',
  'kotot',
  'koyak',
  'dikoyak',
  'mengoyak',
  'koyam',
  'koyan',
  'koyok',
  'kraf',
  'kraftangan',
  'krayon',
  'kreasi',
  'kreatif',
  'kreativiti',
  'kredibel',
  'kredibiliti',
  'kredit',
  'kreditkan',
  'perkreditan',
  'kredo',
  'kremasi',
  'krematorium',
  'kren',
  'krew',
  'kriket',
  'krim',
  'kriminal',
  'kriminologi',
  'krisis',
  'krismas',
  'kristal',
  'kristian',
  'kriteria',
  'kritik',
  'kritikan',
  'kritiklah',
  'kritikal',
  'kritis',
  'krom',
  'kromium',
  'kromo',
  'kromosom',
  'kroni',
  'kronik',
  'kronograf',
  'kronologi',
  'kronologikal',
  'kronometer',
  'kronometrik',
  'ku',
  'kuaci',
  'kuah',
  'kuahnya',
  'kuah',
  'kuak',
  'dikuak',
  'menguak',
  'kuala',
  'kuala',
  'kuali',
  'kualifikasi',
  'kualitatif',
  'kualiti',
  'kualitinya',
  'kuang',
  'kuangkuit',
  'kuantan',
  'kuantiti',
  'kuantitinya',
  'kuantum',
  'kuap',
  'kuarantin',
  'kuari',
  'kuarters',
  'kuarto',
  'kuarza',
  'kuasa',
  'kuasanya',
  'kuasai',
  'dikuasainya',
  'menguasainya',
  'dikuasakan',
  'menguasakan',
  'kekuasaan',
  'penguasaan',
  'kuat',
  'kuatnya',
  'kuatlah',
  'kuatkan',
  'kekuatan',
  'penguatan',
  'kuatkuasa',
  'kuatkuasakan',
  'penguatkuasaan',
  'kuau',
  'kuayah',
  'kubah',
  'kubal',
  'kubang',
  'kubarasa',
  'kubik',
  'kubis',
  'kuboid',
  'kubu',
  'terkubu',
  'berkubu',
  'perkubuan',
  'kubur',
  'kuburkan',
  'kuburan',
  'mengubur',
  'kucai',
  'kucar',
  'kacir',
  'ngacirkan',
  'kuching',
  'kucil',
  'kucing',
  'berkucing',
  'kucup',
  'kucupan',
  'berkucupan',
  'mengucupkan',
  'kuda',
  'kudanya',
  'perkudaan',
  'kudai',
  'kudap',
  'kudapan',
  'kudat',
  'kudeta',
  'kudis',
  'kudisan',
  'kudrat',
  'kudrati',
  'kudsi',
  'kudung',
  'kudungan',
  'mengudung',
  'kudup',
  'kudus',
  'menguduskan',
  'kekudusan',
  'kuetiau',
  'kufur',
  'mengkufurkan',
  'kugiran',
  'kuih',
  'muih',
  'kuihuselessmuih',
  'kuil',
  'kuini',
  'kuinin',
  'kuis',
  'menguis',
  'dikuiskan',
  'kuit',
  'menguit',
  'kuiz',
  'kujur',
  'kuku',
  'kukunya',
  'kukuh',
  'kukuhkan',
  'kukuhnya',
  'mengukuhi',
  'dikukuhi',
  'kekukuhan',
  'pengukuhan',
  'kukup',
  'kukur',
  'kukuran',
  'kukus',
  'kukusan',
  'kulahuselesskulah',
  'kulai',
  'berkulaian',
  'kulak',
  'kulampir',
  'kulan',
  'kulang',
  'kulansing',
  'kulapuk',
  'kulasentana',
  'kulat',
  'kulau',
  'kulawangsa',
  'kuli',
  'kuliah',
  'kuliahnya',
  'kulikuselesskulik',
  'kulim',
  'kulipis',
  'kulir',
  'kulit',
  'berkulit',
  'mengulit',
  'perkulitan',
  'kultur',
  'kulturkebun',
  'kulum',
  'kuma',
  'kumauselesskuma',
  'kumai',
  'kumal',
  'kuman',
  'kumandang',
  'dikumandangkan',
  'mengumandangkan',
  'kumangga',
  'kumat',
  'kamit',
  'ngamitkan',
  'kumatuselesskamit',
  'kumbah',
  'kumbang',
  'kumbik',
  'kumbuh',
  'kumin',
  'kumis',
  'kumkuma',
  'kumlah',
  'kumpai',
  'kumpal',
  'kumpar',
  'kumpuan',
  'kumpul',
  'berkumpul',
  'sekumpul',
  'pengumpul',
  'kumpulan',
  'kumpulkan',
  'kumuh',
  'dikumuhkan',
  'mengumuhkan',
  'perkumuhan',
  'kumulatif',
  'kumur',
  'kumus',
  'kumut',
  'kun',
  'kunang',
  'kunani',
  'kunapa',
  'kunca',
  'kunci',
  'kuncikan',
  'kuncinya',
  'kuncit',
  'kuncup',
  'menguncup',
  'penguncupan',
  'kuncur',
  'kundai',
  'kundang',
  'kundangan',
  'kundasang',
  'kunduh',
  'kundur',
  'kungfu',
  'kungki',
  'kungkur',
  'kuning',
  'kuningan',
  'kunjung',
  'kunjungi',
  'dikunjunginya',
  'mengunjunginya',
  'pengunjungnya',
  'kunjungan',
  'kuno',
  'kekunoan',
  'kuntul',
  'kuntum',
  'kuntuman',
  'kuntung',
  'kunyah',
  'kunyahan',
  'mengunyahkan',
  'kunyit',
  'kuorum',
  'kuota',
  'kupang',
  'kupas',
  'kupaskan',
  'kupasan',
  'kupi',
  'kupil',
  'kuping',
  'kupluk',
  'kupoi',
  'kupon',
  'kuprum',
  'kupu',
  'kupuan',
  'kupur',
  'kur',
  'kura',
  'kurauselesskura',
  'kuran',
  'kurang',
  'berkurang',
  'kurangnya',
  'kurangkan',
  'kurangkannya',
  'kurangi',
  'kekurangan',
  'pengurangan',
  'perkurangan',
  'kurap',
  'kuras',
  'pengurasan',
  'kurator',
  'kurau',
  'kurawal',
  'kurbal',
  'kurcaci',
  'kuria',
  'kurier',
  'kurikulum',
  'kuripal',
  'kuripan',
  'kurita',
  'kurma',
  'kurnia',
  'kurnianya',
  'kurniakan',
  'kurniai',
  'kurniaan',
  'kurniaanmu',
  'kursif',
  'kursus',
  'kursusnya',
  'dikursus',
  'kurun',
  'kurung',
  'kurungan',
  'kurungkan',
  'kurungnya',
  'kurus',
  'mengurus',
  'kekurusan',
  'kurva',
  'kusauselesskusa',
  'kusal',
  'kusam',
  'kekusaman',
  'kusau',
  'kusi',
  'kusin',
  'kusir',
  'kusta',
  'kusuuselesskusu',
  'kusum',
  'kusut',
  'kusutnya',
  'mengusut',
  'kekusutan',
  'masai',
  'kusutuselessmasai',
  'kusutuselessmurut',
  'kusyen',
  'kutang',
  'kutik',
  'kutikel',
  'kutil',
  'kutip',
  'kutipan',
  'mengutipnya',
  'kutu',
  'kutub',
  'kutuk',
  'kutukan',
  'mengutuki',
  'kuwait',
  'kuwung',
  'kuya',
  'kuyu',
  'kuyuh',
  'kuyuk',
  'kuyung',
  'kuyup',
  'kyoto',
  'laba',
  'labanya',
  'melabakan',
  'labah',
  'labak',
  'labang',
  'labas',
  'label',
  'labelkan',
  'labelnya',
  'pelabelan',
  'laberang',
  'labi',
  'labirin',
  'labu',
  'labuan',
  'labuci',
  'labuh',
  'labuhkan',
  'pelabuhan',
  'labun',
  'labur',
  'laburkan',
  'pelaburan',
  'labut',
  'lacak',
  'laci',
  'lacinya',
  'lacur',
  'lacurkan',
  'pelacuran',
  'lada',
  'ladaian',
  'ladam',
  'ladan',
  'ladang',
  'ladangnya',
  'diperladangkan',
  'memperladang',
  'perladangan',
  'laden',
  'ladi',
  'lading',
  'ladu',
  'ladung',
  'meladungkan',
  'lafal',
  'lafaz',
  'lafazkan',
  'laga',
  'lagakan',
  'perlagaan',
  'lagak',
  'lagaknya',
  'melagak',
  'dilagakkan',
  'lagam',
  'lagan',
  'lagang',
  'lagi',
  'selagi',
  'lagu',
  'lagunya',
  'laguku',
  'melagu',
  'dilagukan',
  'laguan',
  'lagun',
  'lagundi',
  'lagut',
  'lah',
  'lahad',
  'lahap',
  'lahar',
  'lahir',
  'dilahirkan',
  'kelahiran',
  'lahirkan',
  'lahiriah',
  'lai',
  'laici',
  'lailahaillallah',
  'lailatulqadar',
  'lain',
  'selain',
  'berlain',
  'kelainan',
  'melainkan',
  'memperlainkan',
  'lais',
  'laissezuselessfaire',
  'laja',
  'lajak',
  'lajakan',
  'lajang',
  'laju',
  'kelajuan',
  'kelajuannya',
  'lajukan',
  'pelajuan',
  'perlajuan',
  'lajur',
  'lak',
  'laka',
  'lakab',
  'lakak',
  'lakar',
  'lakaran',
  'laki',
  'berlaki',
  'memperlaki',
  'perlakikan',
  'kelakian',
  'kelelakian',
  'lakiuselesslaki',
  'laknat',
  'melaknatkan',
  'laknatullah',
  'lakon',
  'pelakon',
  'lakonan',
  'lakonkan',
  'lakri',
  'laksa',
  'laksam',
  'laksamana',
  'laksan',
  'laksana',
  'terlaksana',
  'perlaksanaan',
  'pelaksana',
  'laksanakan',
  'laksemi',
  'laktosa',
  'laku',
  'berlaku',
  'lakukan',
  'kelakuan',
  'berkelakuan',
  'perlakuan',
  'lakumu',
  'lakum',
  'lakur',
  'lakuran',
  'melakurkan',
  'lala',
  'lalah',
  'lalai',
  'lalaikan',
  'kelalaian',
  'pelalai',
  'lalak',
  'lalang',
  'lalap',
  'lalar',
  'lalat',
  'lalau',
  'lali',
  'melalikan',
  'pelalian',
  'lalu',
  'terlalu',
  'lalui',
  'lalukan',
  'laluan',
  'keterlaluan',
  'kelaluan',
  'lalulintas',
  'laluuselesslalang',
  'laluuselesslandang',
  'lam',
  'lama',
  'berlama',
  'kelamaan',
  'lamakah',
  'melamakan',
  'lamai',
  'lamuselessalif',
  'laman',
  'lamang',
  'lamar',
  'melamar',
  'lamaran',
  'lamat',
  'lambai',
  'lambaikan',
  'lambaian',
  'pelambai',
  'lambak',
  'lambakan',
  'lambakkan',
  'lamban',
  'lambang',
  'melambangkan',
  'memperlambangkan',
  'perlambangan',
  'lambaran',
  'lambas',
  'lambat',
  'lambatkan',
  'lambatnya',
  'lambatlah',
  'berlambat',
  'kelambatan',
  'lambau',
  'lambing',
  'lambir',
  'lambu',
  'lambuk',
  'lambung',
  'lambungkan',
  'lambungan',
  'lambur',
  'lamdukpai',
  'lamiding',
  'lamin',
  'lamina',
  'lampai',
  'lampam',
  'lampan',
  'lampar',
  'lampas',
  'melampaskan',
  'lampau',
  'melampau',
  'kelampauan',
  'lampeni',
  'lampias',
  'melampiaskan',
  'lampin',
  'lampir',
  'lampirkan',
  'melampiri',
  'lampit',
  'lampoyang',
  'lampu',
  'lampunya',
  'melampukan',
  'lampuk',
  'lampung',
  'lamun',
  'lamunan',
  'lamur',
  'lana',
  'lanang',
  'lanar',
  'lanau',
  'lanca',
  'lancang',
  'melancangkan',
  'kelancangan',
  'lancap',
  'lancar',
  'lancarnya',
  'lancarkan',
  'lancaran',
  'lancip',
  'lancong',
  'pelancongan',
  'lancung',
  'lancungan',
  'lancur',
  'lancut',
  'landa',
  'landahur',
  'landai',
  'landaian',
  'landak',
  'landap',
  'landas',
  'landasan',
  'berlandas',
  'melandaskan',
  'landau',
  'landskap',
  'landuk',
  'landung',
  'melandung',
  'landur',
  'melandur',
  'landut',
  'melandut',
  'langah',
  'melangak',
  'terbelangah',
  'langakuselesslanguk',
  'langakuselesslongok',
  'langau',
  'langgah',
  'langgai',
  'langgam',
  'langgan',
  'langgannya',
  'langganan',
  'langganannya',
  'langgani',
  'langgar',
  'berlanggar',
  'melanggar',
  'dilanggar',
  'perlanggaran',
  'pelanggaran',
  'langgas',
  'langgung',
  'langi',
  'langir',
  'langit',
  'lelangit',
  'langkah',
  'langkahnya',
  'langkahku',
  'melangkah',
  'dilangkah',
  'kelangkahan',
  'pelangkahan',
  'langkan',
  'langkap',
  'langkas',
  'langkat',
  'langkau',
  'melangkau',
  'langkawi',
  'langkisau',
  'langkup',
  'langlang',
  'langsai',
  'langsaikan',
  'langsar',
  'langsat',
  'langsi',
  'langsing',
  'melangsingkan',
  'langsir',
  'melangsir',
  'langsit',
  'langsuir',
  'langsung',
  'langsungnya',
  'langsungkan',
  'perlangsungan',
  'kelangsungan',
  'langu',
  'languk',
  'langut',
  'lanjai',
  'lanjam',
  'lanjang',
  'lanjar',
  'lanji',
  'lanjung',
  'lanjur',
  'terlanjurkan',
  'melanjurkan',
  'keterlanjuran',
  'lanjut',
  'lanjutkan',
  'selanjutnya',
  'lanjutan',
  'lanset',
  'lantai',
  'lantainya',
  'berlantaikan',
  'lantak',
  'melantak',
  'lantakan',
  'lantam',
  'lantan',
  'lantang',
  'melantang',
  'kelantangan',
  'lantar',
  'lantaran',
  'melantarkan',
  'menterlantarkan',
  'lantas',
  'melantas',
  'lantik',
  'lantikan',
  'lantin',
  'lanting',
  'terpelanting',
  'melanting',
  'lantun',
  'melantun',
  'lantung',
  'lantur',
  'melantur',
  'lanun',
  'lanus',
  'lanyah',
  'lanyak',
  'lanyau',
  'lap',
  'pengelap',
  'lapah',
  'lapak',
  'lapan',
  'perlapan',
  'lapannya',
  'lapang',
  'lapangan',
  'melapangi',
  'lapangkan',
  'lapar',
  'laparnya',
  'laparkan',
  'kelaparan',
  'lapik',
  'melapik',
  'dilapik',
  'lapis',
  'melapis',
  'dilapisi',
  'lapiskan',
  'lapisan',
  'lapor',
  'laporan',
  'laporkan',
  'laporkanlah',
  'pelapor',
  'lapuk',
  'melapukkan',
  'kelapukan',
  'lapun',
  'lapur',
  'lara',
  'berlarauselesslara',
  'terlarauselesslara',
  'larah',
  'larak',
  'laram',
  'larang',
  'melarang',
  'larangan',
  'larangannya',
  'larap',
  'melarapkan',
  'laras',
  'laraskan',
  'larasan',
  'selaraskan',
  'menyelaras',
  'penyelaras',
  'keselarasan',
  'larat',
  'laratlah',
  'larau',
  'lari',
  'berlari',
  'pelari',
  'selari',
  'terlari',
  'larikan',
  'larih',
  'larik',
  'laris',
  'melariskan',
  'larung',
  'larut',
  'larutan',
  'larutannya',
  'larutkan',
  'larva',
  'las',
  'lasa',
  'lasah',
  'lasak',
  'kelasakan',
  'lasam',
  'lasana',
  'laser',
  'laso',
  'lastik',
  'lasu',
  'lasuh',
  'lasykar',
  'lat',
  'lata',
  'latah',
  'melatah',
  'kelatahan',
  'latak',
  'latam',
  'melatam',
  'latar',
  'berlatar',
  'pelataran',
  'berlatarbelakangkan',
  'laterit',
  'latih',
  'latihnya',
  'latihan',
  'pelatih',
  'latis',
  'latitud',
  'latlau',
  'latu',
  'latuh',
  'latuk',
  'latur',
  'lau',
  'lauh',
  'lauk',
  'berlauk',
  'laukuselesspauk',
  'laukuselesspituk',
  'laun',
  'laung',
  'laungkan',
  'laungan',
  'sepelaung',
  'laur',
  'laut',
  'lautnya',
  'melaut',
  'dilautkan',
  'lautan',
  'lawa',
  'melawa',
  'lawah',
  'lawak',
  'lawaknya',
  'lawan',
  'berlawan',
  'melawan',
  'dilawan',
  'perlawanan',
  'memperlawankan',
  'lawang',
  'lawar',
  'lawas',
  'melawas',
  'lawat',
  'melawatnya',
  'lawati',
  'lawatan',
  'lawi',
  'layah',
  'layak',
  'selayaknya',
  'melayakkan',
  'kelayakan',
  'kelayakannya',
  'layam',
  'layan',
  'pelayan',
  'melayan',
  'dilayan',
  'layanan',
  'layang',
  'layangkan',
  'layangan',
  'layanguselesslayang',
  'layap',
  'melayap',
  'layar',
  'belayar',
  'layari',
  'layarkan',
  'pelayaran',
  'pelayar',
  'layas',
  'layeran',
  'layu',
  'layuan',
  'melayukan',
  'layuh',
  'layuk',
  'layur',
  'dilayur',
  'melayur',
  'layut',
  'lazat',
  'lazatnya',
  'kelazatan',
  'lazim',
  'melazimkan',
  'kelaziman',
  'lazuardi',
  'lebah',
  'lebai',
  'lebak',
  'lebam',
  'leban',
  'lebang',
  'lebap',
  'lebar',
  'lebarnya',
  'lebarkan',
  'kelebaran',
  'lebaran',
  'lebas',
  'lebat',
  'lebatnya',
  'kelebatan',
  'lebih',
  'lebihnya',
  'berlebih',
  'melebih',
  'lebihkan',
  'lebihan',
  'lebir',
  'lebu',
  'pelebuan',
  'lebuh',
  'melebuhkan',
  'lebuhraya',
  'lebuk',
  'lebum',
  'lebun',
  'lebung',
  'lebur',
  'leburkan',
  'leburan',
  'leca',
  'lecah',
  'lecak',
  'lecakuselesslecuk',
  'lecap',
  'leceh',
  'peleceh',
  'meleceh',
  'lecek',
  'lecer',
  'leces',
  'lecet',
  'melecet',
  'lecit',
  'lecok',
  'lecuh',
  'lecup',
  'lecur',
  'melecur',
  'lecus',
  'lecut',
  'melecut',
  'lecutan',
  'ledak',
  'meledak',
  'diledak',
  'ledakan',
  'ledang',
  'leding',
  'ledor',
  'leduk',
  'ledung',
  'leftenan',
  'lega',
  'melega',
  'kelegaan',
  'legam',
  'legap',
  'legar',
  'melegari',
  'melegarkan',
  'legaran',
  'legat',
  'legenda',
  'legih',
  'legong',
  'legu',
  'leguhuselesslegah',
  'legum',
  'legumuselesslegam',
  'legundi',
  'legung',
  'legup',
  'legupuselesslegap',
  'leher',
  'lehernya',
  'leja',
  'lejang',
  'melejang',
  'lejar',
  'lejit',
  'lejuh',
  'leka',
  'melekakan',
  'kelekaan',
  'lekah',
  'lekak',
  'lekam',
  'lekang',
  'melekang',
  'lekap',
  'melekap',
  'dilekapkan',
  'lekapan',
  'lekup',
  'lekar',
  'melekari',
  'lekas',
  'lekasnya',
  'melekaskan',
  'lekat',
  'lekatkan',
  'melekat',
  'dilekat',
  'lekatan',
  'lekeh',
  'perlekeh',
  'lekehkan',
  'lekir',
  'lekis',
  'lekit',
  'leksikal',
  'leksikograf',
  'leksikografi',
  'leksikon',
  'leku',
  'lekuh',
  'lekuhuselesslekah',
  'lekuhuselesslekih',
  'lekuk',
  'melekuk',
  'pelekuk',
  'lekumuselesslekam',
  'lekung',
  'lela',
  'melela',
  'lelabah',
  'lelabi',
  'lelabu',
  'lelada',
  'lelah',
  'melelah',
  'lelai',
  'melelai',
  'lelais',
  'lelak',
  'lelaki',
  'lelampang',
  'lelan',
  'lelang',
  'lelangit',
  'lelantar',
  'lelap',
  'melelapkan',
  'lelar',
  'lelas',
  'melelas',
  'lelat',
  'lelatu',
  'lelauh',
  'lelawi',
  'lelayang',
  'leleh',
  'meleleh',
  'lelehan',
  'lelemah',
  'lelemak',
  'lelembu',
  'leler',
  'leletup',
  'lelimau',
  'lelipan',
  'leloceng',
  'lelompat',
  'lelong',
  'lelongnya',
  'lelongan',
  'lelongkan',
  'leluasa',
  'lelucon',
  'leluhur',
  'lelumba',
  'lelumbah',
  'lemah',
  'lemahkan',
  'kelemahan',
  'lemak',
  'lemang',
  'lemas',
  'melemaskan',
  'kelemasan',
  'lemau',
  'lemba',
  'lembab',
  'lembaga',
  'perlembagaan',
  'perlembagaannya',
  'lembah',
  'lembahan',
  'lembai',
  'lembak',
  'lembam',
  'kelembaman',
  'lembang',
  'melembang',
  'lembangan',
  'lembap',
  'lembapkan',
  'melembapkannya',
  'kelembapan',
  'lembar',
  'melembarkan',
  'lembaran',
  'lembayung',
  'lembega',
  'lembek',
  'lembidang',
  'lembiding',
  'lembik',
  'melembikkan',
  'kelembikan',
  'lembing',
  'lembok',
  'lembora',
  'lembu',
  'lembuara',
  'lembung',
  'melembungkan',
  'pelembungan',
  'lembur',
  'lemburu',
  'lembusir',
  'lembut',
  'lembutnya',
  'lembutkan',
  'kelembutan',
  'melembutkanku',
  'pelembutan',
  'lemek',
  'lemena',
  'lemidi',
  'lemon',
  'lemoned',
  'lempai',
  'berlempaian',
  'lempami',
  'lempang',
  'melempang',
  'lempanguselesslempung',
  'lempap',
  'lempar',
  'melempar',
  'lemparkan',
  'lemparan',
  'lempari',
  'lempaung',
  'lempedu',
  'lempel',
  'lempenai',
  'lempeng',
  'lemper',
  'lempiri',
  'lempoyan',
  'lempoyangan',
  'lempuk',
  'lempumuselesslempang',
  'lempunai',
  'lempung',
  'lempur',
  'lemputih',
  'lemuas',
  'lemuduk',
  'lemuk',
  'lemunggai',
  'lemuru',
  'lena',
  'melenakan',
  'kelenaan',
  'lenang',
  'lencana',
  'lencang',
  'lencit',
  'lencong',
  'dilencongkan',
  'lencongan',
  'lencun',
  'lendap',
  'lendat',
  'lendeh',
  'lendip',
  'lendir',
  'lendit',
  'lenduh',
  'lendung',
  'lendut',
  'leng',
  'lengah',
  'lengahkan',
  'kelengahan',
  'lengai',
  'lengan',
  'lengang',
  'melengangkan',
  'lengas',
  'melengas',
  'lengat',
  'lengeng',
  'lenggadai',
  'lenggai',
  'lenggak',
  'lenggakuselesslenggok',
  'lenggana',
  'lenggang',
  'melenggang',
  'lenggangan',
  'lenggapus',
  'lenggek',
  'lenggok',
  'lenggoknya',
  'lenggokkan',
  'lenggokan',
  'lenggundi',
  'lengit',
  'lengkap',
  'lengkapnya',
  'lengkapkan',
  'lengkapi',
  'kelengkapan',
  'perlengkapan',
  'lengkanas',
  'lengkang',
  'lengkara',
  'lengkeng',
  'lengket',
  'lengkian',
  'lengking',
  'lengkingan',
  'lengkitang',
  'lengkok',
  'lengkong',
  'lengkua',
  'lengkuas',
  'lengkung',
  'melengkung',
  'dilengkungi',
  'lengkungan',
  'lengos',
  'lengsan',
  'lengseng',
  'lengser',
  'lengset',
  'lenguai',
  'lenguh',
  'melenguh',
  'kelenguhan',
  'lengung',
  'lening',
  'lenja',
  'lenjuang',
  'lensa',
  'lensing',
  'lentam',
  'lentamuselesslentum',
  'lentang',
  'lente',
  'lenteng',
  'lentera',
  'lentik',
  'melentik',
  'lenting',
  'lentok',
  'lentokkan',
  'lentuk',
  'lentul',
  'lentum',
  'lentumuselesslentam',
  'lentung',
  'lentur',
  'dilentur',
  'melentur',
  'lenturan',
  'lenyai',
  'lenyak',
  'lenyap',
  'melenyapkan',
  'kelenyapan',
  'lenyeh',
  'lenyek',
  'lenyet',
  'lepa',
  'lepak',
  'lepan',
  'lepang',
  'lepap',
  'lepar',
  'lepas',
  'lepaslah',
  'lepaskan',
  'melepas',
  'selepas',
  'dilepasi',
  'lepasan',
  'lepat',
  'lepau',
  'lepek',
  'leper',
  'meleperkan',
  'lepet',
  'lepih',
  'lepik',
  'lepoh',
  'lepot',
  'lepuh',
  'lepuk',
  'lepung',
  'lepur',
  'lepus',
  'lerah',
  'lerai',
  'leraikan',
  'lerak',
  'leram',
  'lerang',
  'lerap',
  'lereng',
  'lerengan',
  'leret',
  'leretan',
  'lerik',
  'lerkuing',
  'lerum',
  'lesa',
  'lesak',
  'lesap',
  'lesapkan',
  'lesar',
  'lesau',
  'lesbian',
  'lesen',
  'lesenkan',
  'lesennya',
  'pelesen',
  'leser',
  'berpeleseran',
  'leset',
  'lesi',
  'lesih',
  'lesing',
  'lesir',
  'lesit',
  'lestaka',
  'lesu',
  'kelesuan',
  'lesukuselesslesak',
  'lesung',
  'lesus',
  'lesut',
  'leta',
  'letahuselessletai',
  'letak',
  'letakkan',
  'letaknya',
  'peletakan',
  'letang',
  'letap',
  'letas',
  'leter',
  'leternya',
  'meleter',
  'leteran',
  'letih',
  'meletihkan',
  'keletihan',
  'letik',
  'leting',
  'letuk',
  'letukuselessletak',
  'letung',
  'letunguselessletang',
  'letup',
  'letupkan',
  'letupan',
  'letus',
  'meletus',
  'letusan',
  'leukemia',
  'leukosit',
  'lewa',
  'lewah',
  'lewat',
  'lewatnya',
  'lewati',
  'lewatkan',
  'kelewatan',
  'levi',
  'liabiliti',
  'liang',
  'lianguselessliuk',
  'liar',
  'keliaran',
  'liat',
  'keliatan',
  'liau',
  'libas',
  'libat',
  'libatkan',
  'penglibatan',
  'liberal',
  'liberalisasi',
  'peliberalan',
  'libur',
  'libut',
  'licau',
  'lici',
  'licik',
  'kelicikan',
  'licin',
  'melicinkan',
  'kelicinan',
  'licinuselesslicau',
  'licung',
  'lidah',
  'lidahnya',
  'lidal',
  'lidas',
  'lidi',
  'lif',
  'liga',
  'ligamen',
  'ligas',
  'ligat',
  'ligih',
  'lignit',
  'lihat',
  'lihatlah',
  'dilihat',
  'melihat',
  'perlihatkan',
  'penglihat',
  'kelihatan',
  'likas',
  'likat',
  'kelikatan',
  'likir',
  'liku',
  'likuh',
  'likur',
  'lilan',
  'lilau',
  'lilin',
  'lilis',
  'lilit',
  'melilit',
  'lilitkan',
  'lilitan',
  'lilitannya',
  'lillah',
  'lillahi',
  'lima',
  'limanya',
  'limas',
  'limau',
  'limbah',
  'limbahan',
  'limbai',
  'limban',
  'limbang',
  'limbat',
  'limbing',
  'limbuk',
  'limbung',
  'limbur',
  'limpa',
  'limpah',
  'melimpah',
  'dilimpahi',
  'limpahkan',
  'limpahan',
  'limpap',
  'limut',
  'linang',
  'linangan',
  'linau',
  'lincah',
  'kelincahan',
  'lincir',
  'melincirkan',
  'lindang',
  'lindap',
  'lindas',
  'lindung',
  'lindungan',
  'lindungannya',
  'lindungkan',
  'lindungi',
  'linear',
  'linen',
  'ling',
  'lingar',
  'lingga',
  'linggata',
  'linggi',
  'linggi',
  'linggisan',
  'lingkap',
  'lingkar',
  'melingkar',
  'dilingkarkan',
  'dilingkari',
  'lingkaran',
  'lingkung',
  'melingkung',
  'dilingkungi',
  'lingkungan',
  'lingkup',
  'dilingkupi',
  'melingkupi',
  'linglung',
  'lingua',
  'linguistik',
  'linjak',
  'link',
  'lino',
  'linoleum',
  'linotaip',
  'lintabung',
  'lintadu',
  'lintah',
  'lintang',
  'melintang',
  'terlintang',
  'lintanguselesskedak',
  'lintanguselesspukang',
  'lintap',
  'lintar',
  'lintas',
  'melintas',
  'lintasan',
  'lintuh',
  'lintup',
  'linu',
  'linyang',
  'lipan',
  'lipas',
  'lipat',
  'lipatnya',
  'lipatkan',
  'lipatan',
  'melipatgandakan',
  'lipis',
  'lipit',
  'liplap',
  'lipu',
  'lipur',
  'melipur',
  'penglipur',
  'liput',
  'diliputi',
  'meliputi',
  'menyeliputi',
  'liputan',
  'lirik',
  'lirikan',
  'liriknya',
  'liris',
  'lisah',
  'lisan',
  'lisuselesslis',
  'lisol',
  'lisu',
  'lisut',
  'litah',
  'litak',
  'litar',
  'liter',
  'literasi',
  'litigan',
  'litium',
  'litmus',
  'lito',
  'litograf',
  'litografi',
  'litoral',
  'litosfera',
  'litup',
  'melitupi',
  'dilitupi',
  'litupan',
  'liuk',
  'meliuk',
  'liunguselessliung',
  'liur',
  'liurnya',
  'liut',
  'liwat',
  'loba',
  'lobak',
  'loban',
  'lobi',
  'locak',
  'loceng',
  'locengnya',
  'locok',
  'lodak',
  'lode',
  'lodoh',
  'lodong',
  'log',
  'logam',
  'logaritma',
  'loghat',
  'loghatnya',
  'logik',
  'logistik',
  'logo',
  'berlogo',
  'logu',
  'loh',
  'loha',
  'lohmahfuz',
  'lohong',
  'loji',
  'lok',
  'loka',
  'lokalisasi',
  'melokalisasikan',
  'lokaliti',
  'lokan',
  'lokap',
  'lokar',
  'lokasi',
  'lokek',
  'loket',
  'lokman',
  'lokomotif',
  'lokos',
  'lokus',
  'lolak',
  'loleh',
  'lolen',
  'loloh',
  'lolok',
  'lolong',
  'loma',
  'lombar',
  'lombok',
  'lombong',
  'perlombongan',
  'lompang',
  'lompat',
  'lompatan',
  'lompatannya',
  'melompat',
  'lompi',
  'lompong',
  'lonan',
  'loncat',
  'berloncat',
  'meloncat',
  'loncatan',
  'loncos',
  'londang',
  'londar',
  'londeh',
  'londehkan',
  'long',
  'longgak',
  'longgar',
  'longgarnya',
  'longgarkan',
  'melonggari',
  'dilonggari',
  'kelonggaran',
  'pelonggaran',
  'longgok',
  'longgokkan',
  'longgokan',
  'longitud',
  'longkah',
  'longkai',
  'longkang',
  'longlai',
  'longlang',
  'lonjak',
  'melonjak',
  'lonjakan',
  'lonjong',
  'lontai',
  'lontar',
  'melontar',
  'lontarkan',
  'lontaran',
  'lontok',
  'lontong',
  'lontos',
  'lop',
  'lopak',
  'lopakuselesslapik',
  'lopek',
  'lopes',
  'lopong',
  'melopong',
  'lorah',
  'lorat',
  'lorek',
  'loreng',
  'lori',
  'lorong',
  'melorongkan',
  'dilorongkan',
  'lorongan',
  'lorot',
  'melorot',
  'losen',
  'loseng',
  'losong',
  'lot',
  'lota',
  'lotah',
  'lotek',
  'loteng',
  'loteri',
  'loto',
  'lotong',
  'lowong',
  'loya',
  'meloya',
  'loyang',
  'loyong',
  'lu',
  'luah',
  'terluah',
  'luahan',
  'luahkanlah',
  'luak',
  'luang',
  'luangkan',
  'luap',
  'meluap',
  'luar',
  'luaran',
  'keluarbiasaan',
  'luas',
  'luasnya',
  'luaskan',
  'keluasan',
  'peluasan',
  'lubang',
  'melubang',
  'dilubangkan',
  'dilubangi',
  'lubuk',
  'lucah',
  'kelucahan',
  'lucu',
  'melucukan',
  'kelucuan',
  'lucup',
  'lucut',
  'melucut',
  'lucutkan',
  'dilucuti',
  'perlucutan',
  'ludah',
  'ludahnya',
  'meludah',
  'diludah',
  'ludai',
  'ludang',
  'ludat',
  'luding',
  'ludruk',
  'lugas',
  'lugu',
  'luhur',
  'meluhurkan',
  'keluhuran',
  'luik',
  'luing',
  'luka',
  'dilukai',
  'melukai',
  'lukakan',
  'berluka',
  'terluka',
  'kelukaan',
  'lukah',
  'lukat',
  'lukih',
  'lukis',
  'lukisnya',
  'lukiskan',
  'lukisan',
  'luku',
  'lukut',
  'lulai',
  'lulang',
  'luli',
  'luluh',
  'meluluhkan',
  'luluhan',
  'luluhawa',
  'luluk',
  'lulur',
  'lulus',
  'selulus',
  'melulus',
  'luluskan',
  'lulusan',
  'berkelulusan',
  'lulut',
  'lumai',
  'lumang',
  'lumas',
  'lumat',
  'melumatkan',
  'lumayan',
  'lumba',
  'lumbaan',
  'lumbah',
  'lumban',
  'lumbu',
  'lumbung',
  'lumbur',
  'lumer',
  'lumiuselesslumi',
  'lumping',
  'lumpuh',
  'melumpuhkan',
  'kelumpuhan',
  'lumpuk',
  'lumpur',
  'lumpurnya',
  'melumpuri',
  'perlumpuran',
  'lumrah',
  'lumur',
  'lumurkan',
  'melumuri',
  'pelumuran',
  'lumus',
  'lumut',
  'lunak',
  'melunak',
  'memperlunak',
  'kelunakan',
  'lunas',
  'melunasi',
  'lunaskan',
  'luncai',
  'luncas',
  'luncung',
  'luncur',
  'meluncur',
  'peluncuran',
  'lundang',
  'lundi',
  'lundu',
  'lungguh',
  'lungkup',
  'lunglung',
  'lungsin',
  'lungsur',
  'lungsuran',
  'lunjur',
  'melunjurkan',
  'luntang',
  'luntanguselesslanting',
  'luntanguselesslantung',
  'luntur',
  'meluntur',
  'dilunturkan',
  'kelunturan',
  'pelunturan',
  'lunyah',
  'lunyai',
  'lup',
  'lupa',
  'lupai',
  'lupakan',
  'lupaan',
  'lupang',
  'lupat',
  'lupi',
  'lupuh',
  'lupuk',
  'lupung',
  'lupus',
  'lupuskan',
  'pelupusan',
  'luput',
  'luputnya',
  'luputkan',
  'peluputan',
  'lurah',
  'lurik',
  'luru',
  'luruh',
  'meluruh',
  'berluruhan',
  'lurus',
  'lurusnya',
  'luruskan',
  'selurusan',
  'berselurus',
  'kelurusan',
  'lurut',
  'lurutan',
  'lusa',
  'lusuh',
  'melusuhkan',
  'kelusuhan',
  'lut',
  'lutcahaya',
  'luti',
  'lutu',
  'lutut',
  'lututnya',
  'luyak',
  'luyu',
  'luyut',
  'maab',
  'maaf',
  'pemaaf',
  'bermaaf',
  'maafkan',
  'permaafan',
  'maafkanku',
  'kemaafan',
  'maakul',
  'maakulat',
  'maal',
  'maalaf',
  'mabir',
  'mabrur',
  'mabuk',
  'memabukkan',
  'mabuklah',
  'mabung',
  'mabur',
  'mac',
  'macam',
  'macamlah',
  'macan',
  'macang',
  'macet',
  'machiavellianisme',
  'machiavelly',
  'macis',
  'macuk',
  'mad',
  'mada',
  'mada',
  'madah',
  'madahkan',
  'madali',
  'madam',
  'madang',
  'madani',
  'madat',
  'pemadat',
  'maddi',
  'madgad',
  'madrasah',
  'madu',
  'madunya',
  'dimadu',
  'memadu',
  'dipermadukan',
  'mempermadukan',
  'permaduan',
  'madukara',
  'madya',
  'maesan',
  'mafela',
  'mafhum',
  'mafia',
  'mafsadah',
  'magal',
  'magang',
  'magawasih',
  'magazin',
  'magel',
  'magenta',
  'magersari',
  'maghrib',
  'maghribi',
  'maghribi',
  'maghrur',
  'magi',
  'maging',
  'magis',
  'maglub',
  'magma',
  'magneluim',
  'magnesium',
  'magnet',
  'magnetnya',
  'memagnetkan',
  'magnetan',
  'magnetik',
  'magnetisme',
  'magnetit',
  'magneto',
  'magnitud',
  'magnitudnya',
  'magrifat',
  'maha',
  'mahaguru',
  'mahahakim',
  'mahal',
  'mahalnya',
  'memahalkan',
  'mahar',
  'maharaja',
  'maharajalela',
  'maharani',
  'mahasiswa',
  'mahasiswi',
  'mahir',
  'memahirkan',
  'kemahiran',
  'kemahirannya',
  'mahkamah',
  'mahkota',
  'mahkotanya',
  'dimahkotakan',
  'memahkotakan',
  'kemahkotaan',
  'mahligai',
  'mahram',
  'mahsyar',
  'mahu',
  'semahu',
  'dimahukan',
  'memahu',
  'kemahuan',
  'berkemahuan',
  'main',
  'pemainnya',
  'mainkan',
  'dipermainnya',
  'mainan',
  'majalah',
  'majelis',
  'majikan',
  'majistret',
  'majlis',
  'majmuk',
  'majoriti',
  'maju',
  'majunya',
  'majukan',
  'memajukannya',
  'kemajuan',
  'pemajuan',
  'majusi',
  'mak',
  'maka',
  'makam',
  'makamnya',
  'dimakamkan',
  'memakamkan',
  'permakaman',
  'makan',
  'makannya',
  'makanlah',
  'dimakan',
  'memakan',
  'makanan',
  'makaroni',
  'makbul',
  'termakbul',
  'makbulkan',
  'makcik',
  'makenah',
  'makhluk',
  'maki',
  'makian',
  'makin',
  'maklum',
  'maklumkan',
  'maklumkannya',
  'maklumi',
  'makluman',
  'maklumat',
  'maklumatnya',
  'memaklumatkan',
  'makmal',
  'makmum',
  'makmur',
  'makmurkan',
  'kemakmuran',
  'makna',
  'memaknakan',
  'makrifat',
  'makro',
  'makruf',
  'makruh',
  'maksiat',
  'maksila',
  'maksimum',
  'maksimumnya',
  'maksimumkan',
  'maksud',
  'maksudnya',
  'maksudkan',
  'maksum',
  'maktab',
  'maktub',
  'makyung',
  'makzul',
  'mal',
  'mala',
  'malah',
  'malai',
  'malaikat',
  'malaikatulmaut',
  'malam',
  'bermalam',
  'memalamkan',
  'malaman',
  'malang',
  'kemalangan',
  'memalangi',
  'malap',
  'memalapkan',
  'malapetaka',
  'malar',
  'malarkan',
  'kemalaran',
  'malaria',
  'malas',
  'kemalasan',
  'memalaskan',
  'malaysia',
  'malik',
  'malim',
  'malinja',
  'maltosa',
  'malu',
  'malunya',
  'kemaluan',
  'malukan',
  'mamah',
  'mamahan',
  'mamai',
  'mamak',
  'mamak',
  'mamalia',
  'mamanda',
  'mambang',
  'mambu',
  'mampan',
  'mampat',
  'mampatkan',
  'mampatan',
  'mampu',
  'mampunya',
  'mampukah',
  'kemampuan',
  'kemampuannya',
  'mampung',
  'mampus',
  'mana',
  'manatah',
  'manakala',
  'mancis',
  'mancung',
  'kemancungan',
  'manda',
  'mandarin',
  'mandat',
  'mandatori',
  'mandi',
  'mandilah',
  'mandikan',
  'mandikannya',
  'mandian',
  'mandibel',
  'mandolin',
  'mandul',
  'kemandulan',
  'mandur',
  'manfaat',
  'manfaatkan',
  'manfaatnya',
  'mangan',
  'mangau',
  'mangga',
  'manggar',
  'manggis',
  'mangkat',
  'kemangkatan',
  'mangkin',
  'memangkinkan',
  'mangku',
  'mangkubumi',
  'mangkuk',
  'mangkuknya',
  'mangsa',
  'mangsalah',
  'memangsakan',
  'mangu',
  'mani',
  'permanian',
  'manifestasi',
  'manifesto',
  'manik',
  'maniknya',
  'manikam',
  'manipulasi',
  'manis',
  'maniskan',
  'manisan',
  'manisnya',
  'manja',
  'memanja',
  'manjakan',
  'manjaan',
  'manjanya',
  'manjur',
  'manometer',
  'mansuh',
  'mansuhkan',
  'pemansuhan',
  'mantan',
  'mantap',
  'mantapnya',
  'mantapkan',
  'kemantapan',
  'pemantapan',
  'mantera',
  'memanterakan',
  'manual',
  'manusia',
  'kemanusiaan',
  'perikemanusiaan',
  'perikemanusiaannya',
  'manuskrip',
  'mapan',
  'kemapanan',
  'mara',
  'kemaraan',
  'marabahaya',
  'marah',
  'kemarah',
  'memarah',
  'dimarah',
  'pemarah',
  'marak',
  'memarak',
  'dimarakkan',
  'maraton',
  'mardud',
  'marga',
  'margasatwa',
  'margosa',
  'marhalah',
  'marhum',
  'mari',
  'marijuana',
  'marikh',
  'marin',
  'marina',
  'maritim',
  'marjerin',
  'markah',
  'pemarkahan',
  'markas',
  'markasnya',
  'markat',
  'markisah',
  'marmar',
  'marmut',
  'martabak',
  'martabat',
  'martabatkan',
  'pemartaban',
  'martil',
  'maruah',
  'bermaruah',
  'marut',
  'mas',
  'masa',
  'semasa',
  'masak',
  'masakkan',
  'memasakkannya',
  'masakan',
  'masakannya',
  'masaknya',
  'masalah',
  'masalahnya',
  'permasalahan',
  'masalode',
  'masam',
  'mamasam',
  'masamnya',
  'mashaf',
  'masih',
  'masihi',
  'masin',
  'kemasinan',
  'masing',
  'masjid',
  'maskara',
  'maskot',
  'maskulin',
  'maslahat',
  'kemaslahatan',
  'masnawi',
  'massa',
  'mastautin',
  'mastitis',
  'masuk',
  'termasuk',
  'masukkan',
  'dimasuki',
  'memasuki',
  'masukkan',
  'kemasukan',
  'pemasukan',
  'masya',
  'masyarakat',
  'masyarakatnya',
  'dimasyarakatkan',
  'memasyarakatkan',
  'kemasyarakatan',
  'masyghul',
  'masyhur',
  'masyhurkan',
  'kemasyhuran',
  'pemasyhuran',
  'mat',
  'mata',
  'semata',
  'matahari',
  'matang',
  'pramatang',
  'mematangkan',
  'dimatangkan',
  'kematangan',
  'matematik',
  'materi',
  'mematerikan',
  'material',
  'materialstik',
  'mati',
  'matikan',
  'kematian',
  'bermati',
  'pemati',
  'matlamat',
  'bermatlamat',
  'matriks',
  'matrikulasi',
  'matron',
  'mauduk',
  'maujud',
  'maulid',
  'maulud',
  'maun',
  'maung',
  'maut',
  'pemautan',
  'mawar',
  'mawas',
  'maya',
  'mayam',
  'mayang',
  'mayat',
  'mayor',
  'mazhab',
  'mazhabnya',
  'mazi',
  'mazmumah',
  'medal',
  'medan',
  'medang',
  'media',
  'median',
  'meditasi',
  'medium',
  'medley',
  'medu',
  'mega',
  'megabait',
  'megabit',
  'megah',
  'megahkan',
  'kemegahan',
  'megat',
  'megun',
  'mei',
  'meja',
  'semeja',
  'mejar',
  'mekanik',
  'mekanikal',
  'mekanisme',
  'mekap',
  'mekapnya',
  'mekar',
  'memekar',
  'mekonium',
  'mel',
  'melaka',
  'melaka',
  'melarat',
  'kemelaratan',
  'melati',
  'melayu',
  'kemelayuan',
  'memelayukan',
  'melela',
  'meleset',
  'kemelesetan',
  'melodi',
  'melodrama',
  'melukut',
  'melulu',
  'melur',
  'memang',
  'sememangnya',
  'memar',
  'membran',
  'memek',
  'memorandum',
  'memori',
  'mempan',
  'mempedal',
  'mempelai',
  'mempelam',
  'mempelas',
  'mempoyan',
  'mena',
  'menang',
  'kemenangan',
  'menangi',
  'menangilah',
  'menangkan',
  'menantu',
  'bermenantu',
  'menara',
  'mencok',
  'mencong',
  'mendak',
  'pemendakan',
  'mendalu',
  'mendam',
  'mendap',
  'mendapan',
  'mendiang',
  'mending',
  'mendung',
  'mengah',
  'mengkal',
  'kemengkalan',
  'mengkarung',
  'mengkelan',
  'mengkis',
  'mengkuang',
  'mengkudu',
  'menora',
  'mentadak',
  'mentah',
  'mental',
  'mentaliti',
  'mentang',
  'sementang',
  'mentari',
  'mentega',
  'mentereng',
  'menteri',
  'kementerian',
  'mentibang',
  'mentimum',
  'mentol',
  'mentua',
  'menu',
  'menung',
  'menungkan',
  'menungan',
  'menungannya',
  'merah',
  'merahnya',
  'merahan',
  'memerah',
  'dimerahi',
  'merak',
  'merana',
  'meranti',
  'merawal',
  'merbah',
  'merbau',
  'merbuk',
  'mercik',
  'mercu',
  'mercun',
  'merdeka',
  'merdekakan',
  'kemerdekaan',
  'merdu',
  'kemerduan',
  'mereka',
  'merekah',
  'mergastua',
  'meriah',
  'meriahkan',
  'memeriahkannya',
  'kemeriahan',
  'meriam',
  'meridian',
  'merih',
  'meritokrasi',
  'merkuri',
  'merlimau',
  'meroyan',
  'merpati',
  'mersik',
  'merta',
  'mertua',
  'mesej',
  'mesenteri',
  'mesin',
  'memesin',
  'pemesinan',
  'mesingan',
  'mesiu',
  'meski',
  'meskipun',
  'mesokarp',
  'mesra',
  'mesranya',
  'kemesraan',
  'memesrai',
  'memesrakan',
  'mesti',
  'semestinya',
  'kemestian',
  'mestikan',
  'termesti',
  'mestika',
  'mesyuarat',
  'bermesyuarat',
  'dimesyuaratkan',
  'memesyuaratkan',
  'permesyuaratan',
  'metabolisme',
  'metafizik',
  'metafora',
  'metalik',
  'metamorfosis',
  'metanol',
  'meteor',
  'meteorologi',
  'meter',
  'meternya',
  'meterai',
  'memeterai',
  'pemeteraian',
  'metrik',
  'metro',
  'metropolis',
  'metropolitan',
  'mewah',
  'mewahnya',
  'kemewahan',
  'mi',
  'miang',
  'migrain',
  'migrasi',
  'mihun',
  'mika',
  'mikail',
  'mikraj',
  'mikro',
  'mikrob',
  'mikrobiologi',
  'mikrofon',
  'mikrometer',
  'mikron',
  'mikroskop',
  'mikroskopik',
  'mil',
  'milenium',
  'mili',
  'milik',
  'miliki',
  'memiliki',
  'pemilik',
  'termilik',
  'milimeter',
  'miligram',
  'militan',
  'militer',
  'milyar',
  'mimbar',
  'mimik',
  'mimpi',
  'mimpikan',
  'bermimpi',
  'termimpi',
  'min',
  'minat',
  'berminat',
  'peminat',
  'diminati',
  'meminati',
  'minda',
  'mineral',
  'minggu',
  'berminggu',
  'seminggu',
  'mini',
  'minimum',
  'minimumnya',
  'minimumkan',
  'minit',
  'minitnya',
  'minoriti',
  'minta',
  'permintaan',
  'meminta',
  'mintanya',
  'mintalah',
  'minum',
  'terminum',
  'meminum',
  'minuman',
  'minyak',
  'minyaknya',
  'meminyaki',
  'perminyakan',
  'miriapoda',
  'miring',
  'memiringkan',
  'dimiringkan',
  'mirip',
  'misai',
  'bermisai',
  'misal',
  'misalkan',
  'semisal',
  'miselium',
  'misi',
  'misil',
  'miskal',
  'miskin',
  'termiskin',
  'kemiskinan',
  'ketermiskinan',
  'mistar',
  'misteri',
  'mistik',
  'mitos',
  'mobil',
  'mobiliti',
  'mod',
  'modal',
  'modalnya',
  'modaliti',
  'bermodalkan',
  'permodalan',
  'model',
  'bermodelkan',
  'pemodelan',
  'modem',
  'moden',
  'modennya',
  'modenkan',
  'kemodenan',
  'pemodenan',
  'moderasi',
  'modul',
  'modulnya',
  'modus',
  'moga',
  'mogalah',
  'mogok',
  'pemogok',
  'mohon',
  'mohonnya',
  'mohonlah',
  'permohonan',
  'memohon',
  'mohor',
  'molek',
  'molekul',
  'molekular',
  'moluska',
  'momok',
  'monitor',
  'monarki',
  'monatom',
  'mondok',
  'monetari',
  'monobes',
  'monogami',
  'monograf',
  'monokotiledon',
  'monolog',
  'monomer',
  'monopoli',
  'monorel',
  'monotaip',
  'monsun',
  'montel',
  'montok',
  'monumen',
  'monyet',
  'mood',
  'morain',
  'moral',
  'moralnya',
  'moralis',
  'moraliti',
  'morat',
  'moratuselessmarit',
  'moreh',
  'morfem',
  'morfin',
  'morfologi',
  'mortar',
  'morula',
  'motel',
  'motif',
  'bermotif',
  'motivasi',
  'motivasikan',
  'motivator',
  'moto',
  'motobot',
  'motokar',
  'motor',
  'motornya',
  'permotoran',
  'pemotoran',
  'motosikal',
  'motosikalnya',
  'moyang',
  'moyok',
  'moyot',
  'mu',
  'mua',
  'muakan',
  'muafakat',
  'muafakatkan',
  'kemuafakatan',
  'pemuafakatan',
  'permuafakatan',
  'muafakatlah',
  'muai',
  'muak',
  'memuakkan',
  'mual',
  'memualkan',
  'kemualan',
  'mualaf',
  'mualim',
  'muara',
  'muassasah',
  'muat',
  'dimuat',
  'memuat',
  'muatkan',
  'muatan',
  'muazin',
  'mubah',
  'mubaligh',
  'mubarak',
  'mubarat',
  'mubazir',
  'muda',
  'mudanya',
  'mudah',
  'mudahnya',
  'mudahkan',
  'dimudahkan',
  'kemudahan',
  'mudahan',
  'mudahlah',
  'mudarat',
  'mudaratkan',
  'memudaratkannya',
  'kemudaratan',
  'mudi',
  'mudik',
  'mudiknya',
  'memudiki',
  'mudigah',
  'mudin',
  'muflis',
  'kemuflisan',
  'mufti',
  'mughallazah',
  'muhajirin',
  'muhalil',
  'muharam',
  'muhasabah',
  'muhasabahnya',
  'muhibah',
  'muhrim',
  'mujahidin',
  'mujarab',
  'kemujaraban',
  'mujur',
  'kemujuran',
  'muka',
  'bermuka',
  'kemukakan',
  'kemukakannya',
  'semuka',
  'pemuka',
  'permukaan',
  'terkemuka',
  'pengemukaan',
  'mukadimah',
  'mukah',
  'mukalaf',
  'mukhaffafah',
  'mukim',
  'mukimnya',
  'mukjizat',
  'mukmin',
  'mukosa',
  'muktamad',
  'dimuktamadkan',
  'memuktamad',
  'mula',
  'mulakan',
  'mulai',
  'memulainya',
  'mulanya',
  'mulalah',
  'permulaan',
  'sebermula',
  'mulas',
  'mulhid',
  'mulia',
  'kemuliaan',
  'muliakan',
  'mulianya',
  'multilateral',
  'multimedia',
  'multinasional',
  'muluk',
  'mulur',
  'mulus',
  'mulut',
  'bermulut',
  'mumbang',
  'mumbung',
  'munafik',
  'kemunafikan',
  'munajat',
  'munajatnya',
  'munasabah',
  'kemunasabahan',
  'muncul',
  'bermunculan',
  'memunculkan',
  'kemunculan',
  'muncung',
  'bermuncung',
  'mundar',
  'mandir',
  'mundur',
  'kemunduran',
  'mungkar',
  'memungkari',
  'kemungkaran',
  'mungkin',
  'memungkinkan',
  'kemungkinan',
  'kemungkinannya',
  'mungkir',
  'memungkiri',
  'memungkirkan',
  'mungkum',
  'mungmung',
  'munkar',
  'munsyi',
  'muntah',
  'muntahkan',
  'muqaddam',
  'murah',
  'murahkan',
  'murahan',
  'murahnya',
  'murai',
  'muram',
  'memuramkan',
  'kemuraman',
  'murba',
  'murid',
  'bermuridkan',
  'murka',
  'memurkai',
  'kemurkaan',
  'murni',
  'memurnikan',
  'kemurnian',
  'mursyidul',
  'murtad',
  'murung',
  'kemurungan',
  'musabaqah',
  'musafir',
  'musafirnya',
  'musang',
  'musibat',
  'musim',
  'musimnya',
  'muslihat',
  'muslim',
  'muslimat',
  'muslimin',
  'muslin',
  'musnah',
  'musnahlah',
  'musnahkan',
  'kemusnahan',
  'pemusnahan',
  'mustahak',
  'memustahakkan',
  'mustahil',
  'memustahilkan',
  'kemustahilan',
  'mustaid',
  'mustajab',
  'kemustajaban',
  'mustakmal',
  'musuh',
  'bermusuh',
  'memusuhi',
  'permusuhan',
  'musykil',
  'musykilkan',
  'kemusykilan',
  'musyrik',
  'kemusyrikan',
  'musyrikin',
  'musytari',
  'mutakhir',
  'mutalaah',
  'memutalaah',
  'mutawassitah',
  'mutiara',
  'mutlak',
  'mutu',
  'mutunya',
  'muzakarah',
  'muzik',
  'muzikal',
  'muzium',
  'nabi',
  'kenabian',
  'nada',
  'nadanya',
  'nadi',
  'nadinya',
  'nadim',
  'nadir',
  'nafar',
  'nafas',
  'nafasnya',
  'pernafasan',
  'nafi',
  'nafikan',
  'penafian',
  'nafiri',
  'nafkah',
  'nafkahnya',
  'nafsi',
  'nafsu',
  'nafsunya',
  'naga',
  'nagara',
  'nagasari',
  'nahak',
  'nah',
  'nahas',
  'kenahasan',
  'nahi',
  'nahu',
  'naib',
  'naif',
  'kenaifan',
  'naik',
  'menaik',
  'dinaik',
  'kenaikan',
  'penaik',
  'dinaiktaraf',
  'menaiktaraf',
  'penaiktarafan',
  'najis',
  'kenajisan',
  'nak',
  'nakal',
  'kenakalan',
  'menakal',
  'nakara',
  'nakhoda',
  'nakhodanya',
  'nal',
  'nalar',
  'nalih',
  'naluri',
  'nama',
  'bernama',
  'penama',
  'ternama',
  'kenamaan',
  'namakan',
  'menamai',
  'nambi',
  'namuselessnam',
  'nampak',
  'nampakkan',
  'nampaknya',
  'nampaklah',
  'kenampakan',
  'nampung',
  'namun',
  'nan',
  'nana',
  'nanah',
  'nanahnya',
  'nanar',
  'nanas',
  'nandung',
  'nangka',
  'nangoi',
  'naning',
  'nanometer',
  'nantaboga',
  'nanti',
  'menanti',
  'dinanti',
  'ternanti',
  'penanti',
  'napal',
  'napan',
  'napkin',
  'napuh',
  'naqli',
  'nara',
  'narapati',
  'narkotik',
  'narung',
  'narwastu',
  'nas',
  'dinaskan',
  'nasab',
  'nasi',
  'bernasi',
  'nasib',
  'senasib',
  'bernasib',
  'nasihat',
  'nasihatnya',
  'nasihati',
  'dinasihati',
  'nasihatkan',
  'nasihatku',
  'nasional',
  'nasionalis',
  'nasionalisme',
  'nasionalisasi',
  'nasionalistik',
  'naskhah',
  'naskhahnya',
  'nasrani',
  'nasyid',
  'nasyidnya',
  'nat',
  'nata',
  'natal',
  'natang',
  'natijah',
  'natural',
  'naturalisasi',
  'nauliuselessnauli',
  'naung',
  'naungan',
  'menaungi',
  'dinaungi',
  'nautika',
  'nawa',
  'nayaka',
  'nayam',
  'nazak',
  'nazam',
  'nazar',
  'nazarnya',
  'nazir',
  'nebula',
  'negara',
  'senegara',
  'negarawan',
  'kenegaraan',
  'negatif',
  'negeri',
  'bernegeri',
  'kenegerian',
  'nekad',
  'nekadnya',
  'kenekatan',
  'nelayan',
  'nempanjur',
  'nenas',
  'nenda',
  'nenek',
  'senenek',
  'neneri',
  'nenes',
  'neng',
  'nenyuar',
  'neokonservatif',
  'neolitik',
  'neon',
  'nepotisme',
  'neraca',
  'neraka',
  'kenerakaan',
  'neram',
  'nerapi',
  'neri',
  'nerian',
  'nering',
  'nescaya',
  'nestapa',
  'kenestapaan',
  'net',
  'netral',
  'neuralgia',
  'neurologi',
  'neuron',
  'neurosains',
  'neutral',
  'neutralkan',
  'keneutralan',
  'neutron',
  'nganga',
  'menganga',
  'ngangakan',
  'ngangut',
  'ngarai',
  'ngaung',
  'ngayap',
  'ngelu',
  'ngening',
  'ngeram',
  'pengeraman',
  'ngeri',
  'mengerikan',
  'kengerian',
  'ngerunguselessngering',
  'ngian',
  'ngiang',
  'ngiau',
  'ngilu',
  'kengiluan',
  'ngomel',
  'ngompol',
  'ngongoi',
  'nguak',
  'ngungap',
  'ngutngit',
  'ngutngut',
  'ni',
  'niaga',
  'niaganya',
  'niagakan',
  'perniagaan',
  'nian',
  'niat',
  'niatnya',
  'niatkan',
  'niatlah',
  'nibung',
  'nidera',
  'nifas',
  'nihil',
  'nik',
  'nikah',
  'nikahkan',
  'menikah',
  'pernikahan',
  'nikel',
  'nikmat',
  'nikmati',
  'kenikmatan',
  'nikolit',
  'nikotin',
  'nila',
  'nilai',
  'ternilai',
  'nilaikan',
  'nilaian',
  'nilakandi',
  'nilam',
  'nilau',
  'nilon',
  'nilur',
  'ninabobok',
  'ningrat',
  'nipah',
  'nipis',
  'nipiskan',
  'nipisnya',
  'nira',
  'nirmala',
  'nirwana',
  'nisa',
  'nisab',
  'nisan',
  'nisannya',
  'nisbah',
  'nisbahnya',
  'nisbi',
  'niskala',
  'nista',
  'menista',
  'nistaan',
  'nitrat',
  'nitrik',
  'nitrit',
  'nitrogen',
  'nobat',
  'dinobatkan',
  'menobat',
  'penobatan',
  'nobel',
  'noda',
  'menodai',
  'dinodai',
  'nodai',
  'noja',
  'noktah',
  'noktahnya',
  'nol',
  'nomad',
  'nombor',
  'nombornya',
  'nomborkan',
  'dinomborkan',
  'penomboran',
  'nona',
  'noni',
  'nonok',
  'nonol',
  'nonong',
  'norma',
  'normal',
  'normalkan',
  'penormalan',
  'nostalgia',
  'nostalgianya',
  'not',
  'nota',
  'notis',
  'notisnya',
  'novel',
  'bernovel',
  'pernovelan',
  'novelis',
  'november',
  'novis',
  'nubuat',
  'nujum',
  'menujumkan',
  'nujuman',
  'nukil',
  'nuklear',
  'nukleon',
  'nukleus',
  'nun',
  'nur',
  'nuraga',
  'nurani',
  'nuri',
  'nurseri',
  'nus',
  'nusa',
  'nusantara',
  'nusus',
  'nutrien',
  'nutraseutikal',
  'nuzul',
  'nya',
  'nyah',
  'nyahkan',
  'mengenyahkan',
  'nyai',
  'nyala',
  'nyalakan',
  'nyalaan',
  'nyalang',
  'nyalar',
  'nyaman',
  'nyamannya',
  'menyamankan',
  'kenyamanan',
  'nyambing',
  'nyampang',
  'nyampelung',
  'nyamuk',
  'nyana',
  'nyanyah',
  'nyanyar',
  'nyanyi',
  'penyanyi',
  'menyanyi',
  'nyanyian',
  'nyanyikan',
  'nyanyu',
  'nyanyuk',
  'nyarang',
  'nyaring',
  'kenyaringan',
  'menyaringkan',
  'nyaris',
  'nyarisuselessnyaris',
  'nyarum',
  'nyasap',
  'nyata',
  'penyata',
  'nyatakan',
  'kenyataan',
  'pernyataan',
  'nyatuh',
  'nyawa',
  'nyawanya',
  'senyawa',
  'senyawakan',
  'persenyawaan',
  'nyenyai',
  'nyenyak',
  'nyenyarum',
  'nyenyeh',
  'nyenyulung',
  'nyinyir',
  'nyirih',
  'nyiru',
  'nyiur',
  'nyolo',
  'nyonya',
  'nyonyeh',
  'nyonyong',
  'nyuar',
  'nyunyut',
  'oak',
  'oasis',
  'oat',
  'obar',
  'obes',
  'obesiti',
  'objek',
  'objektif',
  'berobjektif',
  'keobjektifan',
  'obo',
  'obor',
  'mengobor',
  'obral',
  'obrol',
  'mengobrol',
  'ocakuselessocak',
  'odograf',
  'odometer',
  'ofset',
  'ogah',
  'ogak',
  'ogoh',
  'ogos',
  'oh',
  'ohm',
  'oidium',
  'ok',
  'oknum',
  'okok',
  'oksida',
  'mengoksidakan',
  'pengoksidaan',
  'oksigen',
  'mengoksigenkan',
  'pengoksigenan',
  'oktaf',
  'oktagon',
  'oktana',
  'oktober',
  'olah',
  'olahnya',
  'olahan',
  'olahraga',
  'keolahragaan',
  'olahragawan',
  'olahragawati',
  'olak',
  'olakan',
  'olakuselessalik',
  'olanguselessaling',
  'oleander',
  'oleh',
  'beroleh',
  'memperoleh',
  'diperoleh',
  'peroleh',
  'olek',
  'mengolekkan',
  'oleng',
  'olengan',
  'oleograf',
  'oles',
  'mengoles',
  'oligarki',
  'olimpiad',
  'olimpik',
  'olok',
  'olokkan',
  'olokan',
  'olokuselessolok',
  'olonguselessolong',
  'ombak',
  'ombaknya',
  'omboh',
  'omboruselessombor',
  'omega',
  'omel',
  'mengomel',
  'omong',
  'mengomong',
  'omongan',
  'ompanguselessompang',
  'onak',
  'onanguselessaning',
  'onar',
  'mengonarkan',
  'keonaran',
  'oncom',
  'ondak',
  'ondeuselessonde',
  'ondoh',
  'ondok',
  'onenguselessoneng',
  'ong',
  'onggak',
  'onggek',
  'onggok',
  'ongkos',
  'mengonkosi',
  'perongkosan',
  'ongoluselessongol',
  'onkologi',
  'ontologi',
  'onyahuselessanyih',
  'onyok',
  'opah',
  'opak',
  'opas',
  'oper',
  'opera',
  'operandi',
  'operasi',
  'dioperasi',
  'mengoperasi',
  'operator',
  'opor',
  'oportunis',
  'ops',
  'opsyen',
  'optik',
  'optimis',
  'optimistik',
  'optimisme',
  'optimum',
  'optimumkan',
  'orak',
  'arik',
  'oral',
  'oran',
  'orang',
  'berorang',
  'seorang',
  'berseorang',
  'keseorangan',
  'perseorangan',
  'oranguselessaring',
  'orbit',
  'orde',
  'ordinal',
  'ordinan',
  'oren',
  'oreng',
  'organ',
  'organik',
  'organisasi',
  'organisasinya',
  'mengorganisasi',
  'diorganisasikan',
  'organisma',
  'orgasma',
  'orgi',
  'orientalis',
  'orientasi',
  'diorientasikan',
  'mengorientasikan',
  'berorientasi',
  'origami',
  'origanum',
  'original',
  'orisinal',
  'orkes',
  'orkestra',
  'orkid',
  'ornamen',
  'ornamental',
  'ornitologi',
  'orok',
  'ortodoks',
  'oskar',
  'osteoartritis',
  'otak',
  'otaknya',
  'otek',
  'oto',
  'otot',
  'ototnya',
  'ovari',
  'overdraf',
  'overtur',
  'ovulasi',
  'ovum',
  'ozon',
  'pabean',
  'pabrik',
  'pacai',
  'pacak',
  'pacakkan',
  'pacal',
  'pacang',
  'pacar',
  'pacat',
  'pacau',
  'pacih',
  'pacis',
  'pacu',
  'dipacu',
  'memacu',
  'pemacu',
  'pacuan',
  'pacuk',
  'pacul',
  'pada',
  'berpada',
  'memada',
  'dipadakan',
  'terpada',
  'padah',
  'padahal',
  'memadah',
  'padam',
  'padamnya',
  'padamkan',
  'pemadaman',
  'padami',
  'padan',
  'sepadan',
  'berpadan',
  'memadan',
  'dipadankan',
  'pemadan',
  'padang',
  'padangnya',
  'padasan',
  'padat',
  'memadat',
  'dipadatkan',
  'padatan',
  'berkepadatan',
  'padau',
  'paderi',
  'padi',
  'padinya',
  'padma',
  'padu',
  'padukan',
  'paduan',
  'paduk',
  'paduka',
  'padung',
  'paesan',
  'pagai',
  'pagan',
  'pagar',
  'berpagar',
  'dipagar',
  'memagar',
  'pageri',
  'pagi',
  'sepagi',
  'kepagian',
  'pagoda',
  'pagut',
  'pagutan',
  'paha',
  'pahala',
  'pahalanya',
  'pahang',
  'pahar',
  'pahat',
  'pahatkan',
  'pahatan',
  'pahi',
  'pahit',
  'pahitnya',
  'kepahitan',
  'pahlawan',
  'kepahlawanan',
  'pahuk',
  'pai',
  'pain',
  'paip',
  'pair',
  'pais',
  'pajak',
  'dipajak',
  'memajak',
  'pajakkan',
  'pajakan',
  'pajang',
  'pajeri',
  'pajuh',
  'pak',
  'paka',
  'pakai',
  'dipakai',
  'memakai',
  'terpakai',
  'pemakai',
  'berpakai',
  'terimapakai',
  'gunapakai',
  'pakal',
  'pakam',
  'pakan',
  'pakar',
  'kepakaran',
  'pakat',
  'sepakat',
  'menyepakati',
  'sepakatan',
  'pakatan',
  'pakau',
  'pakcik',
  'pakej',
  'pakejnya',
  'pakejkan',
  'pakem',
  'pakis',
  'pakma',
  'paksa',
  'terpaksa',
  'dipaksa',
  'memaksa',
  'paksaan',
  'paksi',
  'paksina',
  'paku',
  'pakunya',
  'pakukan',
  'pakuh',
  'pakuk',
  'pal',
  'pala',
  'palai',
  'palak',
  'palam',
  'palang',
  'palangnya',
  'memalang',
  'palar',
  'memalar',
  'palas',
  'palat',
  'palau',
  'paldu',
  'palen',
  'paleografi',
  'paleolitik',
  'paleontologi',
  'pali',
  'paling',
  'palingkan',
  'palingan',
  'palis',
  'palit',
  'palitkan',
  'memalit',
  'palitan',
  'palka',
  'palma',
  'palpa',
  'palsu',
  'palsunya',
  'palsukan',
  'pemalsuan',
  'kepalsuan',
  'paltu',
  'palu',
  'berpalu',
  'paluan',
  'paluh',
  'palung',
  'palut',
  'pam',
  'pengepam',
  'pamah',
  'paman',
  'pamer',
  'pamerkan',
  'pameran',
  'pamit',
  'pamor',
  'pampa',
  'pampan',
  'pampang',
  'pampas',
  'pampasan',
  'pampat',
  'panah',
  'panahnya',
  'panahan',
  'memanah',
  'panas',
  'berpanas',
  'dipanaskan',
  'pemanas',
  'memanas',
  'kepanasan',
  'panasaran',
  'panau',
  'panaunya',
  'panca',
  'pancaka',
  'pancakara',
  'pancal',
  'pancaindera',
  'pancapersada',
  'pancang',
  'pancangkan',
  'pancangan',
  'pancar',
  'pancarkan',
  'pancaran',
  'berpancaran',
  'pancaragam',
  'pancaroba',
  'pancasila',
  'pancasuara',
  'pancawarna',
  'pancing',
  'pancingnya',
  'pancingan',
  'pancir',
  'pancisuselesspancis',
  'pancit',
  'pancitkan',
  'pancung',
  'pancur',
  'pancuran',
  'pancut',
  'pancutan',
  'pancutkan',
  'pandai',
  'pandainya',
  'kepandaian',
  'mandai',
  'pandak',
  'pandam',
  'pandan',
  'pandang',
  'terpandang',
  'berpandang',
  'dipandang',
  'memandang',
  'pemandang',
  'pandau',
  'pandir',
  'pandu',
  'panduan',
  'berpandu',
  'memandu',
  'panel',
  'panen',
  'pangah',
  'pangan',
  'pangeran',
  'panggang',
  'panggangku',
  'pemanggangan',
  'panggar',
  'panggas',
  'panggil',
  'panggilkan',
  'panggilan',
  'pangguk',
  'panggung',
  'memanggung',
  'pangkah',
  'pangkahan',
  'pangkai',
  'pangkal',
  'pangkalnya',
  'pangkalan',
  'pangkalannya',
  'pangkas',
  'pangkat',
  'pangkatnya',
  'kepangkatan',
  'pangking',
  'pangku',
  'berpangku',
  'pangkuan',
  'pemangkuan',
  'pangkung',
  'pangkur',
  'panglima',
  'panglin',
  'panglong',
  'pangsa',
  'pangsanya',
  'pangsapuri',
  'pangsi',
  'pangsit',
  'panguk',
  'pangus',
  'pani',
  'panik',
  'panir',
  'panitia',
  'panja',
  'panjak',
  'panjang',
  'panjangnya',
  'panjangkan',
  'panjangkannya',
  'panjangan',
  'kepanjangan',
  'panjar',
  'panjarwala',
  'panjat',
  'memanjat',
  'dipanjat',
  'pemanjat',
  'panjing',
  'panji',
  'panjunan',
  'panjut',
  'memanjut',
  'panorama',
  'panta',
  'pantai',
  'pantainya',
  'pantak',
  'pantang',
  'pantangnya',
  'memantang',
  'dipantangkan',
  'pantangan',
  'pantas',
  'kepantasan',
  'pantasnya',
  'memantas',
  'dipantaskan',
  'pantat',
  'pantau',
  'dipantau',
  'memantau',
  'pemantau',
  'pantelon',
  'pantik',
  'panting',
  'pantis',
  'pantograf',
  'pantomim',
  'pantul',
  'pantulkan',
  'pantulan',
  'pantun',
  'pantunnya',
  'memantunkan',
  'dipantunkan',
  'panus',
  'papa',
  'kepapaan',
  'papah',
  'memapah',
  'papak',
  'papakerma',
  'papan',
  'memapan',
  'papar',
  'memapar',
  'dipapar',
  'terpapar',
  'papas',
  'berpapasan',
  'para',
  'parabola',
  'paradamuselessparadom',
  'paradigma',
  'paradoks',
  'parafrasa',
  'paragraf',
  'parah',
  'parak',
  'parakit',
  'paralel',
  'paralimpik',
  'paramasastera',
  'paramasasterawan',
  'parameter',
  'paramuselessparam',
  'parang',
  'memarang',
  'parap',
  'paras',
  'parasnya',
  'parasetamol',
  'parasit',
  'parasitologi',
  'parau',
  'pari',
  'paria',
  'parih',
  'paris',
  'parit',
  'perparitan',
  'pariti',
  'pariwarta',
  'pariwisata',
  'parket',
  'parlimen',
  'parlimennya',
  'paron',
  'parpu',
  'parsi',
  'parsi',
  'parti',
  'partinya',
  'kepartian',
  'partikel',
  'paruh',
  'parung',
  'paru',
  'parut',
  'parutnya',
  'parutan',
  'parwah',
  'pas',
  'pasah',
  'pasak',
  'pasal',
  'pasalewa',
  'pasang',
  'pasangnya',
  'berpasang',
  'pasangkan',
  'pasangan',
  'pasar',
  'pasarkan',
  'pasaran',
  'pasasir',
  'pasat',
  'pasca',
  'pasif',
  'kepasifan',
  'pasifik',
  'pasilan',
  'pasir',
  'pasirnya',
  'pasirah',
  'paspor',
  'pasport',
  'pasrah',
  'kepasrahan',
  'pasta',
  'pasti',
  'pastikan',
  'kepastian',
  'pastri',
  'pastur',
  'pasu',
  'pasunya',
  'pemasuan',
  'pasuk',
  'pasukan',
  'pasukannya',
  'pasung',
  'memasung',
  'dipasung',
  'pata',
  'patah',
  'patahnya',
  'patahkan',
  'mematahkannya',
  'patahan',
  'patalogi',
  'patam',
  'patar',
  'paten',
  'pateri',
  'memateri',
  'patgulipat',
  'pati',
  'patih',
  'patik',
  'patil',
  'patin',
  'pating',
  'patinggi',
  'patok',
  'patologi',
  'patriot',
  'patriotik',
  'patrum',
  'patuh',
  'patuhi',
  'kepatuhan',
  'pematuhan',
  'patuk',
  'dipatuk',
  'mematuk',
  'patung',
  'mematung',
  'pepatung',
  'patut',
  'sepatutnya',
  'berpatutan',
  'mematut',
  'pau',
  'pauh',
  'pauk',
  'paun',
  'paupau',
  'paus',
  'paus',
  'paut',
  'memaut',
  'pautan',
  'pautannya',
  'pawagam',
  'pawah',
  'memawahkan',
  'pawai',
  'pawaka',
  'pawana',
  'pawang',
  'pawitera',
  'paya',
  'payanya',
  'payah',
  'memayah',
  'kepayahan',
  'payahlah',
  'payang',
  'payau',
  'payu',
  'payung',
  'payungnya',
  'payungi',
  'memayunginya',
  'payungkan',
  'pear',
  'pebahu',
  'pebisi',
  'pecah',
  'pecahkan',
  'pecahan',
  'pecahnya',
  'pecahlah',
  'pecahbelahkan',
  'pecai',
  'pecak',
  'pecal',
  'pecat',
  'dipecat',
  'memecat',
  'pemecatan',
  'pecok',
  'pecuk',
  'pecut',
  'dipecut',
  'memecut',
  'pecutan',
  'peda',
  'pedada',
  'pedadah',
  'pedak',
  'pedaka',
  'pedal',
  'pedanda',
  'pedang',
  'pedangit',
  'pedap',
  'pedapa',
  'pedarat',
  'pedas',
  'pedasnya',
  'memedaskan',
  'kepedasan',
  'pedati',
  'pedena',
  'pedendang',
  'pediah',
  'pediatrik',
  'pedih',
  'pedihnya',
  'memedihkan',
  'kepedihan',
  'pedologi',
  'pedoman',
  'berpedoman',
  'pedongkang',
  'pedu',
  'pedukang',
  'peduli',
  'pedulikan',
  'pedung',
  'pegaga',
  'pegal',
  'pegang',
  'dipegang',
  'berpegang',
  'memegang',
  'pemegang',
  'terpegang',
  'pegar',
  'pegari',
  'pegas',
  'pegat',
  'pegawai',
  'pegoh',
  'pegon',
  'peguam',
  'peguambela',
  'peguamcara',
  'pegun',
  'pehong',
  'pejabat',
  'pejabatnya',
  'pejal',
  'memejalkan',
  'pejam',
  'pejamkan',
  'pejera',
  'pejur',
  'pek',
  'peka',
  'kepekaan',
  'pekaja',
  'pekak',
  'memekak',
  'pekaka',
  'pekakuselesskedadak',
  'pekan',
  'pekannya',
  'pekap',
  'memekap',
  'pekasam',
  'pekasih',
  'pekat',
  'pekatkan',
  'kepekatan',
  'pekata',
  'pekatu',
  'pekatul',
  'pekedai',
  'pekerma',
  'pekerti',
  'peket',
  'pekik',
  'memekik',
  'pekiknya',
  'pekikan',
  'pekin',
  'peking',
  'pekis',
  'pekojan',
  'pekong',
  'peku',
  'pekuku',
  'pekula',
  'pekung',
  'pekup',
  'pekur',
  'pekuru',
  'pel',
  'pelaga',
  'pelagra',
  'pelah',
  'pelahang',
  'pelajau',
  'pelak',
  'pelakat',
  'pelaling',
  'pelamin',
  'berpelamin',
  'pelampas',
  'pelampung',
  'pelan',
  'pelannya',
  'pelana',
  'pelananya',
  'pelancar',
  'pelanduk',
  'pelang',
  'pelanga',
  'pelangah',
  'pelanggi',
  'pelangki',
  'pelantar',
  'pelantik',
  'pelanting',
  'berpelantingan',
  'pelara',
  'pelas',
  'pelasah',
  'pelat',
  'pelatuk',
  'pelatut',
  'pelawa',
  'pelawaan',
  'pelawai',
  'pelawan',
  'pelawas',
  'pelebaya',
  'pelecok',
  'pelekuh',
  'pelempap',
  'pelencit',
  'pelepah',
  'pelepar',
  'pelesat',
  'pelesir',
  'pelesiran',
  'pelesit',
  'peleting',
  'peletok',
  'pelias',
  'pelih',
  'pelihara',
  'peliharakan',
  'memelihara',
  'peliharaan',
  'peliharalah',
  'pelik',
  'kepelikan',
  'pelimban',
  'pelinggam',
  'pelipat',
  'pelipis',
  'pelir',
  'pelita',
  'pelog',
  'pelohong',
  'pelokok',
  'pelonco',
  'pelong',
  'pelonok',
  'pelontang',
  'pelopor',
  'pelopori',
  'pelor',
  'pelosok',
  'peluang',
  'peluangnya',
  'peluh',
  'perpeluhan',
  'peluit',
  'peluk',
  'terpeluk',
  'berpeluk',
  'pelukan',
  'peluklah',
  'peluluk',
  'pelung',
  'pelupuh',
  'pelupuk',
  'peluru',
  'pelurunya',
  'peluruh',
  'peluwap',
  'pelvis',
  'pemali',
  'pemaling',
  'pemara',
  'pemarip',
  'pemarit',
  'pematung',
  'pembobrokan',
  'pemelak',
  'pemengkul',
  'pemimpit',
  'pemirah',
  'pemiutang',
  'pempamer',
  'pempan',
  'pemparang',
  'pemplagiat',
  'pemplot',
  'pempopularan',
  'pemproses',
  'pempuk',
  'pemuntal',
  'pemuras',
  'pen',
  'pena',
  'penabas',
  'penaga',
  'penahap',
  'penai',
  'penak',
  'penaka',
  'penakan',
  'penalti',
  'penampan',
  'penat',
  'penatnya',
  'memenatkan',
  'kepenatan',
  'penatuh',
  'pencak',
  'pencalak',
  'pencalang',
  'pencaluk',
  'pencar',
  'pencaus',
  'pencen',
  'pencennya',
  'memencenkan',
  'pencil',
  'dipencilkan',
  'memencil',
  'kepencilan',
  'pencit',
  'pencolam',
  'pencong',
  'memencongkan',
  'penconguselessmencong',
  'pencuk',
  'pendaga',
  'pendakwaraya',
  'pendam',
  'pendamkan',
  'pendaman',
  'pendap',
  'memendap',
  'pendapa',
  'pendar',
  'pendawa',
  'pendek',
  'pendeknya',
  'pendekkan',
  'kependekan',
  'pendekar',
  'pendeta',
  'pendiat',
  'pending',
  'pendipun',
  'pendongkok',
  'penduk',
  'penes',
  'pengajap',
  'penganan',
  'pengantin',
  'pengantinnya',
  'pengap',
  'pengapuh',
  'pengaruh',
  'pengaruhnya',
  'pengaruhi',
  'pengat',
  'pengawinan',
  'pengekos',
  'pengembar',
  'pengeng',
  'pengepin',
  'pengerawan',
  'pengerek',
  'pengerih',
  'pengestu',
  'penget',
  'pengetus',
  'pengetut',
  'pengga',
  'penggah',
  'penggal',
  'penggalnya',
  'penggalan',
  'penggawa',
  'penggehi',
  'penghulu',
  'berpenghulukan',
  'pengilar',
  'pengkar',
  'pengki',
  'pengkilai',
  'pengkis',
  'pengklonan',
  'pengorak',
  'pengsan',
  'pengulun',
  'peni',
  'peniaram',
  'peniau',
  'pening',
  'peningkan',
  'kepeningan',
  'penisilin',
  'peniti',
  'penjan',
  'penjara',
  'penjaranya',
  'penjarakan',
  'kepenjaraan',
  'penjong',
  'penjul',
  'penjuru',
  'penomah',
  'pensel',
  'pensi',
  'pentagon',
  'pentakwil',
  'pental',
  'pentan',
  'pentang',
  'pentar',
  'pentas',
  'pentaskan',
  'mementaskannya',
  'pementasan',
  'pentil',
  'penting',
  'kepentingan',
  'kepentingannya',
  'mementingkan',
  'dipentingkan',
  'terpenting',
  'pentopan',
  'pentung',
  'penubal',
  'penuh',
  'penuhi',
  'penuhkan',
  'penujuh',
  'penurun',
  'penyangak',
  'penyap',
  'penyek',
  'penyet',
  'penyu',
  'penyum',
  'peok',
  'pepacal',
  'pepah',
  'pepai',
  'pepak',
  'pepaku',
  'pepanggil',
  'pepara',
  'peparam',
  'peparu',
  'pepas',
  'pepasang',
  'pepat',
  'pepatah',
  'pepatil',
  'pepauh',
  'pepayang',
  'pepek',
  'peper',
  'peperai',
  'pepes',
  'pepet',
  'pepina',
  'pepinang',
  'pepisang',
  'pepsin',
  'pepuah',
  'pepulut',
  'peputut',
  'pepuyu',
  'per',
  'perabot',
  'perabotnya',
  'perabu',
  'perabung',
  'peracit',
  'peracut',
  'perada',
  'perah',
  'perahan',
  'perahu',
  'perahunya',
  'perai',
  'peraja',
  'perajurit',
  'perak',
  'perak',
  'peram',
  'peraman',
  'perambut',
  'peran',
  'peranan',
  'peranannya',
  'perancah',
  'perang',
  'keperang',
  'perangan',
  'perangi',
  'perangilah',
  'peperangan',
  'perangai',
  'perangainya',
  'perangat',
  'peranggang',
  'peranggu',
  'perangkap',
  'perangkapnya',
  'perangkat',
  'perangsang',
  'perani',
  'peranjat',
  'memeranjatkan',
  'peranti',
  'perap',
  'peras',
  'pemeras',
  'perasan',
  'perasat',
  'perat',
  'perata',
  'perawan',
  'keperawanan',
  'perawas',
  'perawis',
  'perban',
  'perbani',
  'perbankan',
  'perbatin',
  'perbegu',
  'perca',
  'percaya',
  'percayakan',
  'percayai',
  'kepercayaan',
  'percik',
  'memercik',
  'dipercikkan',
  'percikan',
  'percikannya',
  'percul',
  'percuma',
  'perdah',
  'perdana',
  'perdata',
  'perdu',
  'peredus',
  'pereh',
  'perempuan',
  'keperempuanan',
  'perencah',
  'perengat',
  'perengau',
  'perenggan',
  'berperenggankan',
  'perenyuk',
  'perepat',
  'perewah',
  'pergam',
  'perganda',
  'pergar',
  'pergata',
  'pergedel',
  'pergi',
  'pemergian',
  'kepergian',
  'berpergian',
  'perhilitan',
  'peri',
  'perinya',
  'memerikan',
  'terperi',
  'peria',
  'peribadi',
  'berperibadi',
  'keperibadian',
  'berkeperibadian',
  'perian',
  'peridung',
  'perigi',
  'perih',
  'keperihan',
  'perihal',
  'perijih',
  'periksa',
  'pemeriksa',
  'memeriksa',
  'peperiksaan',
  'perimeter',
  'perincat',
  'perincit',
  'perindu',
  'pering',
  'peringat',
  'peringgi',
  'peringgi',
  'peringgitan',
  'peringkat',
  'peringkatnya',
  'memperingkatkan',
  'diperingkatkan',
  'pemeringkatan',
  'perintah',
  'perintahnya',
  'perintahkan',
  'pemerintah',
  'peririana',
  'perisa',
  'perisanya',
  'perisai',
  'perisainya',
  'periskop',
  'perisian',
  'peristiwa',
  'peristiwanya',
  'perit',
  'keperitan',
  'memeritkan',
  'periuk',
  'perjaka',
  'perkakas',
  'memperkakas',
  'perkara',
  'perkaranya',
  'memperkarakan',
  'perkasa',
  'memperkasa',
  'diperkasa',
  'keperkasaan',
  'perkosa',
  'perkosaan',
  'perkutut',
  'perlahan',
  'lahan',
  'perlahankan',
  'perlak',
  'perlang',
  'perlenteh',
  'perli',
  'memperli',
  'perlian',
  'perlina',
  'perling',
  'perlis',
  'perlu',
  'keperluan',
  'memerlukan',
  'perlukan',
  'diperlukan',
  'seperlunya',
  'perlung',
  'perlus',
  'diperlusi',
  'permai',
  'kepermaian',
  'permaidani',
  'permaisura',
  'permaisuri',
  'permana',
  'permasan',
  'permata',
  'permatang',
  'permisi',
  'permit',
  'permitnya',
  'pernah',
  'perni',
  'pernik',
  'perohong',
  'peroi',
  'peroman',
  'peronyok',
  'peropok',
  'peropong',
  'perosok',
  'perot',
  'perpatih',
  'persada',
  'persangga',
  'persekot',
  'persepsi',
  'persero',
  'persih',
  'persil',
  'persis',
  'perspektif',
  'personaliti',
  'personel',
  'pertama',
  'dipertamakan',
  'pertang',
  'pertiwi',
  'pertua',
  'pertun',
  'peruan',
  'peruang',
  'peruk',
  'perum',
  'perumpung',
  'perun',
  'perundung',
  'perunggu',
  'perungus',
  'perunjung',
  'perupuk',
  'perus',
  'perusa',
  'perusah',
  'perusat',
  'perut',
  'seperut',
  'berperut',
  'perutak',
  'perwara',
  'perwawai',
  'perwira',
  'keperwiraan',
  'pes',
  'pesa',
  'pesai',
  'pesaing',
  'pesak',
  'pesakit',
  'pesalah',
  'pesam',
  'pesan',
  'berpesan',
  'memesan',
  'dipesan',
  'pemesan',
  'pesantren',
  'pesara',
  'pesat',
  'sepesat',
  'pesatkan',
  'kepesatan',
  'peseban',
  'pesek',
  'pesemendan',
  'peser',
  'pesi',
  'pesimis',
  'pesimistik',
  'pesing',
  'pesisir',
  'pesolot',
  'pesona',
  'mempesona',
  'dipesonakan',
  'keterpersonaan',
  'pesong',
  'pesongkan',
  'memesong',
  'pesta',
  'pestanya',
  'memestakan',
  'pesuk',
  'pesuruhjaya',
  'peta',
  'petanya',
  'petakan',
  'pemeta',
  'petah',
  'memetahkan',
  'kepetahan',
  'petai',
  'petak',
  'petaknya',
  'petaka',
  'petala',
  'petaling',
  'petaling',
  'petamari',
  'petan',
  'petang',
  'petangnya',
  'kepetangan',
  'petanis',
  'petap',
  'petaram',
  'petarang',
  'petarangan',
  'petas',
  'petaya',
  'petekat',
  'petekuselesspetek',
  'peterana',
  'peteras',
  'peti',
  'petinya',
  'petia',
  'petiban',
  'petik',
  'dipetik',
  'memetik',
  'petikan',
  'petilan',
  'peting',
  'petinggi',
  'petir',
  'petis',
  'petisyen',
  'petitih',
  'petola',
  'petopan',
  'petrol',
  'petroleum',
  'petrologi',
  'petua',
  'petuanya',
  'petualang',
  'peturun',
  'petus',
  'petut',
  'petutu',
  'pi',
  'piadah',
  'piagam',
  'piah',
  'piai',
  'piak',
  'pial',
  'piala',
  'pialang',
  'pialing',
  'pialu',
  'piama',
  'pian',
  'piang',
  'pianggang',
  'pianggu',
  'piano',
  'piantan',
  'piara',
  'piarit',
  'pias',
  'piat',
  'piatuselesspiut',
  'piatu',
  'piawai',
  'berpiawai',
  'kepiawaian',
  'pic',
  'pica',
  'picagari',
  'pici',
  'picik',
  'picing',
  'picis',
  'picisan',
  'picit',
  'picitkan',
  'picitnya',
  'picitan',
  'picu',
  'picung',
  'pidana',
  'pidato',
  'pidatonya',
  'pigmen',
  'pigura',
  'pihak',
  'pihaknya',
  'pijak',
  'pijakkan',
  'pijakan',
  'pijama',
  'pijar',
  'memijar',
  'pijat',
  'pijatuselesspijat',
  'pika',
  'pikap',
  'pikat',
  'memikat',
  'pikau',
  'piket',
  'piknik',
  'piksel',
  'pikuk',
  'pikul',
  'dipikul',
  'memikul',
  'pikulkan',
  'pikulan',
  'pil',
  'pilas',
  'pili',
  'pilih',
  'berpilih',
  'pilihkan',
  'pilihan',
  'pilihlah',
  'pilihanraya',
  'pemilihan',
  'pilin',
  'pilis',
  'pilon',
  'pilu',
  'memilukan',
  'kepiluan',
  'pilus',
  'pimpan',
  'pimpin',
  'dipimpin',
  'memimpin',
  'pemimpin',
  'pimpinan',
  'pimping',
  'pin',
  'pinang',
  'peminang',
  'pinangkan',
  'pinangan',
  'pinauselesspina',
  'pinar',
  'pincang',
  'kepincangan',
  'pincanguselesspincung',
  'pincuk',
  'pinda',
  'pindaan',
  'meminda',
  'pindah',
  'pindahkan',
  'pindahan',
  'pindang',
  'pindis',
  'pingai',
  'pinga',
  'pingat',
  'pinggah',
  'pinggan',
  'pinggannya',
  'pinggang',
  'sepinggang',
  'pinggir',
  'meminggir',
  'pinggirkan',
  'dipinggiri',
  'pinggiran',
  'pinggul',
  'pingit',
  'pingpong',
  'pinjal',
  'pinjam',
  'dipinjam',
  'meminjam',
  'peminjam',
  'pinset',
  'pinta',
  'dipinta',
  'terpinta',
  'pintal',
  'pemintal',
  'pintar',
  'pintarnya',
  'kepintaran',
  'pintas',
  'memintas',
  'pintasan',
  'pintau',
  'pintil',
  'pintu',
  'pintunya',
  'pinuh',
  'pipa',
  'pipet',
  'pipi',
  'pipih',
  'memipih',
  'pipis',
  'memipis',
  'pipisan',
  'pipit',
  'pirai',
  'piramid',
  'piranha',
  'pirau',
  'pirik',
  'piring',
  'pisah',
  'pisahkan',
  'pemisah',
  'perpisahan',
  'pisang',
  'pisat',
  'pisau',
  'pisit',
  'pistil',
  'pistol',
  'berpistol',
  'pita',
  'pitam',
  'pitar',
  'pitarah',
  'piting',
  'pitis',
  'pitut',
  'piuh',
  'piuhuselesspilin',
  'piung',
  'piut',
  'piutang',
  'memiutangkan',
  'piuter',
  'plag',
  'plagiarisme',
  'plagiat',
  'plaintif',
  'planet',
  'plasenta',
  'plasma',
  'plasmodium',
  'plasmolisis',
  'plaster',
  'plastik',
  'plastisin',
  'plastometer',
  'plat',
  'platform',
  'platinum',
  'plato',
  'platun',
  'playar',
  'plaza',
  'pleno',
  'plot',
  'plotnya',
  'pluton',
  'plutonium',
  'pneumatik',
  'pneumonia',
  'poci',
  'podak',
  'podium',
  'pohon',
  'pohonnya',
  'pojok',
  'pokai',
  'pokeng',
  'poket',
  'berpoket',
  'pokok',
  'pokoknya',
  'pokrol',
  'pokta',
  'pola',
  'polanya',
  'polan',
  'polanguselesspaling',
  'polaroid',
  'polek',
  'polemik',
  'poliandri',
  'polifonik',
  'poligami',
  'poliglot',
  'poligon',
  'poliklinik',
  'polio',
  'polip',
  'polis',
  'kepolisan',
  'polisi',
  'politeisme',
  'politeknik',
  'politena',
  'politik',
  'politiknya',
  'politikkan',
  'kepolitikan',
  'polo',
  'polok',
  'polong',
  'polos',
  'pompa',
  'pompang',
  'pompong',
  'ponco',
  'pondan',
  'pondok',
  'memondok',
  'pondokan',
  'pondong',
  'pongah',
  'ponggok',
  'pongia',
  'pongkes',
  'pongpong',
  'pongsu',
  'poni',
  'ponjen',
  'ponok',
  'pontang',
  'pontanguselesspanting',
  'ponten',
  'ponteng',
  'pontoh',
  'ponton',
  'pop',
  'popi',
  'popia',
  'poplin',
  'popok',
  'popular',
  'popularkan',
  'mempopularkannya',
  'kepopularan',
  'populariti',
  'populasi',
  'porak',
  'peranda',
  'porakuselessperanda',
  'poren',
  'pori',
  'porok',
  'porong',
  'poros',
  'portal',
  'portfolio',
  'porselin',
  'pos',
  'diposkan',
  'pengepos',
  'posisi',
  'positif',
  'poskad',
  'poskod',
  'posmen',
  'poso',
  'poster',
  'post',
  'mortem',
  'postur',
  'potasium',
  'potehi',
  'potensi',
  'potensinya',
  'potong',
  'potongnya',
  'potongkan',
  'potongan',
  'potret',
  'memotret',
  'pemotret',
  'poyang',
  'pra',
  'praakhir',
  'pragmatik',
  'pragmatisme',
  'prahoto',
  'prairi',
  'praja',
  'prejudis',
  'prakarsa',
  'prakarya',
  'prakata',
  'praktik',
  'praktiknya',
  'praktikkan',
  'praktikal',
  'praktis',
  'pramugara',
  'pramugari',
  'pranatal',
  'prasangka',
  'prasarana',
  'prasasti',
  'prasejarah',
  'prasekolah',
  'prasetia',
  'praujian',
  'prauniversiti',
  'prausul',
  'prawacana',
  'prebet',
  'predikat',
  'preman',
  'premis',
  'premium',
  'presiden',
  'presidium',
  'preskripsi',
  'preskriptif',
  'prestasi',
  'prestasinya',
  'prestij',
  'priayi',
  'pribumi',
  'prihatin',
  'keprihatinan',
  'primadona',
  'primbon',
  'primer',
  'primitif',
  'prinsip',
  'berprinsip',
  'prinsipal',
  'prisma',
  'pro',
  'proaktif',
  'produk',
  'produksi',
  'produktif',
  'produktiviti',
  'produser',
  'prof',
  'profesion',
  'profesional',
  'profesor',
  'profil',
  'profilnya',
  'program',
  'programkan',
  'pemprogram',
  'progresif',
  'projek',
  'memprojek',
  'projektor',
  'proklamasi',
  'memproklamasikan',
  'proksi',
  'proletar',
  'proletariat',
  'prolog',
  'promosi',
  'promosinya',
  'promosikan',
  'promoter',
  'prop',
  'propaganda',
  'mempropagandakan',
  'dipropagandakan',
  'prosa',
  'prosedur',
  'prosenium',
  'proses',
  'prosiding',
  'prospek',
  'prospektus',
  'protein',
  'proteinnya',
  'protes',
  'protestan',
  'protokol',
  'proton',
  'protoplasma',
  'prototaip',
  'protozoa',
  'provokasi',
  'provokatif',
  'pruf',
  'prun',
  'psikiatri',
  'psikik',
  'psikoanalisis',
  'psikologi',
  'psikoterapi',
  'puadai',
  'puah',
  'puak',
  'puaknya',
  'puaka',
  'puala',
  'pualam',
  'puan',
  'puan',
  'puar',
  'puas',
  'berpuas',
  'sepuas',
  'kepuasan',
  'puaskan',
  'pemuasan',
  'puasa',
  'puasanya',
  'puatang',
  'publisiti',
  'pucat',
  'kepucatan',
  'pucatuselesslesi',
  'pucatuselesspasi',
  'pucik',
  'pucuk',
  'pucuknya',
  'pucung',
  'pudar',
  'pudarkan',
  'kepudaran',
  'pudat',
  'pudi',
  'pudina',
  'pudinanya',
  'puding',
  'pudur',
  'pugar',
  'pugau',
  'puing',
  'puinguselesspuing',
  'puisi',
  'puisinya',
  'puitis',
  'kepuitisan',
  'puja',
  'pujaan',
  'pemujaan',
  'pujangga',
  'puji',
  'terpuji',
  'pujian',
  'memuji',
  'pujuk',
  'pujuknya',
  'pujukan',
  'pujuklah',
  'pujur',
  'pujut',
  'pukah',
  'pukal',
  'pukang',
  'pukas',
  'pukat',
  'pukatnya',
  'pukau',
  'dipukaunya',
  'puki',
  'pukul',
  'pukulan',
  'pukulkan',
  'pul',
  'pula',
  'pulai',
  'pulan',
  'pulang',
  'pulangnya',
  'pulanglah',
  'pulangan',
  'kepulangan',
  'pulangkan',
  'pulangku',
  'pulas',
  'dipulas',
  'pulaskan',
  'pulasan',
  'pulasari',
  'pulau',
  'pulaukan',
  'kepulauan',
  'pemulauan',
  'pulih',
  'pulihkan',
  'pemulihan',
  'puling',
  'pulpa',
  'puluh',
  'puluhan',
  'persepuluh',
  'pulun',
  'pulur',
  'pulut',
  'pulutan',
  'pumis',
  'pumpun',
  'pun',
  'punah',
  'memunahkan',
  'pemunahan',
  'punai',
  'punat',
  'punca',
  'puncanya',
  'puncak',
  'puncaknya',
  'punci',
  'pundak',
  'pundi',
  'punding',
  'punitif',
  'punggah',
  'punggahan',
  'punggai',
  'punggal',
  'pungguk',
  'punggung',
  'memunggung',
  'punggur',
  'pungkur',
  'pungut',
  'memungut',
  'pungutan',
  'punjin',
  'punjung',
  'punjut',
  'puntal',
  'puntalan',
  'punti',
  'puntianak',
  'puntung',
  'punya',
  'punyai',
  'mempunyai',
  'dipunyai',
  'kepunyaan',
  'pupa',
  'pupal',
  'pupil',
  'pupu',
  'pupunya',
  'sepupu',
  'bersepupu',
  'pupuh',
  'pupuk',
  'pupukan',
  'pupul',
  'pupuuselesspoyang',
  'pupur',
  'pupurkan',
  'pupuran',
  'pupus',
  'pupusnya',
  'pupuskan',
  'kepupusan',
  'puput',
  'puputan',
  'pura',
  'puraan',
  'purata',
  'puratanya',
  'purba',
  'purbakala',
  'purbawara',
  'purdah',
  'purdahnya',
  'puri',
  'puring',
  'purnama',
  'puru',
  'puruh',
  'puruk',
  'purukuselessporak',
  'purun',
  'purung',
  'purut',
  'purwakanti',
  'pusa',
  'pusaka',
  'pusakanya',
  'pusakai',
  'dipusakai',
  'memusakakan',
  'dipusakakan',
  'pusam',
  'pusang',
  'pusar',
  'pusaran',
  'pusara',
  'pusat',
  'pusatnya',
  'pusatkan',
  'memusatkannya',
  'pusatan',
  'pusing',
  'pusingkan',
  'pusingan',
  'pusingannya',
  'puspa',
  'pusparagam',
  'puspawarna',
  'puspita',
  'pusta',
  'pustaka',
  'pustakaan',
  'kepustakaan',
  'pusu',
  'putar',
  'putarkan',
  'putaran',
  'putarbalik',
  'diputarbalikkan',
  'memutarbalikkan',
  'putarbelit',
  'diputarbelitkan',
  'memutarbelitkan',
  'putarwali',
  'putat',
  'putra',
  'putrajaya',
  'putera',
  'berputera',
  'diputerakan',
  'keputeraan',
  'puteranda',
  'puteri',
  'putih',
  'berputih',
  'memutih',
  'diputihkan',
  'keputih',
  'pemutihan',
  'putik',
  'putiknya',
  'putil',
  'puting',
  'putingnya',
  'putu',
  'putus',
  'putuslah',
  'putusnya',
  'putuskan',
  'putusan',
  'keputusan',
  'putut',
  'puyu',
  'puyuh',
  'puyun',
  'qada',
  'mengqada',
  'qadak',
  'qadar',
  'qadim',
  'qaf',
  'qalam',
  'qari',
  'qariah',
  'qasidah',
  'qasidahnya',
  'qidam',
  'qiyamullail',
  'qunut',
  'quo',
  'quran',
  'raba',
  'diraba',
  'meraba',
  'peraba',
  'rabah',
  'rabak',
  'raban',
  'rabauselessrabu',
  'rabas',
  'rabbana',
  'rabbani',
  'rabbi',
  'rabik',
  'rabit',
  'rabiulakhir',
  'rabiulawal',
  'rabu',
  'rabu',
  'rabuk',
  'merabuk',
  'rabun',
  'merabun',
  'kerabunan',
  'rabung',
  'perabungan',
  'rabut',
  'racau',
  'kemeracauan',
  'racik',
  'racikan',
  'racun',
  'racuni',
  'keracunan',
  'peracunan',
  'racut',
  'radak',
  'radang',
  'radangkan',
  'keradangan',
  'kemeradangan',
  'radar',
  'radas',
  'radasnya',
  'raden',
  'raden',
  'radian',
  'radiasi',
  'radiator',
  'radikal',
  'keradikalan',
  'radikalisme',
  'radin',
  'radio',
  'meradiokan',
  'radioaktif',
  'radiobiologi',
  'radiograf',
  'radiogram',
  'radiolisis',
  'radiolisiso',
  'radiologi',
  'radioskop',
  'radiotelefon',
  'radioteleskop',
  'radioterapi',
  'radium',
  'radius',
  'radung',
  'radup',
  'rafia',
  'rafidi',
  'raga',
  'peragakan',
  'peragaan',
  'peragawan',
  'peragawati',
  'ragam',
  'ragamnya',
  'seragamkan',
  'seragaman',
  'keragaman',
  'meragamkan',
  'ragang',
  'ragangan',
  'ragas',
  'ragbi',
  'ragi',
  'meragikan',
  'ragin',
  'ragu',
  'keraguan',
  'ragui',
  'keraguuselessraguan',
  'ragum',
  'ragung',
  'ragus',
  'ragut',
  'meragutkan',
  'rahang',
  'rahap',
  'merahapkan',
  'rahasia',
  'rahat',
  'rahib',
  'rahim',
  'rahimnya',
  'kerahiman',
  'rahmat',
  'rahmatnya',
  'dirahmati',
  'dirahmatinya',
  'merahmati',
  'rahmatullah',
  'rahsia',
  'rahsiakan',
  'kerahsiaan',
  'rai',
  'raikan',
  'keraian',
  'raih',
  'rais',
  'raja',
  'rajanya',
  'rajakan',
  'kerajaan',
  'berkerajaan',
  'merajai',
  'rajah',
  'dirajah',
  'rajan',
  'rajang',
  'merajang',
  'rajau',
  'rajawali',
  'raji',
  'rajim',
  'rajin',
  'merajin',
  'kerajinan',
  'rajuk',
  'rajuknya',
  'merajuki',
  'rajungan',
  'rajut',
  'rajutan',
  'rak',
  'derak',
  'raka',
  'rakaat',
  'rakam',
  'dirakamnya',
  'merakamnya',
  'rakamkan',
  'rakaman',
  'rakan',
  'rakanan',
  'rakannya',
  'rakanlah',
  'rakap',
  'rakat',
  'raket',
  'rakit',
  'rakitnya',
  'raksa',
  'raksamala',
  'raksasa',
  'raksasaan',
  'raksi',
  'rakuk',
  'rakung',
  'rakungan',
  'rakus',
  'rakusnya',
  'kerakusan',
  'rakut',
  'rakyat',
  'kerakyatan',
  'ralat',
  'ralip',
  'ramadan',
  'ramah',
  'ramahi',
  'ramahnya',
  'ramahan',
  'keramahan',
  'tamah',
  'ramai',
  'ramainya',
  'ramailah',
  'meramaikan',
  'peramai',
  'keramaian',
  'ramal',
  'ramalan',
  'rama',
  'ramas',
  'ramasan',
  'rambah',
  'rambahan',
  'rambai',
  'rambak',
  'merambakkan',
  'rambang',
  'rambas',
  'rambat',
  'rambih',
  'rambu',
  'rambun',
  'rambung',
  'rambuni',
  'rambut',
  'rambutnya',
  'rambutku',
  'rambutmu',
  'rambutan',
  'rambuti',
  'rami',
  'ramin',
  'meraminkan',
  'rampai',
  'merampaikan',
  'rampaian',
  'rampak',
  'rampang',
  'rampas',
  'rampasan',
  'merampas',
  'dirampas',
  'rampat',
  'ramping',
  'ramping',
  'rampok',
  'rampokan',
  'rampung',
  'rampungkan',
  'rampus',
  'ramu',
  'ramuan',
  'ramunia',
  'ramus',
  'ramusnya',
  'ran',
  'rana',
  'ranah',
  'ranai',
  'ranap',
  'ranapkan',
  'meranap',
  'ranca',
  'rancak',
  'rancakan',
  'merancakkan',
  'rancaknya',
  'rancaklah',
  'rancang',
  'dirancang',
  'merancang',
  'rancangan',
  'perancang',
  'terancang',
  'rancap',
  'rancu',
  'merancukan',
  'rancung',
  'randa',
  'randah',
  'randai',
  'randajawa',
  'randau',
  'randek',
  'randi',
  'randu',
  'merandukan',
  'randuk',
  'randung',
  'terserandung',
  'merandungkan',
  'rang',
  'rangah',
  'rangai',
  'rangam',
  'rangan',
  'rangga',
  'ranggah',
  'ranggak',
  'meranggak',
  'ranggas',
  'ranggi',
  'meranggikan',
  'ranggit',
  'meranggitkan',
  'ranggoi',
  'ranggu',
  'ranggul',
  'ranggung',
  'rangin',
  'rangka',
  'berangka',
  'rangkai',
  'rangkaikan',
  'rangkaian',
  'menyerangkaikan',
  'rangkak',
  'merangkak',
  'rangkap',
  'rangkapkan',
  'rangkapan',
  'rangkaya',
  'rangkik',
  'ranking',
  'rangking',
  'rangkit',
  'rangkul',
  'rangkulan',
  'rangkum',
  'serangkum',
  'rangkuman',
  'rangkun',
  'serangkunkan',
  'rangkung',
  'rangkup',
  'merangkupi',
  'rango',
  'rangrang',
  'rangrangan',
  'rangsa',
  'rangsang',
  'rangsangan',
  'rangsangkan',
  'memberangsangkan',
  'rangsum',
  'rangu',
  'rangum',
  'rangup',
  'rani',
  'ranjang',
  'ranjau',
  'ranjing',
  'keranjingan',
  'rantai',
  'rantainya',
  'rantaikan',
  'rantaian',
  'rantam',
  'rantang',
  'rantas',
  'rantau',
  'rantaunya',
  'perantau',
  'ranti',
  'ranting',
  'rantingnya',
  'rantuk',
  'rantus',
  'ranum',
  'meranumkan',
  'ranyah',
  'ranyau',
  'ranyuk',
  'rap',
  'rapah',
  'rapai',
  'rapang',
  'rapat',
  'rapatnya',
  'rapatkan',
  'rapati',
  'berapat',
  'rapatan',
  'memperapat',
  'rapi',
  'rapikan',
  'kerapian',
  'rapih',
  'rapik',
  'rapsodi',
  'raptai',
  'rapu',
  'rapuh',
  'rapuhkan',
  'kerapuhan',
  'perapuh',
  'rapun',
  'merapunkan',
  'rapung',
  'merapungkan',
  'rapus',
  'ras',
  'rasa',
  'rasakan',
  'rasai',
  'rasanya',
  'rasalah',
  'rasailah',
  'perasaan',
  'kerasaan',
  'berperasaan',
  'memperasakan',
  'merasaikan',
  'perasaian',
  'rasan',
  'rasau',
  'rasi',
  'merasikan',
  'serasikan',
  'diserasikan',
  'keserasian',
  'rasian',
  'rasional',
  'rasionalisasi',
  'rasionalisme',
  'rasmi',
  'rasmikan',
  'merasmikannya',
  'perasmian',
  'raspberi',
  'rastali',
  'rasuah',
  'rasuk',
  'dirasuki',
  'merasuki',
  'kerasukan',
  'rasul',
  'kerasulan',
  'rasul',
  'rasulullah',
  'rata',
  'ratakan',
  'ratanya',
  'ratalah',
  'ratakah',
  'diratakan',
  'seratakan',
  'rataan',
  'ratah',
  'ratahan',
  'ratap',
  'ratapan',
  'ratib',
  'meratibkan',
  'ratifikasi',
  'ratna',
  'ratu',
  'ratus',
  'ratusan',
  'peratus',
  'seratus',
  'rau',
  'raung',
  'raungan',
  'raup',
  'raut',
  'rautan',
  'rawa',
  'rawah',
  'rawai',
  'rawak',
  'rawan',
  'merawan',
  'rawang',
  'rawat',
  'rawatan',
  'dirawat',
  'merawat',
  'rawatlah',
  'rawatib',
  'rawi',
  'merawikan',
  'rawit',
  'rawitan',
  'rawon',
  'raya',
  'rayakan',
  'dirayakan',
  'perayaan',
  'rayah',
  'rayan',
  'rayang',
  'rayap',
  'terayap',
  'rayapan',
  'rayau',
  'rayon',
  'rayu',
  'rayulah',
  'rayuan',
  'perayu',
  'reaksi',
  'reaksinya',
  'reaktif',
  'reaktor',
  'realis',
  'realisasi',
  'merealisasikannya',
  'realisme',
  'realistik',
  'realiti',
  'reba',
  'rebab',
  'rebah',
  'merebah',
  'direbahkan',
  'rebak',
  'direbak',
  'reban',
  'rebannya',
  'merebankan',
  'rebana',
  'rebang',
  'rebas',
  'rebat',
  'rebeh',
  'rebek',
  'reben',
  'rebu',
  'rebuk',
  'rebung',
  'rebus',
  'rebusan',
  'rebuslah',
  'rebut',
  'merebut',
  'direbut',
  'perebut',
  'rebutlah',
  'recak',
  'receh',
  'receng',
  'recik',
  'merecik',
  'recok',
  'kerecokan',
  'recup',
  'reda',
  'bereda',
  'mereda',
  'keredaan',
  'redah',
  'redaksi',
  'redam',
  'peredam',
  'redan',
  'redang',
  'redani',
  'redap',
  'redas',
  'redha',
  'keredhaan',
  'keredhaannya',
  'redih',
  'redip',
  'reduksi',
  'redum',
  'redup',
  'meredupkan',
  'keredupan',
  'referendum',
  'reflasi',
  'refleks',
  'refleksi',
  'reflektor',
  'reformasi',
  'regan',
  'regang',
  'diregang',
  'meregang',
  'regangan',
  'regas',
  'regat',
  'regata',
  'regek',
  'regis',
  'register',
  'beregister',
  'registrasi',
  'regresi',
  'regresif',
  'regu',
  'regukan',
  'rehabilitasi',
  'merehabilitasikan',
  'rehal',
  'rehat',
  'rehatkan',
  'kerehatan',
  'reja',
  'rejab',
  'rejah',
  'merejahkan',
  'rejam',
  'direjam',
  'rejan',
  'rejang',
  'rejasa',
  'rejeh',
  'rejeng',
  'rejim',
  'rejimen',
  'rejuk',
  'reka',
  'merekakannya',
  'rekalah',
  'rekaan',
  'pereka',
  'rekabentuk',
  'rekacipta',
  'rekah',
  'rekahan',
  'rekan',
  'rekanan',
  'rekat',
  'perekat',
  'rekata',
  'reke',
  'rekemen',
  'reken',
  'rekenan',
  'rekening',
  'rekod',
  'rekoder',
  'rekreasi',
  'rekrut',
  'reksa',
  'rektifikasi',
  'rektor',
  'rektum',
  'rel',
  'rela',
  'merelakan',
  'merelai',
  'direlai',
  'kerelaan',
  'relawan',
  'relai',
  'relaks',
  'relang',
  'relap',
  'relas',
  'relasi',
  'relatif',
  'kerelatifan',
  'relau',
  'relevan',
  'kerelevanan',
  'relif',
  'reluh',
  'relung',
  'merelung',
  'rem',
  'remah',
  'remahkan',
  'remai',
  'remaja',
  'meremajakan',
  'keremajaan',
  'peremajaan',
  'remak',
  'reman',
  'remang',
  'meremangkan',
  'remayung',
  'remba',
  'rembah',
  'remban',
  'rembang',
  'rembanglah',
  'rembas',
  'rembat',
  'rembatan',
  'rembega',
  'rembes',
  'rembesan',
  'rembet',
  'rembih',
  'rembik',
  'rembuk',
  'merembukkan',
  'rembukan',
  'rembulan',
  'rembunai',
  'rembut',
  'remeh',
  'keremehan',
  'temeh',
  'remet',
  'remis',
  'remit',
  'remos',
  'rempa',
  'rempah',
  'rempahi',
  'rempahnya',
  'rempahuselesspawah',
  'rempahuselessperawis',
  'rempahuselesspiah',
  'rempahuselessratus',
  'rempak',
  'serempak',
  'rempanguselessrempong',
  'rempat',
  'merempatlah',
  'rempelas',
  'rempenai',
  'rempenang',
  'rempeyek',
  'rempit',
  'rempong',
  'rempuh',
  'rempuhan',
  'rempung',
  'remujung',
  'remuk',
  'remukuselessredam',
  'remung',
  'remunggai',
  'remut',
  'renang',
  'renangi',
  'direnang',
  'merenang',
  'renangan',
  'rencah',
  'rencahnya',
  'rencam',
  'rencaman',
  'rencana',
  'rencanakan',
  'rencananya',
  'perencanaan',
  'rencang',
  'rencat',
  'menrencatkan',
  'renceh',
  'renceng',
  'rencet',
  'berencetan',
  'rencik',
  'rencing',
  'gemerincing',
  'rencis',
  'rencong',
  'renda',
  'rendah',
  'rendahkan',
  'rendahnya',
  'rendahlah',
  'rendahan',
  'rendam',
  'rendamkan',
  'rendaman',
  'rendang',
  'rendangan',
  'rendangnya',
  'renduh',
  'reneh',
  'renehan',
  'renga',
  'rengadean',
  'rengan',
  'rengap',
  'rengas',
  'rengat',
  'rengeh',
  'rengek',
  'rengekan',
  'rengga',
  'renggam',
  'renggang',
  'renggangkan',
  'merenggang',
  'renggangnya',
  'renggangan',
  'memperenggang',
  'renggat',
  'renggut',
  'renggutan',
  'rengit',
  'rengka',
  'rengkah',
  'rengkahan',
  'rengkat',
  'rengkeh',
  'rengkel',
  'rengkiang',
  'rengkinang',
  'rengkuh',
  'perengkuh',
  'rengkuk',
  'rengkung',
  'rengsa',
  'renguh',
  'rengus',
  'rengut',
  'renik',
  'rening',
  'renjana',
  'renjat',
  'renjer',
  'renjis',
  'renjiskan',
  'renjong',
  'renta',
  'rentak',
  'merentak',
  'rentaknya',
  'rentan',
  'rentang',
  'serentang',
  'terentang',
  'rentap',
  'merentapkan',
  'rentas',
  'rentasi',
  'rentasan',
  'renten',
  'renteng',
  'rentet',
  'rentetan',
  'rentung',
  'renung',
  'renungan',
  'renungkanlah',
  'renut',
  'renyah',
  'renyahkan',
  'renyai',
  'berenyaian',
  'renyak',
  'renyam',
  'renyap',
  'renyau',
  'renyeh',
  'renyik',
  'renyuh',
  'renyuk',
  'serenyuk',
  'renyut',
  'reologi',
  'reostat',
  'repak',
  'repang',
  'repas',
  'reparasi',
  'repek',
  'repertoir',
  'repes',
  'repet',
  'repetan',
  'repih',
  'repihan',
  'replika',
  'repot',
  'repotan',
  'reptilia',
  'repu',
  'republik',
  'repuh',
  'repuhan',
  'reput',
  'reputan',
  'reputasi',
  'rerak',
  'bererakan',
  'rerambut',
  'reriang',
  'reribu',
  'reruku',
  'reruntuh',
  'reruntuk',
  'resa',
  'resah',
  'keresahan',
  'beresah',
  'resak',
  'resam',
  'resaman',
  'resan',
  'resap',
  'resaplah',
  'resapan',
  'resdung',
  'resensi',
  'resi',
  'residen',
  'keresidenan',
  'resik',
  'resipi',
  'resit',
  'resmi',
  'resolusi',
  'resort',
  'respek',
  'respirasi',
  'respon',
  'responden',
  'responsif',
  'restoran',
  'restu',
  'restui',
  'restung',
  'reta',
  'retak',
  'meretak',
  'diretakkan',
  'retakan',
  'reti',
  'retai',
  'retas',
  'retih',
  'retin',
  'retina',
  'retorik',
  'retort',
  'retrospektif',
  'reumatik',
  'revolusi',
  'merevolusikan',
  'revolusioner',
  'kerevolusioneran',
  'revolver',
  'rewak',
  'rewan',
  'rewang',
  'rewanglah',
  'rewel',
  'kerewelan',
  'rewet',
  'rezeki',
  'ria',
  'riakan',
  'keriaan',
  'riadah',
  'riah',
  'riak',
  'rial',
  'riam',
  'rian',
  'riang',
  'riangnya',
  'meriang',
  'keriangan',
  'riap',
  'rias',
  'riasan',
  'riayah',
  'riba',
  'meriba',
  'beriba',
  'ribang',
  'ribu',
  'seribu',
  'ribuan',
  'ribut',
  'ributkan',
  'keributan',
  'ricau',
  'ricih',
  'ricu',
  'kericuan',
  'ridan',
  'riding',
  'ridip',
  'rifel',
  'rigi',
  'rijalullah',
  'riket',
  'rim',
  'rima',
  'rimas',
  'merimaskan',
  'rimau',
  'rimba',
  'rimbas',
  'rimbat',
  'rimbu',
  'rimbun',
  'merimbun',
  'dirimbunkan',
  'rimis',
  'rimpai',
  'rimpang',
  'rimpi',
  'rimping',
  'rimpuh',
  'merimpuhkan',
  'rimpung',
  'rinai',
  'merinaikan',
  'rinci',
  'perinci',
  'perincian',
  'perincikan',
  'rincih',
  'rincik',
  'rincis',
  'perincis',
  'rinding',
  'merindingkan',
  'rindu',
  'dirindu',
  'merindu',
  'rinduan',
  'ring',
  'ringan',
  'ringankan',
  'keringanan',
  'peringanan',
  'ringannya',
  'ringgi',
  'ringgin',
  'ringgis',
  'ringgit',
  'meringgiti',
  'diringgiti',
  'ringgitnya',
  'ringih',
  'ringin',
  'ringis',
  'ringkai',
  'ringkas',
  'ringkasnya',
  'ringkaskan',
  'ringkasan',
  'ringkik',
  'ringking',
  'ringkuk',
  'ringkukan',
  'ringsing',
  'rintang',
  'rintangi',
  'rintangkan',
  'rintangan',
  'rintas',
  'rintih',
  'merintih',
  'rintik',
  'rinting',
  'rintis',
  'rintisan',
  'ripai',
  'ripuk',
  'riri',
  'risa',
  'risak',
  'risalah',
  'risau',
  'dirisaukan',
  'merisau',
  'risauan',
  'perisau',
  'risik',
  'risikan',
  'merisik',
  'risikkan',
  'risiklah',
  'risiko',
  'berisiko',
  'merisikokan',
  'ritau',
  'ritma',
  'ritual',
  'riuh',
  'keriuhan',
  'meriuhkan',
  'riuk',
  'riung',
  'riu',
  'riwayat',
  'riwayatkan',
  'riwayatnya',
  'riyak',
  'rizab',
  'rizabnya',
  'rizom',
  'robak',
  'robat',
  'robek',
  'merobekkan',
  'roboh',
  'robohan',
  'robohkan',
  'meroboh',
  'robok',
  'robot',
  'robotnya',
  'robotik',
  'rocah',
  'rocoh',
  'rod',
  'roda',
  'beroda',
  'rodak',
  'rodan',
  'rodat',
  'rodi',
  'rodok',
  'rodong',
  'merodongkan',
  'rogoh',
  'rogol',
  'roh',
  'rohani',
  'kerohanian',
  'rohaniah',
  'roi',
  'rojak',
  'rojol',
  'roket',
  'rokok',
  'merokok',
  'antimerokok',
  'rolet',
  'roma',
  'roman',
  'romantik',
  'romantis',
  'romba',
  'rombak',
  'rombakan',
  'rombang',
  'rambing',
  'rombeng',
  'rombengkan',
  'rombong',
  'rombongannya',
  'merombong',
  'rombus',
  'romen',
  'romok',
  'rompak',
  'rompakan',
  'rompang',
  'rompeng',
  'rompes',
  'rompong',
  'merompongkan',
  'romusa',
  'romusya',
  'diromusyakan',
  'rona',
  'ronda',
  'rondaan',
  'rondah',
  'randih',
  'rondas',
  'rondok',
  'merondokkan',
  'roneo',
  'rong',
  'rongak',
  'merongakkan',
  'kerongakan',
  'rongga',
  'meronggai',
  'ronggeng',
  'ronggok',
  'rongkok',
  'rongkol',
  'rongkong',
  'rongoh',
  'rongok',
  'rongos',
  'ronta',
  'ronyeh',
  'ronyoh',
  'ronyok',
  'ropak',
  'ropol',
  'ros',
  'rosak',
  'kerosakan',
  'perosak',
  'berosak',
  'rosan',
  'rosario',
  'roset',
  'rosok',
  'merosokkan',
  'rosokan',
  'rosot',
  'merosot',
  'kemerosotan',
  'rota',
  'rotan',
  'berotan',
  'roti',
  'rotogravur',
  'rotok',
  'rotor',
  'royak',
  'royal',
  'meroyalkan',
  'keroyalan',
  'royalti',
  'royan',
  'royong',
  'ru',
  'rua',
  'ruah',
  'meruahkan',
  'ruai',
  'ruak',
  'ruam',
  'ruang',
  'meruang',
  'ruap',
  'meruap',
  'ruas',
  'ruasnya',
  'peruasan',
  'ruat',
  'ruatan',
  'rubah',
  'rubai',
  'ruban',
  'rubella',
  'rubiah',
  'rubin',
  'rubing',
  'rubu',
  'rubung',
  'merubungi',
  'rudu',
  'rudus',
  'rugi',
  'kerugian',
  'merugi',
  'dirugikan',
  'berugi',
  'ruhban',
  'ruhbanat',
  'ruhulkudus',
  'ruing',
  'ruit',
  'rujah',
  'ruji',
  'rujuk',
  'merujuk',
  'dirujuk',
  'rujukan',
  'rukam',
  'rukhsah',
  'ruku',
  'rukuk',
  'rukun',
  'merukunkan',
  'dirukunkan',
  'kerukunan',
  'rukyah',
  'rulus',
  'rum',
  'rumah',
  'berumah',
  'diserumahkan',
  'menyerumahkan',
  'rumahan',
  'perumahkan',
  'rumahtangga',
  'berumahtangga',
  'rumawi',
  'rumba',
  'rumbah',
  'rumbai',
  'rumbia',
  'rumbu',
  'rumbun',
  'merumbunkan',
  'rumbung',
  'rumi',
  'merumikan',
  'perumian',
  'rumia',
  'rumi',
  'rumit',
  'rumitkan',
  'kerumitan',
  'rumpai',
  'rumpun',
  'rumpunnya',
  'rumput',
  'merumput',
  'rumputan',
  'rerumputan',
  'rumus',
  'rumuskan',
  'rumusan',
  'runcing',
  'meruncing',
  'diruncing',
  'peruncing',
  'keruncingan',
  'runcit',
  'runcitnya',
  'peruncitan',
  'runding',
  'rundingkan',
  'rundingan',
  'runduk',
  'merundukkan',
  'rundung',
  'rungas',
  'runggas',
  'runggau',
  'rungguh',
  'rungguhan',
  'runggun',
  'runggu',
  'rungkai',
  'rungkap',
  'rungkas',
  'rungkau',
  'rungkuh',
  'kerungkuhan',
  'rungkup',
  'rungus',
  'rungut',
  'rungutnya',
  'rungutan',
  'runjam',
  'runjang',
  'runjau',
  'runjun',
  'runjung',
  'runsing',
  'runsingkan',
  'kerunsingan',
  'runsingnya',
  'runtai',
  'runtas',
  'runti',
  'runtih',
  'runtuh',
  'meruntuh',
  'diruntuh',
  'runtuhan',
  'runtun',
  'meruntun',
  'diruntun',
  'runtunan',
  'runut',
  'merunuti',
  'dirunuti',
  'runyas',
  'rupa',
  'merupa',
  'berupa',
  'serupa',
  'menyerupa',
  'dirupakan',
  'diserupakan',
  'serupaan',
  'rupal',
  'rupawan',
  'rupi',
  'rupiah',
  'merupiahkan',
  'rupuh',
  'rupung',
  'rurut',
  'rusa',
  'rusam',
  'rusih',
  'rusuh',
  'merusuh',
  'rusuhan',
  'dirusuhkan',
  'rusuk',
  'rutai',
  'rutbir',
  'rutin',
  'rutuk',
  'rutup',
  'ruwat',
  'meruwatkan',
  'keruwatan',
  'ruwin',
  'ruyung',
  'ruyup',
  'saat',
  'saatnya',
  'saba',
  'sabah',
  'sabak',
  'saban',
  'sabang',
  'sabar',
  'sabarlah',
  'sabarkan',
  'kesabaran',
  'sabasani',
  'sabatikal',
  'sabda',
  'sabdanya',
  'sabdakan',
  'sabdu',
  'sabi',
  'sabil',
  'sabit',
  'sabitkan',
  'sabitan',
  'sabitah',
  'sabotaj',
  'sabtu',
  'sabuk',
  'sabun',
  'menyabun',
  'sabung',
  'sabungan',
  'menyabung',
  'sabur',
  'menyabur',
  'sabut',
  'sad',
  'sadah',
  'sadai',
  'menyadaikan',
  'sadak',
  'sadang',
  'sading',
  'sadis',
  'sadap',
  'sadapan',
  'sadariah',
  'sadau',
  'saderi',
  'sado',
  'sadu',
  'sadung',
  'sadur',
  'saduran',
  'saf',
  'safan',
  'safa',
  'safar',
  'safari',
  'safih',
  'saga',
  'sagai',
  'sagang',
  'sagar',
  'sagat',
  'sagu',
  'saguhati',
  'saguir',
  'sagun',
  'sagur',
  'sah',
  'disah',
  'mengesah',
  'pengesah',
  'sahabat',
  'bersahabat',
  'persahabatan',
  'mempersahabatkan',
  'sahaja',
  'menyehajakan',
  'kesahajaan',
  'persahajaan',
  'sahajalah',
  'saham',
  'sesaham',
  'sahan',
  'sahang',
  'sahara',
  'saharah',
  'sahaya',
  'sahayanda',
  'sahi',
  'sahih',
  'kesahihan',
  'sahibulhikayat',
  'sahisahmura',
  'sahsiah',
  'sahur',
  'sahut',
  'sahutan',
  'bersahutan',
  'sahutnya',
  'sahuti',
  'saie',
  'sain',
  'saing',
  'saingi',
  'saingan',
  'bersaing',
  'sains',
  'saintifik',
  'saintis',
  'sair',
  'sais',
  'saiz',
  'saja',
  'sajak',
  'sajaknya',
  'menyajak',
  'persajakan',
  'sajang',
  'sajen',
  'saji',
  'sajikan',
  'sajian',
  'saka',
  'sakai',
  'sakai',
  'sakal',
  'sakan',
  'sakar',
  'sakarat',
  'sakat',
  'tersakat',
  'menyakatkan',
  'sake',
  'sakhawat',
  'sakhlat',
  'sakhrat',
  'saki',
  'sakit',
  'sakiti',
  'sakitkan',
  'penyakit',
  'kesakitan',
  'bersakit',
  'menyakit',
  'saksama',
  'saksi',
  'saksikan',
  'bersaksi',
  'kesaksian',
  'menyaksi',
  'penyaksi',
  'saksofon',
  'sakti',
  'kesakti',
  'menyaktikan',
  'saku',
  'menyakukan',
  'sakur',
  'sal',
  'sala',
  'salad',
  'salah',
  'bersalah',
  'disalah',
  'kesalahan',
  'menyalah',
  'tersalah',
  'persalahkan',
  'salahguna',
  'menyalahgunakan',
  'penyalahgunaan',
  'salaherti',
  'salahlaku',
  'salahanggap',
  'salahtafsir',
  'salai',
  'salaian',
  'salak',
  'salakan',
  'salam',
  'salamnya',
  'salambo',
  'salang',
  'salap',
  'salasilah',
  'salbi',
  'sali',
  'salib',
  'menyalibkan',
  'disalibkan',
  'salih',
  'salin',
  'salinkan',
  'salinan',
  'persalin',
  'saling',
  'salip',
  'salir',
  'saliran',
  'salji',
  'saljinya',
  'salju',
  'salmon',
  'saluk',
  'salun',
  'salung',
  'salur',
  'salurkan',
  'saluran',
  'salut',
  'salutan',
  'sama',
  'bersama',
  'menyama',
  'samakan',
  'dipersama',
  'mempersama',
  'kesamaan',
  'persamaan',
  'penyamaan',
  'kesaksamaan',
  'samanya',
  'samah',
  'samai',
  'samak',
  'penyamakan',
  'samampai',
  'saman',
  'samar',
  'samaran',
  'samara',
  'samar',
  'samas',
  'samba',
  'sambal',
  'sambalan',
  'sambalewa',
  'sambang',
  'sambar',
  'sambaran',
  'sambat',
  'sambatan',
  'sambau',
  'sambil',
  'sambilkan',
  'sambilan',
  'sambit',
  'sambuk',
  'sambung',
  'sambungan',
  'bersambung',
  'penyambung',
  'sambut',
  'bersambut',
  'sambutan',
  'sambutkan',
  'sambutlah',
  'menyambuti',
  'sami',
  'samin',
  'samir',
  'sampa',
  'sampah',
  'persampahan',
  'sampai',
  'sampaian',
  'tersampai',
  'sampailah',
  'sampak',
  'sampan',
  'sampannya',
  'sampang',
  'sampar',
  'sampat',
  'sampil',
  'samping',
  'menyamping',
  'bersamping',
  'kesampingkan',
  'sampir',
  'menyampirkan',
  'sampu',
  'sampuk',
  'menyampukkan',
  'tersampuk',
  'sampul',
  'sampun',
  'samsam',
  'samseng',
  'samsir',
  'samsu',
  'samudera',
  'samum',
  'samun',
  'samunan',
  'samurai',
  'sana',
  'kesanakan',
  'sanad',
  'sanak',
  'sandak',
  'sandal',
  'sandang',
  'penyandang',
  'mempersandang',
  'sandar',
  'menyandar',
  'sandaran',
  'sandarkan',
  'sandera',
  'sandi',
  'sanding',
  'sandingan',
  'sandingkan',
  'sandiwara',
  'sandiwarakan',
  'sando',
  'sandung',
  'sandwic',
  'sang',
  'sanga',
  'sangai',
  'sangat',
  'menyangat',
  'bersangat',
  'sangatkah',
  'kesangatan',
  'sangau',
  'sangga',
  'sanggah',
  'sanggahan',
  'sanggam',
  'sanggama',
  'sanggan',
  'sanggar',
  'sanggat',
  'sanggerah',
  'sanggeraha',
  'sanggit',
  'sanggul',
  'bersanggul',
  'sanggup',
  'menyanggup',
  'kesanggupan',
  'sanggurdi',
  'sangir',
  'sangka',
  'sangkaan',
  'sangkakan',
  'sangkanya',
  'sangkak',
  'sangkakala',
  'sangkal',
  'sangkalnya',
  'sangkalan',
  'sangkala',
  'sangkan',
  'sangkap',
  'sangkar',
  'sangkat',
  'sangkil',
  'sangku',
  'sangkur',
  'sangkut',
  'sangkutlah',
  'sangkutkan',
  'sangkutan',
  'sangluat',
  'sanglur',
  'sangsi',
  'kesangsian',
  'menyangsikan',
  'sangulun',
  'sangyang',
  'sanitasi',
  'sanjung',
  'nyanjung',
  'sanjungan',
  'sano',
  'sanskerta',
  'sanskrit',
  'santa',
  'santai',
  'santak',
  'santam',
  'santan',
  'santap',
  'santapan',
  'bersantap',
  'santau',
  'santun',
  'santunan',
  'menyantuni',
  'santung',
  'sanubar',
  'sanubari',
  'sanuk',
  'sanya',
  'sap',
  'sapa',
  'bersapa',
  'menyapa',
  'tersapa',
  'sapar',
  'sapersi',
  'sapi',
  'sapih',
  'sapta',
  'saptadarma',
  'saptamarga',
  'sapu',
  'sapukan',
  'sapuan',
  'sapunya',
  'sapung',
  'saput',
  'menyaputi',
  'sara',
  'sarai',
  'saraan',
  'saraf',
  'sarak',
  'saran',
  'saranan',
  'menyarani',
  'sarang',
  'sarangnya',
  'sarap',
  'sarapan',
  'sarat',
  'menyarat',
  'sarau',
  'sarawak',
  'sardin',
  'sarhad',
  'sari',
  'menyarikan',
  'sarik',
  'sarikan',
  'saring',
  'saringan',
  'sarira',
  'sarirah',
  'sarjan',
  'sarjana',
  'kesarjanaan',
  'sarkas',
  'saron',
  'sartan',
  'saru',
  'menyarukan',
  'penyaruan',
  'sarudung',
  'saruk',
  'sarung',
  'sarungkan',
  'sarungnya',
  'sarut',
  'sarwa',
  'sasa',
  'sasak',
  'sasap',
  'sasar',
  'sasarkan',
  'sasaran',
  'sastera',
  'sasterawan',
  'kesusasteraan',
  'sasul',
  'satah',
  'satang',
  'satar',
  'sate',
  'persatean',
  'satelit',
  'sateria',
  'satin',
  'satir',
  'satira',
  'satu',
  'satukan',
  'menyatu',
  'satukanlah',
  'satukah',
  'satuan',
  'berpersatuan',
  'bersatupadu',
  'menyatupadukan',
  'persatu',
  'satupun',
  'satwa',
  'sau',
  'saudagar',
  'saudara',
  'bersaudara',
  'persaudarakan',
  'persaudaraan',
  'saudari',
  'sauh',
  'saujana',
  'sauk',
  'saukkan',
  'saukkanlah',
  'saukan',
  'sauna',
  'saur',
  'saut',
  'savana',
  'sawa',
  'sawab',
  'sawah',
  'sawahnya',
  'persawahan',
  'sawai',
  'sawala',
  'sawan',
  'sawang',
  'sawangan',
  'sawar',
  'sawardi',
  'sawat',
  'berpesawat',
  'sawi',
  'sawi',
  'sawit',
  'sawo',
  'saya',
  'sayak',
  'sayang',
  'sayangkan',
  'sayangi',
  'disayang',
  'tersayang',
  'penyayang',
  'kesayangan',
  'bersayang',
  'sayap',
  'sayapnya',
  'sayat',
  'sayembara',
  'sayid',
  'sayidina',
  'sayu',
  'menyayukan',
  'kesayuan',
  'sayunya',
  'sayugia',
  'sayung',
  'sayup',
  'sayur',
  'sayuran',
  'mayur',
  'sebab',
  'sebabkan',
  'disebabkannya',
  'menyebabkannya',
  'sebabnya',
  'musabab',
  'sebai',
  'sebak',
  'sebaknya',
  'menyebakkan',
  'sebakang',
  'sebal',
  'kesebalan',
  'menyebalkan',
  'sebalai',
  'sebam',
  'seban',
  'sebantan',
  'sebar',
  'sebaran',
  'sebarkan',
  'sebarnya',
  'sebarang',
  'sebarau',
  'sebasah',
  'sebasuh',
  'sebat',
  'sebatan',
  'sebati',
  'sebatian',
  'sebekah',
  'sebelah',
  'sebeng',
  'sebentar',
  'seberang',
  'seberangi',
  'penyeberangan',
  'keseberangan',
  'seberetih',
  'seberhana',
  'sebiak',
  'sebih',
  'sebik',
  'sebit',
  'sebokoh',
  'sebokok',
  'sebu',
  'menyebukan',
  'sebuih',
  'sebuk',
  'sebun',
  'sebura',
  'seburas',
  'seburu',
  'seburus',
  'seburut',
  'sebusuk',
  'sebut',
  'sebutlah',
  'sebutan',
  'penyebut',
  'tersebut',
  'secerek',
  'secina',
  'sedah',
  'sedak',
  'kesedakan',
  'sedakang',
  'sedam',
  'sedan',
  'sedannya',
  'sedang',
  'sedap',
  'menyedapkan',
  'penyedap',
  'sesedap',
  'sedapan',
  'sedar',
  'sedari',
  'sedarkan',
  'sedarlah',
  'kesedaran',
  'sedat',
  'sedekah',
  'sedekahkan',
  'sedelinggam',
  'sederhana',
  'kesederhanaan',
  'menyederhana',
  'sedia',
  'bersedia',
  'tersedia',
  'penyedia',
  'menyedia',
  'sediakan',
  'sediakanlah',
  'sediaan',
  'sediakala',
  'sedih',
  'sedihkan',
  'sedihnya',
  'kesedihan',
  'sedik',
  'sedu',
  'sedua',
  'seduayah',
  'seduh',
  'seduhan',
  'sedut',
  'sedutan',
  'seismik',
  'seismologi',
  'sega',
  'segading',
  'segah',
  'segak',
  'menyegakkan',
  'segala',
  'segan',
  'segankan',
  'segani',
  'keseganan',
  'penyegan',
  'seganda',
  'segani',
  'segar',
  'segarkan',
  'menyegarkannya',
  'kesegaran',
  'segarnya',
  'segara',
  'segaruselessbugar',
  'segari',
  'segau',
  'segeli',
  'segenting',
  'segera',
  'segerakan',
  'segerang',
  'segi',
  'seginya',
  'segmen',
  'segmenkan',
  'segombang',
  'segondol',
  'segoreh',
  'seguih',
  'segumbar',
  'segunja',
  'seh',
  'seharah',
  'sejadah',
  'sejahtera',
  'kesejahteraan',
  'menyejahterakan',
  'sejak',
  'semenjak',
  'sejangat',
  'sejangkang',
  'sejarah',
  'mensejarahkan',
  'persejarahan',
  'pensejarahan',
  'sejarahnya',
  'sejarang',
  'sejat',
  'sejatkan',
  'sejatan',
  'sejenak',
  'sejerit',
  'sejingkat',
  'sejoli',
  'sejuk',
  'sejukkan',
  'sejukan',
  'sejuki',
  'penyejuk',
  'sesejuk',
  'sejumbuk',
  'sejuru',
  'seka',
  'diseka',
  'menyeka',
  'sekaca',
  'sekah',
  'sekam',
  'sekang',
  'sekap',
  'sekapan',
  'sekapar',
  'sekarang',
  'sekasap',
  'sekat',
  'sekatan',
  'menyekat',
  'sekaten',
  'sekatuk',
  'sekebah',
  'sekeber',
  'sekeduduk',
  'sekeduk',
  'sekedup',
  'sekeh',
  'sekejap',
  'sekelonet',
  'sekelumit',
  'sekembang',
  'sekencong',
  'sekendai',
  'sekendi',
  'sekentut',
  'sekepet',
  'sekerap',
  'sekeri',
  'seketeng',
  'sekijang',
  'sekiki',
  'sekin',
  'sekip',
  'sekobang',
  'sekoci',
  'sekoi',
  'sekolah',
  'sekolahkan',
  'bersekolah',
  'sekolahan',
  'sepersekolahan',
  'sekongkol',
  'sekongkolan',
  'sekonyong',
  'konyong',
  'sekopong',
  'sekotah',
  'sekoteng',
  'sekoyah',
  'sekoyak',
  'sekretariat',
  'seks',
  'seksa',
  'seksaan',
  'menyeksa',
  'seksofon',
  'seksologi',
  'seksual',
  'seksualiti',
  'seksyen',
  'sektor',
  'sekuang',
  'sekubin',
  'sekuit',
  'sekul',
  'sekular',
  'sekunder',
  'sekuriti',
  'sekuriti',
  'sekut',
  'sekutu',
  'sekutunya',
  'menyekutu',
  'persekutuan',
  'persekutukan',
  'sel',
  'sela',
  'menyela',
  'selabar',
  'selaberak',
  'selada',
  'seladang',
  'seladeri',
  'selaju',
  'selak',
  'selaknya',
  'selakkan',
  'selaka',
  'selalu',
  'menyelalukan',
  'diselalukan',
  'selam',
  'selami',
  'selamkan',
  'selamat',
  'selamatkan',
  'selamatan',
  'selamba',
  'selambut',
  'selampai',
  'menyelampaikan',
  'selampit',
  'berselampitkan',
  'menyelampitkan',
  'selancak',
  'selang',
  'selangkan',
  'selangi',
  'selangan',
  'selangat',
  'selangka',
  'selangkup',
  'selangor',
  'seli',
  'nyeli',
  'nyeling',
  'selanting',
  'selap',
  'keselapan',
  'selapanan',
  'selaput',
  'diselaput',
  'menyelaputkan',
  'menyelaputi',
  'selaputan',
  'selar',
  'selaran',
  'selara',
  'selarung',
  'selasa',
  'selasar',
  'selasih',
  'selat',
  'selatan',
  'selawat',
  'menyelawatkan',
  'selayah',
  'selayak',
  'selayang',
  'selayun',
  'selayur',
  'selayut',
  'selebar',
  'selebaran',
  'selebriti',
  'selebu',
  'seleguri',
  'selekeh',
  'berselekeh',
  'menyelekehi',
  'selekoh',
  'selekor',
  'seleler',
  'selemah',
  'selembada',
  'selembana',
  'selembat',
  'selembu',
  'selembubu',
  'selempada',
  'selempah',
  'selempang',
  'selempangkan',
  'selempuri',
  'selencur',
  'selendang',
  'berselendang',
  'menyelendangkan',
  'menyelendangi',
  'selendap',
  'selenggara',
  'selenggarakan',
  'selenggaraan',
  'selepi',
  'selepot',
  'selera',
  'menyelerakan',
  'selerak',
  'selerakkan',
  'selerakan',
  'selerang',
  'seleret',
  'selesa',
  'keselesaan',
  'penyelesa',
  'selesai',
  'selesaikan',
  'selesaian',
  'selesema',
  'seleweng',
  'penyeleweng',
  'seli',
  'selia',
  'seliaan',
  'seliap',
  'seliat',
  'selibah',
  'selibu',
  'selibut',
  'selicin',
  'selidik',
  'penyelidik',
  'selidiknya',
  'seligi',
  'seligit',
  'seligut',
  'selikur',
  'selimang',
  'selimbar',
  'selimpang',
  'selimpas',
  'selimpat',
  'selimut',
  'selimuti',
  'selimutnya',
  'selinap',
  'selinat',
  'selindung',
  'selindungkan',
  'seling',
  'selingan',
  'selingar',
  'selingkup',
  'selingsing',
  'selip',
  'menyelipkan',
  'selipar',
  'seliput',
  'menyeliputi',
  'selir',
  'selirat',
  'seliri',
  'selisa',
  'selisih',
  'perselisihan',
  'menyelisihkan',
  'selisik',
  'selisip',
  'selisir',
  'selit',
  'selitkan',
  'selitan',
  'seliuh',
  'seliwir',
  'selo',
  'selodok',
  'selofan',
  'seloka',
  'selokan',
  'selok',
  'selongkang',
  'selongkar',
  'penyelongkaran',
  'selongsong',
  'menyelongsongi',
  'selopong',
  'seloroh',
  'menyelorohkan',
  'seloyak',
  'seluang',
  'seluar',
  'berseluar',
  'selubat',
  'selubung',
  'selubungi',
  'selubungkan',
  'selubungnya',
  'seludang',
  'seludu',
  'seludup',
  'seludupan',
  'seludupi',
  'penyeludup',
  'seluk',
  'memperselukkan',
  'selukat',
  'selukung',
  'selular',
  'seluloid',
  'selulosa',
  'selulur',
  'selum',
  'selumar',
  'selumbar',
  'selumur',
  'seluncur',
  'selundat',
  'selungkap',
  'selungkup',
  'menyelungkupi',
  'selungkur',
  'selup',
  'selupan',
  'selupat',
  'selurai',
  'seluru',
  'seluruh',
  'menyeluruh',
  'keseluruhan',
  'selurut',
  'menyeluruti',
  'selusuh',
  'selusup',
  'selusur',
  'selut',
  'seluwit',
  'semadi',
  'semadikan',
  'persemadian',
  'semah',
  'semahan',
  'semahkan',
  'semai',
  'semaikan',
  'semaian',
  'semaja',
  'semak',
  'semakkan',
  'semakan',
  'semambu',
  'semampai',
  'semampang',
  'semampat',
  'seman',
  'kesemanan',
  'semandan',
  'semandarasa',
  'semandarasah',
  'semandera',
  'semang',
  'semang',
  'semangat',
  'bersemangat',
  'menyemangati',
  'semangga',
  'semanggang',
  'semanggi',
  'semangka',
  'semangsuk',
  'semantah',
  'semantan',
  'semantik',
  'semantung',
  'semar',
  'semarai',
  'semarak',
  'semarakkan',
  'semaram',
  'semauselesssema',
  'semat',
  'sematkan',
  'sematan',
  'sematauselessmata',
  'semawa',
  'semawar',
  'semayam',
  'disemayamkan',
  'menyemayamkan',
  'persemayaman',
  'sembah',
  'sembahkan',
  'sembahan',
  'persembah',
  'sembahlah',
  'sembahnya',
  'sembahyang',
  'bersembahyang',
  'sembahyangkan',
  'sembai',
  'sembak',
  'sembam',
  'menyembamkan',
  'sembang',
  'sembap',
  'sembarang',
  'menyembarangi',
  'sembari',
  'sembat',
  'sembawang',
  'sembelih',
  'sembelihan',
  'sembelit',
  'semberani',
  'semberap',
  'semberip',
  'semberono',
  'sembesi',
  'sembilan',
  'sembilu',
  'sembir',
  'menyembir',
  'semboyan',
  'bersemboyankan',
  'menyemboyankan',
  'sembuang',
  'sembuh',
  'sembuhkan',
  'sembuhan',
  'sembul',
  'menyembulkan',
  'sembung',
  'sembunyi',
  'sembunyikan',
  'sembunyian',
  'sembur',
  'semburkan',
  'semburan',
  'menyemburi',
  'semburlah',
  'semburit',
  'semedang',
  'sememeh',
  'semenanjung',
  'semenda',
  'semenggah',
  'semenjak',
  'sementang',
  'sementara',
  'sementaraan',
  'sementelah',
  'semerbak',
  'semesta',
  'semester',
  'semi',
  'semiak',
  'semiang',
  'semikonduktor',
  'semilang',
  'semilir',
  'semilu',
  'seminar',
  'seminaris',
  'seminau',
  'semir',
  'sempadan',
  'bersempadan',
  'persempadan',
  'menyempadani',
  'sempak',
  'sempal',
  'sempalai',
  'sempang',
  'sempat',
  'menyempat',
  'kesempatan',
  'sempelah',
  'sempelat',
  'sempena',
  'menyempenakan',
  'semperit',
  'semperung',
  'sempil',
  'semping',
  'sempit',
  'bersempit',
  'sempitkan',
  'persempit',
  'sempitan',
  'sempoa',
  'semprit',
  'semprot',
  'menyemprotkan',
  'semprotan',
  'sempul',
  'sempuras',
  'sempurna',
  'sempurnakan',
  'kesempurnaan',
  'penyempurnaan',
  'semput',
  'semu',
  'menyemu',
  'semua',
  'semuncup',
  'semut',
  'semutan',
  'sen',
  'sennya',
  'sena',
  'senai',
  'senak',
  'senakan',
  'menyenakkan',
  'senam',
  'senaman',
  'senamrobik',
  'senandung',
  'senang',
  'bersenang',
  'senangkan',
  'senangi',
  'kesenangan',
  'senangin',
  'senantiasa',
  'senapang',
  'senapangnya',
  'senapati',
  'senarai',
  'senarainya',
  'senaraikan',
  'senaraihitamkan',
  'disenaraihitam',
  'menyenaraihitam',
  'senaraipendek',
  'senaraian',
  'senario',
  'senat',
  'senator',
  'senda',
  'persenda',
  'mempersendakannya',
  'sendak',
  'sendal',
  'menyendalkan',
  'kesendalan',
  'sendalu',
  'sendam',
  'sendar',
  'sendat',
  'sendawa',
  'sendayan',
  'sendel',
  'sendeng',
  'sendengkan',
  'bersendengan',
  'sendi',
  'persendian',
  'sendiri',
  'sendirian',
  'sendirinya',
  'sendirikan',
  'sendirilah',
  'sendok',
  'sendorong',
  'sendu',
  'kesenduan',
  'senduduk',
  'senduk',
  'sendukkan',
  'senegal',
  'senerih',
  'sengah',
  'sengaja',
  'bersengaja',
  'kesengajaan',
  'sengal',
  'tersengal',
  'sengam',
  'sengap',
  'sengat',
  'sengatan',
  'sengau',
  'sengaukan',
  'sengauan',
  'sengelat',
  'senget',
  'sengetkan',
  'sengetnya',
  'senggak',
  'senggang',
  'menyenggangkan',
  'kesenggangan',
  'senggara',
  'penyenggara',
  'senggaraan',
  'senggat',
  'senggatan',
  'senggau',
  'senggayut',
  'senggerah',
  'sengget',
  'senggok',
  'senggol',
  'senggolan',
  'senggugut',
  'sengguk',
  'sengih',
  'sengit',
  'kesengitan',
  'sengkak',
  'sengkang',
  'sengkek',
  'sengketa',
  'persengketaan',
  'persengketakan',
  'sengkokol',
  'sengkuang',
  'sengsara',
  'kesengsaraan',
  'seni',
  'kesenian',
  'senibina',
  'seniman',
  'kesenimanan',
  'seniwati',
  'senior',
  'senja',
  'senjakala',
  'senjata',
  'bersenjata',
  'persenjataan',
  'senonoh',
  'sensasi',
  'sensasikan',
  'sensitif',
  'sensitiviti',
  'sensor',
  'sensual',
  'sentak',
  'sentaknya',
  'sentakkan',
  'sentakan',
  'sental',
  'sentalkan',
  'sentap',
  'disentap',
  'menyentap',
  'senteng',
  'sentengkan',
  'senter',
  'senteri',
  'sentiasa',
  'sentigram',
  'sentil',
  'sentilan',
  'sentiliter',
  'sentimen',
  'sentimental',
  'sentimeter',
  'sentosa',
  'bersentosa',
  'kesentosaan',
  'sentral',
  'sentralisasi',
  'sentri',
  'sentrum',
  'sentuh',
  'sentuhnya',
  'sentuhan',
  'sentuhannya',
  'sentuhlah',
  'sentung',
  'senyap',
  'senyaplah',
  'senyonyong',
  'senyum',
  'tersenyum',
  'senyuman',
  'sep',
  'sepadan',
  'sepadu',
  'disepadu',
  'sepadukan',
  'sepah',
  'sepahnya',
  'sepahkan',
  'sepai',
  'bersepai',
  'sepak',
  'sepakan',
  'sepana',
  'sepanduk',
  'sepang',
  'separa',
  'sepat',
  'sepatu',
  'sepatung',
  'sepeling',
  'sepenuh',
  'seperti',
  'sepertimana',
  'sepet',
  'sepetang',
  'sepi',
  'kesepian',
  'menyepikan',
  'sepina',
  'sepit',
  'sepitkan',
  'sepoi',
  'september',
  'sepuh',
  'sepuhan',
  'sepuk',
  'sepuluh',
  'seput',
  'sera',
  'serabut',
  'serah',
  'serahkan',
  'serahan',
  'serai',
  'serak',
  'serakkan',
  'serakan',
  'serakah',
  'keserakahan',
  'seram',
  'menyeramkan',
  'serama',
  'serambi',
  'seramik',
  'serampang',
  'serampangan',
  'seranah',
  'serandang',
  'seranduk',
  'serandung',
  'serang',
  'serangan',
  'serangga',
  'seranggung',
  'serani',
  'menyeranikan',
  'seranta',
  'serap',
  'serapkan',
  'serapan',
  'serapah',
  'serapat',
  'serapuh',
  'serat',
  'menyeratkan',
  'serati',
  'seraut',
  'serawa',
  'seraya',
  'menyerayakan',
  'serba',
  'serbi',
  'serbak',
  'menyerbakkan',
  'serban',
  'serbaguna',
  'serbaneka',
  'serbat',
  'serbet',
  'serbu',
  'serbuan',
  'serbuk',
  'serdak',
  'serdang',
  'serebelum',
  'serembab',
  'serembah',
  'seremban',
  'serempak',
  'serempet',
  'serendeng',
  'serentak',
  'serentang',
  'serenti',
  'seret',
  'seretkan',
  'sergah',
  'sergahan',
  'sergam',
  'sergap',
  'seri',
  'serikan',
  'seriau',
  'seribulan',
  'serigala',
  'serik',
  'serindit',
  'sering',
  'seringlah',
  'seringkali',
  'seringai',
  'menyeringaikan',
  'serit',
  'serius',
  'keseriusan',
  'serkap',
  'menyerkapi',
  'serkup',
  'serlah',
  'serlahkan',
  'serobot',
  'penyerobotan',
  'serodok',
  'seroja',
  'serok',
  'seroka',
  'serombong',
  'serondeng',
  'serondong',
  'serong',
  'keserongan',
  'seronok',
  'berseronok',
  'menyeronokkan',
  'keseronokan',
  'serot',
  'serpih',
  'serpihan',
  'serpuk',
  'serta',
  'sertai',
  'sertakan',
  'penyertaan',
  'sertu',
  'seru',
  'seruan',
  'penyeru',
  'seruak',
  'seruas',
  'seruling',
  'serum',
  'serunai',
  'serunding',
  'serut',
  'serutan',
  'serviks',
  'servis',
  'sesah',
  'sesak',
  'sesaklah',
  'sesaknya',
  'menyesakkan',
  'kesesakan',
  'sesal',
  'sesalan',
  'sesalanku',
  'sesali',
  'sesalkan',
  'sesap',
  'sesapan',
  'sesar',
  'sesat',
  'sesatkan',
  'kesesatan',
  'sesawi',
  'sesentuh',
  'sesi',
  'sesuai',
  'sesuaian',
  'sesuaikan',
  'sesuailah',
  'sesyen',
  'set',
  'setan',
  'setandang',
  'setang',
  'setat',
  'setawar',
  'setel',
  'setelah',
  'setem',
  'seterika',
  'seteru',
  'menyeterui',
  'perseteruan',
  'setia',
  'bersetia',
  'kesetiaan',
  'persetiaan',
  'setiakawan',
  'setiausaha',
  'kesetiausahaan',
  'setinggan',
  'setir',
  'setop',
  'setopan',
  'setu',
  'setui',
  'sewa',
  'sewanya',
  'sewakan',
  'sewaan',
  'shaf',
  'si',
  'sia',
  'siakan',
  'siah',
  'siak',
  'siakap',
  'sial',
  'sialan',
  'sialkan',
  'sialang',
  'sialit',
  'siam',
  'siamang',
  'siamit',
  'sianang',
  'siang',
  'disiang',
  'menyiang',
  'kesiangan',
  'sianggit',
  'siantan',
  'siap',
  'siapnya',
  'siapkan',
  'siapan',
  'siapa',
  'sesiapa',
  'siapuh',
  'siar',
  'siarkan',
  'siaran',
  'siarah',
  'siasah',
  'siasat',
  'siasatan',
  'siasati',
  'siasatnya',
  'siat',
  'siau',
  'sibar',
  'siber',
  'sibu',
  'sibuk',
  'sibukkan',
  'kesibukan',
  'sibur',
  'sida',
  'sidai',
  'bersidaian',
  'sidang',
  'persidangan',
  'sidi',
  'sidik',
  'siding',
  'sidratulmuntaha',
  'sifar',
  'sifat',
  'sifatkan',
  'bersifat',
  'sifatullah',
  'sifilis',
  'sifir',
  'sigai',
  'sigak',
  'sigando',
  'sigap',
  'sigar',
  'sigasir',
  'sigenting',
  'sigi',
  'signal',
  'signifikan',
  'siguh',
  'sigun',
  'sigung',
  'sihat',
  'sihatkan',
  'kesihatan',
  'sihir',
  'menyihir',
  'sijil',
  'persijilan',
  'sijundai',
  'sika',
  'sikah',
  'sikai',
  'sikak',
  'sikang',
  'sikap',
  'sikapnya',
  'sikari',
  'sikat',
  'sikedempung',
  'sikeduduk',
  'sikelambai',
  'sikeras',
  'sikik',
  'sikikih',
  'sikin',
  'sikit',
  'sikjut',
  'siklon',
  'siklostil',
  'siku',
  'sikudomba',
  'sila',
  'silakan',
  'bersila',
  'persilakan',
  'mempersilakannya',
  'silah',
  'silam',
  'silang',
  'silangkan',
  'silangan',
  'silap',
  'silapnya',
  'kesilapan',
  'silara',
  'silat',
  'persilatan',
  'silaturahim',
  'silau',
  'kesilauan',
  'menyilau',
  'tersilau',
  'silia',
  'silibus',
  'silih',
  'silika',
  'silikat',
  'silikon',
  'silinder',
  'siling',
  'silir',
  'silu',
  'menyilukan',
  'siluk',
  'siluman',
  'silungkang',
  'simbah',
  'simbahkan',
  'simbahnya',
  'simbahan',
  'simbai',
  'simbang',
  'simbar',
  'simbol',
  'disimbolkan',
  'simbolik',
  'simbukan',
  'simbur',
  'simen',
  'simetri',
  'simfoni',
  'simpai',
  'simpan',
  'simpankan',
  'bersimpan',
  'simpanan',
  'simpang',
  'simpangkan',
  'simpangan',
  'simpati',
  'simpatinya',
  'simpir',
  'simposium',
  'simptom',
  'simpuh',
  'simpuk',
  'simpul',
  'simpulkan',
  'simpulan',
  'simulasi',
  'simuntu',
  'sin',
  'sinaga',
  'sinai',
  'sinaps',
  'sinar',
  'sinari',
  'sinaran',
  'sinarkan',
  'sinau',
  'sinawar',
  'sindiket',
  'sindir',
  'sindiran',
  'tersindir',
  'sindrom',
  'sinematografi',
  'sinergi',
  'singa',
  'singapura',
  'singgah',
  'menyinggah',
  'disinggahi',
  'persinggahan',
  'singgahsana',
  'singgang',
  'singguh',
  'singgul',
  'singgung',
  'singir',
  'singkap',
  'singkapkan',
  'singkapan',
  'singkar',
  'singkat',
  'singkatkan',
  'mempersingkat',
  'singkatan',
  'singkil',
  'singkir',
  'singkirkan',
  'singkiri',
  'penyingkiran',
  'singkur',
  'singlet',
  'singse',
  'singsing',
  'singsingkan',
  'sini',
  'sinis',
  'sinisme',
  'sinki',
  'sinologi',
  'sinonim',
  'sinopsis',
  'sintaksis',
  'sintal',
  'sintar',
  'sintesis',
  'sinting',
  'sintua',
  'sintuk',
  'sintulang',
  'sinuhun',
  'sinus',
  'sinyo',
  'siongka',
  'sipahi',
  'sipai',
  'sipat',
  'sipi',
  'sipu',
  'sipuan',
  'sipulut',
  'siput',
  'sira',
  'sirah',
  'siram',
  'sirami',
  'siramkan',
  'siraman',
  'sirap',
  'menyirapkan',
  'sirat',
  'siraut',
  'siren',
  'siri',
  'sirih',
  'siring',
  'sirip',
  'sirkit',
  'sirna',
  'menyirnakan',
  'sisa',
  'sisanya',
  'sisal',
  'sisi',
  'sisih',
  'sisihkan',
  'penyisihan',
  'sisik',
  'sisiknya',
  'sisip',
  'sisipan',
  'disisip',
  'menyisip',
  'tersisip',
  'sisir',
  'kesisiran',
  'sista',
  'sistem',
  'mensistemkan',
  'sistematik',
  'sistematiknya',
  'siswa',
  'siswazah',
  'sita',
  'penyitaan',
  'sitak',
  'sitar',
  'sitegang',
  'siti',
  'sitih',
  'siting',
  'situ',
  'situasi',
  'siuk',
  'siul',
  'siulan',
  'siuman',
  'siung',
  'siur',
  'sivik',
  'sivil',
  'siwak',
  'bersiwak',
  'siya',
  'skala',
  'skandal',
  'skema',
  'sketsa',
  'ski',
  'skim',
  'skima',
  'skirt',
  'skolastik',
  'skop',
  'skor',
  'skornya',
  'skrin',
  'skrinnya',
  'skrip',
  'diskripkan',
  'skru',
  'skrukan',
  'skrum',
  'skuad',
  'skuadron',
  'skuasy',
  'skuba',
  'skuner',
  'skuter',
  'slaid',
  'slanga',
  'slip',
  'slogan',
  'slot',
  'smoking',
  'snek',
  'snekbar',
  'snorkeling',
  'snuker',
  'soak',
  'soal',
  'soalnya',
  'soalan',
  'persoalkan',
  'sobek',
  'sobok',
  'soda',
  'sodek',
  'sodok',
  'soek',
  'sofa',
  'bersofakan',
  'sofbol',
  'sofistikated',
  'soga',
  'sogang',
  'sogok',
  'sogokan',
  'sogokkan',
  'sohor',
  'soip',
  'soja',
  'sojah',
  'sok',
  'soka',
  'sokak',
  'soket',
  'sokoguru',
  'sokom',
  'sokong',
  'sokongan',
  'sokonglah',
  'solak',
  'solat',
  'solatnya',
  'solek',
  'solekan',
  'solo',
  'solok',
  'solstis',
  'sombok',
  'sombol',
  'sombong',
  'kesombongan',
  'menyombongkan',
  'someng',
  'sompek',
  'sompeng',
  'sompoh',
  'sompok',
  'sompong',
  'sondai',
  'sondol',
  'sondong',
  'sonet',
  'songel',
  'songgeng',
  'songket',
  'songkok',
  'songsang',
  'songsangkan',
  'songsangan',
  'songsong',
  'sonor',
  'sontak',
  'sontek',
  'sontoloyo',
  'sopak',
  'sopan',
  'kesopanan',
  'soprano',
  'sorak',
  'sorakan',
  'sorai',
  'sore',
  'sorek',
  'soren',
  'sorjan',
  'sorok',
  'sorokkan',
  'sorokan',
  'sorong',
  'sorongan',
  'sorongkan',
  'sorot',
  'soroti',
  'sorotan',
  'sos',
  'soseh',
  'sosej',
  'sosial',
  'sosialnya',
  'kesosialan',
  'sosialis',
  'sosio',
  'sosiobudaya',
  'sosioekonomi',
  'sosiologi',
  'sosoh',
  'soto',
  'sotong',
  'sotor',
  'soya',
  'soyak',
  'spa',
  'span',
  'speaker',
  'sped',
  'spektrometer',
  'spektroskop',
  'spektrum',
  'spekulasi',
  'spekulatif',
  'sperma',
  'spesies',
  'spesifik',
  'spesifikasi',
  'spesimen',
  'spesis',
  'spirit',
  'spontan',
  'kespontanan',
  'spora',
  'spring',
  'sputnik',
  'sri',
  'srikandi',
  'stabil',
  'stabilkan',
  'penstabil',
  'kestabilan',
  'stabilisasi',
  'stabiliti',
  'stadium',
  'staf',
  'stail',
  'stamina',
  'standard',
  'standardkan',
  'standardisasi',
  'stanza',
  'stapler',
  'statik',
  'statistik',
  'stator',
  'status',
  'berstatus',
  'sten',
  'stensil',
  'stensilan',
  'stereng',
  'stereo',
  'stereofonik',
  'stereoskop',
  'stereotaip',
  'steril',
  'sterling',
  'steroid',
  'stesen',
  'stetoskop',
  'stigma',
  'stim',
  'stimulasi',
  'stok',
  'stoking',
  'stoma',
  'ston',
  'stor',
  'storan',
  'menstorkan',
  'strategi',
  'strategik',
  'stratosfera',
  'straw',
  'strawberi',
  'strontium',
  'struktur',
  'strukturkan',
  'strukturnya',
  'penstrukturan',
  'studio',
  'sua',
  'suakan',
  'persuakan',
  'suah',
  'suai',
  'suak',
  'suaka',
  'suam',
  'suami',
  'bersuami',
  'mempersuami',
  'suang',
  'suangi',
  'suap',
  'suapkan',
  'suapan',
  'menyuapi',
  'suar',
  'suara',
  'suaranya',
  'suarakan',
  'penyuaraan',
  'suarang',
  'suari',
  'suasa',
  'suasana',
  'suatu',
  'sub',
  'subahat',
  'subak',
  'subal',
  'subam',
  'suban',
  'subang',
  'subbidang',
  'subfamili',
  'subgenus',
  'subgolongan',
  'subjek',
  'subjektif',
  'subkelas',
  'subkontraktor',
  'subseksyen',
  'subsidi',
  'subsidiari',
  'subsistem',
  'subspesies',
  'substandard',
  'subuh',
  'subur',
  'suburnya',
  'suburkan',
  'kesuburan',
  'penyuburan',
  'subversif',
  'suci',
  'sucinya',
  'sucikan',
  'kesucian',
  'penyucian',
  'suda',
  'sudah',
  'sudahlah',
  'sudahi',
  'sudahkan',
  'kesudahan',
  'berkesudahan',
  'penyudah',
  'sudera',
  'sudi',
  'kesudian',
  'sudip',
  'sudu',
  'suduayah',
  'sudung',
  'sudut',
  'suf',
  'sufal',
  'sufi',
  'sugar',
  'sugi',
  'suguh',
  'sugul',
  'sugun',
  'suh',
  'suhad',
  'suhian',
  'suhu',
  'suhunya',
  'suhuf',
  'suhun',
  'suis',
  'sujana',
  'sujen',
  'suji',
  'sujud',
  'suka',
  'sukaan',
  'sukai',
  'bersuka',
  'sesuka',
  'sukacita',
  'bersukacita',
  'menyukacitakan',
  'sukamandi',
  'sukan',
  'bersukan',
  'kesukanan',
  'sukar',
  'sukarkan',
  'kesukaran',
  'sukarela',
  'sukarelawan',
  'kesukarelawan',
  'sukarelawati',
  'sukaria',
  'sukat',
  'sukatan',
  'sukduf',
  'sukma',
  'sukses',
  'suku',
  'sukuan',
  'sukun',
  'sula',
  'sulaan',
  'sulah',
  'sulam',
  'sulaman',
  'sulami',
  'sulamkan',
  'sulang',
  'menyulang',
  'sulap',
  'sulapan',
  'sulat',
  'salit',
  'sulfat',
  'sulfida',
  'sulfit',
  'sulfur',
  'suling',
  'sulingan',
  'sulit',
  'kesulitan',
  'sulitkan',
  'suloi',
  'sultan',
  'kesultanan',
  'sultanah',
  'suluh',
  'suluhkan',
  'suluhan',
  'menyuluhi',
  'suluk',
  'sulung',
  'sulur',
  'suluran',
  'sum',
  'sumbang',
  'sumbangan',
  'sumbangkan',
  'sumbangnya',
  'sumbangsih',
  'sumbat',
  'sumbatkan',
  'sumber',
  'bersumber',
  'sumbi',
  'sumbing',
  'sumbu',
  'menyumbui',
  'sumbuk',
  'sumengit',
  'sumin',
  'sumir',
  'sumpah',
  'menyumpah',
  'sumpahan',
  'sumpal',
  'sumpit',
  'sumpitan',
  'sumpu',
  'sumpuh',
  'sumsum',
  'sumur',
  'sumurung',
  'sun',
  'sunah',
  'sunai',
  'sunam',
  'sunan',
  'sunat',
  'sunatkan',
  'sunbulat',
  'sundai',
  'sundak',
  'sundal',
  'persundalan',
  'sundang',
  'sundari',
  'sundusi',
  'sundusin',
  'sundut',
  'sungai',
  'sungga',
  'sungging',
  'sungguh',
  'sesungguhnya',
  'kesungguhan',
  'menyungguh',
  'sungguhpun',
  'sungkai',
  'sungkal',
  'sungkap',
  'sungkawa',
  'sungkit',
  'sungkuk',
  'sungkum',
  'sungkup',
  'menyungkup',
  'sungkur',
  'sungkuran',
  'sungul',
  'sungut',
  'sungutan',
  'disungutkan',
  'sunjam',
  'suntang',
  'sunti',
  'suntih',
  'suntik',
  'suntikan',
  'suntikkan',
  'sunting',
  'suntingan',
  'penyunting',
  'suntuk',
  'kesuntukan',
  'sunyi',
  'kesunyian',
  'sunyinya',
  'sup',
  'supaya',
  'superhero',
  'superintenden',
  'superlatif',
  'supersonik',
  'supir',
  'suplemen',
  'surah',
  'surai',
  'suraikan',
  'bersurai',
  'suralaya',
  'suraloka',
  'suram',
  'kesuraman',
  'surat',
  'suratan',
  'suratkan',
  'surati',
  'suratkhabar',
  'surau',
  'suraya',
  'surgeri',
  'suri',
  'suria',
  'surian',
  'surih',
  'surihkan',
  'surin',
  'suruh',
  'suruhan',
  'suruhnya',
  'suruhkan',
  'suruhanjaya',
  'surut',
  'surutkan',
  'suryakanta',
  'susah',
  'susahkan',
  'bersusah',
  'kesusahan',
  'susastera',
  'susila',
  'kesusilaan',
  'suspek',
  'suspen',
  'suspensi',
  'susu',
  'susukan',
  'susui',
  'susuan',
  'susuh',
  'susuk',
  'susul',
  'susulan',
  'menyusulkan',
  'susun',
  'susunan',
  'susunkan',
  'susunnya',
  'susup',
  'menyusup',
  'susupan',
  'susur',
  'susuri',
  'susuran',
  'susut',
  'menyusut',
  'disusut',
  'susutan',
  'sut',
  'sutan',
  'suten',
  'sutera',
  'sutradara',
  'sutuh',
  'suun',
  'suwir',
  'swadaya',
  'swades',
  'swapraja',
  'swasambada',
  'swasta',
  'swastakan',
  'penswastaan',
  'swatantra',
  'syaaban',
  'syabas',
  'syafaat',
  'syafak',
  'syah',
  'syahadan',
  'syahadat',
  'syahbandar',
  'syahdu',
  'syahid',
  'syahmat',
  'syahmura',
  'syahod',
  'syahwat',
  'syair',
  'penyair',
  'bersyair',
  'syairkan',
  'syaitan',
  'syajar',
  'syak',
  'disyaki',
  'mengesyak',
  'syal',
  'syala',
  'syampu',
  'syamsiah',
  'syarah',
  'bersyarah',
  'syarahan',
  'syarak',
  'syarat',
  'syaratkan',
  'syaratnya',
  'syariah',
  'syariat',
  'syariatnya',
  'syarif',
  'syarifah',
  'syarikat',
  'syarikatnya',
  'mensyarikat',
  'persyarikatan',
  'syaulam',
  'syawal',
  'syekh',
  'syelek',
  'syiah',
  'syiar',
  'syiling',
  'syin',
  'syirik',
  'mensyirikkan',
  'syogun',
  'syok',
  'syor',
  'syorkan',
  'mengesyorkan',
  'syuhada',
  'syukur',
  'syukuri',
  'kesyukuran',
  'syumul',
  'kesyumulan',
  'syurga',
  'syurgaloka',
  'syuruk',
  'ta',
  'taajal',
  'taakul',
  'penaakulan',
  'taala',
  'taasub',
  'taat',
  'taati',
  'ketaatan',
  'tabah',
  'bertabah',
  'tabahkan',
  'tabahkanlah',
  'ketabahan',
  'tabak',
  'tabal',
  'tabalkan',
  'pertabalan',
  'penabalan',
  'taban',
  'tabaruk',
  'tabayun',
  'tabela',
  'tabernakel',
  'tabia',
  'tabiat',
  'bertabiat',
  'tabib',
  'ketabiban',
  'tabii',
  'tabik',
  'tabika',
  'tabir',
  'menabiri',
  'tabla',
  'tablet',
  'tabligh',
  'tablighnya',
  'mentablighkan',
  'tablo',
  'tabo',
  'tabrak',
  'tabrakan',
  'tabu',
  'tabuh',
  'tabuhan',
  'tabun',
  'tabung',
  'tabungan',
  'tabungkan',
  'tabur',
  'taburan',
  'taburi',
  'penabur',
  'tertabur',
  'tabut',
  'taci',
  'tadah',
  'tadahan',
  'tadahkan',
  'tadarus',
  'tadbir',
  'tadbirkan',
  'tadbiran',
  'tadi',
  'tadika',
  'tadung',
  'taekwondo',
  'taf',
  'tafahus',
  'tafakur',
  'mentafakurkan',
  'tafsir',
  'tafsirkan',
  'tafsiran',
  'tafsirnya',
  'tagak',
  'tagan',
  'tagar',
  'tagih',
  'tagihan',
  'tahajud',
  'tahalul',
  'tahan',
  'tahanan',
  'tahani',
  'tahankan',
  'pertahan',
  'tertahan',
  'tahanlah',
  'tahana',
  'tahang',
  'tahap',
  'tahar',
  'tahayul',
  'tahbis',
  'tahfifah',
  'tahfiz',
  'tahi',
  'tahil',
  'tahir',
  'tahiyat',
  'tahkik',
  'tahkikkan',
  'tahkim',
  'tahlil',
  'tahmid',
  'tahniah',
  'tahrif',
  'tahrifkan',
  'tahsil',
  'tahu',
  'setahu',
  'bertahu',
  'menahu',
  'ketahui',
  'ketahuilah',
  'ketahuan',
  'pengetahuan',
  'berpengetahuan',
  'tahun',
  'tahunnya',
  'tahunan',
  'taifah',
  'taijin',
  'taiko',
  'taip',
  'taipkan',
  'taipnya',
  'taipusam',
  'tairu',
  'tais',
  'taiso',
  'taitut',
  'taja',
  'tajaan',
  'tajak',
  'penajakan',
  'tajalli',
  'tajam',
  'setajam',
  'menajam',
  'ditajamkan',
  'ketajaman',
  'pertajam',
  'tajau',
  'taji',
  'tajin',
  'taju',
  'tajuk',
  'tajuknya',
  'pertajuk',
  'tajung',
  'tajun',
  'tajur',
  'tajwid',
  'tak',
  'takaful',
  'takah',
  'takak',
  'takal',
  'takang',
  'takar',
  'takaran',
  'takarir',
  'takat',
  'takau',
  'takbir',
  'takbiran',
  'mentakbirkan',
  'takbur',
  'ketakburan',
  'takdim',
  'takdir',
  'takdirkan',
  'ketakdiran',
  'takdis',
  'takeyari',
  'takhlik',
  'takhta',
  'ditakhtakan',
  'mentakhtakan',
  'taki',
  'takik',
  'takjub',
  'menakjubkan',
  'ketakjuban',
  'taklid',
  'taklif',
  'taklikat',
  'taklimat',
  'takluk',
  'menakluk',
  'ditakluk',
  'taklukan',
  'takma',
  'takraw',
  'takrif',
  'pentakrif',
  'takrifan',
  'takrim',
  'takrir',
  'taksir',
  'taksirkan',
  'taksiran',
  'taksis',
  'taksub',
  'taktik',
  'takuh',
  'takuk',
  'takung',
  'takungan',
  'takup',
  'takur',
  'takut',
  'takuti',
  'takutkan',
  'ketakutan',
  'menakut',
  'nakutkan',
  'takuwih',
  'takwa',
  'ketakwaan',
  'takwil',
  'takwim',
  'takziah',
  'takzim',
  'takzir',
  'tal',
  'tala',
  'talanya',
  'talak',
  'talaknya',
  'talam',
  'talan',
  'talang',
  'talar',
  'talas',
  'talbiah',
  'tali',
  'talian',
  'pertali',
  'talinya',
  'talibun',
  'taling',
  'temali',
  'talkin',
  'talkinkan',
  'talkum',
  'talu',
  'talun',
  'taluuselesstalu',
  'tamadun',
  'tamadunnya',
  'ketamadunan',
  'tamak',
  'ketamakan',
  'tamakninah',
  'tamam',
  'taman',
  'tamar',
  'tamat',
  'tamatkan',
  'penamat',
  'tamatan',
  'tambah',
  'tambahkan',
  'tambahan',
  'tambahlah',
  'tambak',
  'penambakan',
  'tambal',
  'tamban',
  'tambang',
  'tambangan',
  'tambangnya',
  'menambangkan',
  'tambar',
  'tambat',
  'ditambat',
  'tambatan',
  'tambatannya',
  'tambatkan',
  'tambera',
  'tambi',
  'tambo',
  'tamborin',
  'tambuh',
  'tambul',
  'tambun',
  'tambung',
  'tambur',
  'tamil',
  'taming',
  'tampa',
  'tampak',
  'tampaknya',
  'menampak',
  'tampal',
  'tampalan',
  'tampalkan',
  'tampan',
  'tampannya',
  'ketampanan',
  'tampang',
  'tampar',
  'tamparan',
  'tampas',
  'tampi',
  'tampik',
  'tampil',
  'tampilkan',
  'penampilan',
  'tampillah',
  'tampin',
  'tamping',
  'tampoi',
  'tamponek',
  'tampu',
  'tampuk',
  'tampuknya',
  'tampung',
  'tampungkan',
  'tampungan',
  'tampus',
  'tamsil',
  'tamtam',
  'tamtama',
  'tamu',
  'tamunya',
  'tamuk',
  'tamyiz',
  'tan',
  'tan',
  'tanah',
  'bertanah',
  'tanih',
  'tanak',
  'tanaknya',
  'tanam',
  'tanaman',
  'tanamkan',
  'tanami',
  'tanang',
  'tanau',
  'tanbiat',
  'tancap',
  'tancapkan',
  'tanda',
  'tandanya',
  'tandakan',
  'tandai',
  'tandaan',
  'tandatangan',
  'tandatangannya',
  'bertandatangan',
  'penandatangan',
  'tandak',
  'tandan',
  'tandang',
  'tandas',
  'tandau',
  'tandik',
  'tandikat',
  'tandil',
  'tanding',
  'tandingnya',
  'tandingi',
  'tandingan',
  'tandingkan',
  'pertandingkan',
  'tandu',
  'bertandu',
  'tanduan',
  'tanduk',
  'bertanduk',
  'tandukan',
  'tandun',
  'tandur',
  'tandus',
  'ketandusan',
  'tanfiziah',
  'tangan',
  'tangani',
  'tangankan',
  'tangannya',
  'tangas',
  'penangasan',
  'tangen',
  'tangga',
  'tanggah',
  'tanggal',
  'tanggalkan',
  'tanggalan',
  'menanggali',
  'tanggam',
  'tanggang',
  'tanggap',
  'ditanggapi',
  'menanggap',
  'tanggapan',
  'tanggo',
  'tangguh',
  'tangguhkan',
  'tangguhan',
  'tangguhlah',
  'tangguk',
  'tanggul',
  'tanggung',
  'tanggungkan',
  'tanggungan',
  'tanggungjawab',
  'tanggungjawabnya',
  'pertanggungjawabkan',
  'pertanggungjawapan',
  'tangis',
  'tangisan',
  'tangisi',
  'tangiskan',
  'tangkai',
  'tangkal',
  'tangkap',
  'tangkapan',
  'bertangkap',
  'menangkap',
  'tangkas',
  'ketangkasan',
  'tangki',
  'tangkil',
  'tangkis',
  'tangkisan',
  'ditangkis',
  'menangkis',
  'tangkoi',
  'tangkul',
  'tangkung',
  'tangkup',
  'tangkupkan',
  'tangkuuselesstangku',
  'tanglin',
  'tanglung',
  'tangsa',
  'tangsi',
  'tani',
  'pertanian',
  'tanin',
  'tanjak',
  'tanjakkan',
  'tanjakan',
  'tanji',
  'tanjidur',
  'tanju',
  'tanjul',
  'tanjung',
  'tanjur',
  'tanpa',
  'tantah',
  'tantalum',
  'tantang',
  'tantangan',
  'tante',
  'tanti',
  'tanur',
  'tanwin',
  'tanya',
  'tanyakan',
  'tanyalah',
  'pertanyaan',
  'tanzil',
  'tapa',
  'pertapaan',
  'tapai',
  'tapaian',
  'tapak',
  'tapaknya',
  'tapakan',
  'setapak',
  'tapal',
  'tapang',
  'tapelak',
  'tapi',
  'tapih',
  'tapin',
  'tapir',
  'tapis',
  'tapisan',
  'taptibau',
  'tapuh',
  'tapuk',
  'tapung',
  'tapus',
  'tar',
  'tara',
  'taranya',
  'kesetaraan',
  'persetaraan',
  'taraf',
  'tarafnya',
  'setarafkan',
  'penarafan',
  'tarah',
  'tarahan',
  'tarak',
  'taram',
  'taran',
  'tarang',
  'tarasul',
  'tarawih',
  'tarbiah',
  'mentarbiahkan',
  'tarbil',
  'tarekat',
  'tarhim',
  'tari',
  'tarian',
  'menarikan',
  'taridra',
  'tarif',
  'tarik',
  'tariklah',
  'tariknya',
  'tarikan',
  'tarikh',
  'tarikhnya',
  'taring',
  'taris',
  'tarkas',
  'tartir',
  'taruh',
  'bertaruh',
  'taruhan',
  'pertaruhkan',
  'taruk',
  'tarum',
  'tarung',
  'tarungan',
  'pertarungkan',
  'tas',
  'tasai',
  'tasak',
  'tasalsul',
  'tasamuh',
  'tasawuf',
  'tasbih',
  'mentasbihkan',
  'tasdik',
  'tasik',
  'taslim',
  'tasrif',
  'mentasrifkan',
  'taswir',
  'tasyahud',
  'tasydid',
  'tasyhid',
  'tasyrih',
  'tasyrik',
  'tat',
  'tata',
  'penataan',
  'tataacara',
  'tataadab',
  'tataayat',
  'tatabahasa',
  'tatabuku',
  'tatacahaya',
  'tatacara',
  'tataetika',
  'tatah',
  'bertatahkan',
  'tatahukum',
  'tatai',
  'tatakalimat',
  'tatakata',
  'tatakrama',
  'tatal',
  'tatalaku',
  'tatanama',
  'tatanegara',
  'ketatanegaraan',
  'tatang',
  'tataniaga',
  'tatap',
  'tatapan',
  'tatapraja',
  'tatar',
  'tatarakyat',
  'tatasuria',
  'tatasusila',
  'tatatertib',
  'tatausaha',
  'tatera',
  'tatih',
  'tating',
  'tatkala',
  'tatu',
  'tau',
  'taubat',
  'bertaubat',
  'tauci',
  'taucu',
  'taufah',
  'taufan',
  'taufik',
  'tauge',
  'tauhid',
  'mentauhidkan',
  'tauhidiah',
  'tauhu',
  'tauke',
  'taukua',
  'taul',
  'taulan',
  'tauliah',
  'tauliahkan',
  'pentauliahan',
  'taun',
  'taurat',
  'taung',
  'menaungi',
  'taup',
  'taut',
  'bertaut',
  'tautkan',
  'pertautkan',
  'pertautan',
  'tawa',
  'ketawakan',
  'tertawakan',
  'tawaduk',
  'tawaf',
  'tawafuk',
  'tawajuh',
  'tawak',
  'tawakal',
  'tawan',
  'tawanlah',
  'tawanan',
  'tawang',
  'tawar',
  'penawar',
  'bertawar',
  'ditawar',
  'menawar',
  'tawaran',
  'tawarikh',
  'tawaruk',
  'tawas',
  'tawasul',
  'tayamum',
  'tayang',
  'tayangkan',
  'tayangan',
  'tayar',
  'tayum',
  'teater',
  'tebah',
  'tebak',
  'tebal',
  'tebalkan',
  'tebalnya',
  'teban',
  'tebanan',
  'tebang',
  'tebangan',
  'tebar',
  'tebarkan',
  'tebaran',
  'bertebaran',
  'mempertebar',
  'tebas',
  'tebasan',
  'tebaskan',
  'tebat',
  'tebatan',
  'tebeng',
  'tebengau',
  'tebera',
  'teberau',
  'tebing',
  'tebingnya',
  'tebok',
  'tebu',
  'tebukan',
  'tebuan',
  'tebuk',
  'tebukan',
  'tebus',
  'tebusan',
  'tedarus',
  'tedas',
  'tedeng',
  'teduh',
  'diteduhi',
  'meneduh',
  'keteduhan',
  'tedung',
  'tega',
  'tegadas',
  'tegah',
  'menegahkan',
  'tegahan',
  'tegak',
  'tegakkan',
  'penegakan',
  'tegal',
  'tegang',
  'tegangkan',
  'tegangan',
  'tegap',
  'menegapkan',
  'ketegapan',
  'tegar',
  'menegarkan',
  'ketegaran',
  'tegari',
  'tegarun',
  'tegas',
  'tegasnya',
  'tegaskan',
  'ketegasan',
  'tegasan',
  'teguh',
  'teguhnya',
  'teguhkan',
  'perteguhkan',
  'keteguhan',
  'peneguhan',
  'teguk',
  'seteguk',
  'tegun',
  'tegur',
  'tegurnya',
  'menegur',
  'teguran',
  'tegurannya',
  'teh',
  'tehran',
  'teisme',
  'teja',
  'teji',
  'teka',
  'tekanya',
  'tekaan',
  'tekad',
  'tekadnya',
  'tekah',
  'tekak',
  'tekaknya',
  'tekam',
  'tekan',
  'tekankan',
  'tekanan',
  'tekang',
  'tekap',
  'tekapan',
  'tekapkan',
  'tekat',
  'tekatan',
  'teker',
  'teki',
  'tekis',
  'teknik',
  'teknikal',
  'teknologi',
  'teknologinya',
  'teko',
  'tekong',
  'teks',
  'teksi',
  'tekstil',
  'tekstur',
  'tektonik',
  'teku',
  'tekua',
  'tekuk',
  'tekukan',
  'tekukur',
  'tekun',
  'tekunnya',
  'ketekunan',
  'tekung',
  'tekup',
  'tekur',
  'menekur',
  'ketekuran',
  'tela',
  'telaah',
  'telabang',
  'telabat',
  'teladan',
  'teladani',
  'telaga',
  'telagah',
  'pertelagahan',
  'telah',
  'menelahkan',
  'telahan',
  'telan',
  'telanlah',
  'telancang',
  'telancung',
  'telang',
  'telangkai',
  'telangkup',
  'telangkupkan',
  'telanjang',
  'telanjangi',
  'telanjangkan',
  'telanjur',
  'ketelanjuran',
  'telap',
  'telapak',
  'telas',
  'telatah',
  'telatap',
  'telau',
  'teledek',
  'teledera',
  'teledor',
  'telefon',
  'bertelefon',
  'telefonis',
  'telefoto',
  'telefotografi',
  'telegraf',
  'telegram',
  'telek',
  'telekan',
  'telekap',
  'telekom',
  'telekomunikasi',
  'teleks',
  'teleku',
  'telekung',
  'telekup',
  'telempap',
  'telempong',
  'teleng',
  'menelengkan',
  'telengkoh',
  'telenovela',
  'telentang',
  'telentangkan',
  'telepati',
  'telepok',
  'teleprinter',
  'telepuk',
  'telerang',
  'teleskop',
  'teleskopik',
  'telesukan',
  'tele',
  'televisyen',
  'telimpuh',
  'telinga',
  'telingkah',
  'pertelingkahan',
  'telingkung',
  'teliti',
  'ketelitian',
  'penelitian',
  'telor',
  'keteloran',
  'telotak',
  'teluk',
  'teluki',
  'telungkup',
  'telungkupkan',
  'telunjuk',
  'telur',
  'menelur',
  'telus',
  'ketelusan',
  'telut',
  'telutut',
  'tema',
  'bertema',
  'temabur',
  'temak',
  'temalang',
  'teman',
  'temani',
  'temankan',
  'berteman',
  'temandang',
  'temangau',
  'temara',
  'temas',
  'temasya',
  'tembadau',
  'tembaga',
  'tembak',
  'tembakan',
  'tembakang',
  'tembakau',
  'tembakul',
  'tembam',
  'tembang',
  'tembangau',
  'tembasah',
  'tembatar',
  'tembatu',
  'tembek',
  'tembel',
  'tembelah',
  'tembelang',
  'tembelian',
  'tembeliung',
  'tembelok',
  'tembera',
  'temberak',
  'temberam',
  'temberang',
  'menemberang',
  'temberek',
  'tembereng',
  'temberih',
  'tembikai',
  'tembikar',
  'tembilang',
  'tembilar',
  'tembis',
  'tembok',
  'tembokor',
  'tembolok',
  'tembosa',
  'tembuk',
  'tembuku',
  'tembung',
  'pertembungan',
  'tembuni',
  'tembus',
  'tembusi',
  'tembusan',
  'tembusu',
  'temelung',
  'temengalan',
  'temenggang',
  'temenggung',
  'temenung',
  'temesar',
  'temiang',
  'temilang',
  'temilung',
  'temin',
  'tempa',
  'tempaan',
  'tempah',
  'tempahkan',
  'tempahan',
  'tempang',
  'ketempangan',
  'tempap',
  'tempat',
  'tempatnya',
  'tempatan',
  'tempatkan',
  'tempatku',
  'tempatmu',
  'menempati',
  'tempaus',
  'tempawak',
  'tempawan',
  'tempayak',
  'tempayan',
  'tempayung',
  'tempe',
  'tempel',
  'menempel',
  'ditempel',
  'tempelan',
  'tempelak',
  'tempelas',
  'tempeleng',
  'temperas',
  'temperau',
  'tempiar',
  'bertempiaran',
  'tempias',
  'tempiasnya',
  'tempik',
  'tempikan',
  'tempilai',
  'tempinah',
  'tempinis',
  'tempo',
  'tempoh',
  'tempoi',
  'tempolong',
  'temponek',
  'tempong',
  'temporok',
  'tempoyak',
  'tempoyan',
  'tempua',
  'tempuh',
  'tempuhi',
  'menempuhkan',
  'ketempuhan',
  'tempuling',
  'tempunai',
  'tempunik',
  'tempur',
  'pertempuran',
  'tempurung',
  'tempuyung',
  'temu',
  'temukan',
  'temui',
  'pertemukan',
  'pertemuan',
  'penemuan',
  'temubual',
  'temuduga',
  'temucut',
  'temukut',
  'temuramah',
  'temuras',
  'temurun',
  'temut',
  'tenaga',
  'tenahak',
  'tenak',
  'tenam',
  'tenang',
  'tenangkan',
  'ketenangan',
  'penenang',
  'tenar',
  'tenat',
  'tenda',
  'tendang',
  'bertendang',
  'tendangan',
  'tendas',
  'tender',
  'tendon',
  'teng',
  'tengadah',
  'tengah',
  'setengah',
  'tengahnya',
  'pertengahan',
  'ketengahkan',
  'tengalan',
  'tengar',
  'tengas',
  'tenggak',
  'tenggala',
  'tenggalung',
  'tenggang',
  'tenggangkan',
  'pertenggangkan',
  'tenggara',
  'tenggarung',
  'tenggat',
  'tenggayung',
  'tenggek',
  'tenggekkan',
  'tenggekan',
  'tenggelam',
  'tenggelamkan',
  'ditenggelami',
  'penenggelaman',
  'tengger',
  'tenggik',
  'tenggiling',
  'tenggiri',
  'tenggorok',
  'tengguli',
  'tengik',
  'tengkalak',
  'tengkalang',
  'tengkaluk',
  'tengkam',
  'tengkang',
  'tengkar',
  'bertengkaran',
  'pertengkaran',
  'tengkaras',
  'tengkawang',
  'tengkek',
  'tengkel',
  'tengkerong',
  'tengkes',
  'tengking',
  'tengkingan',
  'tengkoh',
  'tengkolok',
  'tengkorak',
  'tengku',
  'tengku',
  'tengkujuh',
  'tengkuk',
  'tengkurap',
  'tengkuyung',
  'tengok',
  'tengokkan',
  'tengoklah',
  'tengteng',
  'tengung',
  'tenik',
  'tenis',
  'tenjak',
  'tenjet',
  'tenor',
  'tentang',
  'setentang',
  'tentangan',
  'menentang',
  'ditentang',
  'tentangnya',
  'tentawan',
  'tentayu',
  'tentera',
  'ketenteraan',
  'tenteram',
  'tenteramkan',
  'ketenteraman',
  'tentu',
  'tentukan',
  'bertentukan',
  'ketentuan',
  'ketentuannya',
  'penentuan',
  'tentulah',
  'tentunya',
  'tentulang',
  'tenturan',
  'tenuk',
  'tenun',
  'tenunan',
  'tenung',
  'tenungkan',
  'tenungan',
  'tenusu',
  'tenyeh',
  'teodolit',
  'teokrasi',
  'teologi',
  'teorem',
  'teori',
  'berteori',
  'teosofi',
  'tepak',
  'tepam',
  'tepang',
  'tepas',
  'tepat',
  'tepatan',
  'tepati',
  'tepatnya',
  'menepatkan',
  'tepaut',
  'tepeh',
  'tepek',
  'tepekkan',
  'teper',
  'tepet',
  'tepi',
  'bertepi',
  'ketepikan',
  'mengenepikan',
  'tepian',
  'tepik',
  'tepis',
  'tepiskan',
  'tepok',
  'tepu',
  'menepukan',
  'ketepuan',
  'tepuk',
  'tepuknya',
  'tepukan',
  'tepung',
  'tepur',
  'tepus',
  'tera',
  'terakan',
  'teraan',
  'teracak',
  'terada',
  'teradang',
  'terajam',
  'terajang',
  'teraju',
  'terajui',
  'terak',
  'terakup',
  'teral',
  'terala',
  'teraling',
  'teran',
  'teranas',
  'terang',
  'terangi',
  'terangkan',
  'terangan',
  'terangnya',
  'terangkik',
  'terantai',
  'terap',
  'terapkan',
  'terapan',
  'penerapan',
  'terapai',
  'terapi',
  'teras',
  'terasi',
  'terasul',
  'teratai',
  'teratak',
  'teratu',
  'terau',
  'terawang',
  'terazo',
  'terbak',
  'terban',
  'terbang',
  'terbangkan',
  'penerbangan',
  'berterbangan',
  'terbis',
  'terbit',
  'terbitnya',
  'terbitlah',
  'terbitkan',
  'terbitan',
  'terbul',
  'terbus',
  'terbut',
  'teremal',
  'terembesi',
  'terenang',
  'terendak',
  'terengganu',
  'terentang',
  'teres',
  'teri',
  'teriak',
  'teriakkan',
  'teriang',
  'teriba',
  'terigu',
  'terik',
  'teriko',
  'terilin',
  'terima',
  'terimanya',
  'penerimaan',
  'terimalah',
  'menerima',
  'terimba',
  'teringkit',
  'teripang',
  'terista',
  'teriti',
  'teritik',
  'teritip',
  'terjah',
  'terjahan',
  'terjal',
  'terjang',
  'terjemah',
  'terjemahkan',
  'terjemahan',
  'terjun',
  'terjunan',
  'menerjunkan',
  'menerjuni',
  'terka',
  'terkam',
  'terkaman',
  'terkap',
  'terkerlap',
  'terkul',
  'terkup',
  'terlak',
  'terlinak',
  'terma',
  'terminal',
  'termograf',
  'termometer',
  'termoplastik',
  'termos',
  'termostat',
  'ternak',
  'ternakan',
  'menternakkan',
  'terobos',
  'terok',
  'teroka',
  'terokai',
  'penerokaan',
  'teromasa',
  'teromba',
  'teromol',
  'terompah',
  'terongko',
  'teronok',
  'teropong',
  'terowong',
  'terpa',
  'terpaan',
  'terpal',
  'terpas',
  'terperongo',
  'terpersil',
  'tertiari',
  'tertib',
  'ketertiban',
  'menertibkan',
  'teruah',
  'terubin',
  'terubuk',
  'terubul',
  'terubung',
  'teruk',
  'seteruk',
  'terum',
  'terumbu',
  'terumbuh',
  'teruna',
  'terung',
  'teruntum',
  'terup',
  'terus',
  'teruskan',
  'terusan',
  'teruslah',
  'seterusnya',
  'terusi',
  'terutup',
  'terwelu',
  'tesak',
  'tesis',
  'tesmak',
  'testimonial',
  'testis',
  'testosteron',
  'tetabu',
  'tetaguk',
  'tetak',
  'tetakan',
  'tetal',
  'tetambak',
  'tetampan',
  'tetamu',
  'tetangga',
  'tetanus',
  'tetap',
  'tetapkan',
  'tetapan',
  'penetap',
  'tetapi',
  'tetar',
  'tetarap',
  'tetarik',
  'tetas',
  'menetaskan',
  'tetawak',
  'tetebeng',
  'teteguk',
  'tetek',
  'menetekkan',
  'tetemas',
  'tetepung',
  'teterapan',
  'teterapang',
  'tetes',
  'tetesan',
  'tetibar',
  'tetibau',
  'tetikus',
  'tetiruk',
  'tetopi',
  'tetuan',
  'tetuang',
  'tetuban',
  'tetubuh',
  'tetuhu',
  'tetumpang',
  'tetwelu',
  'tewas',
  'tewaskan',
  'ketewasan',
  'teyan',
  'thailand',
  'tiada',
  'tiadakan',
  'ketiadaan',
  'tiah',
  'tiak',
  'tiam',
  'tian',
  'tiang',
  'tiap',
  'tiara',
  'tiarap',
  'tiarapkan',
  'tias',
  'tib',
  'tiba',
  'ketibaan',
  'tibalah',
  'tibi',
  'tidak',
  'setidak',
  'menidakkan',
  'tidur',
  'tertidur',
  'tidurkan',
  'tiduri',
  'ketiduran',
  'tifa',
  'tifoid',
  'tifus',
  'tiga',
  'tiganya',
  'ketiga',
  'pertiga',
  'tikai',
  'pertikai',
  'pertikaian',
  'mempertikaikannya',
  'dipertikaikannya',
  'tikal',
  'tikam',
  'ditikam',
  'menikam',
  'tikaman',
  'tikamkan',
  'tikar',
  'tikas',
  'tika',
  'tiket',
  'tikpi',
  'tiku',
  'tikus',
  'tilam',
  'bertilam',
  'tilan',
  'tilapia',
  'tilawah',
  'tilawat',
  'tilik',
  'tilikan',
  'tim',
  'timah',
  'timang',
  'timangan',
  'timarah',
  'timba',
  'timbal',
  'timbalan',
  'timbalannya',
  'timbang',
  'timbangnya',
  'timbangan',
  'timbangkan',
  'pertimbangkan',
  'timbangtara',
  'timbau',
  'timbuk',
  'timbul',
  'timbulkan',
  'menimbul',
  'bertimbulan',
  'timbun',
  'timbunan',
  'menimbun',
  'ditimbun',
  'timbus',
  'menimbus',
  'timbusan',
  'timpa',
  'timpanya',
  'timpakan',
  'timpaan',
  'menimpaku',
  'timpal',
  'setimpal',
  'timpanum',
  'timpas',
  'timpuh',
  'timpus',
  'timun',
  'timur',
  'ketimuran',
  'timur',
  'timuuselesstimu',
  'tin',
  'tinak',
  'tindak',
  'bertindak',
  'tindakan',
  'menindakbalaskan',
  'penindakbalasan',
  'tindan',
  'ditindan',
  'menindan',
  'tindas',
  'tindasan',
  'tindih',
  'tindihan',
  'tindihannya',
  'bertindih',
  'tindik',
  'tindis',
  'tingau',
  'tinggal',
  'tinggalkan',
  'meninggalkan',
  'tinggalan',
  'ketinggalan',
  'sepeninggal',
  'tinggallah',
  'tinggam',
  'tinggang',
  'tinggi',
  'meninggi',
  'tertinggi',
  'setinggi',
  'tinggikan',
  'ketinggian',
  'tinggung',
  'tingi',
  'tingkah',
  'tingkahan',
  'meningkahi',
  'tingkal',
  'tingkalak',
  'tingkap',
  'tingkapnya',
  'tingkapan',
  'tingkas',
  'tingkat',
  'tingkatan',
  'tingkatkan',
  'tingkatnya',
  'tingkil',
  'tingkir',
  'tingkis',
  'tingting',
  'tinjak',
  'tinjau',
  'tinjauan',
  'ninjau',
  'tinju',
  'pertinjuan',
  'tinta',
  'tinting',
  'tintir',
  'tip',
  'tipai',
  'tipikal',
  'tipis',
  'menipis',
  'tipografi',
  'tipu',
  'menipu',
  'tertipu',
  'tipuan',
  'tipus',
  'tir',
  'tirah',
  'tirai',
  'bertirai',
  'tirak',
  'tirakat',
  'tiram',
  'tiras',
  'tiri',
  'dianaktirikan',
  'menganaktirikan',
  'tiris',
  'tirjup',
  'tirkah',
  'tiroid',
  'tiru',
  'tiruan',
  'tiruk',
  'tirus',
  'penirusan',
  'tisik',
  'tisu',
  'titah',
  'dititahkan',
  'menitahkan',
  'titanit',
  'titanium',
  'titar',
  'titi',
  'titian',
  'titik',
  'titikkan',
  'titikan',
  'titikberat',
  'titinada',
  'titip',
  'titipan',
  'titir',
  'titis',
  'titiskan',
  'titisan',
  'tiub',
  'tiung',
  'tiup',
  'tiupkan',
  'tiupan',
  'toaha',
  'tobak',
  'tobji',
  'tobros',
  'tocang',
  'tocangkan',
  'todak',
  'todi',
  'toga',
  'togak',
  'togan',
  'togel',
  'togelkan',
  'togok',
  'toh',
  'tohmah',
  'tohok',
  'tohor',
  'tojen',
  'tojok',
  'tok',
  'tokak',
  'tokcan',
  'tokek',
  'toko',
  'tokoh',
  'menokoh',
  'ketokohan',
  'tokohnya',
  'tokok',
  'tokokan',
  'tokong',
  'toksik',
  'ketoksikan',
  'toksikologi',
  'toksin',
  'toktok',
  'tol',
  'tolak',
  'tolakan',
  'tolakkan',
  'tolaknya',
  'tolap',
  'toleh',
  'menolehkan',
  'pertolehan',
  'toleransi',
  'toleransinya',
  'tolok',
  'tolol',
  'ketololan',
  'tolong',
  'tolongan',
  'tolongnya',
  'tolonglah',
  'tom',
  'toman',
  'tomato',
  'tombak',
  'tombakan',
  'tombok',
  'tombol',
  'tombola',
  'tombong',
  'tomong',
  'tompok',
  'tompokan',
  'ton',
  'tona',
  'tonanya',
  'tonceng',
  'tong',
  'tongnya',
  'tonggak',
  'tonggek',
  'menonggekkan',
  'tonggeng',
  'tonggengkan',
  'tonggok',
  'tonggong',
  'tonggos',
  'tongkah',
  'tongkan',
  'tongkang',
  'tongkat',
  'menongkatkan',
  'tongkeng',
  'tongkok',
  'tongkol',
  'tongkong',
  'tongsan',
  'tongset',
  'tongtong',
  'tonik',
  'tonil',
  'tonjeng',
  'tonjol',
  'tonjolkan',
  'tonjolan',
  'tonsil',
  'tonton',
  'tontonan',
  'tontonkan',
  'tontonlah',
  'pertontonkan',
  'tonyah',
  'tonyoh',
  'menonyohkan',
  'top',
  'topak',
  'topang',
  'topdal',
  'topek',
  'topekong',
  'topeng',
  'bertopeng',
  'topes',
  'topi',
  'topinya',
  'topik',
  'topiknya',
  'topografi',
  'topologi',
  'topong',
  'torak',
  'toreh',
  'torehan',
  'torek',
  'torium',
  'torpedo',
  'tose',
  'total',
  'totalitarian',
  'totaliti',
  'totem',
  'totet',
  'toti',
  'totok',
  'toya',
  'toyah',
  'toyak',
  'toyoh',
  'toyol',
  'tradisi',
  'tradisinya',
  'tradisional',
  'trafik',
  'tragedi',
  'tragik',
  'tragis',
  'trai',
  'trailer',
  'trak',
  'traktor',
  'trampil',
  'ketrampilan',
  'ketrampilannya',
  'transaksi',
  'transformasi',
  'transformasikan',
  'transistor',
  'transisi',
  'transit',
  'transitif',
  'transkrip',
  'transkripsi',
  'transliterasi',
  'transmigrasi',
  'transmisi',
  'trapezium',
  'trauma',
  'traumatik',
  'trek',
  'treker',
  'treking',
  'treler',
  'trem',
  'tren',
  'trengkas',
  'triatlon',
  'tribrata',
  'tribunal',
  'trigon',
  'trigonometri',
  'trilion',
  'trim',
  'trimatra',
  'trimurti',
  'triniti',
  'trio',
  'tripleks',
  'triprasetia',
  'trisaksi',
  'triwangsa',
  'triwindu',
  'triwulan',
  'trofi',
  'troli',
  'trombon',
  'trompet',
  'tropika',
  'tsunami',
  'tu',
  'tua',
  'tuanya',
  'ketua',
  'tuaan',
  'tuaku',
  'tuamu',
  'menuakan',
  'tuah',
  'tuai',
  'tuaian',
  'tuak',
  'tual',
  'tuala',
  'tualang',
  'pertualang',
  'tuam',
  'tuamkan',
  'tuan',
  'bertuan',
  'tuanan',
  'pertuan',
  'tuan',
  'tuang',
  'tuangan',
  'bertuang',
  'tuanku',
  'tuanku',
  'tuap',
  'tuar',
  'tuarang',
  'tuas',
  'tuasah',
  'tuat',
  'tuau',
  'tuba',
  'tubagus',
  'tuban',
  'tubi',
  'tubin',
  'tubing',
  'tubir',
  'tubruk',
  'tubrukan',
  'tubuh',
  'tubuhkan',
  'tubuhnya',
  'pertubuhan',
  'penubuhan',
  'setubuh',
  'setubuhi',
  'persetubuhan',
  'tubul',
  'tuding',
  'tuduh',
  'tuduhan',
  'pertuduhan',
  'tuduhkan',
  'tudung',
  'tudungkan',
  'tudungnya',
  'tugal',
  'tugar',
  'tugas',
  'tugaskan',
  'tugasnya',
  'tugasan',
  'tugasku',
  'tugasmu',
  'tugu',
  'tuhan',
  'bertuhan',
  'ketuhanan',
  'tuhan',
  'tuhfah',
  'tuhmah',
  'tui',
  'tuil',
  'tuisyen',
  'tuit',
  'tujah',
  'tuji',
  'tuju',
  'tujui',
  'tujuan',
  'tujuannya',
  'tujukan',
  'setuju',
  'setujui',
  'setujukan',
  'persetujuan',
  'tujuh',
  'tujuhnya',
  'tukal',
  'tukam',
  'tukang',
  'menukangi',
  'pertukangan',
  'tukar',
  'tukarnya',
  'bertukar',
  'tukaran',
  'tukarkan',
  'tukas',
  'tukik',
  'tukil',
  'tukul',
  'tukun',
  'tukus',
  'tul',
  'tulah',
  'ketulahan',
  'tulang',
  'bertulang',
  'menulangi',
  'tulangan',
  'tular',
  'menular',
  'tularan',
  'tulat',
  'tulen',
  'ketulenan',
  'tuli',
  'menulikan',
  'tulis',
  'tulisnya',
  'tuliskan',
  'tulisan',
  'bertulisan',
  'tulur',
  'tulus',
  'tuluskan',
  'ketulusan',
  'tum',
  'tuma',
  'tumang',
  'ditumangkan',
  'tumbang',
  'tumbangkan',
  'tumbesar',
  'tumbit',
  'tumbuh',
  'tumbuhan',
  'tumbuhkan',
  'ditumbuhi',
  'tumbuk',
  'bertumbuk',
  'tumbukan',
  'tumbukkan',
  'tumbung',
  'tumis',
  'tumiskan',
  'tumit',
  'tumpah',
  'tumpahkan',
  'tumpahan',
  'tumpak',
  'tumpakan',
  'tumpang',
  'tumpangnya',
  'tumpangkan',
  'tumpangi',
  'ditumpanginya',
  'menumpanginya',
  'tumpangan',
  'tumpas',
  'tumpat',
  'ketumpatan',
  'tumpil',
  'tumpu',
  'tumpuan',
  'tumpukan',
  'setumpu',
  'tumpul',
  'tumpulkan',
  'ketumpulan',
  'tumpur',
  'tumu',
  'tumus',
  'tun',
  'tuna',
  'tunaaksara',
  'tunabusana',
  'tunai',
  'tunaikan',
  'tunak',
  'tunakarya',
  'tunam',
  'tunanetra',
  'tunang',
  'tunangnya',
  'tunangan',
  'tunangkan',
  'tunas',
  'tunasusila',
  'tunawicara',
  'tunawisma',
  'tunda',
  'tundanya',
  'tundakan',
  'penundaan',
  'tundra',
  'tunduk',
  'tundukkan',
  'penundukan',
  'tundun',
  'tundung',
  'tungau',
  'tunggak',
  'tunggakan',
  'tunggal',
  'menunggalkan',
  'tungganai',
  'tunggang',
  'menunggang',
  'penunggang',
  'ditunggang',
  'tertunggang',
  'mempertunggang',
  'langgang',
  'tunggit',
  'tunggu',
  'menunggu',
  'tunggunya',
  'penungguan',
  'tunggul',
  'tungkahan',
  'tungkai',
  'tungkap',
  'tungku',
  'tungkul',
  'tungkum',
  'tungkup',
  'tungkus',
  'tungkusan',
  'tungsten',
  'tunjal',
  'tunjam',
  'tunjang',
  'tunjangan',
  'bertunjang',
  'tunjuk',
  'tunjukkan',
  'pertunjukan',
  'tunjuknya',
  'tunjung',
  'tunku',
  'tuntas',
  'tuntun',
  'tuntunan',
  'tuntung',
  'tuntut',
  'tuntutan',
  'tunu',
  'tupai',
  'turang',
  'turap',
  'turas',
  'turasan',
  'turbin',
  'turbo',
  'turi',
  'turpentin',
  'turun',
  'turunkan',
  'turuni',
  'menuruninya',
  'dituruninya',
  'turunan',
  'berketurunan',
  'memperturun',
  'turus',
  'turut',
  'turutan',
  'turutkan',
  'turuti',
  'diturutinya',
  'menurut',
  'tusam',
  'tusuk',
  'tusukan',
  'tusukkan',
  'tut',
  'tutor',
  'tutorial',
  'tutuh',
  'tutuk',
  'tutul',
  'tutup',
  'tutupkan',
  'tutupi',
  'tutupan',
  'penutupannya',
  'tutupnya',
  'tutuplah',
  'tutur',
  'tuturkan',
  'tuturan',
  'tuwu',
  'uak',
  'uap',
  'uar',
  'uarkan',
  'uba',
  'ubah',
  'mengubah',
  'ubahan',
  'ubahlah',
  'ubahsuai',
  'ubahsuaian',
  'ubai',
  'uban',
  'ubang',
  'ubar',
  'ubat',
  'terubat',
  'ubatkan',
  'ubati',
  'ubatan',
  'ubi',
  'ubin',
  'ubit',
  'ububan',
  'ubun',
  'ubur',
  'ucap',
  'ucapkan',
  'ucapan',
  'ucaptama',
  'ucis',
  'ucul',
  'uda',
  'udang',
  'udani',
  'udap',
  'udar',
  'udara',
  'pengudaraan',
  'mengudarakan',
  'udat',
  'udik',
  'udu',
  'uduh',
  'udul',
  'udut',
  'ufti',
  'ufuk',
  'ugahari',
  'ugam',
  'ugama',
  'ugut',
  'ugutnya',
  'ugutan',
  'uik',
  'uir',
  'uis',
  'uit',
  'uja',
  'ujana',
  'ujang',
  'ujar',
  'mengujarkan',
  'uji',
  'diujinya',
  'mengujinya',
  'teruji',
  'ujian',
  'ujiannya',
  'ujibakat',
  'ujikaji',
  'ujud',
  'ukas',
  'ukelele',
  'ukik',
  'ukir',
  'diukirnya',
  'mengukirnya',
  'ukiran',
  'ukirannya',
  'ukirkan',
  'berukir',
  'uktab',
  'ukup',
  'ukupan',
  'ukur',
  'ukuran',
  'ukurannya',
  'ulah',
  'ulak',
  'ulam',
  'berulan',
  'ulama',
  'ulan',
  'ulang',
  'ulangi',
  'ulangnya',
  'ulangkan',
  'ulangan',
  'ulangkaji',
  'ulap',
  'ular',
  'ulas',
  'ulasan',
  'ulasannya',
  'ulat',
  'ula',
  'uli',
  'ulik',
  'uling',
  'ulir',
  'ulit',
  'mengulit',
  'ulser',
  'ultra',
  'ultralembayung',
  'ultramoden',
  'ultrasonik',
  'ultruisme',
  'ulun',
  'ulung',
  'keulungan',
  'ulup',
  'uman',
  'umang',
  'umapodi',
  'umat',
  'umbai',
  'umbalan',
  'umbang',
  'umbar',
  'umbi',
  'umbinya',
  'umbra',
  'umbuk',
  'umbun',
  'umbut',
  'ummah',
  'umpak',
  'umpama',
  'umpamanya',
  'umpamakan',
  'umpamaan',
  'umpan',
  'umpankan',
  'umpanan',
  'umpat',
  'umpatan',
  'umpatannya',
  'umpil',
  'umpuk',
  'umpukkan',
  'umrah',
  'umu',
  'umum',
  'umumkan',
  'pengumuman',
  'seumumnya',
  'umun',
  'umur',
  'umurnya',
  'umut',
  'unam',
  'uncak',
  'uncang',
  'undak',
  'undan',
  'undang',
  'mengundangnya',
  'undangan',
  'undangannya',
  'undi',
  'undian',
  'undiannya',
  'undil',
  'unduk',
  'undung',
  'undur',
  'undurkan',
  'pengunduran',
  'ungah',
  'ungam',
  'ungar',
  'unggang',
  'unggas',
  'unggat',
  'unggit',
  'unggis',
  'unggul',
  'ungguli',
  'keunggulan',
  'unggun',
  'menunggunkan',
  'unggunan',
  'unggut',
  'ungka',
  'ungkai',
  'ungkak',
  'ungkal',
  'ungkang',
  'ungkap',
  'ungkapan',
  'ungkapkan',
  'ungkau',
  'ungkil',
  'ungkit',
  'ungku',
  'ungsi',
  'ungti',
  'ungu',
  'unguh',
  'uniform',
  'unik',
  'keunikan',
  'unilateral',
  'uniseks',
  'unit',
  'unitnya',
  'universal',
  'universiti',
  'unjam',
  'unjap',
  'unjuk',
  'unjukkan',
  'unjukan',
  'unjun',
  'unjur',
  'unjurkan',
  'unjuran',
  'unjukkan',
  'unjut',
  'unsat',
  'unsur',
  'unsurnya',
  'unta',
  'untai',
  'untaian',
  'untal',
  'untang',
  'until',
  'unting',
  'untuk',
  'peruntuk',
  'peruntukan',
  'untung',
  'keuntungan',
  'untungkan',
  'keberuntungan',
  'peruntungan',
  'untut',
  'unyai',
  'upacara',
  'upaduta',
  'upah',
  'upahkan',
  'upahan',
  'diupahi',
  'mengupahi',
  'upak',
  'upam',
  'upan',
  'upar',
  'upas',
  'upat',
  'upaya',
  'keupayaan',
  'keupayaannya',
  'upayanya',
  'mengupayakan',
  'upiah',
  'upih',
  'upik',
  'ura',
  'urakan',
  'urai',
  'uraikan',
  'uraian',
  'uraninit',
  'uranium',
  'uranus',
  'urap',
  'urapkan',
  'urapan',
  'uras',
  'menguras',
  'urat',
  'urea',
  'uri',
  'uring',
  'urinoeter',
  'uruk',
  'urung',
  'urup',
  'urupan',
  'urus',
  'diurusnya',
  'mengurusnya',
  'uruskan',
  'menguruskannya',
  'diuruskannya',
  'urusan',
  'berurus',
  'urusetia',
  'urusniaga',
  'urut',
  'urutkan',
  'urutan',
  'usah',
  'usaha',
  'usahakan',
  'perusahaan',
  'pengusaha',
  'usahawan',
  'keusahawanan',
  'usahasama',
  'usai',
  'mengusai',
  'usak',
  'usali',
  'usam',
  'usang',
  'keusangan',
  'usap',
  'diusap',
  'mengusap',
  'usar',
  'usia',
  'berusia',
  'seusia',
  'usik',
  'usiknya',
  'usikan',
  'usir',
  'diusirnya',
  'mengusirnya',
  'usiran',
  'usirkan',
  'uskup',
  'usta',
  'ustaz',
  'ustazah',
  'usul',
  'usulkan',
  'usulnya',
  'pengusulan',
  'usuluddin',
  'usung',
  'diusungnya',
  'mengusungnya',
  'usungan',
  'berusung',
  'usur',
  'usus',
  'usut',
  'utama',
  'utamakan',
  'keutamaan',
  'pengutamaan',
  'utara',
  'utarakan',
  'pengutaraan',
  'utarid',
  'utar',
  'utas',
  'uterus',
  'utih',
  'utilitarian',
  'utiliti',
  'utit',
  'utopia',
  'utuh',
  'mengutuhkan',
  'keutuhan',
  'utus',
  'utusan',
  'utuskan',
  'uzlah',
  'uzur',
  'menguzurkan',
  'keuzuran',
  'vagina',
  'vaksin',
  'vakum',
  'memvakum',
  'valensi',
  'validasi',
  'valkani',
  'van',
  'vandalisme',
  'vanila',
  'varia',
  'variasi',
  'varnis',
  'memvarnis',
  'vas',
  'vaselin',
  'vaskular',
  'vegitarian',
  'vektor',
  'vendor',
  'ventilator',
  'vernakular',
  'vernier',
  'versi',
  'vertebra',
  'vertebrata',
  'veteran',
  'veterinar',
  'veto',
  'memveto',
  'via',
  'video',
  'videofon',
  'videokonferens',
  'vietnam',
  'vila',
  'vilus',
  'violet',
  'virgo',
  'virus',
  'visa',
  'visi',
  'visual',
  'visualisasi',
  'vitamin',
  'viva',
  'vivarium',
  'vivid',
  'vogue',
  'vokal',
  'vokalis',
  'vokasional',
  'volt',
  'voltameter',
  'voltan',
  'vulva',
  'wa',
  'waad',
  'waadah',
  'wabak',
  'wacana',
  'wad',
  'wadah',
  'berwadahkan',
  'wadi',
  'wadih',
  'waduk',
  'wafak',
  'wafat',
  'wah',
  'wahadah',
  'wahah',
  'wahai',
  'waham',
  'wahana',
  'wahi',
  'mewahikan',
  'wahid',
  'wahyu',
  'wahyukan',
  'wai',
  'waima',
  'wain',
  'waisya',
  'waja',
  'wajah',
  'berwajah',
  'wajar',
  'sewajar',
  'kewajaran',
  'berwajaran',
  'wajib',
  'berwajib',
  'sewajibnya',
  'wajibkan',
  'kewajipan',
  'berkewajipan',
  'wajik',
  'wak',
  'wakaf',
  'wakafnya',
  'wakafkan',
  'wakil',
  'wakilnya',
  'wakili',
  'diwakilinya',
  'mewakilinya',
  'wakilkan',
  'mewakilkannya',
  'perwakilan',
  'waktu',
  'waktunya',
  'wakwak',
  'walafiat',
  'walakin',
  'walang',
  'walau',
  'walaupun',
  'walhal',
  'walhasil',
  'wali',
  'walikan',
  'perwalian',
  'walimah',
  'walimana',
  'walimatulurus',
  'walkie',
  'talkie',
  'wallah',
  'wallahi',
  'wallahuaklam',
  'walnat',
  'waltz',
  'wan',
  'wanara',
  'wang',
  'berwang',
  'kewangan',
  'wangi',
  'mewangi',
  'pewangi',
  'wangian',
  'wangka',
  'wangkang',
  'wangsa',
  'wanita',
  'kewanitaan',
  'wantan',
  'wap',
  'wapnya',
  'wapkan',
  'mengewap',
  'pengewapan',
  'warak',
  'waran',
  'warangan',
  'waras',
  'kewarasan',
  'warden',
  'warga',
  'kewargaan',
  'wargadunia',
  'wargakota',
  'warganegara',
  'kewarganegaraan',
  'warid',
  'waris',
  'warisi',
  'wariskan',
  'warisan',
  'warkah',
  'warna',
  'warnai',
  'diwarnainya',
  'mewarnainya',
  'warnakan',
  'warnanya',
  'warni',
  'pewarnaan',
  'warta',
  'wartakan',
  'diwartakannya',
  'mewartakannya',
  'pewartaan',
  'wartawan',
  'kewartawanan',
  'wartawati',
  'waru',
  'waruna',
  'warung',
  'wasak',
  'wasangka',
  'wasap',
  'mewasapkan',
  'pewasapan',
  'was',
  'wasi',
  'wasiat',
  'wasiatkan',
  'berwasiat',
  'wasiatkan',
  'wasit',
  'wasitah',
  'waskita',
  'waslah',
  'waspada',
  'kewaspadaan',
  'wassalam',
  'waswas',
  'wat',
  'watak',
  'wataknya',
  'perwatakan',
  'berperwatakan',
  'watan',
  'wataniah',
  'watas',
  'watasan',
  'mewatasi',
  'perwatasan',
  'wati',
  'watikah',
  'watt',
  'wau',
  'wawancara',
  'wawancarai',
  'wawansabda',
  'wawas',
  'berwawasan',
  'wayang',
  'mewayangkan',
  'wayar',
  'wayarles',
  'wazir',
  'web',
  'weda',
  'welahar',
  'wenang',
  'sewenang',
  'wewenang',
  'wibawa',
  'kewibawaan',
  'widuri',
  'wijaya',
  'wiku',
  'wila',
  'wiladah',
  'wilayah',
  'wilis',
  'wimana',
  'windu',
  'wineka',
  'wira',
  'keperwiraan',
  'wirawan',
  'wirawati',
  'wirid',
  'wisel',
  'wisesa',
  'wiski',
  'wisma',
  'witir',
  'wizurai',
  'woksyop',
  'writ',
  'wuduk',
  'wuduknya',
  'wujud',
  'wujudnya',
  'wujudkan',
  'kewujudan',
  'pewujudan',
  'wujudlah',
  'wuquf',
  'wushu',
  'xenolit',
  'xerofit',
  'xilem',
  'ya',
  'mengiyakan',
  'yahudi',
  'memperyahudikan',
  'yais',
  'yajuj',
  'yak',
  'yakin',
  'diyakini',
  'menyakini',
  'yakinkan',
  'keyakinan',
  'keyakinannya',
  'yakni',
  'yakut',
  'yamtuan',
  'yang',
  'yangki',
  'yasin',
  'yatim',
  'keyatiman',
  'yaum',
  'yayasan',
  'yis',
  'yoga',
  'yogia',
  'seyogia',
  'yojana',
  'yong',
  'yu',
  'yunan',
  'yunda',
  'yuran',
  'zabaniah',
  'zabarjad',
  'zabib',
  'zabur',
  'zahid',
  'zahir',
  'zahirkan',
  'penzahiran',
  'zai',
  'zaitun',
  'zakar',
  'zakat',
  'zakatkan',
  'zakiah',
  'zal',
  'zalad',
  'zalim',
  'zalimi',
  'menzalimkan',
  'kezaliman',
  'zaman',
  'zamannya',
  'zamin',
  'zamindar',
  'zamrud',
  'zamzam',
  'zanggi',
  'zapin',
  'zarah',
  'zat',
  'zatnya',
  'zati',
  'zeolit',
  'zeroks',
  'ziarah',
  'ziarahi',
  'menziarahinya',
  'ziarahan',
  'zib',
  'zigot',
  'zihar',
  'zikir',
  'zillullah',
  'zilofon',
  'zimologi',
  'zina',
  'penzinaan',
  'zindik',
  'zink',
  'zinkum',
  'zionis',
  'zip',
  'zipkan',
  'zipnya',
  'zipkan',
  'zirafah',
  'zirah',
  'zirbad',
  'zirkonium',
  'zodiak',
  'zon',
  'zonkan',
  'penzonan',
  'zoo',
  'zoologi',
  'zuhal',
  'zuhrah',
  'zuhud',
  'zuhur',
  'zulhijah',
  'zulkaedah',
  'zulmat',
  'zum',
  'zuriatb',
]